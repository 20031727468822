import { TASKS } from "@superblocksteam/shared";
import { Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as AppIllustration } from "assets/icons/home/app-illustration.svg";
import { Button } from "components/ui/Button";
import { useAppDispatch } from "store/helpers";
import { createApplicationInit } from "store/slices/homepage/slice";
import { selectUserChecklist } from "store/slices/user";
import { EntityType } from "utils/entity";
import { getColorForType, getIconForType } from "../EntityCard";
import {
  CardBody,
  CardTitleRow,
  CreateEntityIconWrapper,
  ICON_SIZE,
  StyledCard,
} from "./CommonComponents";
import { CommonCardProps } from "./common";

export const CreateApplicationCard = (props: CommonCardProps) => {
  const {
    highlight,
    setGeneratedEntityType,
    processFieldValues,
    resetFields,
    organizationId,
  } = props;

  const [isCreating, setIsCreating] = useState(false);
  const checklist = useSelector(selectUserChecklist);
  const appNotDone = !checklist.find((task) => task.id === TASKS.DEPLOY_APP)
    ?.completed;

  const dispatch = useAppDispatch();
  const createNewApplication = useCallback(
    (applicationName: string, orgId: string, folderId?: string) => {
      return dispatch(
        createApplicationInit({
          applicationName,
          orgId,
          folderId,
        }),
      );
    },
    [dispatch],
  );

  const handleCreateNew = useCallback(
    async (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      ev.stopPropagation();
      setIsCreating(true);
      const values = await processFieldValues();
      createNewApplication(values.name, organizationId, values.folder);
      resetFields();
    },
    [
      setIsCreating,
      organizationId,
      processFieldValues,
      createNewApplication,
      resetFields,
    ],
  );

  return (
    <StyledCard
      highlight={highlight}
      color={getColorForType(EntityType.APPLICATION)}
      onMouseEnter={(e) => {
        setGeneratedEntityType(EntityType.APPLICATION);
      }}
      disabled={isCreating}
      onClick={handleCreateNew}
      data-test="create-application"
    >
      <AppIllustration />
      <CardBody>
        <CreateEntityIconWrapper
          className="entity-type-icon"
          color={getColorForType(EntityType.APPLICATION)}
          size={ICON_SIZE}
        >
          {getIconForType(EntityType.APPLICATION)}
        </CreateEntityIconWrapper>

        <CardTitleRow
          entityType={EntityType.APPLICATION}
          taskNotDone={appNotDone}
        />

        <Typography.Paragraph>
          Build user interfaces through pre-built components and connect to data
        </Typography.Paragraph>
        <Button type="default" block onClick={handleCreateNew}>
          Create Application
        </Button>
      </CardBody>
    </StyledCard>
  );
};
