import { WarningTwoTone } from "@ant-design/icons";
import { RbacRole } from "@superblocksteam/shared";
import { Button, Card, Tag, Tooltip, Typography } from "antd";
import { CardProps } from "antd/lib/card";
import Title from "antd/lib/typography/Title";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { AGENTS_BASE_URL } from "legacy/constants/routes";
import { colors } from "styles/colors";

const StyledCard = (props: CardProps) => (
  <Card
    hoverable={false}
    bordered={false}
    bodyStyle={{
      ...props.bodyStyle,
      width: "270px",
      padding: 0,
      border: "1px solid #d8d8d8",
      borderRadius: "4px",
    }}
  >
    {props.children}
  </Card>
);

const CardImgWrapper = styled.div`
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntegrationIcon = styled.img`
  height: 50px;
  max-width: 50px;
  margin: 20px 0;
`;

const CardContent = styled.div`
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f5f7;
  flex-direction: column;
  text-align: center;
  h5 {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

interface IProps {
  iconLocation?: string;
  link: string;
  title: string;
  buttonText: string;
  buttonIcon: JSX.Element;
  integrationId: string | undefined;
  dataTest: string | undefined;
  isSupported?: boolean;
  role?: RbacRole;
}

export default function IntegrationCard({
  iconLocation,
  link,
  title,
  buttonText,
  buttonIcon,
  integrationId,
  dataTest,
  isSupported = true,
  role = RbacRole.CONFIGURATOR,
}: IProps): ReactElement {
  const navigate = useNavigate();
  const enabled = useMemo(() => {
    return isSupported && role === RbacRole.CONFIGURATOR;
  }, [isSupported, role]);
  const unsupportedMessage = {
    text: "Agent Upgrade Required",
    tooltip: (
      <>
        Please upgrade your agent to get support for this integration. More info
        can be found in the
        <Typography.Link
          href={AGENTS_BASE_URL}
          target="_blank"
          rel="noreferrer"
        >
          {" on-premise agents page."}
        </Typography.Link>
      </>
    ),
  };
  const contents = useMemo(() => {
    return (
      <div
        data-test={`${dataTest}-${integrationId}${enabled ? "" : "-disabled"}`}
        style={{
          cursor: "pointer",
        }}
        onClick={(event) => {
          // TODO(taha) potentially show modal with an update notice on click
          // if the plugin is unsupported
          if (enabled) {
            if (event.ctrlKey || event.metaKey) {
              window.open(link, "_blank");
            } else {
              navigate({ pathname: link });
            }
          }
        }}
      >
        <StyledCard bodyStyle={{ opacity: enabled ? 1 : 0.5 }}>
          <CardImgWrapper>
            <IntegrationIcon src={iconLocation ?? ""} />
            {role === RbacRole.BUILDER && (
              <Tag
                color="warning"
                style={{ position: "absolute", top: 8, right: 0 }}
              >
                Build only
              </Tag>
            )}
            {role === RbacRole.NONE && (
              <Tag style={{ position: "absolute", top: 8, right: 0 }}>
                No access
              </Tag>
            )}
          </CardImgWrapper>
          <CardContent>
            <Title level={5}>{title}</Title>
            {isSupported ? (
              <Button
                color={"#9BDCAD"}
                style={{
                  color: "#9BDCAD",
                  borderColor: "#d8d8d8",
                  borderRadius: "5px",
                }}
                icon={buttonIcon}
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                color={colors.ACCENT_PURPLE}
                style={{
                  borderColor: "#d8d8d8",
                  borderRadius: "5px",
                }}
                icon={<WarningTwoTone />}
              >
                {unsupportedMessage.text}
              </Button>
            )}
          </CardContent>
        </StyledCard>
      </div>
    );
  }, [
    buttonIcon,
    buttonText,
    dataTest,
    enabled,
    navigate,
    iconLocation,
    integrationId,
    isSupported,
    link,
    role,
    title,
    unsupportedMessage.text,
  ]);

  switch (true) {
    case !isSupported:
      return <Tooltip title={unsupportedMessage.tooltip}>{contents}</Tooltip>;
    case role === RbacRole.BUILDER:
      return (
        <Tooltip title="You can build with this integration but cannot configure it.">
          {contents}
        </Tooltip>
      );
    case role === RbacRole.NONE:
      return (
        <Tooltip title="You cannot build or configure this integration.">
          {contents}
        </Tooltip>
      );
    default:
      return contents;
  }
}
