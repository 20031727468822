import { Button } from "antd";
import React from "react";
import styled from "styled-components";
import { ReactComponent as InfoIcon } from "assets/icons/common/info-blue.svg";
import { ReactComponent as WarningIcon } from "assets/icons/common/warning.svg";
import { colors } from "styles/colors";

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
`;

const Title = styled.div`
  display: block;
  font-size: 12px;
  flex: 1 1 0%;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 2px;

  b {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
  }
`;
const ActionButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 4px;
  transition: all 0.2s ease-in-out;

  svg {
    height: 14px;
    width: 14px;
    color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  }

  .ant-btn-loading-icon {
    display: flex;
  }

  && .anticon-loading {
    padding-right: 0px;
    align-self: center;
    svg {
      color: ${(props) => props.theme.colors.ORANGE_600};
    }
  }
`;

type ActionShortcutProps = {
  message: string | React.ReactNode;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  level?: "warn" | "info" | "none";
  isLoading?: boolean;
  onClick: (e: React.MouseEvent) => void;
  mode?: "light" | "dark";
};

export const ActionShortcut = (props: ActionShortcutProps) => {
  return (
    <ActionWrapper>
      {props.level === "warn" && <WarningIcon />}
      {props.level === "info" && <InfoIcon />}
      <Title>{props.message}</Title>
      {props.buttonText && (
        <ActionButton
          type="ghost"
          size="small"
          loading={props.isLoading}
          icon={props.buttonIcon}
          onClick={props.onClick}
          style={{ color: props.mode === "light" ? "" : colors.WHITE }}
        >
          {props.buttonText}
        </ActionButton>
      )}
    </ActionWrapper>
  );
};
