// event type naming convention is all lowercase started with an action e.g. created application
export enum UIEvent {
  FETCHED_APPLICATION = "fetched application",
  PREVIEWED_APPLICATION = "previewed application",
  OPEN_SHARE_MODAL_DEPLOYED = "opened share modal from deployed app header",
  VIEWED_AUDIT_LOGS = "viewed audit logs",
  VIEWED_CHECKOUT_SCREEN = "viewed checkout screen",
  DISMISSED_PRICING_MODAL_PROMPTED = "dismissed prompted pricing modal",
  VIEWED_PRICING_MODAL = "viewed pricing modal",
  LIST_PROFILES_IS_EMPTY = "list of profiles is empty",
  VIEWED_DEMO_VIDEO = "viewed demo video",
  COMPLETE_CHECKLIST = "completed checklist task",
  CREATED_COMPONENT = "created component",
  ADDED_EVENT_HANDLER = "added event handler",
  ADDED_DATA_BINDING = "added data binding",
  TESTED_INTEGRATION = "tested integration",
  LOGGED_IN = "logged in",
  SIGNED_UP = "signed up",
  SESSION_START = "session start",
}
