import { Space, Switch } from "antd";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { DynamicFormItemProps } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormTooltip } from "../FormTooltip";

const DynamicFormSwitch = ({
  path,
  label,
  tooltip,
  renderIconFirst,
}: DynamicFormItemProps) => {
  const context = useContext(FormContext);
  const changeHandler = useCallback(
    (checked: boolean) => {
      context.onChange(path, checked);
    },
    [context, path],
  );
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) =>
      setValue(value as boolean),
    );

    return () => unsubscribe();
  }, [path, context]);

  const switchIcon: JSX.Element = (
    <Switch id={path} checked={value} onChange={changeHandler} size="small" />
  );
  const switchText: JSX.Element = (
    <div>
      <FormLabel>{label}</FormLabel>
      <FormTooltip tooltip={tooltip} />
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <label style={{ display: "flex", alignItems: "center" }}>
        <Space direction="horizontal">
          {renderIconFirst ? (
            <>
              {switchIcon}
              {switchText}
            </>
          ) : (
            <>
              {switchText}
              {switchIcon}
            </>
          )}
        </Space>
      </label>
    </div>
  );
};

export default DynamicFormSwitch;
