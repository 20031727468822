import {
  AccessMode,
  GroupType,
  SUPERBLOCKS_ORG_NAME,
} from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { User } from "legacy/constants/userConstants";
import { selectOnlyOrganization } from "store/slices/organizations/selectors";
import type { AppState } from "store/types";

export const getCurrentUser = (state: AppState): User | undefined =>
  state.legacy.ui.users.currentUser;
export const getAccessMode = (state: AppState): AccessMode | undefined =>
  state.legacy.ui.users.accessMode;
export const getIsSuperUser = (state: AppState): boolean =>
  Boolean(
    state.legacy.ui.users.currentUser?.groups.some(
      (group) => group.type === GroupType.SUPER_USER,
    ),
  );

export const getCurrentUserIsAdmin = (state: AppState): boolean =>
  Boolean(state.legacy.ui.users.currentUser?.isAdmin);

export const getIsOrgSwitched = createSelector(
  getIsSuperUser,
  selectOnlyOrganization,
  (isSuperUser, currentOrg) =>
    isSuperUser && currentOrg?.name !== SUPERBLOCKS_ORG_NAME,
);
