import {
  AgentType,
  FormSection,
  FormTemplate,
  GOOGLE_SHEETS_PLUGIN_ID,
  IntegrationAuthType,
  IntegrationKind,
  Organization,
  Profile,
  isFormItem,
} from "@superblocksteam/shared";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { produce } from "immer";
import { filter, isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { DataTreeDef } from "autocomplete/dataTreeTypeDefCreator";
import { Layout } from "components/app";
import { AutocompleteConfiguration } from "components/app/CodeEditor/EditorConfig";
import { CodeEditorHintStyles } from "components/app/CodeEditor/editorStyles";
import { makeApplicableInputsDynamic } from "components/app/DynamicForm/resolvers/workflow";
import { FormRef } from "components/app/SBDynamicForm";
import TemplatedDynamicForm from "components/app/SBDynamicForm/TemplatedDynamicForm";
import { InitialValueFn } from "components/app/SBDynamicForm/utils";
import { FullWidthSpace } from "components/ui/Space";
import envs from "env";
import { useSaga } from "hooks/store";
import { resetDynamicFormState } from "legacy/actions/dynamicFormActions";
import { ReactComponent as EditIcon } from "legacy/assets/images/EditPen.svg";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { DOCS_PAGE_URL, DocsPage } from "legacy/constants/routes";
import { getDynamicFormState } from "legacy/selectors/dynamicFormSelectors";
import WarningsContainer from "pages/Agents/WarningsContainer";
import { useAppDispatch } from "store/helpers";
import { ApiDto, fetchApiSaga } from "store/slices/apis";
import { getSecretMetadataSaga } from "store/slices/datasources";
import { fastClone } from "utils/clone";

import ConfigurationNav from "./ConfigurationNav";
import { ConfigurationProfilesModal } from "./ConfigurationProfilesModal";
import WorkflowForm from "./WorkflowForm";
import { ConfigMeta } from "./utils";

const OAUTH2_AUTH = [
  IntegrationAuthType.OAUTH2_CLIENT_CREDS,
  IntegrationAuthType.OAUTH2_CODE,
  IntegrationAuthType.OAUTH2_IMPLICIT,
  IntegrationAuthType.OAUTH2_PASSWORD,
];

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 12px;
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 24px;
  font-size: ${(props) => props.theme.text.sizes.default};
  background-color: ${LegacyNamedColors.WHITE};
`;

const LayoutWrapper = styled.div`
  border: 1px solid ${LegacyNamedColors.GRAY_LIGHT};
  margin-top: 20px;

  .ant-alert-warning {
    background-color: ${(props) => props.theme.colors.ACCENT_ORANGE}14;
    border-color: ${(props) => props.theme.colors.ACCENT_ORANGE}80;
  }
`;

// margin-left: -20px;
const StyledCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0px;
    padding-right: 0px;
  }
  & > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const ProfilesSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .profiles-select-title {
    display: block;
    flex-direction: row;
    gap: 10px;

    div:first-child {
      margin-right: 10px;
    }

    & > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .edit-icon-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    line-height: 14px;
    svg {
      color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    }
    svg:hover {
      color: ${(props) => props.theme.colors.ACCENT_BLUE_700};
    }
  }

  .edit-icon-wrapper:hover {
    svg {
      color: ${(props) => props.theme.colors.ACCENT_BLUE_700};
    }
  }

  .edit-link-wrapper {
    display: inline-block;
    color: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.colors.ACCENT_BLUE_700};
    }
  }
`;

interface Props {
  alerts: any;
  activeConfigurationId: string | undefined;
  isNew?: boolean;
  loading: boolean;
  loadingInitialValues: boolean;
  formTemplate: FormTemplate;
  initialValues: any;
  hasTest?: boolean;
  onRunTest: (values: any, configMeta: ConfigMeta) => void;
  onClearCache: (configMeta: ConfigMeta) => void;
  pluginId: string;
  readOnly: boolean;
  datasourceId: string;
  datasourceName: string;
  organizationId: string;
  setCurrentConfigurationId: (profileId: string) => void;
  organization: Organization;
  setConfigIdToFormData: (configIdToFormData: any) => void;
  configIdToFormData: Record<string, any>;
  profile: Profile;
  profiles: Profile[];
  configMeta: ConfigMeta;
  configMetas: ConfigMeta[];
  setConfigIdToConfigMeta: (
    value:
      | Record<string, ConfigMeta>
      | ((configIdToConfigMeta: Record<string, ConfigMeta>) => void),
  ) => void;
  // this function returns the initial values for the form based on plugin template, and the resolved template based on organization's plugin version
  initialValuesFn: InitialValueFn;
  setIsDirty: (isDirty: boolean) => void;
  configIdToFormRefs: React.MutableRefObject<
    Record<string, React.RefObject<FormRef>>
  >;
  kind: IntegrationKind;
  clearingCache: boolean;
}

const IntegrationSetupForm = ({
  alerts,

  // Currently active configuration's ID
  activeConfigurationId,

  isNew,
  loading,
  loadingInitialValues,
  formTemplate,
  initialValues,
  onRunTest,
  hasTest,
  pluginId,
  readOnly,
  datasourceId,
  setCurrentConfigurationId,
  organization,
  setConfigIdToFormData,
  configIdToFormData,
  profile,
  profiles,
  configMeta,
  configMetas,
  setConfigIdToConfigMeta,
  initialValuesFn,
  setIsDirty,
  configIdToFormRefs,
  kind,
  onClearCache,
  clearingCache,
}: Props) => {
  const formData = useMemo(() => {
    return configIdToFormData[configMeta.id];
    // NOTE: We use the JSON.stringify() here to avoid rerendering all forms
    // when any specific form changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(configIdToFormData[configMeta.id])]);

  const setFormData = useCallback(
    (values: any, doNotSetDirty?: boolean) => {
      setIsClearCacheEnabled(values.cache);
      setConfigIdToFormData(
        produce((draft: any) => {
          draft[configMeta.id] = values;
        }),
      );
      if (!doNotSetDirty) {
        setIsDirty(true);
      }
    },
    [setConfigIdToFormData, setIsDirty, configMeta.id],
  );

  const [isClearCacheEnabled, setIsClearCacheEnabled] = useState(
    formData.cache,
  );

  const [hasSetVal, setHasSetVal] = useState(false);
  // Note: We need this useEffect to make page refreshes work properly. Without
  // this, the form will get cleared out on page refreshes.
  useEffect(() => {
    if (!hasSetVal && !loadingInitialValues) {
      setHasSetVal(true);
      setFormData(initialValues, true);
    }
  }, [initialValues, loadingInitialValues, hasSetVal, setFormData]);

  const alert = useMemo(() => {
    return alerts ? alerts[configMeta.id] : undefined;
  }, [alerts, configMeta.id]);

  const dynamicFormState = useSelector(getDynamicFormState);

  const formRef = useRef<FormRef>(null);
  configIdToFormRefs.current[configMeta.id] = formRef;

  const workflowId = formData["dynamicWorkflowConfiguration.workflowId"];
  const workflowEntity = useSelector<any, ApiDto | undefined>(
    (state) => state.apis.entities[workflowId],
  );

  const hasDynamicConfiguration =
    formData["dynamicWorkflowConfiguration.enabled"] ?? false;

  const [fetchApi] = useSaga(fetchApiSaga);

  useEffect(() => {
    if (hasDynamicConfiguration && workflowEntity?.id !== workflowId) {
      fetchApi({
        apiId: workflowId,
        environment: profile.key,
      });
    }
  }, [
    profile.key,
    hasDynamicConfiguration,
    fetchApi,
    workflowId,
    workflowEntity,
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetDynamicFormState());
    };
  }, [dispatch]);

  const [visiblePasswordFields, setVisiblePasswordFields] = useState({});

  const onPasswordVisibilityChange = useCallback(
    (fieldName: string, visible: boolean) => {
      setVisiblePasswordFields((prev) => ({
        ...prev,
        [fieldName]: visible,
      }));
    },
    [],
  );

  const augmentedFormTemplate = useMemo(() => {
    let tempFormTemplate = formTemplate;
    tempFormTemplate = makeApplicableInputsDynamic({
      formTemplate,
      dynamicPasswordFields: visiblePasswordFields,
      showAllPasswordFieldsAsDynamic:
        kind !== IntegrationKind.SECRET && hasDynamicConfiguration,
    });

    const augmentedSections: FormSection[] = [];
    for (const section of tempFormTemplate.sections) {
      const augSection = fastClone(section);
      augSection.items = filter(augSection.items, (sectionItem) => {
        const isRow = !isFormItem(sectionItem);
        if (isRow) {
          sectionItem.rowItems = filter(
            sectionItem.rowItems,
            (item) => item.name !== "name",
          );
          return sectionItem.rowItems.length > 0;
        }
        return sectionItem.name !== "name";
      });
      augmentedSections.push(augSection);
    }

    return { sections: augmentedSections };
  }, [formTemplate, hasDynamicConfiguration, visiblePasswordFields, kind]);

  const additionalDefs = useMemo<DataTreeDef>(() => {
    if (OAUTH2_AUTH.includes(formData.authType)) {
      return {
        oauth: {
          token: "string",
        },
      };
    }

    if (IntegrationAuthType.FIREBASE === formData.authType) {
      return {
        firebase: {
          token: "string",
          userId: "string",
        },
      };
    }
    return {};
  }, [formData.authType]);

  const autocompleteConfiguration = useMemo<AutocompleteConfiguration>(
    () => ({
      global: false,
      env: true,
      additionalDefs,
    }),
    [additionalDefs],
  );

  const handleSetDynamicConfigurationEnabled = useCallback(
    (e: CheckboxChangeEvent) => {
      setFormData({
        ...formData,
        "dynamicWorkflowConfiguration.enabled": e.target?.checked ?? false,
      });
    },
    [formData, setFormData],
  );

  const handleSetDynamicConfigurationWorkflowId = useCallback(
    (value: string) => {
      setFormData({
        ...formData,
        "dynamicWorkflowConfiguration.workflowId": value,
      });
    },
    [formData, setFormData],
  );

  const showTestButton = useMemo(() => {
    const isGSheetsCreationFlow = isNew && pluginId === GOOGLE_SHEETS_PLUGIN_ID;
    return hasTest && !isGSheetsCreationFlow;
  }, [hasTest, isNew, pluginId]);

  const handleRunTest = useCallback(() => {
    if (formRef.current?.validate()) {
      onRunTest(formData, configMeta);
    }
  }, [onRunTest, formData, configMeta]);

  const handleClearCache = useCallback(() => {
    onClearCache(configMeta);
  }, [onClearCache, configMeta]);

  const getValue = useCallback(
    (path: string): string => {
      return formData[path];
    },
    [formData],
  );

  const [isProfilesModalVisible, setIsProfilesModalVisible] = useState(false);
  const showProfilesModal = useCallback(() => {
    setIsProfilesModalVisible(true);
  }, [setIsProfilesModalVisible]);

  const [getSecretMetadata] = useSaga(getSecretMetadataSaga);
  useEffect(() => {
    if (kind === IntegrationKind.SECRET) return;
    const configMetaProfile = configMeta.profileIds[0];
    const environment = profiles.find(
      (profile) => profile.id === configMetaProfile,
    )?.key;
    if (environment) {
      getSecretMetadata({
        environment,
      });
    }
  }, [profiles, configMeta, getSecretMetadata, kind]);

  if (activeConfigurationId !== configMeta.id) {
    return null;
  }

  const showClearCacheButton = kind === IntegrationKind.SECRET && !isNew;

  return (
    <div>
      <LayoutWrapper className="in-tab-wrapper">
        <Layout
          Sider={
            <ConfigurationNav
              currentConfigMeta={configMeta}
              setCurrentConfigurationId={setCurrentConfigurationId}
              profiles={profiles}
              configMetas={configMetas}
              configIdToFormData={configIdToFormData}
              setConfigIdToFormData={setConfigIdToFormData}
              setConfigIdToConfigMeta={setConfigIdToConfigMeta}
              initialValuesFn={initialValuesFn}
              setIsDirty={setIsDirty}
            />
          }
        >
          <FormWrapper>
            {loadingInitialValues ? (
              <Skeleton active />
            ) : (
              <>
                <CodeEditorHintStyles />
                <FullWidthSpace direction="vertical" size={20}>
                  <ProfilesSelectWrapper>
                    {!isEmpty(configMeta.profileIds) ? (
                      <div
                        className="profiles-select-title"
                        data-test="profiles-select-title"
                      >
                        <div>This configuration is used for:</div>
                        {profiles
                          .filter((profile) =>
                            configMeta.profileIds.includes(profile.id),
                          )
                          .map((profile) => (
                            <Tag key={profile.id}>{profile.displayName}</Tag>
                          ))}
                        {!configMeta.isDefault && (
                          <div
                            className="edit-icon-wrapper"
                            onClick={showProfilesModal}
                          >
                            <EditIcon data-test="edit-selected-profiles" />
                          </div>
                        )}
                      </div>
                    ) : configMeta.isDefault ? (
                      <div
                        className="profiles-select-title"
                        data-test="profiles-select-title-empty"
                      >
                        {`This configuration isn't being used by any profiles. It will be used for newly created profiles.`}
                      </div>
                    ) : (
                      <Alert
                        type="warning"
                        message={
                          <div
                            className="profiles-select-title"
                            data-test="profiles-select-title-empty"
                          >
                            {!isEmpty(configMeta.originalProfileIds) ? (
                              <>
                                {`This configuration was used for `}
                                <strong>
                                  {profiles
                                    .filter((profile) =>
                                      configMeta.originalProfileIds.includes(
                                        profile.id,
                                      ),
                                    )
                                    .map((profile) => profile.displayName)
                                    .join(", ")}
                                </strong>
                                {`, but is no longer used for any profiles. It
                                will be `}
                                <strong>removed</strong> {` on save.`}
                              </>
                            ) : (
                              `This configuration isn't being used by any profiles. It will be removed on save.`
                            )}
                            <span>&nbsp;</span>
                            {!configMeta.isDefault && (
                              <span
                                className="edit-link-wrapper"
                                onClick={showProfilesModal}
                              >
                                Edit Profiles
                              </span>
                            )}
                          </div>
                        }
                      />
                    )}
                    <ConfigurationProfilesModal
                      isNew={false}
                      profiles={profiles}
                      configMetaToClone={configMeta}
                      configMeta={configMeta}
                      configIdToFormData={configIdToFormData}
                      setConfigIdToFormData={setConfigIdToFormData}
                      setConfigIdToConfigMeta={setConfigIdToConfigMeta}
                      configMetas={configMetas}
                      isModalVisible={isProfilesModalVisible}
                      setIsModalVisible={setIsProfilesModalVisible}
                      initialValuesFn={initialValuesFn}
                    />
                  </ProfilesSelectWrapper>

                  {kind === IntegrationKind.PLUGIN && (
                    <Space direction="horizontal">
                      <Checkbox
                        data-test="fetch-dynamically"
                        checked={hasDynamicConfiguration}
                        onChange={handleSetDynamicConfigurationEnabled}
                      >
                        Fetch credentials dynamically
                      </Checkbox>
                    </Space>
                  )}
                  {hasDynamicConfiguration && (
                    <>
                      <Typography.Text strong>
                        1. Choose Superblocks Workflow
                      </Typography.Text>
                      <WorkflowForm
                        environment={profile.key}
                        workflowId={
                          formData["dynamicWorkflowConfiguration.workflowId"] ??
                          ""
                        }
                        onChange={handleSetDynamicConfigurationWorkflowId}
                      />
                    </>
                  )}
                  <TemplatedDynamicForm
                    autocompleteConfiguration={autocompleteConfiguration}
                    ref={formRef}
                    onChange={setFormData}
                    values={formData}
                    getValue={getValue}
                    formTemplate={augmentedFormTemplate}
                    showAdvancedSections={false}
                    pluginId={pluginId}
                    datasourceId={datasourceId}
                    configurationId={activeConfigurationId}
                    isNew={isNew}
                    isResizable={false}
                    readOnly={readOnly}
                    onPasswordVisibilityChange={onPasswordVisibilityChange}
                  >
                    <WarningsContainer
                      errors={dynamicFormState.dynamicFormErrors}
                      closable={false}
                    />
                    {(showTestButton || showClearCacheButton) && (
                      <ButtonContainer style={{ justifyContent: "flex-start" }}>
                        {showTestButton && (
                          <Button
                            data-test="run-test-button"
                            loading={loading}
                            type="default"
                            size={"middle"}
                            onClick={handleRunTest}
                            style={{ marginRight: "12px" }}
                          >
                            Test Connection
                          </Button>
                        )}
                        {showClearCacheButton && (
                          <Button
                            onClick={handleClearCache}
                            loading={clearingCache}
                            disabled={!isClearCacheEnabled}
                          >
                            Clear Cache
                          </Button>
                        )}
                      </ButtonContainer>
                    )}

                    {alert && (
                      <ButtonContainer>
                        <Alert
                          data-test="integration-alert"
                          showIcon
                          message={alert.message}
                          type={alert.type}
                          style={{ width: "100%" }}
                        />
                      </ButtonContainer>
                    )}
                    {organization.agentType === AgentType.ONPREMISE ? (
                      <Alert
                        message={
                          <Typography.Text>
                            Remember to add your on-premise agent IP address to
                            your allow-list.
                          </Typography.Text>
                        }
                        type="info"
                      />
                    ) : (
                      <StyledCollapse ghost>
                        <Collapse.Panel
                          header={
                            <Typography.Text
                              style={{
                                color: LegacyNamedColors.GRAY_DARK,
                              }}
                            >
                              Expand to see the IP addresses to add to your
                              allow-list. Detailed instructions can be{" "}
                              <Typography.Link
                                href={DOCS_PAGE_URL(
                                  DocsPage.WHITELIST_DOC_LINK,
                                )}
                                target="_blank"
                              >
                                found here
                              </Typography.Link>
                              .
                            </Typography.Text>
                          }
                          key={1}
                        >
                          <Typography.Paragraph>
                            <pre style={{ fontSize: "85%" }}>
                              <Typography.Text code copyable={true}>
                                {JSON.stringify(
                                  envs
                                    .get("SUPERBLOCKS_UI_WHITELIST_IPS")
                                    .split(", "),
                                  null,
                                  2,
                                )}
                              </Typography.Text>
                            </pre>
                          </Typography.Paragraph>
                        </Collapse.Panel>
                      </StyledCollapse>
                    )}
                  </TemplatedDynamicForm>
                </FullWidthSpace>
              </>
            )}
          </FormWrapper>
        </Layout>
      </LayoutWrapper>
    </div>
  );
};

export default IntegrationSetupForm;
