import { isGroupDeletable } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, { RecColumn } from "components/ui/RecommendedTable";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { GROUP_PAGE_URL } from "pages/routes";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { styleAsClass } from "styles/styleAsClass";
import { sendSuccessUINotification } from "utils/notification";
import { deleteGroup } from "./client";
import { GroupToRender } from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<GroupToRender>;

const GroupList = ({
  groups,
  setGroups,
  loading,
  totalCount,
}: {
  groups: GroupToRender[];
  setGroups: React.Dispatch<React.SetStateAction<GroupToRender[]>>;
  loading: boolean;
  totalCount: number;
}) => {
  const [groupToModify, setGroupToModify] = useState<GroupToRender | undefined>(
    undefined,
  );

  const currentUser = useSelector(getCurrentUser);

  const removeTooltip = useCallback(
    (group: GroupToRender) => {
      if (!isGroupDeletable(group.type) || !currentUser?.isAdmin) {
        return `Cannot delete ${group.name} group`;
      }
    },
    [currentUser?.isAdmin],
  );

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div data-test={`group-item-${value}`} className={NameStyle}>
            {value}
          </div>
        ),
      },
      {
        Header: "Members",
        accessor: "memberCount",
      },
    ],
    [],
  );

  const menuItems = useCallback(
    (group: GroupToRender) => {
      const tooltip = removeTooltip(group);
      return [
        {
          key: "1",
          label: "Copy group ID",
          onClick: () => {
            setGroupToModify(group);
            navigator.clipboard.writeText(group.id);
          },
          "data-test": "copy-group-id-button",
        },
        {
          key: "2",
          label: (
            <Tooltip title={removeTooltip(group)} placement="left">
              <div>Remove group</div>
            </Tooltip>
          ),
          onClick: () => {
            setGroupToModify(group);
            setDeleteModalOpen(true);
          },
          "data-test": "delete-group-button",
          disabled: Boolean(tooltip),
        },
      ];
    },
    [removeTooltip],
  );

  const navigate = useNavigate();
  const onRowClick = useCallback(
    (group: GroupToRender) => {
      navigate({ pathname: GROUP_PAGE_URL(group.id, "users") });
    },
    [navigate],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const orgId = useSelector(selectOnlyOrganizationId);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (!groupToModify || !orgId) {
      return;
    }
    setIsDeleting(true);
    try {
      const { error } = await deleteGroup(orgId, groupToModify.id);
      if (error) {
        setDeleteError(error ?? "Failed to delete group");
      } else {
        setGroups((groups) => groups.filter((g) => g.id !== groupToModify.id));
        setGroupToModify(undefined);
        setDeleteModalOpen(false);
        sendSuccessUINotification({
          message: `Deleted "${groupToModify.name}" group`,
        });
      }
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to delete group");
    }
    setIsDeleting(false);
  }, [groupToModify, orgId, setGroups]);

  const onCancel = useCallback(() => {
    setDeleteModalOpen(false);
    setGroupToModify(undefined);
  }, []);

  return (
    <>
      <RecommendedTable<GroupToRender>
        data={groups}
        dataLabel="groups"
        uniqueKey="name"
        columns={columns}
        actionMenuItems={menuItems}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={totalCount > 10 ? { pageSize: 10 } : undefined}
        loading={loading}
        onRowClick={onRowClick}
      />
      <DeleteModal
        open={deleteModalOpen}
        title={"Are you sure you want to delete this group"}
        onCancel={onCancel}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="group"
        confirmText="Remove"
      >
        Group <b>{groupToModify?.name}</b> will be removed from your
        organization.
      </DeleteModal>
    </>
  );
};

export default GroupList;
