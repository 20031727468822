import { ApiKeyScope, OrganizationTokenDto } from "@superblocksteam/shared";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CSSProperties } from "styled-components";
import { DeleteModal } from "components/ui/Modal";
import RecommendedTable, {
  RecColumn,
  TagStyle,
} from "components/ui/RecommendedTable";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { HttpMethod, callServer } from "store/utils/client";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import UpdateTokenModal from "./UpdateTokenModal";
import { AccessToken, convertScopeToType, formatDate } from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<AccessToken>;

const renderTime = ({ value }: { value: Date }) =>
  value ? (
    <div>{formatDate(value)}</div>
  ) : (
    <div style={{ color: colors.GREY_200 }}>–</div>
  );

const renderType = ({ value }: { value: ApiKeyScope }) => {
  let style: CSSProperties = {
    backgroundColor: colors.GREY_200,
  };
  if (value === ApiKeyScope.ORG_SESSION_MANAGEMENT) {
    style = {
      ...style,
      color: colors.ACCENT_BLUE_500,
      backgroundColor: colors.ACCENT_BLUE_500_25,
      borderColor: colors.ACCENT_BLUE_500_18,
    };
  } else {
    style = {
      ...style,
      color: colors.ACCENT_PURPLE,
      backgroundColor: `${colors.ACCENT_PURPLE}14`,
      borderColor: `${colors.ACCENT_PURPLE}2E`,
    };
  }
  return (
    <div className={TagStyle} style={style}>
      {convertScopeToType(value)}
    </div>
  );
};

const TokenList = ({
  tokens,
  setTokens,
  loading,
  totalCount,
}: {
  tokens: AccessToken[];
  setTokens: React.Dispatch<React.SetStateAction<AccessToken[]>>;
  loading: boolean;
  totalCount: number;
}) => {
  const [tokenToModify, setTokenToModify] = useState<AccessToken | undefined>(
    undefined,
  );
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div className={NameStyle}>{value}</div>,
      },
      {
        Header: "Token type",
        accessor: "scope",
        Cell: renderType,
      },
      {
        Header: "Created by",
        accessor: "creator",
        Cell: ({ value }) => <div>{value?.name}</div>,
      },
      {
        Header: "Last used",
        accessor: "lastUsed",
        Cell: renderTime,
      },
      {
        Header: "Expires",
        accessor: "expiresAt",
        Cell: renderTime,
      },
    ],
    [],
  );

  const menuItems = useCallback(
    (token: AccessToken) => [
      {
        key: "1",
        label: "Edit token",
        onClick: () => {
          setTokenToModify(token);
          setIsUpdateModalOpen(true);
        },
        "data-test": "edit-token-button",
      },
      {
        key: "2",
        label: "Delete token",
        onClick: () => {
          setTokenToModify(token);
          setDeleteModalOpen(true);
        },
        "data-test": "delete-token-button",
      },
    ],
    [setTokenToModify, setIsUpdateModalOpen],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const orgId = useSelector(selectOnlyOrganizationId);

  const [deleteError, setDeleteError] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (!tokenToModify) {
      return;
    }
    setIsDeleting(true);
    try {
      await callServer<OrganizationTokenDto>({
        url: `/v1/organizations/${orgId}/tokens/${tokenToModify.id}`,
        method: HttpMethod.Delete,
      });
      setTokens(tokens.filter((t) => t.id !== tokenToModify.id));
      setTokenToModify(undefined);
      setDeleteModalOpen(false);
    } catch (e: any) {
      setDeleteError(e?.message ?? "Failed to delete token");
    }
    setIsDeleting(false);
  }, [orgId, setTokens, tokenToModify, tokens]);

  return (
    <>
      <RecommendedTable<AccessToken>
        data={tokens}
        dataLabel="access tokens"
        uniqueKey="id"
        columns={columns}
        actionMenuItems={menuItems}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={totalCount > 10 ? { pageSize: 10 } : undefined}
        loading={loading}
      />
      {tokenToModify && (
        <UpdateTokenModal
          isModalOpen={isUpdateModalOpen}
          setIsModalOpen={setIsUpdateModalOpen}
          tokenToModify={tokenToModify}
          setTokens={setTokens}
        />
      )}
      <DeleteModal
        open={deleteModalOpen}
        title={"Are you sure you want to delete this token?"}
        onCancel={() => setDeleteModalOpen(false)}
        onDelete={onDelete}
        error={deleteError}
        isDeleting={isDeleting}
        dataTestName="access-token"
      >
        This action cannot be undone. Any services or applications using the{" "}
        <b>{tokenToModify?.name}</b> token will not longer be able to access
        your data via the Superblocks API.
      </DeleteModal>
    </>
  );
};

export default TokenList;
