import {
  default as AntdButton,
  ButtonProps as AntButtonProps,
} from "antd/lib/button";
import React, { forwardRef, memo } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/colors";

interface StyledButtonProps {
  $width?: string;
  $bordered?: boolean;
}

const StyledButton = styled(AntdButton)<StyledButtonProps>`
  color: ${(props) => props.color};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ $bordered }) => (!$bordered ? "border: 0px;" : "")};
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    cursor: default;
  }
`;

interface ButtonProps extends AntButtonProps {
  width?: string;
  bordered?: boolean;
}

export const Button = memo(
  forwardRef<HTMLElement, ButtonProps>(
    ({ bordered = true, width, children, ...buttonProps }, ref) => {
      return (
        <StyledButton
          $bordered={bordered}
          $width={width}
          {...buttonProps}
          ref={ref}
        >
          {children}
        </StyledButton>
      );
    },
  ),
);

Button.displayName = "Button";
// https://github.com/ant-design/ant-design/issues/9581
// Some antd functionality breaks (e.g. tooltips) with
// wrapped buttons unless this is set
(Button as any).__ANT_BUTTON = true;

const StyledLink = styled.span`
  color: ${colors.ACCENT_BLUE_500};
  cursor: pointer;
`;

export function LinkButton(props: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <StyledLink {...props} role="link" tabIndex={0}>
      {props.children}
    </StyledLink>
  );
}

const ButtonCss = css`
  border-radius: 4px;
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
`;

export const PrimaryButton = styled(AntdButton)`
  ${ButtonCss}
  background: ${(props) => props.theme.colors.ACCENT_BLUE_500};
  color: white;
  border: none;
  cursor: pointer;

  &:focus:not(:hover) {
    /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
    background: ${(props) => props.theme.colors.ACCENT_BLUE_500};
    color: white;
  }

  &:hover,
  &:focus:not(:hover):focus-visible {
    // when hover or keyboard navigte using tab
    background: ${(props) => props.theme.colors.ACCENT_BLUE_600};
    color: white;
  }
  &:active {
    background: ${(props) => props.theme.colors.ACCENT_BLUE_700};
    color: white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.GREY_300};
    background: ${(props) => props.theme.colors.GREY_50};
    border-color: ${(props) => props.theme.colors.GREY_50};
  }
`;

export const SecondaryButton = styled(AntdButton)`
  ${ButtonCss}
  border-color: ${(props) => props.theme.colors.GREY_100};
  color: ${(props) => props.theme.colors.GREY_700};
  background: white;

  &:focus:not(:hover) {
    /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
    border-color: ${(props) => props.theme.colors.GREY_100};
    color: ${(props) => props.theme.colors.GREY_700};
    background: white;
  }

  &:hover,
  &:focus:not(:hover):focus-visible {
    border-color: ${(props) => props.theme.colors.GREY_100};
    background: ${(props) => props.theme.colors.GREY_25};
    color: ${(props) => props.theme.colors.GREY_700};
  }
  &:active {
    border-color: ${(props) => props.theme.colors.GREY_100};
    background: ${(props) => props.theme.colors.GREY_50};
    color: ${(props) => props.theme.colors.GREY_700};
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.GREY_300};
    background: ${(props) => props.theme.colors.GREY_50};
    border-color: ${(props) => props.theme.colors.GREY_50};
  }
`;

export const DangerButton = styled(AntdButton)`
  ${ButtonCss}
  background: ${(props) => props.theme.colors.DANGER};
  color: white;
  border: none;
  cursor: pointer;

  &:focus:not(:hover) {
    /* because antd v4 button keeps focused state after click, which is unwanted in most cases. do not need this if upgraded to antd button v5 */
    background: ${(props) => props.theme.colors.DANGER};
    color: white;
  }

  &:hover,
  &:focus:not(:hover):focus-visible {
    background: ${(props) => props.theme.colors.RED_600};
    color: white;
  }
  &:active {
    background: ${(props) => props.theme.colors.RED_700};
    color: white;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.GREY_300};
    background: ${(props) => props.theme.colors.GREY_50};
    border-color: ${(props) => props.theme.colors.GREY_50};
  }
`;
