import { ApplicationScope, getNextEntityName } from "@superblocksteam/shared";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import {
  createEvent,
  deleteCustomEvent,
  duplicateCustomEvent,
  editEventPropertyPane,
  setCreatingCustomEvent,
  updateCustomEvent,
} from "legacy/actions/customEventActions";
import { updatePartialLayout } from "legacy/actions/pageActions";
import {
  deleteEntityFromWidgets,
  selectWidgets,
} from "legacy/actions/widgetActions";
import { EventDefinition, EventMap } from "legacy/constants/EventConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import {
  getAllEntityNames,
  getEmittedEmbedEvents,
  getMainContainer,
  getTriggerableEmbedEvents,
  getWidgets,
  selectEventById,
} from "legacy/selectors/sagaSelectors";
import { deleteEntityFromTimers } from "store/slices/application/timers/timerActions";
import { fastClone } from "utils/clone";
import logger from "utils/logger";

function* handleEventError<T>(
  actionType: string,
  action: ReduxAction<T>,
  error: any,
) {
  logger.error(
    `CUSTOM_EVENT_OPERATION_ERROR action type: ${actionType} error: ${
      (error as any)?.message
    }, payload: ${JSON.stringify(action.payload)}`,
  );
  yield put({
    type: ReduxActionErrorTypes.CUSTOM_EVENT_OPERATION_ERROR,
    payload: {
      action: actionType,
      error,
    },
  });
}

function* createEventSaga(
  action: ReturnType<typeof createEvent>,
): Generator<any, any, any> {
  try {
    yield put(setCreatingCustomEvent(true));

    const id = action.payload.id ?? uuidv4();

    let name = action.payload.name;
    if (!name) {
      const entityNames = yield select(getAllEntityNames);
      name = getNextEntityName("Event", [...entityNames]);
    }
    if (!name) {
      // Ensure that names are unique.
      throw new Error("Failed to generate name");
    }

    const stateWidgets = yield select(getWidgets);

    const mainContainerWidget = { ...stateWidgets[PAGE_WIDGET_ID] };

    if (!mainContainerWidget.events) {
      mainContainerWidget.events = {
        eventMap: {},
      };
    }

    const newEvent: EventDefinition = {
      id: id,
      name: name,
      arguments: [],
      onTrigger: [],
    };

    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        events: {
          ...mainContainerWidget.events,
          eventMap: {
            ...mainContainerWidget.events?.eventMap,
            [newEvent.id]: newEvent,
          },
        },
      },
    };

    yield put(updatePartialLayout(updatedWidgetsPartial));

    if (action.payload.open) {
      // TODO(APP_SCOPE): Update this to use the correct scope
      yield put(editEventPropertyPane(newEvent.id, ApplicationScope.PAGE));
      yield put(selectWidgets([]));
    }
  } catch (error) {
    handleEventError(action.type, action, error);
  } finally {
    yield put(setCreatingCustomEvent(false));
  }
}

function* duplicateEventSaga(
  action: ReturnType<typeof duplicateCustomEvent>,
): Generator<any, any, any> {
  try {
    const event: EventDefinition = yield select(
      selectEventById,
      action.payload.eventId,
    );
    if (!event) {
      throw new Error("Event not found");
    }

    const entityNames = yield select(getAllEntityNames);
    const name = getNextEntityName(`${event.name}_copy`, [...entityNames]);

    const newEvent: EventDefinition = {
      ...event,
      id: uuidv4(),
      name,
    };

    const stateWidgets = yield select(getWidgets);
    const mainContainerWidget = { ...stateWidgets[PAGE_WIDGET_ID] };

    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        events: {
          ...mainContainerWidget.events,
          eventMap: {
            ...(mainContainerWidget.events?.eventMap ?? {}),
            [newEvent.id]: newEvent,
          },
        },
      },
    };

    yield put(updatePartialLayout(updatedWidgetsPartial));
    // TODO(APP_SCOPE): Update this to use the correct scope
    yield put(editEventPropertyPane(newEvent.id, ApplicationScope.PAGE));
  } catch (error) {
    handleEventError(action.type, action, error);
  }
}

function* updateEventSaga(
  action: ReturnType<typeof updateCustomEvent>,
): Generator<any, any, any> {
  try {
    const updates = action.payload;
    const mainContainerWidget: ReturnType<typeof getMainContainer> =
      yield select(getMainContainer);

    // No events, just return
    if (!mainContainerWidget?.events) return;

    const newEventMap: EventMap = fastClone(
      mainContainerWidget.events.eventMap,
    );

    Object.entries(updates).forEach(([eventId, update]) => {
      newEventMap[eventId] = {
        ...newEventMap[eventId],
        ...update,
      };
    });

    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        events: {
          ...mainContainerWidget.events,
          eventMap: newEventMap,
        },
      },
    };

    yield put(updatePartialLayout(updatedWidgetsPartial));
  } catch (error) {
    handleEventError(action.type, action, error);
  }
}

function* deleteEventSaga(
  action: ReturnType<typeof deleteCustomEvent>,
): Generator<any, any, any> {
  try {
    const { id } = action.payload;
    const mainContainerWidget: ReturnType<typeof getMainContainer> =
      yield select(getMainContainer);

    if (!mainContainerWidget?.events?.eventMap) return;

    const newEventMap: EventMap = fastClone(
      mainContainerWidget.events.eventMap,
    );

    const eventToDelete = newEventMap[id];
    const eventName = eventToDelete.name;

    delete newEventMap[id];

    // delete the event from the emitted and triggerable embed events
    const existingEmittedEvents = yield select(getEmittedEmbedEvents);
    const existedTriggerableEvents = yield select(getTriggerableEmbedEvents);
    const newEmittedEvents = fastClone(existingEmittedEvents ?? {});
    const newTriggerableEvents = fastClone(existedTriggerableEvents ?? {});
    delete newEmittedEvents[id];
    delete newTriggerableEvents[id];
    const updatedWidgetsPartial = {
      [PAGE_WIDGET_ID]: {
        ...mainContainerWidget,
        events: {
          ...mainContainerWidget.events,
          eventMap: newEventMap,
        },
        embedding: {
          ...mainContainerWidget.embedding,
          emittedEvents: newEmittedEvents,
          triggerableEvents: newTriggerableEvents,
        },
      },
    };
    yield put(updatePartialLayout(updatedWidgetsPartial));

    // TODO(pbardea): These 2 should always be called together - they should be
    // combined.
    yield put(deleteEntityFromWidgets(eventName));
    yield put(deleteEntityFromTimers(eventName));
  } catch (error) {
    handleEventError(deleteCustomEvent.type, action, error);
  }
}

export default function* EventSagas() {
  yield all([takeEvery(createEvent.type, createEventSaga)]);
  yield all([takeEvery(updateCustomEvent.type, updateEventSaga)]);
  yield all([takeEvery(deleteCustomEvent.type, deleteEventSaga)]);
  yield all([takeEvery(duplicateCustomEvent.type, duplicateEventSaga)]);
}
