import { PermissionedEntities } from "@superblocksteam/shared";

export type IntegrationRouteParams = {
  pluginId?: string;
  datasourceId?: string;
};

export const INTEGRATION_URL = "/integrations/";

export const NEW_INTEGRATION_URL = (pluginId = ":pluginId"): string =>
  `/integrations/${pluginId}`;

export const PERMISSION_INTEGRATIONS_URL = "v1/permissions/integrations";
export const PERMISSION_ENTITIES_URL_V2 = "v2/permissions/entities";
export const PERMISSION_ENTITIES_FOR_GROUP_URL_V2 = (
  organizationId: string,
  groupId: string,
): string => `v2/organizations/${organizationId}/groups/${groupId}/entities`;
export const PERMISSION_INTEGRATIONS_FOR_GROUP_URL = (
  organizationId: string,
  groupId: string,
): string =>
  `v1/organizations/${organizationId}/groups/${groupId}/integrations`;

export const GROUP_PAGE_URL = (
  groupId = ":groupId",
  pageType = ":pageType",
): string => `/groups/${groupId}/${pageType}`;

export const PERMISSION_PAGE_URL = (pageType = ":pageType"): string =>
  `/permissions/${pageType}`;

export const OBSERVABILITY_PAGE_URL = (pageType = ":pageType"): string =>
  `/observability`;

export const SECRETS_MANAGEMENT_URL = `/secrets-management`;

export const NEW_SECRETS_MANAGEMENT_URL = (pluginId = ":pluginId"): string =>
  `${SECRETS_MANAGEMENT_URL}/${pluginId}`;

export const EDIT_SECRETS_MANAGEMENT_URL = (
  pluginId = ":pluginId",
  datasourceId = ":datasourceId",
): string => `${SECRETS_MANAGEMENT_URL}/${pluginId}/${datasourceId}`;

export const USERS_FOR_ORG_ID_URL = (orgId = ":orgId"): string =>
  `v1/organizations/${orgId}/users`;

export const INVITE_USERS_TO_ORG = (orgId = ":orgId"): string =>
  `v1/organizations/${orgId}/invite`;

export const GROUPS_FOR_ORG_ID_URL = (orgId = ":orgId"): string =>
  `v1/organizations/${orgId}/groups`;

export const EDITORS_FOR_APPLICATION = (
  applicationId = ":applicationId",
): string => `v1/applications/editors/${applicationId}`;

export const ADD_SHARE_ENTRIES = (
  resourceType: PermissionedEntities,
  resourceId = ":resourceId",
): string => `v1/permissions/${resourceType}/${resourceId}`;

export const GET_SHARE_ENTRIES = (
  resourceType: PermissionedEntities,
  resourceId = ":resourceId",
): string => `v1/permissions/${resourceType}/${resourceId}`;

export const UPDATE_SHARE_ENTRY = (
  resourceType: PermissionedEntities,
  resourceId = ":resourceId",
  shareEntryId = ":shareEntryId",
): string => `v1/permissions/${resourceType}/${resourceId}/${shareEntryId}`;

export const DELETE_SHARE_ENTRY = (
  resourceType: PermissionedEntities,
  resourceId = ":resourceId",
  shareEntryId = ":shareEntryId",
): string =>
  `v1/permissions/${resourceType}/${resourceId}/${shareEntryId}/delete`;

export const REPOSITORY_DETAILS_URL = (
  repositoryId = ":repositoryId",
): string => `/repositories/${repositoryId}`;

export const VERIFY_EMAIL = `v1/emails/verify`;
export const REQUEST_CODE = `v1/emails/new`;

export const CREATE_PAYMENT_INTENT = "v1/billing/create-intent";
export const SUBMIT_PAYMENT = "v1/billing/submit-payment";
export const DOWNGRADE_PLAN = "v1/billing/downgrade-free";
export const CHECK_PROMO_CODE = "v1/billing/check-promo";

export const STATUS_PAGE = "https://status.superblocks.com/";
export const HELP_PAGE = "https://docs.superblocks.com/support/help";

export const PERSONAL_SETTINGS_URL = (section = ":section") =>
  `/personal-settings#${section}`;

export const AI_ASSISTANT_TASK = "v1/ai/tasks";

export const GENERATE_TOKEN = "v1/token";

// for superblocks cli
export const FETCH_USER_API_KEY = "v1/users/token";
