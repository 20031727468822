import React from "react";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { SecretPluginCard } from "./SecretPluginCard";
import { AvailablePlugin } from "./types";

const PluginEmptyStateWrapper = styleAsClass`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center; /* Horizontally center content if needed */
    padding-bottom: 250px; // make it slightly higher than center
    max-width: 1500px;
    .plugin-empty-state-header {
        font-size: 15px;
        color: ${colors.GREY_700};
        font-weight: 500;
        line-height: 20px;
        text-align: center;
    }
    .plugin-empty-state-subheader {
        font-size: 13px;
        color: ${colors.GREY_400};
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        max-width: 450px;
    }
`;

const PluginCardGrid = styleAsClass`
    display: grid;
    grid-template-columns: repeat(2, 234px);
    grid-column-gap: 16px;
    grid-row-gap: 12px;
    margin-top: 40px;
`;

export const EmptyState = ({
  availablePlugins,
}: {
  availablePlugins: Array<AvailablePlugin>;
}) => {
  return (
    <div className={PluginEmptyStateWrapper}>
      <div>
        <div className="plugin-empty-state-header">
          Connect your Secrets Manager
        </div>
        <div className="plugin-empty-state-subheader">
          Let your team access secrets securely from anywhere in Superblocks.
        </div>
      </div>
      <div className={PluginCardGrid}>
        {availablePlugins.map((plugin) => (
          <SecretPluginCard
            key={plugin.pluginId}
            pluginInfo={plugin}
            buttonStyle="PRIMARY"
          />
        ))}
      </div>
    </div>
  );
};
