import { Table as AntTable, TableProps } from "antd";
import styled from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export type { TableProps };

interface Props {
  cellPadding?: string;
}

export const TableClass = styleAsClass`
.ant-table {
  border: 1px solid ${colors.GREY_100};
  table {
    border-radius: 4px;
    thead > tr > th {
      &::before {
        display: none;
      }
      color: ${colors.GREY_700};
      font-weight: 500;
      font-size: 12px;
      line-height 16px;
    }
    tbody > tr  {
      cursor: pointer;
      > td {
        color: ${colors.GREY_700};
        font-weight: 400;
        font-size: 12px;
        line-height 16px;
        .monospace-cell {
          font-family: var(--font-monospace);
        }
        .ant-badge-status-text {
          font-size: 12px;
        }
      }
    }
  }
}
`;

export const Table = styled(AntTable).attrs((props: Props) => ({
  cellPadding: props.cellPadding ?? "10px",
}))`
  thead > tr > th {
    background-color: ${(props) => props.theme.colors.WHITE};
    padding: 10px;
  }
  tbody > tr > td {
    padding: ${(props) => props.cellPadding};
  }
  tbody > .ant-table-placeholder > td {
    border: 0px;
  }
  &.application-list {
    td a {
      display: block;
      padding: 14px 10px;
      color: black;
    }
    tr:hover td a,
    td a:focus {
      color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
    }
  }
` as typeof AntTable;
