import { ApiResponseType } from "@superblocksteam/shared";
import { Typography } from "antd";
import React from "react";
import useOpenSidebar from "hooks/ui/useOpenSidebar";
import { ApiInfo } from "legacy/constants/ApiConstants";
import { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import { getPopoverConfig } from "legacy/widgets/eventHandlerPanel";
import { VarCodeText } from "pages/Editors/ApiEditor/ControlEditor/FormComponents/common/StyledComponents";
import { colors } from "styles/colors";
import { SideBarKeys } from "../constants";

export const apiV1ResponsePropertyPane: PropertyPaneConfig<ApiInfo>[] = [
  {
    sectionName: "Event handlers",
    children: [
      getPopoverConfig<ApiInfo>(
        "onSuccess",
        "Triggers an action whenever the API is run successfully",
      ),
      getPopoverConfig<ApiInfo>(
        "onError",
        "Triggers an action whenever the API has an error",
      ),
      getPopoverConfig<ApiInfo>(
        "onCancel",
        "Triggers an action whenever the API is cancelled",
      ),
    ],
  },
];

const ToggleDeveloperPreferencesLink = () => {
  const openDeveloperPreferencesPanel = useOpenSidebar(
    SideBarKeys.DEVELOPER_PREFERENCES,
  );
  return (
    <Typography.Link onClick={openDeveloperPreferencesPanel}>
      developer preferences
    </Typography.Link>
  );
};

export const apiV2ResponsePropertyPane: PropertyPaneConfig<ApiInfo>[] = [
  {
    sectionName: "Response Type",
    showHeader: true,
    headerType: "Large",
    children: [
      {
        propertyName: "responseType",
        label: "",
        controlType: "RADIO_BUTTON",
        isBindProperty: false,
        isTriggerProperty: false,
        defaultValue: ApiResponseType.SYNC,
        radioType: "RADIO",
        options: [
          {
            name: "Sync",
            value: ApiResponseType.SYNC,
            subtitle:
              "Used for standard synchronous APIs. You can reference this API's response or error in any UI component",
          },
          {
            name: "Stream",
            value: ApiResponseType.STREAM,
            subtitle:
              "Used for streaming APIs. Comes with an onMessage event handler that fires each time the API sends a message",
            tooltip:
              "To access the API output in front-end components, write the output to a variable using the onMessage handler",
          },
        ],
      },
    ],
  },
  {
    sectionName: "Frontend event handlers",
    showHeader: true,
    headerType: "Large",
    sectionStyle: {
      maxWidth: "400px",
    },
    subHeader:
      "Configure frontend event handlers to run after the API responds with Success or Error",
    helpText: (
      <>
        Use <ToggleDeveloperPreferencesLink /> to configure whether these
        handlers are triggered when you run APIs manually
      </>
    ),
    children: [
      getPopoverConfig<ApiInfo>(
        "onMessage",
        <span>
          Triggers an action whenever the streaming API sends a message.{" "}
          <a
            href="https://docs.superblocks.com/applications/streaming-applications/overview#onmessage-event-handler"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>{" "}
          about using the onMessage handler
        </span>,
        {
          hidden: (props) => props.responseType !== ApiResponseType.STREAM,
          subheader: (
            <div
              style={{
                fontWeight: 400,
                color: colors.GREY_500,
                lineHeight: "16px",
              }}
            >
              Access the message with{" "}
              <VarCodeText code>message.value</VarCodeText>{" "}
            </div>
          ),
        },
        {
          additionalAutoComplete: {
            message: {
              value: null,
            },
          },
        },
      ),
      getPopoverConfig<ApiInfo>(
        "onSuccess",
        "Triggers an action whenever the API is run successfully",
      ),
      getPopoverConfig<ApiInfo>(
        "onError",
        "Triggers an action whenever the API has an error",
      ),
      getPopoverConfig<ApiInfo>(
        "onCancel",
        "Triggers an action whenever the API is cancelled",
      ),
    ],
  },
];
