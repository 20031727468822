import { SUPPORTED_FORMATTER_TYPE } from "code-formatting/constants";
import { EditorModes } from "components/app/CodeEditor/EditorConfig";
import type { WidgetProps } from "legacy/widgets";
import type { ApiUnion } from "store/slices/apisShared";

export type EditorOpenTabWithAPI = EditorOpenTab & {
  entityType: EditorOpenTabType.API;
  entity: ApiUnion;
};

export type EditorOpenTabWithWidget = EditorOpenTab & {
  entityType: EditorOpenTabType.WIDGET;
  entity: WidgetProps;
};

export type EditorOpenTabWithEntity =
  | EditorOpenTabWithAPI
  | EditorOpenTabWithWidget;

export enum EditorOpenTabType {
  API = "API",
  WIDGET = "WIDGET",
}

export type EditorOpenTab = {
  id?: string;
  entityType: EditorOpenTabType;
  entityId: string | WidgetProps["widgetId"];
  widgetProperty?: string;
};

type PageId = string;
export type EditorAppPreferences = Record<PageId, PagePreferences>;
export type PagePreferences = {
  openBottomPanelTabs: EditorOpenTab[];
};

export enum DeveloperPreferencesKeys {
  Shared = "shared",
  Application = "application",
}

// Developer preferences that can be customized per EntityType
export type ApplicationDeveloperPreferences = {
  triggerFrontendEventHandlersOnManualRun: boolean;
};

export enum KeyBindings {
  Default = "default",
  Vim = "vim",
}

// Developer preferences shared between EntityTypes
export type SharedDeveloperPreferences = {
  formatCodeOnBlur: boolean;
  formatCodeOnRun: boolean;
  languagesToFormat: SUPPORTED_FORMATTER_TYPE[];
  keyBindings: KeyBindings;
};

// Developer preferences that users can actually customize
const DEFAULT_APPLICATION_DEVELOPER_PREFERENCES: ApplicationDeveloperPreferences =
  {
    triggerFrontendEventHandlersOnManualRun: true,
  };

const DEFAULT_SHARED_DEVELOPER_PREFERENCES: SharedDeveloperPreferences = {
  formatCodeOnRun: false,
  formatCodeOnBlur: true,
  languagesToFormat: [EditorModes.JAVASCRIPT, EditorModes.PYTHON],
  keyBindings: KeyBindings.Default,
};

export type DeveloperPreferences = {
  [DeveloperPreferencesKeys.Shared]: SharedDeveloperPreferences;
  [DeveloperPreferencesKeys.Application]: ApplicationDeveloperPreferences;
};

export const DEFAULT_DEVELOPER_PREFERENCES: DeveloperPreferences = {
  [DeveloperPreferencesKeys.Shared]: DEFAULT_SHARED_DEVELOPER_PREFERENCES,
  [DeveloperPreferencesKeys.Application]:
    DEFAULT_APPLICATION_DEVELOPER_PREFERENCES,
};
