import { RbacRole, TASKS, validateEmail } from "@superblocksteam/shared";
import { Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PrimaryButton } from "components/ui/Button";
import DropdownButtonMinimal from "components/ui/DropdownButtonMinimal";
import { useMarkTaskComplete } from "hooks/ui/useCheckTask";
import { useDebounce } from "hooks/ui/useDebounce";

const ROLE_SELECT_WIDTH = 120;

const StyledSelectRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  flex-flow: row wrap;
`;
const StyledSelectWrapper = styled.div`
  flex: 1 0;
  max-width: 100%;
`;

const StyledRoleBox = styled.div`
  flex: 0 0 ${ROLE_SELECT_WIDTH}px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  flex-basis: 80px;
  letter-spacing: normal;
`;
const StyledInviteButtonWrapper = styled.div`
  flex: 0 0 65px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
  padding-right: 10px;
` as typeof Select;

export type Invitee = {
  name: string;
  email?: string;
  type: "user" | "group";
  id: string;
  size?: number;
};

interface Props {
  allOptions: Invitee[];
  onAddInvitees: (
    invitees: {
      type: "user" | "group";
      lookupId: string;
      name: string;
    }[],
    role: RbacRole,
  ) => Promise<void>;
  roles: { value: string; label: string }[];
  // If only 1 role is allowed, the role dropdown will downgrade
  // to a text box, with this tooltip showing upon hovering.
  roleTooltip?: string;
  inviteButtonText?: string;
  placeholder?: string;
}

// TODO: replace this with RecommendedMultiDropdown
const SearchAndInvite = (props: Props) => {
  const [selectedInvitees, setSelectedInvitees] = useState<Invitee[]>([]);
  const [searchInviteeInput, setSearchInviteeInput] = useState<string>("");
  const [roleForInvitees, setRoleForInvitees] = useState(RbacRole.BUILDER);
  const [searchInviteeInputHided, setSearchInviteeInputHided] =
    useState<string>("");
  const [isInviteeInputValid, setIsInviteeInputValid] = useState(false);
  const [inviteCallPending, setInviteCallPending] = useState(false);
  useEffect(() => {
    return () => {
      setSelectedInvitees([]);
      setSearchInviteeInput("");
      setSearchInviteeInputHided("");
      setRoleForInvitees(RbacRole.BUILDER);
    };
  }, []);

  // we removed selected from allOptions to avoid showing them in the dropdown (previous ux requirement)
  const allOptions = useMemo(
    () =>
      props.allOptions
        .filter(
          (option) =>
            !selectedInvitees.find((selectedInvitee) => {
              if (selectedInvitee.type === "user") {
                return (
                  selectedInvitee.email === option.email ||
                  selectedInvitee.name === option.name
                );
              }
              return selectedInvitee.name === option.name;
            }),
        )
        .map((option) => {
          const identifier = option.email || option.name;
          let label = identifier;
          if (option.type === "user" && option.email !== option.name) {
            label = `${option.name} (${option.email})`;
          }
          if (option.type === "group") {
            label = `${option.name} (${option.size ?? 0})`;
          }
          return {
            key: identifier,
            value: identifier,
            inviteeData: option,
            label,
          };
        }),
    [props.allOptions, selectedInvitees],
  );

  const validateInviteeInput = useCallback((inviteeInput: string) => {
    if (validateEmail(inviteeInput)) {
      setIsInviteeInputValid(true);
    } else {
      setIsInviteeInputValid(false);
    }
  }, []);
  const validateInviteeInputDebounced = useDebounce(validateInviteeInput, 200);
  const setInviteButtonStatus = useCallback(
    (value: any) => {
      setIsInviteeInputValid(false);
      validateInviteeInputDebounced?.(value);
    },
    [validateInviteeInputDebounced],
  );
  const inviteeSelectFilterOption = useCallback(
    (editorInputValue: string, option: any) => {
      const optionData: {
        value: string;
        inviteeData: Invitee;
      } = option;
      return (
        optionData?.value !== editorInputValue &&
        (optionData?.value
          ?.toLowerCase()
          ?.indexOf(editorInputValue.toLowerCase()) !== -1 ||
          optionData?.inviteeData?.name
            ?.toLowerCase()
            ?.indexOf(editorInputValue.toLowerCase()) !== -1)
      );
    },
    [],
  );
  const inviteeSelectOnSelect = useCallback(() => {
    setSearchInviteeInput("");
  }, []);

  const inviteeSelectOnSearch = useCallback(
    (value: any) => {
      if (value === ",") {
        value = "";
      }
      setSearchInviteeInput(value);
      setInviteButtonStatus(value);
    },
    [setInviteButtonStatus],
  );
  const inviteeSelectOnChange = useCallback(
    (optionsValue: string[], options: any) => {
      const optionsData: {
        value: string;
        inviteeData: Invitee;
      }[] = options;
      const newSelectedInvitees = optionsData.map(
        (optionData: { inviteeData: Invitee }, index) => {
          if (isEmpty(optionData)) {
            // optionData will be {} if the input added is not from allOptions
            // because selectedInvitees was removed from allOptions (due to previous ux requirement), only the newly selected option is not {}.
            // if select new item, and current selected is [a,b,c], the new optionsData is [{}, {}, {}, newObj], newOptionsValue is [a, b, c, newValue]
            // if deselect and current selected is [a, b, c], the new optionsData is [{}, {}], new optionsValue is [a, c]
            return optionsValue[index] ===
              (selectedInvitees[index].email || selectedInvitees[index].name)
              ? selectedInvitees[index]
              : selectedInvitees[index + 1];
          }
          return optionData.inviteeData;
        },
      );
      setSelectedInvitees(newSelectedInvitees);
    },
    [selectedInvitees],
  );
  const inviteeSelectOnInputKeyDown = useCallback(
    (e: any) => {
      if (e.key === "Enter" || e.key === ",") {
        // if the input is a group, it will be handled in inviteeSelectOnChange (included in the options)
        // so no need to validate and concat here
        const searchInviteeInputTrimmed = searchInviteeInput.trim();
        if (validateEmail(searchInviteeInputTrimmed)) {
          const newSelectedInvitees = selectedInvitees.concat([
            {
              email: searchInviteeInputTrimmed,
              name: searchInviteeInputTrimmed,
              id: searchInviteeInputTrimmed,
              type: "user",
            },
          ]);
          setSelectedInvitees(newSelectedInvitees);
        }
        setSearchInviteeInput("");
        setSearchInviteeInputHided("");
      }
    },
    [searchInviteeInput, selectedInvitees],
  );
  const inviteeSelectOnFocus = useCallback(() => {
    setSearchInviteeInput(searchInviteeInputHided);
  }, [searchInviteeInputHided]);

  const inviteeSelectOnBlur = useCallback(() => {
    const searchInviteeInputTrimmed = searchInviteeInput.trim();
    if (validateEmail(searchInviteeInputTrimmed)) {
      setSelectedInvitees(
        selectedInvitees.concat([
          {
            email: searchInviteeInputTrimmed,
            name: searchInviteeInputTrimmed,
            id: searchInviteeInputTrimmed,
            type: "user",
          },
        ]),
      );
      setSearchInviteeInputHided("");
    } else {
      setSearchInviteeInputHided(searchInviteeInput);
    }
    setSearchInviteeInput("");
  }, [searchInviteeInput, selectedInvitees]);

  const roleSelectOnSelect = useCallback(
    (value: any) => setRoleForInvitees(value),
    [],
  );

  const [markTaskComplete] = useMarkTaskComplete(TASKS.INVITE_TEAMMATE);
  const inviteButtonOnClick = useCallback(() => {
    let invitees: Invitee[] = selectedInvitees;
    const searchInviteeInputTrimmed = searchInviteeInput.trim();
    if (
      searchInviteeInputTrimmed !== "" &&
      validateEmail(searchInviteeInputTrimmed)
    ) {
      invitees = invitees.concat([
        {
          email: searchInviteeInputTrimmed,
          name: searchInviteeInputTrimmed,
          id: searchInviteeInputTrimmed,
          type: "user",
        },
      ]);
    }

    const inviteesToSend: {
      type: "user" | "group";
      lookupId: string;
      name: string;
    }[] = invitees.map((invitee) => {
      if (invitee.type === "user") {
        return {
          type: "user",
          lookupId: invitee.email || invitee.id,
          name: invitee.name,
        };
      }
      return {
        type: "group",
        lookupId: invitee.id,
        name: invitee.name,
      };
    });
    setInviteCallPending(true);
    props
      .onAddInvitees(inviteesToSend, roleForInvitees)
      .then(() => {
        setSelectedInvitees([]);
        markTaskComplete();
      })
      .catch((error) => {
        //error handled by user of this component, but still throwed here to avoid clearing input on fail
      })
      .finally(() => {
        setInviteCallPending(false);
      });
  }, [
    markTaskComplete,
    props,
    roleForInvitees,
    searchInviteeInput,
    selectedInvitees,
  ]);

  const selectedValues = selectedInvitees.map(
    (invitee) => invitee.email || invitee.name,
  );
  return (
    <StyledSelectRow>
      <StyledSelectWrapper>
        <StyledSelect
          data-test="invitees-search-select"
          mode="multiple"
          placeholder={
            searchInviteeInputHided ||
            props.placeholder ||
            "Enter user email or group name to share"
          }
          options={allOptions}
          filterOption={inviteeSelectFilterOption}
          autoFocus={true}
          value={selectedValues}
          onSelect={inviteeSelectOnSelect}
          onSearch={inviteeSelectOnSearch}
          onChange={inviteeSelectOnChange}
          notFoundContent={""}
          onInputKeyDown={inviteeSelectOnInputKeyDown}
          searchValue={searchInviteeInput}
          autoClearSearchValue={false}
          onFocus={inviteeSelectOnFocus}
          onBlur={inviteeSelectOnBlur}
        />
      </StyledSelectWrapper>
      {props.roles.length > 1 && (
        <DropdownButtonMinimal
          value={roleForInvitees}
          onValueChange={roleSelectOnSelect}
          options={props.roles.map((role) => ({
            key: role.value,
            value: role.value,
            text: role.label,
            dataTest: `invitees-role-option-${role.value}`,
          }))}
        />
      )}
      {props.roles.length === 1 && (
        <Tooltip title={props.roleTooltip}>
          <StyledRoleBox>{props.roles[0].label}</StyledRoleBox>
        </Tooltip>
      )}
      <StyledInviteButtonWrapper>
        <PrimaryButton
          data-test="invitees-invite-button"
          type="primary"
          onClick={inviteButtonOnClick}
          disabled={
            (searchInviteeInput.trim() !== "" && !isInviteeInputValid) ||
            (searchInviteeInput.trim() === "" && selectedInvitees.length === 0)
          }
          loading={inviteCallPending}
        >
          {props.inviteButtonText || "Invite"}
        </PrimaryButton>
      </StyledInviteButtonWrapper>
    </StyledSelectRow>
  );
};

export default SearchAndInvite;
