import { BillingPlan, Organization } from "@superblocksteam/shared";
import { List } from "antd";
import Modal from "antd/lib/modal";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useFeatureFlag } from "hooks/ui";
import { PRICING_URL } from "legacy/constants/routes";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";

const StyledModal = styled(Modal)`
  .ant-typography {
    display: block;
    margin-bottom: 12px;
  }

  .ant-select {
    width: 100%;
  }
`;
styled(List.Item)`
  border-bottom: 0px !important;
`;

interface Props {
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

const PlanAndUsageModal = ({ isVisible, setVisible }: Props) => {
  const organization = useSelector(selectOnlyOrganization) as Organization;

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
  };

  const proUpgrade = useMemo(
    () =>
      organization.billing.plan === BillingPlan.FREE && (
        <>
          &nbsp;or <a href="/checkout">upgrade to pro</a>
        </>
      ),
    [organization.billing.plan],
  );

  const billingEmail = useFeatureFlag(Flag.SALES_BILLING_EMAIL);

  return (
    <StyledModal
      open={isVisible}
      onCancel={handleCancel}
      title="Plan & Usage"
      footer={null}
      data-test={`${
        isVisible
          ? "plan-and-usage-modal-visible"
          : "plan-and-usage-modal-hidden"
      }`}
    >
      Your current plan is{" "}
      <a href={PRICING_URL} target="_blank" rel="noreferrer">{`${capitalize(
        organization.billing.plan,
      )}`}</a>
      . To update your plan, please contact{" "}
      <a href={`mailto:${billingEmail}`}>{billingEmail}</a>
      {proUpgrade}.
    </StyledModal>
  );
};

export default PlanAndUsageModal;
