import { AgentType } from "@superblocksteam/shared";
import { Layout } from "antd";
import React from "react";
import styled from "styled-components";
import { useSaga } from "hooks/store";
import { DOCS_URL } from "legacy/constants/routes";
import { getCurrentUserIsAdmin } from "legacy/selectors/usersSelectors";
import { useAppSelector } from "store/helpers";
import { getAgentsSaga } from "store/slices/agents";
import Link from "../../components/ui/Link";
import { SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS } from "../../env";
import { useInterval } from "../../hooks/ui/useInterval";
import { getUserCurrentOrgId } from "../../legacy/selectors/organizationSelectors";
import { selectShouldShowAgentDisasterHeader } from "../../store/slices/agents";
import {
  selectOrganizationById,
  selectOnlyOrganizationIsCloud,
} from "../../store/slices/organizations";
import { noActiveAgentDetailedMessage } from "../../utils/error/error";

const StyledHeader = styled(Layout.Header)`
  background-color: #cc6666;
  padding: 0;
  width: 100%;
  height: ${({ theme }) => theme.legacy.agentDisasterHeaderHeight};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledMessage = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  height: ${({ theme }) => theme.legacy.agentDisasterHeaderHeight};
  line-height: ${({ theme }) => theme.legacy.agentDisasterHeaderHeight};
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

export const AgentDisasterHeader = () => {
  const orgIsCloud = useAppSelector(selectOnlyOrganizationIsCloud);
  if (orgIsCloud) {
    return null;
  }
  return <AgentDisasterHeaderInner />;
};

const ON_PREMISE_AGENT_DOCS_URL = `${DOCS_URL}/on-premise-agent/manage/troubleshooting`;
const AgentDisasterHeaderInner = () => {
  const organizationId = useAppSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  const showDisasterHeader = useAppSelector(
    selectShouldShowAgentDisasterHeader(organization?.agentType),
  );
  const userIsAdmin = useAppSelector(getCurrentUserIsAdmin);

  const [getAgents] = useSaga(getAgentsSaga);
  useInterval(
    async () => await getAgents({ organization }),
    SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS,
  );

  const disasterMessage = noActiveAgentDetailedMessage({
    organizationAgentType: organization?.agentType,
    userIsAdmin,
  });

  return showDisasterHeader ? (
    <StyledHeader data-test="agent-disaster-test">
      <StyledMessage>
        {disasterMessage}
        {organization?.agentType === AgentType.ONPREMISE && userIsAdmin && (
          <>
            {". "}
            Refer to the{" "}
            <StyledLink
              target="_blank"
              rel="noreferrer"
              to={ON_PREMISE_AGENT_DOCS_URL}
            >
              troubleshooting guide
            </StyledLink>
          </>
        )}
      </StyledMessage>
    </StyledHeader>
  ) : null;
};
