import { ApiKeyScope, OrganizationTokenDto } from "@superblocksteam/shared";
import Fuse from "fuse.js";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Layout, MainWrapper } from "components/app";
import AddButton from "components/ui/AddButton";
import { HeaderWrapper } from "components/ui/Page";
import { SearchContainer, SearchInput } from "components/ui/SearchSection";
import { useDebounce } from "hooks/ui";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper } from "pages/components/PageWrapper";
import { selectOnlyOrganizationId } from "store/slices/organizations";
import { HttpMethod, callServer } from "store/utils/client";
import { colors } from "styles/colors";
import CreateTokenModal from "./CreateTokenModal";
import TokenList from "./TokenList";
import { AccessToken, convertOrgToken } from "./constants";

const AccessTokensPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [tokens, setTokens] = useState<AccessToken[]>([]);
  const orgId = useSelector(selectOnlyOrganizationId);
  const [tokensLoading, setTokensLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    async function getTokens() {
      try {
        setTokensLoading(true);
        const tokens = await callServer<OrganizationTokenDto[]>({
          url: `/v1/organizations/${orgId}/tokens`,
          method: HttpMethod.Get,
        });
        if (tokens) {
          setTokens(
            tokens
              .filter(
                (token) =>
                  token.scope === ApiKeyScope.ORG_SESSION_MANAGEMENT ||
                  token.scope === ApiKeyScope.ORG_ADMIN ||
                  token.scope === ApiKeyScope.ORG_AGENT,
              )
              .map(convertOrgToken),
          );
        }
        setLoadingError(undefined);
      } catch (e: any) {
        setLoadingError(e.message ?? "Failed to fetch tokens");
      } finally {
        setTokensLoading(false);
      }
    }
    getTokens();
  }, [orgId]);

  const fuse = useMemo(
    () =>
      new Fuse(tokens as AccessToken[], {
        shouldSort: true,
        threshold: 0.3,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["name", "scope", "creator.name"],
      }),
    [tokens],
  );
  const filteredTokens = useMemo(
    () =>
      searchTerm.length > 1
        ? fuse.search(searchTerm).map(({ item }) => item)
        : tokens,
    [fuse, searchTerm, tokens],
  );

  return (
    <PageWrapper pageName="Access Tokens">
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper>
          <div className={HeaderWrapper}>
            <div className="page-header-title"> Access Tokens </div>
            <div className="page-header-description">
              Access tokens are used to authenticate with Superblocks APIs.
              Treat your tokens as sensitive information.{" "}
              <a
                href="https://docs.superblocks.com/account-management-and-permissions/access-tokens"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className={SearchContainer}>
            <SearchInput
              placeholder="Search"
              onChange={onSearchChangeDebounced}
              data-test="access-token-search-input"
            />
            <AddButton
              onAdd={() => setIsCreateModalOpen(true)}
              text={"Create token"}
              dataTest="add-token-button"
            />
          </div>
          {loadingError ? (
            <div style={{ color: colors.RED_500 }}>{loadingError}</div>
          ) : (
            <TokenList
              tokens={filteredTokens}
              setTokens={setTokens}
              loading={tokensLoading}
              totalCount={tokens.length}
            />
          )}
        </MainWrapper>

        <CreateTokenModal
          isModalOpen={isCreateModalOpen}
          setIsModalOpen={setIsCreateModalOpen}
          setTokens={setTokens}
          isOpaPage={false}
        />
      </Layout>
    </PageWrapper>
  );
};

export default AccessTokensPage;
