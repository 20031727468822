import { CheckOutlined, LinkOutlined } from "@ant-design/icons";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

const CopyLinkWrapper = styled.div`
  text-align: left;
`;

const CopiedWrapper = styled.div`
  color: ${({ theme }) => theme.colors.ACCENT_GREEN};
`;

const NotCopiedWrapper = styleAsClass`
  color: ${colors.ACCENT_BLUE_500};
  &:hover {
    color: ${colors.ACCENT_BLUE_600};
  }
`;
interface Props {
  url: string;
  text: string;
}

const CopyLink = (props: Props) => {
  const [copied, setCopied] = useState<boolean>(false);

  const copyLink = useCallback(
    (e: any) => {
      e.preventDefault();
      setCopied(true);
      navigator.clipboard.writeText(props.url);
    },
    [props.url],
  );

  return (
    <CopyLinkWrapper key="share-link">
      <Link to="#" onClick={copyLink}>
        {copied ? (
          <CopiedWrapper>
            <CheckOutlined /> Link has been copied
          </CopiedWrapper>
        ) : (
          <div className={NotCopiedWrapper}>
            <LinkOutlined /> {props.text}
          </div>
        )}
      </Link>
    </CopyLinkWrapper>
  );
};

export default CopyLink;
