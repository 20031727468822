import { composeWithDevToolsLogOnlyInProduction } from "@redux-devtools/extension";
import { Middleware } from "redux";
import { isDev } from "utils/env";
import { sendWarningUINotification } from "utils/notification";

import { isPayloadAction } from "./utils/action";

export const measureTiming: Middleware = () => {
  let currentDepth = 0;
  return (next) => (action) => {
    if (!isPayloadAction(action)) return next(action);
    if (performance.mark === undefined) return next(action);

    currentDepth++;
    const depth = currentDepth;
    performance.mark(`${action.type}_start-${depth}`);
    const result = next(action);
    performance.mark(`${action.type}_end-${depth}`);
    currentDepth--;

    const measureName = `♻️ ${action.type}${depth > 0 ? `(${depth})` : ""}`;
    performance.measure(
      measureName,
      `${action.type}_start-${depth}`,
      `${action.type}_end-${depth}`,
    );

    performance.clearMarks(`${action.type}_start-${depth}`);
    performance.clearMarks(`${action.type}_end-${depth}`);
    performance.clearMeasures(measureName);

    return result;
  };
};

// This middleware is used to prevent new serialization bugs from being introduced
export const preventNewSerializationBugs: Middleware = () => {
  return (next) => (action) => {
    if (!isDev()) {
      // We only want to run this in dev mode
      return next(action);
    }

    let state: any;
    try {
      structuredClone(action);
      state = next(action);
      structuredClone(state);
    } catch (e) {
      console.error(
        "Redux actions & state must be serializable (dev warning)",
        action,
        e,
      );
      sendWarningUINotification({
        message: `Redux actions & state must be serializable (dev warning): ${action.type}`,
        duration: 5,
      });
    }
    return state;
  };
};

export const composeEnhacer = composeWithDevToolsLogOnlyInProduction({
  name: "Superblocks main",
  trace: isDev(),
  traceLimit: 100,
});
