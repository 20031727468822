import { Tooltip, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { ChecklistIcons } from "legacy/icons/ChecklistIcons";
import { EntityType, getEntityLabel } from "utils/entity";
import { getColorForType, IconWrapper } from "../EntityCard";
export const ICON_SIZE = 52;

export const StyledCard = styled.div<{
  color?: string;
  highlight: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.GREY_100};
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  opacity: ${({ highlight }) => (highlight ? 1 : 0.32)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "default")};
  cl .entity-type-icon {
    margin-left: 0;
    margin-bottom: 18px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ color }) => color + "08"};
    border: 1px solid ${({ color }) => color};
  }

  h3.ant-typography {
    margin-bottom: 12px;
    font-size: 16px;
  }

  div.ant-typography {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.GREY_500};
  }

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  .bp5-icon {
    margin: 24px 0;
    color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }

  .ant-btn {
    border-color: ${(props) => props.color};
    color: ${(props) => props.color};
    font-weight: 500;
  }
`;

export const CardBody = styled.div`
  padding: ${({ theme }) => theme.paddings.medium};
  padding-top: 50px; // Room for the EntityIcon
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: end;
`;

export const CreateEntityIconWrapper = styled(IconWrapper)`
  position: absolute;
  top: 0px;
  transform: translate(0, -50%);
`;

const TrophyIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: ${({ theme }) => theme.paddings.medium};
  right: ${({ theme }) => theme.paddings.medium};
`;

const CardTitleRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TrophyIconSize = 20;

type CardTitleRowProps = {
  entityType: EntityType;
  taskNotDone: boolean;
};

export const CardTitleRow = (props: CardTitleRowProps) => {
  const entityLabel = getEntityLabel(props.entityType);
  return (
    <CardTitleRowWrapper>
      <Typography.Title level={3}>{entityLabel}</Typography.Title>
      {props.taskNotDone && (
        <Tooltip
          title={`Create & deploy your first ${entityLabel.toLocaleLowerCase()} to complete the onboarding task`}
        >
          <TrophyIconWrapper>
            <ChecklistIcons.TROPHY_ICON
              color={getColorForType(props.entityType)}
              width={TrophyIconSize}
              height={TrophyIconSize}
            />
          </TrophyIconWrapper>
        </Tooltip>
      )}
    </CardTitleRowWrapper>
  );
};

export const DatasourceItem = styled.div<{
  disabled?: boolean;
}>`
  margin-bottom: 4px;
  padding: 6px 8px;
  border-radius: 4px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "default")};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.GREY_50};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.SUBTLE_BLUE};
  }
`;

export const DatasourceIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;

export const DatasourceList = styled.div`
  max-height: 270px;
  overflow-y: auto;
  margin-bottom: 12px;
`;
