import type { AppState } from "store/types";

export const getApplicationSidebarKey = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar.selectedKey;

export const getWorkflowSidebarKey = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.workflow.sidebar.selectedKey;

export const getSidebarFocusedItem = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar.focusedItem;

export const getSidebarExpandedItems = (state: AppState) =>
  state.legacy.ui.editorPreferences.general.application.sidebar
    .expandedItemNames;
