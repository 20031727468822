import { Dimension, Margin, Padding } from "@superblocksteam/shared";
import { get } from "lodash";
import {
  GridDefaults,
  WIDGET_PADDING,
  WidgetTypes,
  SlideoutSize,
  ModalSize,
  MODAL_WIDTH_PRESETS,
  SLIDEOUT_DEFAULT_COLUMNS,
  SLIDEOUT_WIDTH_PRESETS,
  MODAL_COLUMNS,
  MODAL_LEGACY_COLUMNS,
  SLIDEOUT_GAP,
  MODAL_PERIMETER_GAP,
  CanvasLayout,
  CanvasAlignment,
  SectionDefaults,
  PAGE_WIDGET_ID,
  DETACHED_WIDGETS,
  CanvasDefaults,
  CanvasDistribution,
  WidgetWidthModes,
  WidgetHeightModes,
} from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { GeneratedTheme } from "legacy/themes";
import {
  getSectionGridRowsForParentType,
  dimensionToGridRows,
} from "legacy/utils/WidgetPropsUtils";
import { isStackLayout } from "legacy/widgets/StackLayout/utils";
import { DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT } from "legacy/widgets/TabsWidget/constants";
import { computeTabHeaderHeightPx } from "legacy/widgets/TabsWidget/utils";
import {
  getApplicableMinHeight,
  getBorderThickness,
  getCanvasMinHeightNested,
  getCanvasMinWidthNested,
  getWidgetDefaultPadding,
  hackyIsWidgetCollapsed,
  isDynamicSize,
} from "legacy/widgets/base/sizing";
import { getWidgetHeight } from "legacy/widgets/base/sizing";
import { getWidgetWidth } from "legacy/widgets/base/sizing";
import {
  WidthHydrationContext,
  getApplicableMaxWidth,
  getApplicableMinWidth,
  hydrateFitContentWidths,
} from "legacy/widgets/base/sizing/canvasWidthUtil";
import { getLineHeightInPxFromTextStyle } from "legacy/widgets/typographyUtils";
import { AllFlags, Flag } from "store/slices/featureFlags";
import { fastClone } from "./clone";
import logger from "./logger";
import type { WidgetPropsRuntime } from "legacy/widgets/BaseWidget";
import type {
  FlattenedWidgetLayoutProps,
  StaticWidgetProps,
  WidgetLayoutProps,
} from "legacy/widgets/shared";

/**
 * Note to reviewers: the 11px value is determined experimentally by testing the scrollbar width
 * in Chrome, Firefox, and using both mouse and trackpad. Because trackpad users typically get
 * an overlay scrollbar, but mouse users get a normal scrollbar, we need to account for both by
 * reserving additional space.
 *
 * Because we use a thinner scrollbar style, we need to reserve less space than the standard 15px.
 *
 * Previously, we used:

const scrollDiv = document.createElement("div");
scrollDiv.setAttribute(
  "style",
  "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;",
);
document.body.appendChild(scrollDiv);
const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
document.body.removeChild(scrollDiv);
return scrollbarWidth;
*/

function getModalColumns(widget: WidgetLayoutProps) {
  // gracefully handle modals in existing apps, that have an old default of 40 columns
  return widget.widthPreset ? MODAL_COLUMNS : MODAL_LEGACY_COLUMNS;
}

export const hasLeftRightProperties = (
  widget: any,
): widget is StaticWidgetProps & {
  left: Dimension<"gridUnit">;
  width: Dimension<WidgetWidthModes>;
} => {
  for (const propertyName of ["left", "width"] as const) {
    if (typeof widget[propertyName] === "undefined") {
      throw Error(`${widget.widgetName} missing ${propertyName}`);
    }
  }
  return true;
};

export const hasRuntimePositionProperties = (
  widget: any,
): widget is StaticWidgetProps & {
  left: Dimension<"gridUnit">;
  width: Dimension<WidgetWidthModes>;
  parentColumnSpace: number;
  parentRowSpace: number;
} => {
  for (const propertyName of [
    "left",
    "width",
    "parentColumnSpace",
    "parentRowSpace",
  ] as const) {
    if (typeof widget[propertyName] === "undefined") {
      throw Error(`${widget.widgetName} missing ${propertyName}`);
    }
  }
  return true;
};

export const getComponentDimensions = (
  widget: WidgetLayoutProps | WidgetPropsRuntime,
): { componentWidth: number; componentHeight: number } => {
  if (!hasRuntimePositionProperties(widget)) throw Error("");
  return {
    componentWidth: getWidgetWidth(widget),
    componentHeight: getWidgetHeight(widget),
  };
};

// safer way to get # grid columns
const getSafeGridColumns = (
  widget: WidgetLayoutProps | FlattenedWidgetLayoutProps,
): number => {
  if (widget.gridColumns != null) {
    return Math.max(widget.gridColumns, 1);
  }
  if (widget.parentColumnSpace) {
    return Dimension.toGridUnit(
      widget.width,
      widget.parentColumnSpace,
    ).roundUp().value;
  }
  return GridDefaults.DEFAULT_GRID_COLUMNS;
};

const getVisibleGridColumns = (widget: WidgetLayoutProps) => {
  const totalGridColumns = getSafeGridColumns(widget);
  const totalVisibleGridColumns =
    widget.children?.reduce((acc, child) => {
      const hidden =
        child?.isVisible === false && child?.collapseWhenHidden === true;

      if (hidden) return acc;
      return acc + getSafeGridColumns(child);
    }, 0) ?? totalGridColumns;
  return totalVisibleGridColumns;
};

const getParentColumnSpace = (
  parentWidget: WidgetLayoutProps,
  childWidget: WidgetLayoutProps,
  responsiveCanvasScaledWidth: number,
): number => {
  if (
    parentWidget.type === WidgetTypes.SLIDEOUT_WIDGET ||
    parentWidget.type === WidgetTypes.MODAL_WIDGET
  ) {
    if (responsiveCanvasScaledWidth) {
      return responsiveCanvasScaledWidth / getSafeGridColumns(parentWidget);
    }
  }

  return getParentColumnSpaceMinusPadding({ parentWidget, childWidget });
};

export const hstackParentColumnSpace = (
  params:
    | {
        parentWidget: WidgetLayoutProps;
        numVisibleChildren: number;
        parentWidth: Dimension<"px">;
      }
    | {
        parentWidget: FlattenedWidgetLayoutProps;
        children: FlattenedWidgetLayoutProps[];
        numVisibleChildren: number;
        parentWidth: Dimension<"px">;
      },
) => {
  const { parentWidget, parentWidth, numVisibleChildren } = params;
  const children: StaticWidgetProps[] =
    ("children" in params
      ? params.children
      : (parentWidget.children as WidgetLayoutProps["children"])) ?? [];

  const widthPx = parentWidth.value;
  const spacing = parentWidget?.spacing ?? CanvasDefaults.SPACING;
  if (numVisibleChildren <= 1) {
    return widthPx / getSafeGridColumns(parentWidget);
  }
  const totalSpacingGap = spacing.value * (numVisibleChildren - 1);
  const totalMarginGap = children.reduce((acc: number, child: any) => {
    if (child.detachFromLayout) return acc;
    return Margin.x(child.margin).value + acc;
  }, 0);

  const spaceForGridUnits = widthPx - totalSpacingGap - totalMarginGap; // could be negative!
  const computedParentColumnSpace =
    spaceForGridUnits / getSafeGridColumns(parentWidget);

  return Math.max(
    computedParentColumnSpace,
    CanvasDefaults.MIN_GRID_UNIT_WIDTH,
  );
};

export const getParentColumnSpaceMinusPadding = ({
  parentWidget,
  childWidget,
}:
  | {
      parentWidget: WidgetLayoutProps;
      childWidget: WidgetLayoutProps;
    }
  | {
      parentWidget: WidgetLayoutProps & {
        layout: CanvasLayout.FIXED | undefined;
      };
      childWidget?: WidgetLayoutProps;
    }) => {
  const width: Dimension<"px"> =
    parentWidget.internalWidth ?? // IMPORTANT FOR THIS TO WORK: internal width has yet to be influenced by getCanvasMinWidthNested at this point in the code
    Dimension.toPx(parentWidget.width, parentWidget.parentColumnSpace);
  const widthPx = width.value;

  switch (parentWidget.layout) {
    case CanvasLayout.HSTACK: {
      const numVisibleChildren =
        parentWidget.children?.filter((child) => !hackyIsWidgetCollapsed(child))
          ?.length ?? 0;
      return hstackParentColumnSpace({
        parentWidget,
        parentWidth: width,
        numVisibleChildren,
      });
    }
    case CanvasLayout.VSTACK: {
      const gridColumns = getSafeGridColumns(parentWidget);
      const totalMarginGapPx = Margin.x(childWidget?.margin).value;

      const spaceForGridUnits = widthPx - totalMarginGapPx; // could be negative!
      const computedParentColumnSpace = spaceForGridUnits / gridColumns;
      return Math.max(
        computedParentColumnSpace,
        CanvasDefaults.MIN_GRID_UNIT_WIDTH,
      );
    }
    default:
      return widthPx / getSafeGridColumns(parentWidget);
  }
};

/**
 * This function processes the root dsl recursively to setup the correct layout for widgets before rendering.
 * Some widgets create their own children when rendering, like Tabs, Forms, Grids. This calculation does not
 * cover the layout for those children generated in render time, but it provides the correct parent layout for
 * them.
 */
export const recalculateWidgetsLayout = (params: {
  widget: WidgetLayoutProps;
  responsiveCanvasScaledWidth: number;
  theme: GeneratedTheme;
  flags: Partial<AllFlags>;
  appMode: APP_MODE;
  parent?: WidgetLayoutProps;
  widthHydrationContext?: WidthHydrationContext;
}) => {
  const {
    widget,
    responsiveCanvasScaledWidth,
    theme,
    flags,
    appMode,
    parent,
    widthHydrationContext = { visited: new Set<string>() },
  } = params;

  const isEditMode = appMode === APP_MODE.EDIT;

  widget.parentRowSpace = GridDefaults.DEFAULT_GRID_ROW_HEIGHT;
  widget.padding = widget.padding ?? getWidgetDefaultPadding(theme, widget);
  const { componentWidth, componentHeight } = getComponentDimensions(widget);
  if (widget.type === WidgetTypes.PAGE_WIDGET) {
    widget.parentColumnSpace =
      responsiveCanvasScaledWidth / GridDefaults.DEFAULT_GRID_COLUMNS;
  }

  // Update the parents gridColumns to not account for hidden columns
  const fullGridColumns = getSafeGridColumns(widget);
  if (widget.type === WidgetTypes.SECTION_WIDGET) {
    if (!isEditMode) {
      widget.gridColumns = getVisibleGridColumns(widget);
      if (widget.gridColumns === 0) {
        widget.height = Dimension.gridUnit(0);
      }
    }
  }

  const isParentAnEmptySection = isAnEmptySection(widget);
  if (isParentAnEmptySection) {
    widget.height = Dimension.build(
      getEmptyRows(parent, appMode),
      widget.height.mode,
    );
  }

  const shouldStack =
    widget.stackColumnsAt &&
    widget.stackColumnsAt?.value >= responsiveCanvasScaledWidth;
  widget.isStacked = shouldStack;

  // TODO calculate fitContent with container height here.
  // use widget.parentColumnSpace for any fluid children of widget
  // children inherit parentcolumn space
  if (widget.width.mode === "fitContent" && parent) {
    hydrateFitContentWidths(
      widget,
      widthHydrationContext,
      parent,
      theme,
      appMode,
    );
  }

  widget.children?.forEach((child) => {
    if (!child) return;
    if (!hasRuntimePositionProperties(widget)) throw Error("");

    const childIsCanvas = child.type === WidgetTypes.CANVAS_WIDGET;
    const childIsSection = child.type === WidgetTypes.SECTION_WIDGET;
    const childIsLoading = child.type === WidgetTypes.SKELETON_WIDGET;

    const parentIsNotCanvas = widget.type !== WidgetTypes.CANVAS_WIDGET;
    const parentIsSection = widget.type === WidgetTypes.SECTION_WIDGET;
    if (!child.height) {
      logger.warn(`widget ${child.widgetId} ${child.type} has no height`);
    }

    if (
      !parentIsSection &&
      child.height?.mode === "fitContent" &&
      !child.maxHeight &&
      child.layout !== CanvasLayout.HSTACK
    ) {
      child.shouldScrollContents = false;
    } else if (parentIsSection) {
      // There is a minHeight on the main canvas from pre-layouts work that we need to remove
      child.minHeight = undefined;
    }

    // TODO - should horizontal scroll be its own property?
    if (
      child.layout === CanvasLayout.HSTACK ||
      child.layout === CanvasLayout.VSTACK
    ) {
      widget.shouldScrollContents = true;
    }

    if (
      !parentIsSection &&
      (childIsCanvas || (childIsLoading && parentIsNotCanvas)) // if widget type is canvas, the child SKELETON(loading) widget is a not canvas, there is no need to set left/right column for it
    ) {
      // Make sure the canvases resize
      child.left = Dimension.gridUnit(0);
      child.width = widget.width;
      child.gridColumns = widget.gridColumns;
    }

    if (
      childIsCanvas ||
      (childIsLoading && parentIsNotCanvas) // if widget type is canvas, the child SKELETON(loading) widget is a not canvas, there is no need to set left/right column for it
    ) {
      // Make sure the canvases resize
      child.left = Dimension.gridUnit(0);
      // Some canvas widgets have a width of 0 which is incorrect, but do have a correct gridColumns value
      // so use the gridColumns value in those cases
      // TODO(Mark): Should migrate this bug away, and setup the width correctly on new slideout sections
      if (child.width.value === 0) {
        child.width = widget.width;
      } else if (child.gridColumns && child.gridColumns !== child.width.value) {
        child.width = Dimension.gridUnit(child.gridColumns);
      }
    }

    if (isParentAnEmptySection && child.type === WidgetTypes.CANVAS_WIDGET) {
      const minHeight = getInternalHeightGridUnits(child, widget, theme, flags);
      child.height = minHeight;
    }

    // Make sure the children of stretched vstacks resize
    // TODO(Layouts) - we should stretch children when this value changes to fit on the new canvas
    const isStretchedVStack =
      widget.type === WidgetTypes.CANVAS_WIDGET &&
      widget.layout === CanvasLayout.VSTACK &&
      (widget.alignment || CanvasAlignment.STRETCH) === CanvasAlignment.STRETCH;
    const isWidgetStretchedHStack =
      widget.type === WidgetTypes.CANVAS_WIDGET &&
      widget.layout === CanvasLayout.HSTACK &&
      (widget.distribution || CanvasDistribution.STRETCH) ===
        CanvasDistribution.STRETCH;
    const isChildStretchedHStack =
      child.type === WidgetTypes.CANVAS_WIDGET &&
      child.layout === CanvasLayout.HSTACK &&
      (child.distribution || CanvasDistribution.STRETCH) ===
        CanvasDistribution.STRETCH;

    if (!child.width) {
      // currently not sure how this occurs, could be an unusual loading state
      child.width = Dimension.gridUnit(widget.gridColumns ?? 1);
    }
    const isChildFitContentWidthContainer =
      child.width.mode === "fitContent" && child.children?.length;
    const isChildOfFitContentWidth =
      widget.width.mode === "fitContent" ||
      parent?.width?.mode === "fitContent";
    const isChildOfFixedLayout = !isStackLayout(widget.layout);

    // it does not make sense to compute parentColumnSpace for children of fit-content width containers/canvases
    if (isChildOfFitContentWidth) {
      if (child.type === WidgetTypes.CANVAS_WIDGET) {
        child.gridColumns = widget.gridColumns;
      }
      if (!child.parentColumnSpace) {
        // This is a just in case - in theory we should be able to throw if we get here
        child.parentColumnSpace = widget.parentColumnSpace;
      }
    } else {
      // Important: this should happen after all the transformations above
      const parentColumnSpace = getParentColumnSpace(
        widget,
        child,
        responsiveCanvasScaledWidth,
      );
      child.parentColumnSpace = parentColumnSpace;
    }
    child.parentRowSpace = widget.parentRowSpace;

    // this 2nd check is for widgets like containers - as the hstack property is on the canvas
    if (isStretchedVStack) {
      const fullGridColumns = widget.gridColumns
        ? Dimension.gridUnit(widget.gridColumns)
        : widget.width;

      child.left = Dimension.gridUnit(0);
      child.width = Dimension.toGridUnit(
        fullGridColumns,
        child.parentColumnSpace,
      ).raw();
      child.gridColumns = widget.gridColumns;
    } else if (isWidgetStretchedHStack || isChildStretchedHStack) {
      child.top = Dimension.gridUnit(0);
      child.height = Dimension.gridUnit(widget.height.value);
    } else if (
      child.height?.mode === "fillParent" &&
      isStackLayout(widget.layout)
    ) {
      child.height = Dimension.fillParent(widget.height.value);
    }
    if (isChildFitContentWidthContainer && isChildOfFitContentWidth) {
      child.gridColumns = widget.gridColumns;
    }

    // Cases where we dont want to establish a maxWidth on the child:
    // 1. if the child can grow and the parent can grow too
    // 2. if the child is fixed pixel width and not a canvas
    // 3. if the child has its own maxWidth
    // 4. if the child is in an hstack
    if (
      widget.internalWidth &&
      !(child.width.mode === "fitContent" && isChildOfFitContentWidth) &&
      (child.width.mode !== "px" || child.type === WidgetTypes.CANVAS_WIDGET) &&
      !child.maxWidth &&
      widget.layout !== CanvasLayout.HSTACK
    ) {
      child.internalMaxWidth = Dimension.minus(
        widget.internalWidth,
        Margin.x(child.margin),
      ).asFirst();
    }

    // remove min and max width for children of fixed grid layouts
    if (isChildOfFixedLayout) {
      child.minWidth = undefined;
      child.maxWidth = undefined;
    }

    if (widget.type === WidgetTypes.CANVAS_WIDGET) {
      child.parentLayout = widget.layout; // needed so calculations involving getApplicableMin/Max width work
    }

    if (parentIsSection && widget.isStacked) {
      const paddingX = Padding.x(
        widget.padding ?? getWidgetDefaultPadding(theme, widget),
      );

      child.left = Dimension.gridUnit(0);
      child.width = Dimension.minus(
        Dimension.toPx(
          Dimension.gridUnit(fullGridColumns ?? widget.width.value),
          widget.parentColumnSpace,
        ),
        paddingX,
      ).asFirst();
    }

    if (
      childIsCanvas ||
      (childIsSection && widget.type === WidgetTypes.PAGE_WIDGET)
    ) {
      child.internalWidth = getCanvasInternalMinWidthPx(child, widget, theme);
    }

    let adjustWidthToFitChildren = false;

    if (parentIsNotCanvas) {
      // Parents control their children's scroll
      if (!parentIsSection) {
        child.shouldScrollContents = false;
        child.canExtend =
          widget.shouldScrollContents || widget.height.mode === "fitContent";
      }

      switch (widget.type) {
        case WidgetTypes.GRID_WIDGET: {
          const grid = widget;
          child.width = Dimension.px(
            componentWidth / (grid.columnCount ?? 1) - WIDGET_PADDING * 2 - 2,
          ) as unknown as Dimension<"gridUnit">;
          // Unlike every other component, the grid's snapping is based on its inner
          // container (2 levels deep) because this is repeated
          child.gridColumns = get(child, "children.0.children.0.gridColumns");
          child.parentColumnSpace =
            child.width.value / (child.gridColumns ?? 96); // not sure if using safeGridColumns would break this default
          child.internalWidth = Dimension.toPx(
            child.width,
            child.parentColumnSpace,
          );
          // make cell container scrollable based on grid property
          if (
            child?.children?.length &&
            grid?.gridCellScrollable !== undefined
          ) {
            // check undefined in case some user want the unexpected scrollable cell behavior before we make the toggle explicit
            child.children[0].shouldScrollContents = grid?.gridCellScrollable;
          }

          child.shouldScrollContents = false;
          break;
        }
        case WidgetTypes.SLIDEOUT_WIDGET: {
          const slideout = widget;
          if (slideout.size === SlideoutSize.FULLSCREEN) {
            const slideoutWidth = responsiveCanvasScaledWidth - SLIDEOUT_GAP;

            // Make full-screen slideout dimensions same as main canvas, minus the left gap and padding
            child.left = Dimension.gridUnit(0);
            child.width = Dimension.px(slideoutWidth);
            child.gridColumns = SLIDEOUT_DEFAULT_COLUMNS.value;

            /*
              Note: This code currently exists because there was a bug introduced into production where a full-screen Slideout 
              could be default, grid columns wide. So, what we do is we check if anything is larger than our current default value of 80,
              and if that's the case, then we temporarily update the parent's column widths.
              At the time the default screen columns was 96
              TODO: migrate this away
            */
            const maxChildRightColumn = getMaxChildRightColumn(child);
            if (maxChildRightColumn > SLIDEOUT_DEFAULT_COLUMNS.value) {
              // Update the nested Section
              child.gridColumns = GridDefaults.DEFAULT_GRID_COLUMNS;
              // Update the nested Section's Column
              const canvas = child.children?.[0];
              if (canvas?.type === WidgetTypes.CANVAS_WIDGET) {
                canvas.gridColumns = child.gridColumns;
                canvas.width = child.width;
              }
            }
            // This is a fix so that the slideout section has the correct value when it runs getVisibleGridColumns
            if (child.children?.length === 1) {
              const canvas = child.children[0];
              if (
                canvas?.type === WidgetTypes.CANVAS_WIDGET &&
                canvas?.gridColumns == null
              ) {
                canvas.gridColumns = child.gridColumns;
              }
            }

            child.parentColumnSpace = slideoutWidth / getSafeGridColumns(child);
          } else {
            const defaultSlideoutWidth =
              SLIDEOUT_WIDTH_PRESETS[SlideoutSize.MEDIUM];
            const slideoutWidth = (SLIDEOUT_WIDTH_PRESETS[
              slideout.size as SlideoutSize
            ] || defaultSlideoutWidth) as number;

            child.left = Dimension.gridUnit(0);
            child.width = Dimension.px(slideoutWidth);
            child.parentColumnSpace =
              slideoutWidth / SLIDEOUT_DEFAULT_COLUMNS.value;
          }
          // update the inner width for sections
          if (childIsSection) {
            child.internalWidth = getCanvasInternalMinWidthPx(
              child,
              widget,
              theme,
            );
          }
          break;
        }
        case WidgetTypes.MODAL_WIDGET: {
          // This is Part 1 of an override to support legacy modals, which have 40 grid columns rather than the current 96.
          // We get the modal grid columns we should use from the helper, then ensure those are set on the modal, section, and column inside the section.
          // Note that if a user adds a new section column in edit mode to this section inside a modal, the section will be resized to 96 columns
          // and the legacy modal width property gets removed.
          const modalColumns = getModalColumns(widget);
          widget.gridColumns = modalColumns;
          widget.width = Dimension.gridUnit(modalColumns);

          const modalPercentWidth = (MODAL_WIDTH_PRESETS[
            widget.widthPreset as ModalSize
          ] || MODAL_WIDTH_PRESETS[ModalSize.SMALL]) as number;
          const modalPixelWidth =
            responsiveCanvasScaledWidth * (modalPercentWidth / 100) -
            (widget.widthPreset === ModalSize.FULLSCREEN
              ? MODAL_PERIMETER_GAP * 2
              : 0);

          child.left = Dimension.gridUnit(0);
          child.width = Dimension.px(modalPixelWidth);
          child.parentColumnSpace = modalPixelWidth / modalColumns;
          child.gridColumns = modalColumns;

          // Part 2 of modal bug fix, ensuring we set the modalColumns on the section canvas
          if (child.children?.length === 1) {
            const canvas = child.children[0];
            if (canvas?.type === WidgetTypes.CANVAS_WIDGET) {
              canvas.gridColumns = modalColumns;
            }
          }
          if (childIsSection) {
            child.internalWidth = getCanvasInternalMinWidthPx(
              child,
              widget,
              theme,
            );
          }
          break;
        }
        default: {
          if (child.type === WidgetTypes.CANVAS_WIDGET) {
            if (widget.height.mode === "fillParent") {
              widget.height = Dimension.build(
                componentHeight / GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
                widget.height.mode,
              );
            }

            const containerH = getInternalHeightGridUnits(
              child,
              widget,
              theme,
              flags,
            );
            const contentH = Dimension.build(
              Dimension.toGridUnit(
                getCanvasMinHeightNested(child),
                GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              ).raw().value,
              child.height.mode,
            );
            // This logic needs to match WidgetOperationSagas in the resizeSaga
            const shouldScrollContents =
              (parentIsSection && child.shouldScrollContents === true) ||
              widget.shouldScrollContents;
            if (shouldScrollContents) {
              adjustWidthToFitChildren = true;
            }

            if (widget.isStacked) {
              const hasFillParentChild = child.children?.some(
                (child) => child.height.mode === "fillParent",
              );
              // Show the default size when there are no children
              const hasChildren = child.children?.length !== 0;
              if (isChildStretchedHStack) {
                child.height = containerH;
              } else if (!hasFillParentChild && hasChildren) {
                child.height = contentH;
              } else {
                child.height = Dimension.max(
                  dimensionToGridRows(containerH),
                  dimensionToGridRows(contentH),
                ).asFirst();
              }
            } else if (shouldScrollContents) {
              child.height = Dimension.max(
                dimensionToGridRows(contentH),
                dimensionToGridRows(containerH),
              ).asFirst();
            } else {
              child.height = Dimension.build(containerH.value, contentH.mode);
            }

            // We don't want child canvases to have a minHeight as it may cause the canvas to create an unexpected scroll inside it's container
            // and users currently don't have access to update the minHeight
            // Do not apply this the first canvas of the first section if flag is OFF, as that canvas currently needs a minHeight
            // to not become super short
            // TODO: Mark will remove after layouts migration is complete
            if (widget.type !== WidgetTypes.SECTION_WIDGET) {
              child.minHeight = undefined;
            }
          }
          break;
        }
      }
    }

    recalculateWidgetsLayout({
      widget: child,
      responsiveCanvasScaledWidth,
      theme,
      flags,
      appMode,
      parent: widget,
      widthHydrationContext,
    });

    // this needs to be run after child subtree has been processed in order for the child parentColumnSpaces to be properly set
    if (adjustWidthToFitChildren) {
      const containerW = getCanvasInternalMinWidthPx(child, widget, theme);
      const contentW = getCanvasMinWidthNested(child);
      child.internalWidth = Dimension.toPx(
        Dimension.max(contentW, containerW).asFirst(),
        child.parentColumnSpace,
      );
    }
  });
};

// Gets the minimum width of the content of a canvas - basically the width of the inner part
// of the canvas if there were no children rendered
export const getCanvasInternalMinWidthPx = (
  child: WidgetLayoutProps | FlattenedWidgetLayoutProps,
  parent: WidgetLayoutProps | FlattenedWidgetLayoutProps,
  theme: GeneratedTheme,
) => {
  const childPadding = child.padding ?? getWidgetDefaultPadding(theme, child);
  const excessWidth =
    Padding.x(childPadding).value +
    getBorderThickness(parent, theme, child) * 2;

  const isParentDynamic = isDynamicSize(parent.width.mode);
  let outerWidthPx = Dimension.toPx(
    (isParentDynamic ? parent.dynamicWidgetLayout?.width : undefined) ??
      child.width,
    child.parentColumnSpace,
  ).value;

  const applicableParentMinWidth = getApplicableMinWidth(parent);
  if (applicableParentMinWidth) {
    outerWidthPx = Math.max(
      Dimension.toPx(applicableParentMinWidth, parent.parentColumnSpace).value,
      outerWidthPx,
    );
  }

  const applicableParentMaxWidth = getApplicableMaxWidth(parent);
  if (applicableParentMaxWidth) {
    outerWidthPx = Math.min(
      Dimension.toPx(applicableParentMaxWidth, parent.parentColumnSpace).value,
      outerWidthPx,
    );
  }

  if (parent.internalWidth) {
    outerWidthPx = Math.min(outerWidthPx, parent.internalWidth.value);
  }

  return Dimension.px(
    Math.max(
      outerWidthPx - excessWidth,
      CanvasDefaults.MIN_GRID_UNIT_WIDTH, // in case the above value is super small or negative somehow
    ),
  );
};

export const getInternalHeightGridUnits = (
  child: Omit<StaticWidgetProps, "parentColumnSpace" | "parentRowSpace">,
  parent: Omit<StaticWidgetProps, "parentColumnSpace" | "parentRowSpace">,
  theme: GeneratedTheme,
  flags: Partial<AllFlags> = {},
): Dimension<WidgetHeightModes> => {
  const childPadding = child.padding ?? getWidgetDefaultPadding(theme, child);
  const paddingYPx = Padding.y(childPadding);
  const borderWidth = getBorderThickness(parent, theme, child) * 2;
  let parentHeightPx = Dimension.toPx(
    parent.height,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).value;
  const applicableParentMinHeight = getApplicableMinHeight(parent);
  if (applicableParentMinHeight) {
    parentHeightPx = Math.max(
      Dimension.toPx(
        applicableParentMinHeight,
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).value,
      parentHeightPx,
    );
  }
  const innerHeightPx = parentHeightPx - paddingYPx.value - borderWidth;

  let heightModifierGridUnits = 0;
  if (parent.type === WidgetTypes.TABS_WIDGET) {
    const showTabs = parent.shouldShowTabs ?? true;
    if (showTabs) {
      if (!flags[Flag.ENABLE_TYPOGRAPHY]) {
        heightModifierGridUnits = 3;
      } else {
        const headerProps = parent?._semiStaticProps?.headerProps;
        const lineHeightPx = getLineHeightInPxFromTextStyle({
          textStyleVariant: headerProps?.textStyle?.variant,
          defaultTextStyleVariant: DEFAULT_TABS_WIDGET_HEADER_STYLE_VARIANT,
          nestedProps: headerProps?.textStyle,
          typographies: theme.typographies,
        });
        const tabHeaderHeightPx = computeTabHeaderHeightPx(
          lineHeightPx,
          showTabs,
        );
        heightModifierGridUnits = Dimension.toGridUnit(
          Dimension.px(tabHeaderHeightPx),
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ).raw().value;
      }
    }
  }

  const minHeightPx: Dimension<"px"> = Dimension.minus(
    Dimension.px(innerHeightPx),
    Dimension.toPx(
      Dimension.gridUnit(heightModifierGridUnits),
      GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
    ),
  ).asFirst();
  const gridUnit = Dimension.toGridUnit(
    minHeightPx,
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  );

  return Dimension.build(
    gridUnit.raw().value,
    child.type === WidgetTypes.CANVAS_WIDGET
      ? "fitContent"
      : parent.height.mode,
  );
};

/**
 * @returns a boolean value indicating whether the parent section widget is empty or not and in the default state.
 */
function isAnEmptySection(widget: WidgetLayoutProps) {
  let isParentAnEmptySection = false;
  if (widget.type === WidgetTypes.SECTION_WIDGET) {
    const heightIsFitContent = widget.height.mode === "fitContent";
    const minHeightAndMaxHeightNotSet = !widget.minHeight && !widget.maxHeight;
    if (heightIsFitContent && minHeightAndMaxHeightNotSet) {
      isParentAnEmptySection = true;
      const columns = widget.children ?? [];
      columns.forEach((column) => {
        if (
          column.children &&
          column.children.filter((w) => DETACHED_WIDGETS.indexOf(w.type) === -1)
            .length
        ) {
          isParentAnEmptySection = false;
        }
      });
    }
  }
  return isParentAnEmptySection;
}

/**
 * @returns the number of empty rows to show for a given parent widget.
 */
function getEmptyRows(
  parent: WidgetLayoutProps | undefined,
  appMode: APP_MODE,
) {
  if (appMode !== APP_MODE.EDIT) return 0;
  const isOnlySectionOnThePage =
    (parent?.parentId === PAGE_WIDGET_ID || parent?.parentId === undefined) &&
    parent?.children?.length === 1;
  const emptyRows = isOnlySectionOnThePage
    ? SectionDefaults.EMPTY_FIRST_SECTION_GRID_ROWS
    : getSectionGridRowsForParentType(parent?.type ?? WidgetTypes.PAGE_WIDGET);
  return emptyRows;
}

export function scaledXYCoord<T extends { x: number; y: number }>(
  offset: T,
  canvasScaleFactor: number,
): T {
  if (offset === null) {
    return offset;
  }
  const cloned = fastClone(offset);
  cloned.x = offset.x / canvasScaleFactor;
  cloned.y = offset.y / canvasScaleFactor;
  return cloned;
}

const getMaxChildRightColumn = (
  parentWidget: WidgetLayoutProps,
  currentMaxRightCol?: number,
): number => {
  let maxRightCol = currentMaxRightCol || 0;

  parentWidget.children?.forEach((child) => {
    if (!child) return;
    const childRightCol = (child.left?.value || 0) + (child.width?.value || 0);
    if (childRightCol > maxRightCol) {
      maxRightCol = childRightCol;
    }
    if (child.children) {
      maxRightCol = getMaxChildRightColumn(child, maxRightCol);
    }
  });

  return maxRightCol;
};
