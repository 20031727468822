// Note that for each flag you intend to use here, you must
// enable this flag to be used with the client SDK
// inside of the Settings section of the given flag, otherwise
// the flag simply won't be returned to the client
export enum Flag {
  // Setting the Timers feature's minimum allowed interval
  TIMERS_MIN_INTERVAL = "ui.timers-min-interval",

  // Enabling control flow
  SHOW_CONTROL_FLOW = "ui.show-control-flow",

  // Overrides SHOW_CONTROL_FLOW regardless of OPA configuration
  OVERRIDE_CONTROL_FLOW_ON = "ui.control-flow-override-on",

  // Enabling control flow for workflows and jobs
  SHOW_CONTROL_FLOW_WORKFLOWS_JOBS = "ui.show-control-flow-workflows-jobs",

  // Enabling control flow control blocks
  SHOW_CONTROL_FLOW_CONTROL_BLOCKS = "ui.show-control-flow-control-blocks",

  SHOW_CONTROL_FLOW_PARALLEL_BLOCK = "ui.show-control-flow-parallel",

  SHOW_CONTROL_FLOW_CONTROL_BLOCKS_WORKFLOWS = "ui.show-control-blocks-workflows",

  SHOW_CONTROL_FLOW_CONTROL_BLOCKS_JOBS = "ui.show-control-blocks-jobs",

  SHOW_CONTROL_FLOW_ADVANCED_VARIABES = "ui.show-control-flow-advanced-variables",

  // Enabling/disabling org owned integrations
  ORG_OWNED_INTEGRATIONS_ENABLED = "server.rbac.org-owned-integrations-enabled",

  // Enabling profiles UI and send requests with profile param
  ENABLE_PROFILES = "superblocks.profiles.settings-enabled",

  // Enable / disable the AI Assistant feature in code editors
  ENABLE_AI_ASSISTANT = "ui.ai-assistant-enabled",

  // enable / disable passing metadata to AI assistant
  ENABLE_AI_ASSISTANT_METADATA = "ui.ai-assistant-metadata-enabled",

  // Enable/disable autosaves
  ENABLE_AUTOSAVES = "ui.autosaves",

  // for orgs that still use Global.URL.queryParams.environment in app
  ENABLE_PROFILES_WITH_ENV = "superblocks.profiles.settings-enabled-with-env",

  // IFrame wrapper for apps
  ENABLE_IFRAME = "ui.enable-iframe",

  // Enabling custom components
  ENABLE_CUSTOM_COMPONENTS = "ui.custom-components",

  // Enable streaming
  ENABLE_STREAMING = "ui.enable-streaming",

  // Do not extract bindings from APIs until and only when it is actually needed
  ENABLE_LAZY_API_BINDING_EXTRACTION = "ui.lazy-api-binding-extraction",

  // Enable openAPI doc
  ENABLE_OPENAPI_DOC = "ui.enable-openapi-doc",

  PARALLEL_POOL_MAX_SIZE = "agent.quota.api.parallel.pool.size.max",

  ENABLE_REPOSITORIES_PAGE = "ui.enable-repositories-page",

  ENABLE_SUPERBLOCKS_SUPPORT_UPDATES = "server.rbac.superblocks-support-updates-enabled",

  // Use Cloud OPA
  USE_CLOUD_OPA = "ui.use-cloud-opa",

  // Percentage of requests routed to opas if opa is enabled,
  OPA_REQUEST_PERCENTAGE = "ui.opa-request-percentage",

  LAYOUTS_ENABLE_MARGIN = "ui.layouts.enable-margin-padding",

  LAYOUTS_ENABLE_GROUPING = "ui.layouts.enable-group-shortcut",

  // Props panel

  ENABLE_PROPS_PANEL_V2 = "ui.props-panel-v2.enable",

  // Typography
  ENABLE_TYPOGRAPHY = "ui.typography.enabled",

  ENABLE_PADDING_ADVANCED_MODE = "ui.padding.enable-advanced-mode",

  ENABLE_BRANCHES = "ui.enable-branches",

  ENABLE_SECRETS_MANAGEMENT = "ui.enable-secrets-management",

  ENABLE_MULTI_REPOS = "ui.enable-multi-repos",

  ENABLE_GITLAB = "ui.enable-gitlab",

  ENABLE_BRANCH_PROTECTION = "ui.enable-branch-protection",

  ENABLE_TABLE_IMAGE_SIZE = "ui.enable-table-image-size",

  ENABLE_RESOURCE_SIGNING = "ui.enable-resource-signing",

  // Show experimental integration fields
  INTEGRATION_ENABLE_EXPERIMENTAL = "ui.integration.enable-experimental",

  ENABLE_APP_CLONE = "ui.enable-app-clone",

  ENABLE_EMBED = "ui.enable-embed",

  ENABLE_ACCESS_TOKENS_PAGE = "ui.enable-access-tokens-page",

  ACCESS_TOKEN_MAX_EXPIRATION_SECONDS = "server.org.token.max.expiration.seconds",

  ENABLE_MULTIPAGE = "ui.multi-page.enabled",
  MULTIPAGE_SLIDEOUTS = "ui.multi-page.slideouts",
  MULTIPAGE_ROUTE_EVENTS = "ui.multi-page.route-events",

  ENABLE_ORG_MIGRATE_TO_EU_BANNER = "ui.banner.org-migrated-to-eu.enabled",

  SALES_SETTINGS_JSON = "superblocks.sales_settings.json",
  SALES_BILLING_EMAIL = "superblocks.sales_billing_email",

  ENABLE_CUSTOM_EVENTS = "ui.enable-custom-events",

  SKIP_UNESCAPE_IN_EVALUATOR = "ui.evaluator.skip-unescape", // TODO. To be removed: EG-17106

  CODE_FORMATTING = "ui.code-editor.enable-code-formatting",

  ENABLE_TEMPLATE = "ui.enable-template",

  ENABLE_MENU_WIDGET = "ui.widgets.menu.enabled",

  ENABLE_DEPLOY_CONFIRMATION = "ui.enable-deploy-confirmation",

  ENABLE_APP_SCOPE = "ui.app-scope.enabled",

  DISABLE_LOAD_METADATA_FOR_INTEGRATIONS = "ui.disable-load-metadata-for-integrations",

  ENABLE_INTERCOM_LIVE_CHAT = "ui.intercom-live-chat.enabled",
}

export type AllFlags = {
  [K in Flag]: boolean | number | string | Record<string, any>;
};
export type Flags = Partial<AllFlags>;

export const defaults = {
  [Flag.TIMERS_MIN_INTERVAL]: 5000,
  [Flag.ORG_OWNED_INTEGRATIONS_ENABLED]: false,
  [Flag.ENABLE_PROFILES]: true,
  [Flag.ENABLE_AUTOSAVES]: false,
  [Flag.ENABLE_PROFILES_WITH_ENV]: false,
  [Flag.ENABLE_IFRAME]: false,
  [Flag.SHOW_CONTROL_FLOW]: false,
  [Flag.OVERRIDE_CONTROL_FLOW_ON]: false,
  [Flag.SHOW_CONTROL_FLOW_WORKFLOWS_JOBS]: false,
  [Flag.SHOW_CONTROL_FLOW_CONTROL_BLOCKS]: false,
  [Flag.SHOW_CONTROL_FLOW_PARALLEL_BLOCK]: false,
  [Flag.SHOW_CONTROL_FLOW_CONTROL_BLOCKS_WORKFLOWS]: false,
  [Flag.SHOW_CONTROL_FLOW_CONTROL_BLOCKS_JOBS]: false,
  [Flag.ENABLE_CUSTOM_COMPONENTS]: false,
  [Flag.ENABLE_STREAMING]: false,
  [Flag.ENABLE_AI_ASSISTANT]: false,
  [Flag.ENABLE_AI_ASSISTANT_METADATA]: true,
  [Flag.ENABLE_LAZY_API_BINDING_EXTRACTION]: true,
  [Flag.ENABLE_OPENAPI_DOC]: false,
  [Flag.PARALLEL_POOL_MAX_SIZE]: 50,
  [Flag.ENABLE_REPOSITORIES_PAGE]: false,
  [Flag.SHOW_CONTROL_FLOW_ADVANCED_VARIABES]: false,
  [Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES]: false,
  [Flag.USE_CLOUD_OPA]: false,
  [Flag.OPA_REQUEST_PERCENTAGE]: 10,
  [Flag.ENABLE_PADDING_ADVANCED_MODE]: false,
  [Flag.ENABLE_BRANCHES]: false,
  [Flag.ENABLE_SECRETS_MANAGEMENT]: false,
  [Flag.ENABLE_MULTI_REPOS]: false,
  [Flag.ENABLE_GITLAB]: false,
  [Flag.ENABLE_BRANCH_PROTECTION]: false,
  [Flag.ENABLE_TABLE_IMAGE_SIZE]: false,
  [Flag.ENABLE_RESOURCE_SIGNING]: false,
  [Flag.INTEGRATION_ENABLE_EXPERIMENTAL]: false,
  [Flag.ENABLE_APP_CLONE]: true, // this will only be disabled for customers using application signing
  [Flag.ENABLE_EMBED]: false,
  [Flag.ENABLE_ACCESS_TOKENS_PAGE]: false,
  [Flag.ACCESS_TOKEN_MAX_EXPIRATION_SECONDS]: 31536000, // 1 year
  [Flag.ENABLE_MULTIPAGE]: false,
  [Flag.MULTIPAGE_SLIDEOUTS]: false,
  [Flag.MULTIPAGE_ROUTE_EVENTS]: false,
  [Flag.ENABLE_ORG_MIGRATE_TO_EU_BANNER]: false,
  [Flag.ENABLE_CUSTOM_EVENTS]: false,
  [Flag.SKIP_UNESCAPE_IN_EVALUATOR]: false, // TODO. To be removed: EG-17106
  [Flag.SALES_SETTINGS_JSON]: {
    app_block_reason: null,
    block_config: {
      title: null,
      message: null,
      closable: null,
    },
  },
  [Flag.SALES_BILLING_EMAIL]: "billing-requests@superblockshq.com",
  [Flag.LAYOUTS_ENABLE_MARGIN]: false,
  [Flag.LAYOUTS_ENABLE_GROUPING]: false,
  [Flag.ENABLE_PROPS_PANEL_V2]: false,
  [Flag.ENABLE_TYPOGRAPHY]: false,
  [Flag.CODE_FORMATTING]: false,
  [Flag.ENABLE_TEMPLATE]: false,
  [Flag.ENABLE_MENU_WIDGET]: false,
  [Flag.ENABLE_DEPLOY_CONFIRMATION]: true,
  [Flag.ENABLE_APP_SCOPE]: false,
  [Flag.DISABLE_LOAD_METADATA_FOR_INTEGRATIONS]: [],
  [Flag.ENABLE_INTERCOM_LIVE_CHAT]: false,
} satisfies AllFlags;
