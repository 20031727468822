import { TASKS } from "@superblocksteam/shared";
import { Typography } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as JobIllustration } from "assets/icons/home/job-illustration.svg";
import { Button } from "components/ui/Button";
import { selectUserChecklist } from "store/slices/user";
import { EntityType } from "utils/entity";
import { getColorForType, getIconForType } from "../EntityCard";
import {
  CardBody,
  CardTitleRow,
  CreateEntityIconWrapper,
  ICON_SIZE,
  StyledCard,
} from "./CommonComponents";
import { CommonCardProps, CreateSteps } from "./common";

export const CreateJobCard = (props: CommonCardProps) => {
  const {
    highlight,
    setGeneratedEntityType,
    resetFields,
    setActiveStep,
    processFieldValues,
  } = props;

  const [isCreating, setIsCreating] = useState(false);

  const checklist = useSelector(selectUserChecklist);
  const scheduledJobNotDone = !checklist.find(
    (task) => task.id === TASKS.DEPLOY_SCHEDULED_JOB,
  )?.completed;

  const handleCreateNew = useCallback(
    async (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      ev.stopPropagation();
      setIsCreating(true);
      await processFieldValues();
      setActiveStep(CreateSteps.JOB_SCHEDULE);
      resetFields();
      setIsCreating(false);
    },
    [resetFields, setActiveStep, processFieldValues],
  );

  return (
    <StyledCard
      highlight={highlight}
      color={getColorForType(EntityType.SCHEDULED_JOB)}
      onMouseEnter={(e) => {
        setGeneratedEntityType(EntityType.SCHEDULED_JOB);
      }}
      disabled={isCreating}
      onClick={handleCreateNew}
    >
      <JobIllustration />
      <CardBody>
        <CreateEntityIconWrapper
          className="entity-type-icon"
          color={getColorForType(EntityType.SCHEDULED_JOB)}
          size={ICON_SIZE}
        >
          {getIconForType(EntityType.SCHEDULED_JOB)}
        </CreateEntityIconWrapper>
        <CardTitleRow
          entityType={EntityType.SCHEDULED_JOB}
          taskNotDone={scheduledJobNotDone}
        />
        <Typography.Paragraph>
          Schedule a job to run every minute, hour, day, week or month for
          reporting or ETL
        </Typography.Paragraph>
        <Button
          type="default"
          block
          onClick={handleCreateNew}
          data-test="create-scheduled-job"
        >
          Create Scheduled Job
        </Button>
      </CardBody>
    </StyledCard>
  );
};
