import { LoadingOutlined } from "@ant-design/icons";
import {
  AccessMode,
  AgentStatus,
  AgentType,
  Organization,
} from "@superblocksteam/shared";
import { Alert, Col, Row, Space, Steps, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Layout, MainWrapper } from "components/app";
import { Button } from "components/ui/Button";
import { SUPERBLOCKS_UI_DATA_DOMAIN } from "env";
import { useSaga } from "hooks/store";
import { useFeatureFlag } from "hooks/ui";
import { useInterval } from "hooks/ui/useInterval";
import { AGENTS_BASE_URL } from "legacy/constants/routes";
import CreateTokenModal from "pages/AccessTokens/CreateTokenModal";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { AGENTS_TITLE, PageWrapper } from "pages/components/PageWrapper";
import { BreadCrumb } from "pages/components/navigation/BreadCrumb";
import initializeUser from "store/sagas/initializeUser";
import { getNewAgents } from "store/slices/agents/client";
import { Flag } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import {
  getOrganizationAgentKeys,
  updateOrganization,
} from "store/slices/organizations/client";
import {
  AgentSwitchDeploymentModal,
  AgentSwitchDeploymentModalType,
} from "./AgentSwitchDeploymentModal";
import {
  StepDefineHost,
  StepHealthCheckFailed,
  StepHealthCheckSucceeded,
  StepParagraph,
} from "./DeploymentSteps";
import AKS from "./DeploymentTargets/AKS";
import CloudRun from "./DeploymentTargets/CloudRun";
import Docker from "./DeploymentTargets/Docker";
import ECS from "./DeploymentTargets/ECS";
import EKS from "./DeploymentTargets/EKS";
import GKE from "./DeploymentTargets/GKE";
import { ReactComponent as IconAKS } from "./assets/icon-aks.svg";
import { ReactComponent as IconCloudRun } from "./assets/icon-cloud-run.svg";
import { ReactComponent as IconDocker } from "./assets/icon-docker.svg";
import { ReactComponent as IconECS } from "./assets/icon-ecs.svg";
import { ReactComponent as IconEKS } from "./assets/icon-eks.svg";
import { ReactComponent as IconGKE } from "./assets/icon-gke.svg";
import {
  getLatestAgentRelease,
  AgentConfigStatus,
  AgentFormDefault,
  isHostnameValid,
} from "./common";
import {
  Docker_AgentKey,
  Docker_Target,
  Helm_AgentKey,
  Helm_Target,
  Terraform_AgentKey,
  Terraform_Target,
} from "./constants";

const { Text, Title } = Typography;

const NEW_AGENTS_CHECK_INTERVAL_MS = 10000;
const DEFAULT_AGENT_ENVIRONMENT = "*";

const DescriptionText = styled(Text)`
  font-size: 18px;
  color: ${(props) => props.theme.colors.GREY_500};
`;

const Label = styled.span`
  background-color: ${(props) => props.theme.colors.GREY_100};
  color: ${(props) => props.theme.colors.GREY_500};
  position: absolute !important;
  font-size: 14px;
  height: 22px;
  right: -1px;
  top: -22px;
  border-radius: 4px 4px 0 0;
  padding: 0 8px;
`;

const StepTitle = styled(Title)`
  .ant-steps-item-active & {
    color: ${(props) => props.theme.colors.GREY_700};
  }
  .ant-steps-item-wait & {
    color: ${(props) => props.theme.colors.GREY_500};
  }
}
`;

const OptionButton = styled(Button)`
  &.ant-btn-primary,
  &.ant-btn-background-ghost:focus {
    background-color: ${(props) => props.theme.colors.SUBTLE_BLUE};
  }

  & > svg {
    position: relative;
    top: 3px;
    left: -2px;
    margin-right: 10px;
  }

  & > svg + span {
    position: relative;
    top: -2px;
  }
`;

const AgentDeploymentInstructionPage = () => {
  const [pageRenderedAt] = useState<number>(Date.now());
  const organization = useSelector(selectOnlyOrganization);
  const organizationId = organization.id;

  const [isAgentKeyExist, setIsAgentKeyExist] = useState(false);
  const [keyCreated, setKeyCreated] = useState(false);

  const [showNextStep, setShowNextStep] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const agentKeys = await getOrganizationAgentKeys(organizationId);
      if (agentKeys.length > 0) {
        setIsAgentKeyExist(true);
        setShowNextStep(true);
        if (currentStep === 0) {
          setCurrentStep(1);
        }
      }
    })();
  }, [
    organizationId,
    setIsAgentKeyExist,
    setShowNextStep,
    keyCreated,
    currentStep,
    setCurrentStep,
  ]);

  const [selected, setSelected] = useState<number>(0);
  const [domain, setDomain] = useState<string>(AgentFormDefault.DOMAIN);
  const [subdomain, setSubdomain] = useState<string>(
    AgentFormDefault.SUBDOMAIN,
  );
  const hostname = useMemo(() => {
    return subdomain && subdomain.length > 0
      ? `${subdomain}.${domain}`
      : domain;
  }, [subdomain, domain]);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [port, setPort] = useState<string>(AgentFormDefault.PORT);
  const [configStatus, setConfigStatus] = useState<AgentConfigStatus>(
    AgentConfigStatus.NULL,
  );
  const [activeAgentCount, setActiveAgentCount] = useState<number>(0);
  const [unreachableAgentCount, setUnreachableAgentCount] = useState<number>(0);
  const controlFlowOverrideEnabled = useFeatureFlag(
    Flag.OVERRIDE_CONTROL_FLOW_ON,
  );
  const checkForNewAgents = async () => {
    const newAgents = await getNewAgents(
      organization,
      new Date(pageRenderedAt),
      controlFlowOverrideEnabled,
    );
    const activeAgents = newAgents.filter(
      (agent) => agent.status === AgentStatus.ACTIVE,
    );
    const unreachableAgents = newAgents.filter(
      (agent) => agent.status === AgentStatus.BROWSER_UNREACHABLE,
    );
    setActiveAgentCount(activeAgents.length);
    setUnreachableAgentCount(unreachableAgents.length);
    if (activeAgents.length + unreachableAgents.length > 0) {
      setCurrentStep(currentStep + 1);
      if (activeAgents.length > 0) {
        setIsAgentSwitchModalVisible(activeAgents.length > 0);
      }
    }
  };
  useInterval(() => {
    (async () => {
      if (activeAgentCount + unreachableAgentCount === 0) {
        await checkForNewAgents();
      }
    })();
  }, NEW_AGENTS_CHECK_INTERVAL_MS);

  const retryHealthCheck = useCallback(() => {
    setActiveAgentCount(0);
    setUnreachableAgentCount(0);
    setCurrentStep(1);
  }, []);

  const [latestOpaVersion, setLatestOpaVersion] = useState("latest");
  useEffect(() => {
    getLatestAgentRelease().then((version) => setLatestOpaVersion(version));
  }, [latestOpaVersion]);

  const options = useMemo(() => {
    return [
      {
        name: "ECS Fargate",
        icon: <IconECS />,
        local: false,
        needPortConf: false,
        component: (
          <ECS
            key="deployment-target-ecs"
            agentDomain={domain}
            agentSubdomain={subdomain}
            agentEnvironment={DEFAULT_AGENT_ENVIRONMENT}
            agentDataDomain={SUPERBLOCKS_UI_DATA_DOMAIN}
            latestOpaVersion={latestOpaVersion}
            showInstruction={true}
          />
        ),
        deployWith: "Terraform",
        agentKeyText: Terraform_AgentKey,
        target: Terraform_Target,
      },
      {
        name: "CloudRun",
        icon: <IconCloudRun />,
        local: false,
        needPortConf: false,
        component: (
          <CloudRun
            key="deployment-target-cloud-run"
            agentDomain={domain}
            agentSubdomain={subdomain}
            agentEnvironment={DEFAULT_AGENT_ENVIRONMENT}
            agentDataDomain={SUPERBLOCKS_UI_DATA_DOMAIN}
            latestOpaVersion={latestOpaVersion}
            showInstruction={true}
          />
        ),
        deployWith: "Terraform",
        agentKeyText: Terraform_AgentKey,
        target: Terraform_Target,
      },
      {
        name: "Docker",
        icon: <IconDocker />,
        local: true,
        needPortConf: false,
        component: (
          <Docker
            key="deployment-target-docker"
            agentUrl={AgentFormDefault.URL}
            agentDataDomain={SUPERBLOCKS_UI_DATA_DOMAIN}
            latestOpaVersion={latestOpaVersion}
            showInstruction={showNextStep}
          />
        ),
        deployWith: "Docker",
        agentKeyText: Docker_AgentKey,
        target: Docker_Target,
      },
      {
        name: "EKS",
        icon: <IconEKS />,
        local: false,
        needPortConf: true,
        component: (
          <EKS
            key="deployment-target-eks"
            agentHost={hostname}
            agentUrl={`https://${hostname}:${port}`}
            agentEnvironment={DEFAULT_AGENT_ENVIRONMENT}
            agentDataDomain={SUPERBLOCKS_UI_DATA_DOMAIN}
            showInstruction={true}
          />
        ),
        deployWith: "Helm",
        agentKeyText: Helm_AgentKey,
        target: Helm_Target,
      },
      {
        name: "GKE",
        icon: <IconGKE />,
        local: false,
        needPortConf: true,
        component: (
          <GKE
            key="deployment-target-gke"
            agentHost={hostname}
            agentUrl={`https://${hostname}:${port}`}
            agentEnvironment={DEFAULT_AGENT_ENVIRONMENT}
            agentDataDomain={SUPERBLOCKS_UI_DATA_DOMAIN}
            showInstruction={true}
          />
        ),
        deployWith: "Helm",
        agentKeyText: Helm_AgentKey,
        target: Helm_Target,
      },
      {
        name: "AKS",
        icon: <IconAKS />,
        local: false,
        needPortConf: true,
        component: (
          <AKS
            key="deployment-target-aks"
            agentHost={hostname}
            agentUrl={`https://${hostname}:${port}`}
            agentEnvironment={DEFAULT_AGENT_ENVIRONMENT}
            agentDataDomain={SUPERBLOCKS_UI_DATA_DOMAIN}
            showInstruction={true}
          />
        ),
        deployWith: "Helm",
        agentKeyText: Helm_AgentKey,
        target: Helm_Target,
      },
    ];
  }, [domain, subdomain, hostname, port, latestOpaVersion, showNextStep]);

  const stepsEndRef = React.useRef<null | HTMLDivElement>(null);

  // Validate hostname when it's changed after config is generated
  useEffect(() => {
    if (currentStep > 0 && !isHostnameValid(hostname)) {
      setCurrentStep(0);
      setConfigStatus(AgentConfigStatus.ERROR);
    }
  }, [hostname, currentStep]);

  const generateConfig = useCallback(async () => {
    if (!isHostnameValid(hostname)) {
      setCurrentStep(0);
      setConfigStatus(AgentConfigStatus.ERROR);
      return;
    }

    setCurrentStep(currentStep + 1);
    setConfigStatus(AgentConfigStatus.SUCCESS);
  }, [hostname, currentStep, setCurrentStep, setConfigStatus]);

  const createToken = useCallback(async () => {
    setIsCreateModalOpen(true);
  }, [setIsCreateModalOpen]);

  const showInstructions = useCallback(() => {
    return currentStep > 1 || options[selected].local === true;
  }, [currentStep, options, selected]);

  const handleValueChange = useCallback((_: any, allValues: any) => {
    setDomain(allValues["domain"]);
    setSubdomain(allValues["subdomain"]);
    setPort(allValues["port"] || AgentFormDefault.PORT);
  }, []);

  const selectOption = useCallback(async (index: number) => {
    setSelected(index);
    setCurrentStep(0);
    setConfigStatus(AgentConfigStatus.NULL);
  }, []);

  const navigate = useNavigate();
  const [isAgentSwitchModalVisible, setIsAgentSwitchModalVisible] =
    useState(false);
  const agentSwitchModalType =
    AgentSwitchDeploymentModalType.TO_OPA_AFTER_DEPLOY;
  const cancelAgentSwitchModal = useCallback(() => {
    setIsAgentSwitchModalVisible(false);
  }, []);
  const [initializeUserSaga] = useSaga(initializeUser);
  const confirmAgentSwitchModal = useCallback(() => {
    setIsAgentSwitchModalVisible(false);

    const toUpdate: Partial<Organization> = {
      id: organizationId,
      agentType: AgentType.ONPREMISE,
    };
    updateOrganization(toUpdate);
    initializeUserSaga({
      accessMode: AccessMode.AUTH_USER,
      probeAllAgents: true,
    });
    navigate({
      pathname: AGENTS_BASE_URL,
    });
  }, [organizationId, initializeUserSaga, navigate]);

  const step1 = useMemo(() => {
    return {
      title: "Create access token",
      description: (
        <Space direction="vertical">
          <StepParagraph type="secondary">
            Create an access token that has permission to register new agents.
            This token will be used as your agent key when deploying agents.
          </StepParagraph>
          {!isAgentKeyExist && !keyCreated ? (
            <Button
              type="primary"
              size="middle"
              onClick={createToken}
              data-test="create-agent-token"
            >
              Create token
            </Button>
          ) : (
            <Alert
              type="success"
              showIcon={true}
              message={
                <div style={{ fontSize: 12 }}>
                  {!keyCreated ? (
                    <div style={{ fontSize: 12 }}>
                      Access token already created. Use any of your existing
                      tokens as{" "}
                      <strong> {options[selected].agentKeyText} </strong>{" "}
                      {options[selected].target}. Find information about your
                      agent keys on the{" "}
                      <Link to="/access-tokens">Access Tokens </Link> page.
                    </div>
                  ) : (
                    <div style={{ fontSize: 12 }}>
                      Access token created. Reference the token as{" "}
                      <strong> {options[selected].agentKeyText} </strong>{" "}
                      {options[selected].target}.{" "}
                    </div>
                  )}
                </div>
              }
              data-test="token-created-alert"
              style={{ width: "fit-content" }}
            />
          )}
        </Space>
      ),
    };
  }, [createToken, options, selected, isAgentKeyExist, keyCreated]);

  const step2 = useMemo(() => {
    return {
      title: "Define your agent's host",
      description: (
        <StepDefineHost
          title={options[selected].name}
          handleValueChange={handleValueChange}
          needPortConf={options[selected].needPortConf}
          domain={domain}
          subdomain={subdomain}
          generateConfig={generateConfig}
          configStatus={configStatus}
          showNextStep={showNextStep}
        />
      ),
    };
  }, [
    options,
    selected,
    domain,
    subdomain,
    generateConfig,
    configStatus,
    handleValueChange,
    showNextStep,
  ]);

  const step3 = useMemo(() => {
    return {
      title: options[selected].local
        ? "Install and run container on your machine"
        : `Deploy with ${options[selected].deployWith}`,
      description: (
        <>
          <div ref={stepsEndRef}></div>
          {showInstructions() ? options[selected].component : null}
        </>
      ),
    };
  }, [showInstructions, options, selected]);

  // Scroll to step 2 when it's visible
  useEffect(() => {
    if (showInstructions()) {
      stepsEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showInstructions]);

  const step4 = useMemo(() => {
    if (showInstructions() === true && showNextStep) {
      if (activeAgentCount + unreachableAgentCount === 0) {
        return {
          title: "Listening for agent...",
          description: null,
          icon: <LoadingOutlined />,
        };
      } else if (unreachableAgentCount > 0) {
        return {
          title: "Agent health check failed",
          description: <StepHealthCheckFailed retry={retryHealthCheck} />,
          icon: null,
        };
      } else {
        return {
          title: "Agent health check succeeded",
          description: <StepHealthCheckSucceeded />,
          icon: null,
        };
      }
    } else {
      return {
        title: "Connecting the agent",
        description: null,
        icon: null,
      };
    }
  }, [
    showInstructions,
    activeAgentCount,
    unreachableAgentCount,
    retryHealthCheck,
    showNextStep,
  ]);

  const title = "Add an On-Premise Agent";
  return (
    <PageWrapper pageName={AGENTS_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper paddingRight="0px" paddingBottom="50px">
          <Row justify="center">
            <Col span={16}>
              <BreadCrumb
                paths={[
                  {
                    title: AGENTS_TITLE,
                    link: AGENTS_BASE_URL,
                  },
                  { title },
                ]}
              />
              <Space direction="vertical" size={20}>
                <Title level={2} style={{ marginBottom: 0 }}>
                  {title}
                </Title>
                <DescriptionText>
                  Deploy Superblocks Agents to your cloud service of choice
                  <br />
                  ensuring your customers’ data never leaves your VPC.
                  <br />
                  <br />
                </DescriptionText>
                <Title level={5}>How will you deploy the agent?</Title>
                <Space direction="horizontal" size={20}>
                  {options.map((option, index) => (
                    <OptionButton
                      size="large"
                      key="option-btn-{index}"
                      icon={option.icon}
                      type={selected === index ? "primary" : "default"}
                      ghost={selected === index}
                      onClick={() => selectOption(index)}
                      style={
                        option.local ? { borderRadius: "4px 0 4px 4px" } : {}
                      }
                    >
                      {option.name}
                      {option.local && <Label>Local</Label>}
                    </OptionButton>
                  ))}
                </Space>
                {options[selected].local === true && (
                  <Alert
                    description={
                      <Text>
                        A Local deployment should only be used to test the agent
                        before deploying to a Cloud service. <br />
                        Running the OPA locally will impact other users on your
                        Superblocks account.
                      </Text>
                    }
                    type="warning"
                    showIcon
                  />
                )}
                <Steps direction="vertical" current={currentStep} size="small">
                  <Steps.Step
                    title={<StepTitle level={5}>{step1.title}</StepTitle>}
                    description={step1.description}
                  />
                  {options[selected].local !== true ? (
                    <Steps.Step
                      title={<StepTitle level={5}>{step2.title}</StepTitle>}
                      description={step2.description}
                    />
                  ) : null}
                  <Steps.Step
                    title={<StepTitle level={5}>{step3.title}</StepTitle>}
                    description={step3.description}
                  />
                  <Steps.Step
                    title={<StepTitle level={5}>{step4.title}</StepTitle>}
                    description={step4.description}
                    icon={step4.icon}
                  />
                </Steps>
              </Space>
            </Col>
          </Row>
        </MainWrapper>
      </Layout>
      <AgentSwitchDeploymentModal
        open={isAgentSwitchModalVisible}
        modalType={agentSwitchModalType}
        onCancel={cancelAgentSwitchModal}
        onOk={confirmAgentSwitchModal}
      />
      <CreateTokenModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        isOpaPage={true}
        setKeyCreated={setKeyCreated}
        setShowNextStep={setShowNextStep}
        setCurrentStep={setCurrentStep}
      />
    </PageWrapper>
  );
};

export default AgentDeploymentInstructionPage;
