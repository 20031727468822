import { Icon, IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { colors } from "styles/colors";

const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.colors.GREY_300};
  transform: rotate(${({ isOpen }) => (isOpen ? "0deg" : "-90deg")});
  width: 16px;
  height: 16px;
`;

const CollapseToggle = (props: {
  isOpen: boolean;
  isVisible: boolean;
  onClick: () => void;
  disabled: boolean;
  className?: string;
  icon?: ReactElement | null;
}) => {
  const handleClick = (e: any) => {
    props.onClick();
    e.stopPropagation();
  };
  const defaultIcon: IconName = props.isOpen
    ? IconNames.CARET_DOWN
    : IconNames.CARET_RIGHT;

  if (!props.isVisible) return <span></span>;

  if (props.icon)
    return (
      <ChevronIconWrapper isOpen={props.isOpen} onClick={handleClick}>
        {props.icon}
      </ChevronIconWrapper>
    );

  return (
    <Icon
      className={props.className}
      icon={defaultIcon}
      onClick={handleClick}
      color={props.disabled ? colors.GREY_200 : colors.GREY_300}
    />
  );
};

export default CollapseToggle;
