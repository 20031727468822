import {
  Agent,
  MySQLActionConfiguration,
  Organization,
  Profile,
} from "@superblocksteam/shared";
import { select, take, race } from "redux-saga/effects";
import { getCurrentUserIsAdmin } from "legacy/selectors/usersSelectors";
import {
  getAgentsSaga,
  isLoadingAgentsHealth,
  selectActiveAgents,
} from "store/slices/agents";
import {
  ApiExecutionSagaPayload,
  ApiExecutionResponseDto,
} from "store/slices/apis/types";
import { ApiDto } from "store/slices/apis/types";
import { orgIsCloud } from "store/slices/organizations/utils";
import { callSagas } from "store/utils/saga";
import { fastClone } from "utils/clone";
import { noActiveAgentMessage } from "utils/error/error";
import { sendNoActiveAgentUINotification } from "utils/notification";
import type { ApiDtoWithPb } from "store/slices/apisV2/slice";

export function* getActiveAgents({
  organization,
  enableProfiles,
  environment,
  profileKey,
  sendNotification = true,
}: {
  organization: Organization;
  enableProfiles: boolean;
  environment: ApiExecutionSagaPayload["environment"];
  profileKey?: Profile["key"];
  sendNotification?: boolean;
}) {
  // Cloud orgs don't have agents
  if (orgIsCloud(organization)) {
    return [];
  }
  let agents: Agent[] = yield select(
    selectActiveAgents(
      organization.agentType,
      enableProfiles ? profileKey ?? environment : environment,
    ),
  );

  if (!agents.length) {
    const isLoadingAgents: boolean = yield select(isLoadingAgentsHealth);
    if (isLoadingAgents) {
      yield race({
        s: take(getAgentsSaga.success.type),
        f: take(getAgentsSaga.error.type),
      });
    } else {
      yield callSagas([getAgentsSaga.apply({ organization })]);
    }

    // Try again
    agents = yield select(
      selectActiveAgents(
        organization.agentType,
        enableProfiles ? profileKey ?? environment : environment,
      ),
    );
    // If still no agents, show error
    if (!agents.length) {
      const userIsAdmin: ReturnType<typeof getCurrentUserIsAdmin> =
        yield select(getCurrentUserIsAdmin);

      sendNotification &&
        sendNoActiveAgentUINotification({
          organizationAgentType: organization.agentType,
          userIsAdmin,
        });
      return {
        systemError: noActiveAgentMessage(organization.agentType),
      } as ApiExecutionResponseDto;
    }
  }

  return agents;
}

const pluginIdsWithUsePreparedSql = [
  "bigquery",
  "cockroachdb",
  "mariadb",
  "mssql",
  "mysql",
  "postgres",
  "redshift",
  "snowflake",
];

// TODO: this is an ugly temporary fix for a Papaya bug
// we should remove this once they have upgraded to a newer OPA version
export function fixMissingUsePreparedSqlV1(v1Api: ApiDto | undefined) {
  const v1ApiFixed = fastClone(v1Api);
  Object.values(v1ApiFixed?.actions?.actions ?? {}).forEach((action) => {
    if (
      pluginIdsWithUsePreparedSql.includes(action.pluginId ?? "") &&
      action.configuration &&
      (action.configuration as MySQLActionConfiguration).usePreparedSql ===
        undefined
    ) {
      (action.configuration as MySQLActionConfiguration).usePreparedSql = false;
    }
  });
  return v1ApiFixed;
}
export function fixMissingUsePreparedSqlV2(v2Api: ApiDtoWithPb | undefined) {
  const v2ApiFixed = fastClone(v2Api);
  (v2ApiFixed?.apiPb?.blocks ?? []).forEach((block) => {
    if (!block.step) return;

    let pluginNameMatch: string | undefined = undefined;
    for (const pluginName of pluginIdsWithUsePreparedSql) {
      if (pluginName in block.step) {
        pluginNameMatch = pluginName;
      }
    }

    if (
      pluginNameMatch !== undefined &&
      (block.step[pluginNameMatch] as MySQLActionConfiguration)
        .usePreparedSql === undefined
    ) {
      (block.step[pluginNameMatch] as MySQLActionConfiguration).usePreparedSql =
        false;
    }
  });
  return v2ApiFixed;
}
