import { MenuItem, Classes, Button as BButton } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select, ItemRendererProps } from "@blueprintjs/select";
import equal from "@superblocksteam/fast-deep-equal/es6";
import { Dimension } from "@superblocksteam/shared";
import { Popover } from "antd";
import _, { isString, isEmpty, findIndex, without, memoize, get } from "lodash";

import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import tinycolor from "tinycolor2";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import { PlainLink } from "components/ui/Link";
import {
  MARGIN_BETWEEN_TAGS,
  MIN_TAG_WIDTH,
  TableTag,
  TableTagsShowMore,
} from "components/ui/TableTag";
import { useCachedValue, useElementRect } from "hooks/ui";
import { MultiStepDef } from "legacy/constants/ActionConstants";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { RadioButtonControlIcons } from "legacy/icons/BooleanValueIcons";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { TextStyleWithVariant } from "legacy/themes";
import {
  generateBorderStyle,
  styleFromDimension,
} from "legacy/themes/generatedStyles/utils";
import { extractPixels } from "legacy/themes/utils";
import { mergeTextStyles } from "legacy/themes/utils";
import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercentage,
} from "legacy/utils/FormatUtils";
import { getTableCellTextStyleClassName } from "legacy/widgets/TableWidget/TableComponent/TableHelpers";
import { getIconSizeBasedOnFontSize } from "legacy/widgets/typographyUtils";
import { useAppSelector } from "store/helpers";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { rgba2rgb } from "utils/color";
import { getDottedPathTo } from "utils/dottedPaths";
import logger from "utils/logger";
import { NavigationEvent } from "utils/polyfills/interceptNavigation";
import { newTagColorPalette } from "utils/tagsColorPalette";
import { isRelativeURL, isValidUrl } from "utils/url";
import { Button, ButtonStyle } from "../../Shared/Button";
import AutoToolTipComponent from "./AutoToolTipComponent";
import {
  ColumnTypes,
  CellAlignmentTypes,
  VerticalAlignmentTypes,
  ColumnProperties,
  SingleCellProperties,
  TextSizes,
  TableStyles,
  DEFAULT_TABLE_COLUMN_WIDTH,
  TABLE_COLUMN_MIN_WIDTH,
  BooleanStyleFalse,
  TagsColorAssignment,
  CompactMode,
  CompactModeTypes,
  EditInputType,
  EditProps,
  TextBoxEditTypes,
  TABLE_SIZES,
  TagDisplayConfig,
  ImageSize,
  DEFAULT_CELL_TEXT_STYLE_VARIANT,
} from "./Constants";
import PopoverVideo from "./PopoverVideo";

import {
  CellWrapper,
  CELL_WRAPPER_HOR_MARGIN,
  DefaultValueWrapper,
  getCellClass,
  getCellWrapStyles,
  buildCellStyles,
  TextIconWrapper,
  LeftColumnIconWrapper,
  RightColumnIconWrapper,
} from "./TableStyledWrappers";
import EditCell from "./cells/EditCell";
import EditCheckboxCell from "./cells/EditCheckboxCell";
import { EditableLinkCell } from "./cells/EditableLinkCell";
import type { ReactTableComponentProps } from ".";
import type { GeneratedTheme } from "legacy/themes/types";
import type { DropdownOption } from "legacy/widgets/DropdownWidget/types";
import type { AppState } from "store/types";

/** a custom hook that preprocesses tagValues and returns a cached version of it if possible */
const useTagValues = (tagValues: unknown[]) => {
  const transformedValues = tagValues
    .filter((v) => v != null && v !== "")
    .map((v) => `${v}`);
  return useCachedValue(transformedValues);
};

const generateComplementaryTagColors = (
  baseColor: string,
  backgroundColor: string,
) => {
  const base = tinycolor(baseColor);
  const { r, g, b, a } = base.toRgb();
  const background = tinycolor(backgroundColor);
  const { r: bgR, g: bgG, b: bgB } = background.toRgb();
  const opaqueRGB = rgba2rgb([r, g, b, a], [bgR, bgG, bgB]);
  const opaque = tinycolor({
    r: opaqueRGB[0],
    g: opaqueRGB[1],
    b: opaqueRGB[2],
  });
  const alpha = base.getAlpha();
  const textColor =
    opaque.getLuminance() > 0.55
      ? tinycolor(baseColor)
          .setAlpha(1)
          .darken(alpha * 75)
          .toRgbString()
      : colors.WHITE;
  return {
    fg: textColor,
    border: base.toRgbString(),
    bg: base.toRgbString(),
  };
};

const TagsPopoverContentWrapper = styled.div`
  display: flex;
  cursor: default;
`;

interface TableTagsProps {
  isHidden: boolean;
  cellProperties: SingleCellProperties;
  tagValues: unknown[];
  tagsColorAssignment?: TagsColorAssignment;
  tagsCustomColorAssignment?: Record<string, TagDisplayConfig>;
  compactMode: CompactMode;
  handleCellFocus?: () => void;
  maxWidth?: number;
  maxLinesPerRow?: number;
}

// Overlaps with ColumnProperties, but single-cell requires single-value not array
export type TableCellProps = SingleCellProperties & {
  isHidden: boolean;
  cellProperties: SingleCellProperties;
  tableWidth: number;
  tagsColorAssignment?: TagsColorAssignment;
  tagsCustomColorAssignment?: Record<string, TagDisplayConfig>;
  booleanStyleFalse?: BooleanStyleFalse;
  compactMode: CompactMode;
  isFocused: boolean;
};

interface CellPosition {
  currentRowIndex: number;
  originalRowIndex: number;
  columnId: string;
}

export const sanitizeCellValue = (cellValue: any) => {
  const valueStr = `${cellValue}`;
  return `${valueStr.toLowerCase()}_clean`;
};

export const TagsCell = ({
  isHidden,
  cellProperties,
  tagValues,
  tagsColorAssignment,
  tagsCustomColorAssignment,
  compactMode,
  handleCellFocus,
  maxWidth,
  maxLinesPerRow,
}: TableTagsProps) => {
  const transformedValues = useTagValues(tagValues);
  const [hiddenTagCount, setHiddenTagCount] = useState(0);
  // it is possible that the last visible tag has to be clipped, track that in this boolean state
  const [hasClippedTag, setHasClippedTag] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRect = useElementRect(wrapperRef);
  const measurements = useRef<{
    wrapper: DOMRect;
    tags: DOMRect[];
  }>();

  const generatedTheme = useSelector(selectGeneratedTheme);
  const typographyFlagEnabled = useAppSelector((state: AppState) =>
    selectFlagById(state, Flag.ENABLE_TYPOGRAPHY),
  );

  // layout effect that is ran on mount
  // it performs measurements on children elements
  // both this and the next effect have to be layout effects
  // because they have to be executed synchronously to avoid flickering due to a repaint
  // that would cause some transient visibility states to become visibile to the user
  useLayoutEffect(() => {
    if (wrapperRef.current) {
      // ensure that all children are visibile before measuring them
      // we need to do that upfront to ensure that all measurements are accurate and to ensure that there is only one reflow
      // the correct visibility will be set again by the second layout effect which will occur in
      // the same tick of the event loop, so the user will not notice any flickering
      for (const childElt of wrapperRef.current.children) {
        (childElt as HTMLElement).style.display = "block";
        (childElt as HTMLElement).style.flexShrink = "0";
      }
      const showMoreElt =
        wrapperRef.current.children[wrapperRef.current.children.length - 1];
      showMoreElt.textContent = "+0";
      // find the bounding rects for all tags in one go, will cause only one reflow
      const childrenRects = Array.prototype.map.call<
        HTMLCollection,
        [(elt: HTMLElement) => DOMRect],
        DOMRect[]
      >(wrapperRef.current.children, (childElt) =>
        childElt.getBoundingClientRect(),
      );
      // get rid of the show more tag
      childrenRects.pop();
      measurements.current = {
        wrapper: wrapperRef.current.getBoundingClientRect(),
        tags: childrenRects.map((r, i) => {
          (r as any).text = transformedValues[i];
          return r;
        }),
      };
    }
    // this effect actually depends on the DOM rendered, which depends on transformedValues, so use transformedValues here
  }, [transformedValues, maxLinesPerRow, cellProperties.textWrap]);

  // layout effect that is ran every time the cell gets resized
  // it is responsible for show/hiding tags based on whether the overflowed the cell
  useLayoutEffect(() => {
    if (wrapperRef.current && measurements.current) {
      const showMoreElt = wrapperRef.current.children[
        transformedValues.length
      ] as HTMLElement;
      const maxRight = measurements.current.wrapper.right;
      // the amount of horizontal space that we need to display the showMore that
      let marginForShowMore = 0;
      let hiddenCount = 0;
      let hasPartiallyOverflownTag = false;
      for (let idx = transformedValues.length - 1; idx >= 0; idx--) {
        const childElt = wrapperRef.current.children[idx] as HTMLElement;
        const childRight = measurements.current.tags[idx].right;

        if (childRight <= maxRight - marginForShowMore) {
          // not overflown, show the (whole) tag
          childElt.style.display = "";
          childElt.style.flexShrink = "0";
        } else if (
          // check if there is at least MIN_TAG_WIDTH px space for the tag to be partially displayed
          measurements.current.tags[idx].left + MIN_TAG_WIDTH <=
            maxRight - marginForShowMore - CELL_WRAPPER_HOR_MARGIN &&
          hiddenCount === 0
        ) {
          // partially overflown (and the first such) but has a decent part of it visible
          // show the tag truncated with ellipsis
          childElt.style.display = "";
          childElt.style.flexShrink = "1";
          hasPartiallyOverflownTag = true;
        } else {
          childElt.style.display = "none";
          hiddenCount++;
          // hiddenCount has changed, so we need to update marginForShowMore because the element width has changed
          // this will cause a reflow
          // to minimize the number of reflows, only do that if the number of digits in hiddenCount has changed
          // if not, we can assume that the width has not changed so we can keep the old value of marginForShowMore
          if (
            hiddenCount === 1 ||
            `${hiddenCount}`.length !== `${hiddenCount - 1}`.length
          ) {
            showMoreElt.textContent = `+${hiddenCount}`;
            marginForShowMore =
              showMoreElt.getBoundingClientRect().width + MARGIN_BETWEEN_TAGS;
          }
        }
      }
      showMoreElt.style.display = hiddenCount === 0 ? "none" : "";
      showMoreElt.textContent = `+${hiddenCount}`;
      setHiddenTagCount(hiddenCount);
      setHasClippedTag(hasPartiallyOverflownTag);
    }
  }, [
    wrapperRect?.width,
    transformedValues,
    cellProperties.textWrap,
    maxLinesPerRow,
  ]);

  const coloredValues = useMemo(() => {
    return transformedValues.map((value) => {
      const uniqValueIdx =
        tagsColorAssignment?.mapping[sanitizeCellValue(value)] ?? 0;
      let colors;
      if (tagsCustomColorAssignment?.[value]) {
        colors = generateComplementaryTagColors(
          tagsCustomColorAssignment[value].color,
          generatedTheme.colors.neutral,
        );
      } else {
        const nextDefaultColor =
          newTagColorPalette[uniqValueIdx % newTagColorPalette.length];
        colors = generateComplementaryTagColors(
          nextDefaultColor,
          generatedTheme.colors.neutral,
        );
      }
      return { value, colors };
    });
  }, [
    transformedValues,
    tagsColorAssignment?.mapping,
    tagsCustomColorAssignment,
    generatedTheme.colors.neutral,
  ]);
  const firstHiddenIdx = transformedValues.length - hiddenTagCount;

  const {
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    textWrap,
  } = cellProperties;
  const [className, styles] = useMemo(() => {
    const options = {
      canWrap: textWrap,
      maxLines: maxLinesPerRow,
      isTags: true,
    };
    return [
      getCellClass(options),
      {
        ...getCellWrapStyles(options),
        ...buildCellStyles({
          cellTextStyle,
          fontStyle,
          textColor,
          horizontalAlignment,
          verticalAlignment,
          textSize,
          compactMode,
          typographyFlagEnabled: Boolean(typographyFlagEnabled),
        }),
      },
    ];
  }, [
    maxLinesPerRow,
    compactMode,
    textWrap,
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    typographyFlagEnabled,
  ]);

  return (
    <CellWrapper
      ref={wrapperRef}
      isHidden={isHidden}
      cellProperties={cellProperties}
      compactMode={compactMode}
      onClick={handleCellFocus}
      maxWidth={maxWidth}
      className={className}
      style={styles}
    >
      {coloredValues.map(({ value, colors: { bg, fg, border } }, idx) => (
        <TableTag
          key={idx}
          text={value}
          bgColor={bg}
          fgColor={fg}
          borderColor={border}
          showTooltip={idx === firstHiddenIdx - 1 && hasClippedTag}
          compactMode={compactMode}
        />
      ))}
      <Popover
        content={
          <TagsPopoverContentWrapper>
            {coloredValues
              .slice(firstHiddenIdx)
              .map(({ value, colors: { bg, fg, border } }, idx) => (
                <TableTag
                  key={firstHiddenIdx + idx}
                  text={value}
                  bgColor={bg}
                  fgColor={fg}
                  borderColor={border}
                  compactMode={compactMode}
                />
              ))}
          </TagsPopoverContentWrapper>
        }
      >
        <TableTagsShowMore
          // the actual count will updated (synchronously) with DOM opereations
          count={0}
          compactMode={compactMode}
        />
      </Popover>
    </CellWrapper>
  );
};

interface ColumnAction {
  label: string;
  id: string;
  actions: MultiStepDef;
}

export const formatByType = (
  value: unknown,
  type: string,
  options: {
    // generic
    maxLen?: number;
    displayedValue?: string;

    // numeric specific
    notation?: Intl.NumberFormatOptions["notation"] | "unformatted";
    currency?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;

    // date specific
    inputFormat?: string;
    outputFormat?: string;
    manageTimezone?: boolean;
    timezone?: string;
    displayTimezone?: string;
  },
): string => {
  const text = `${value}`;
  switch (type) {
    case ColumnTypes.CURRENCY: {
      return formatCurrency(
        text,
        options.currency,
        options.notation,
        options.minimumFractionDigits,
        options.maximumFractionDigits,
      );
    }
    case ColumnTypes.NUMBER: {
      return formatNumber(
        text,
        options.notation,
        options.minimumFractionDigits,
        options.maximumFractionDigits,
      );
    }
    case ColumnTypes.PERCENTAGE: {
      return formatPercentage(
        text,
        options.minimumFractionDigits,
        options.maximumFractionDigits,
      );
    }
    case ColumnTypes.TEXT: {
      const displayedText = options.displayedValue
        ? `${options.displayedValue}`
        : text;
      if (
        options.maxLen !== undefined &&
        displayedText.length > options.maxLen
      ) {
        return displayedText.substring(0, options.maxLen) + "…";
      }
      return displayedText;
    }
    case ColumnTypes.DATE: {
      return formatDate(
        value,
        options.inputFormat,
        options.outputFormat,
        options.manageTimezone ? options.timezone : undefined,
        options.manageTimezone ? options.displayTimezone : undefined,
      );
    }
    default:
      if (options.maxLen !== undefined && text.length > options.maxLen) {
        return text.substring(0, options.maxLen) + "…";
      }
      return text;
  }
};

const StyledEditableDiv = styled.div<{
  $isValid: boolean;
  hoverColor: string;
}>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  &[data-align="TOP"] {
    align-items: flex-start;
  }
  &[data-align="BOTTOM"] {
    align-items: flex-end;
  }
  &:hover {
    background: ${(props) => props.hoverColor};
    box-shadow: 0px 0px 1px rgb(27 30 34 / 48%);
    svg {
      visibility: visible;
    }
  }
`;

const EditableCellWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0px;
`;

const StyledCheckmarkWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const EditMarker = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  right: 8px;
  height: 100%;
  visibility: hidden;
`;

const EditingCellWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const EditableCell = (props: {
  value: any;
  rawValue: any;
  columnType: string;
  cellProps: TableCellProps;
  editProps: EditProps;
  tableName: string;
  compactMode: CompactMode;
  handleCellFocus: (currentRowIndex: number, columnId: string) => void;
  position: CellPosition;
  isInserted: boolean;
  columnName: string;
  maxLinesPerRow?: number;
}) => {
  const generatedTheme = useSelector(selectGeneratedTheme);
  const cellRef = useRef<HTMLDivElement>(null);
  const { handleCellFocus } = props;
  const {
    validationErrors,
    isEdited,
    isEditFocused,
    handleEditStart,
    editInputType,
  } = props.editProps;

  const focusCell = useCallback(() => {
    handleCellFocus(props.position.currentRowIndex, props.position.columnId);
  }, [handleCellFocus, props.position]);
  const doubleClickTimeout = useRef<undefined | ReturnType<typeof setTimeout>>(
    undefined,
  );
  const isDropdownType =
    editInputType === EditInputType.Dropdown ||
    editInputType === EditInputType.Date;

  const onEditableCellClick = useCallback(
    (e: any) => {
      if (doubleClickTimeout.current) {
        clearTimeout(doubleClickTimeout.current);
        doubleClickTimeout.current = undefined;
        // double click handler
        if (!isEditFocused && !isDropdownType) {
          handleEditStart();
          e.stopPropagation(); // prevent onRowClick from getting called twice
        }
      } else {
        // single click handler'
        focusCell();
        if (!isEditFocused && isDropdownType) {
          handleEditStart();
        } else {
          doubleClickTimeout.current = setTimeout(() => {
            doubleClickTimeout.current = undefined;
          }, 300);
        }
      }
    },
    [handleEditStart, isEditFocused, isDropdownType, focusCell],
  );

  if (editInputType === EditInputType.Checkbox) {
    return (
      <EditableCellWrapper onClick={focusCell}>
        <StyledCheckmarkWrapper className="editable-cell">
          {isEdited && (
            <EditMarker
              style={{
                borderColor: `${generatedTheme.colors.primary500} transparent transparent transparent`,
                top: generatedTheme.borderRadius.value > 8 ? 2 : 0,
                left: generatedTheme.borderRadius.value > 8 ? 2 : 0,
              }}
            />
          )}
          <EditCheckboxCell
            editProps={props.editProps}
            value={props.rawValue}
            compactMode={props.compactMode}
            cellProperties={props.cellProps.cellProperties}
            isFocused={props.cellProps.isFocused}
            readModeComponent={renderCell({
              value: props.value,
              columnType: props.columnType,
              cellProps: props.cellProps,
              position: props.position,
            })}
          />
        </StyledCheckmarkWrapper>
      </EditableCellWrapper>
    );
  }
  if (isEditFocused) {
    return (
      <EditingCellWrapper ref={cellRef} className={"editing-cell-wrapper"}>
        {/* This is needed for the popper to appear on top of the cell */}
        <div
          data-superblocks="table-editor"
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <div style={{ visibility: "hidden" }}>
          {/* Render this hidden so that it keeps the row height correct */}
          {renderCell({
            value: props.value,
            columnType: props.columnType,
            cellProps: props.cellProps,
            position: props.position,
            maxLinesPerRow: props.maxLinesPerRow,
          })}
        </div>
        <EditCell
          targetCellRef={cellRef}
          editProps={props.editProps}
          value={
            props.editProps.editInputType &&
            TextBoxEditTypes.includes(
              props.editProps.editInputType as EditInputType,
            )
              ? props.editProps.currentEditValue
              : props.rawValue
          }
          tableName={props.tableName}
          compactMode={props.compactMode}
          cellProps={props.cellProps}
        />
      </EditingCellWrapper>
    );
  }
  const { linkUrl, linkLabel, openInNewTab } = props.cellProps.cellProperties;
  const defaultValue = props.isInserted ? `Set ${props.columnName}...` : "";
  return (
    <EditableCellWrapper onClick={onEditableCellClick}>
      <StyledEditableDiv
        $isValid={validationErrors.length === 0}
        data-test={
          props.columnType === "link"
            ? `editable-link-${props.tableName}-${props.value}`
            : `editable-cell-${props.tableName}-${props.value}`
        }
        className="editable-cell"
        data-align={props.cellProps.cellProperties.verticalAlignment}
        hoverColor={generatedTheme.colors.neutral}
        style={{
          borderRadius: styleFromDimension(generatedTheme.borderRadius),
          ...(validationErrors.length === 0
            ? {}
            : {
                border: generateBorderStyle(generatedTheme.defaultBorder),
                borderColor: generatedTheme.colors.danger,
              }),
        }}
      >
        {isEdited && (
          <EditMarker
            style={{
              borderColor: `${generatedTheme.colors.primary500} transparent transparent transparent`,
              top: generatedTheme.borderRadius.value > 8 ? 2 : 0,
              left: generatedTheme.borderRadius.value > 8 ? 2 : 0,
            }}
          />
        )}
        {props.columnType === "link" ? (
          <EditableLinkCell
            url={linkUrl ?? ""}
            label={linkLabel ?? linkUrl ?? ""}
            openInNewTab={openInNewTab}
            isHidden={props.cellProps.isHidden}
            isEdited={isEdited}
            value={props.rawValue}
            cellProperties={props.cellProps.cellProperties}
            compactMode={props.compactMode}
            isFocused={props.cellProps.isFocused}
            handleCellFocus={props.handleCellFocus}
            position={props.position}
            defaultValue={defaultValue}
            maxLinesPerRow={props.maxLinesPerRow}
          />
        ) : (
          renderCell({
            value: props.value,
            columnType: props.columnType,
            editInputType: props.editProps.editInputType,
            cellProps: props.cellProps,
            position: props.position,
            defaultValue,
            maxLinesPerRow: props.maxLinesPerRow,
          })
        )}
        {(editInputType === EditInputType.Dropdown ||
          editInputType === EditInputType.Date) && (
          <StyledChevronDown
            style={{
              color: generatedTheme.colors.neutral300,
            }}
          />
        )}
      </StyledEditableDiv>
    </EditableCellWrapper>
  );
};

const getCellClassAndStyleMemo: (
  canWrap: boolean,
  maxLines: number,
  compactMode: CompactMode,
  cellTextStyle?: SingleCellProperties["cellTextStyle"],
  fontStyle?: SingleCellProperties["fontStyle"],
  textColor?: SingleCellProperties["textColor"],
  horizontalAlignment?: SingleCellProperties["horizontalAlignment"],
  verticalAlignment?: SingleCellProperties["verticalAlignment"],
  textSize?: SingleCellProperties["textSize"],
  typographyFlagEnabled?: boolean,
) => [ReturnType<typeof getCellClass>, React.CSSProperties] = memoize(
  (
    canWrap: boolean,
    maxLines: number,
    compactMode: CompactMode,
    cellTextStyle?: SingleCellProperties["cellTextStyle"],
    fontStyle?: SingleCellProperties["fontStyle"],
    textColor?: SingleCellProperties["textColor"],
    horizontalAlignment?: SingleCellProperties["horizontalAlignment"],
    verticalAlignment?: SingleCellProperties["verticalAlignment"],
    textSize?: SingleCellProperties["textSize"],
    typographyFlagEnabled?: boolean,
  ) => {
    const options = {
      canWrap,
      maxLines,
    };
    return [
      getCellClass(options),
      {
        ...getCellWrapStyles(options),
        ...buildCellStyles({
          cellTextStyle,
          fontStyle,
          textColor,
          horizontalAlignment,
          verticalAlignment,
          textSize,
          compactMode,
          typographyFlagEnabled: Boolean(typographyFlagEnabled),
        }),
      },
    ];
  },
  // Custom resolver to create a unique key based on nested properties
  (...args) => JSON.stringify(args),
);

export function getCellProperties(
  tableCellTextStyle: TextStyleWithVariant | undefined,
  columnProperties: ColumnProperties,
  rowIndex: number,
) {
  // We must use getPropertyValue  for all these values because the value could be an array due to using code mode for this property, so we need to get the value from the array using the rowIndex

  const cellTextStyleVariant = getPropertyValue(
    columnProperties.cellProps?.textStyle?.variant,
    rowIndex,
    true,
  );
  const cellTextStyleColor = getPropertyValue(
    columnProperties.cellProps?.textStyle?.textColor?.default,
    rowIndex,
    true,
  );

  const cellTextStyleForRow: TextStyleWithVariant = {
    ...columnProperties.cellProps?.textStyle,
    variant: cellTextStyleVariant,
    textColor: cellTextStyleColor
      ? {
          default: cellTextStyleColor,
        }
      : undefined,
  } as TextStyleWithVariant;

  const cellTextStyle: TextStyleWithVariant | undefined = mergeTextStyles(
    tableCellTextStyle,
    cellTextStyleForRow,
  );

  const cellProperties: SingleCellProperties = {
    isDisabled: getPropertyValue(columnProperties.isDisabled, rowIndex),
    horizontalAlignment: getPropertyValue(
      columnProperties.horizontalAlignment,
      rowIndex,
    ),
    verticalAlignment: getPropertyValue(
      columnProperties.verticalAlignment,
      rowIndex,
    ),
    cellBackground: getPropertyValue(columnProperties.cellBackground, rowIndex),
    buttonBackgroundColor: getPropertyValue(
      columnProperties.buttonStyle,
      rowIndex,
    ),
    buttonVariant: getPropertyValue(columnProperties.buttonVariant, rowIndex),
    buttonLabelColor: getPropertyValue(
      columnProperties.buttonLabelColor,
      rowIndex,
    ),
    cellIcon: getPropertyValue(columnProperties.cellIcon, rowIndex, true),
    cellIconPosition:
      getPropertyValue(columnProperties.cellIconPosition, rowIndex) ?? "LEFT",
    buttonLabel: getPropertyValue(columnProperties.buttonLabel, rowIndex, true),
    linkUrl: getPropertyValue(columnProperties.linkUrl, rowIndex, true),
    linkLabel: getPropertyValue(columnProperties.linkLabel, rowIndex, true),
    openInNewTab: getPropertyValue(
      columnProperties.openInNewTab,
      rowIndex,
      false,
      true,
    ),
    textSize: getPropertyValue(columnProperties.textSize, rowIndex),
    textColor: getPropertyValue(columnProperties.textColor, rowIndex),
    fontStyle: getPropertyValue(columnProperties.fontStyle, rowIndex), //Fix this
    cellTextStyle,
    displayedValue: getPropertyValue(
      columnProperties.displayedValue,
      rowIndex,
      true,
      true,
    ),
    textWrap: getPropertyValue(
      columnProperties.textWrap,
      rowIndex,
      false,
      true,
    ),
    imageSize: getPropertyValue(columnProperties.imageSize, rowIndex),
    imageBorderRadius: getPropertyValue(
      columnProperties.imageBorderRadius,
      rowIndex,
      true,
      true,
    ),
    openImageUrl: getPropertyValue(
      columnProperties.openImageUrl,
      rowIndex,
      false,
      true,
    ),
    minimumFractionDigits: getPropertyValue(
      columnProperties.minimumFractionDigits,
      rowIndex,
    ),
    maximumFractionDigits: getPropertyValue(
      columnProperties.maximumFractionDigits,
      rowIndex,
    ),
    currency: getPropertyValue(columnProperties.currency, rowIndex, true),
    inputFormat: getPropertyValue(columnProperties.inputFormat, rowIndex, true),
    outputFormat: getPropertyValue(
      columnProperties.outputFormat,
      rowIndex,
      true,
    ),
    manageTimezone: columnProperties.manageTimezone,
    timezone: getPropertyValue(columnProperties.timezone, rowIndex, true),
    displayTimezone: getPropertyValue(
      columnProperties.displayTimezone,
      rowIndex,
      true,
    ),
  };
  return cellProperties;
}

export function getPropertyValue(
  value: any,
  index: number,
  preserveCase = false,
  preserveDataType = false,
) {
  if (value && Array.isArray(value)) {
    if (value[index])
      return preserveCase
        ? value[index].toString()
        : value[index].toString().toUpperCase();
    return value[index];
  } else if (value && !preserveDataType) {
    return preserveCase ? value.toString() : value.toString().toUpperCase();
  } else {
    return value;
  }
}

export function getFirstPropertyValue(value: string | string[] | undefined) {
  return Array.isArray(value) ? value.find(Boolean) : value;
}

function parseImageURLValue(value: any) {
  let imageUrls: string[] | undefined;
  if (isString(value)) {
    // We are splitting the value by comma to support multiple images. This is problematic, because comma is a valid
    // character in URIs. In particular, it is used in data URIs (but it can occur in other URIs as well). We are
    // preserving the old splitting behavior here for backwards compatibility, but not for data URIs. We should
    // consider changing this in the future.
    if (/^data:/.test(value)) {
      imageUrls = [value];
    } else {
      imageUrls = value.split(",");
    }
  } else if (Array.isArray(value) && value.every(isString)) {
    imageUrls = value;
  }
  return imageUrls;
}

export const renderCell = ({
  value,
  columnType,
  editInputType,
  cellProps,
  position,
  handleCellFocus,
  defaultValue,
  maxLinesPerRow,
  tableCellTextStyle,
  typographyFlagEnabled,
  typographies,
}: {
  value: any;
  columnType: string;
  editInputType?: string;
  cellProps: TableCellProps;
  position: CellPosition;
  handleCellFocus?: (currentRowIndex: number, columnId: string) => void;
  defaultValue?: string;
  maxLinesPerRow?: number;
  tableCellTextStyle?: TextStyleWithVariant;
  typographyFlagEnabled?: boolean;
  typographies?: GeneratedTheme["typographies"];
}) => {
  const {
    isHidden,
    cellProperties,
    tableWidth,
    compactMode,
    displayedValue,
    cellIcon,
    cellIconPosition,
  } = cellProps;
  const onClick = () => {
    handleCellFocus &&
      handleCellFocus(position.currentRowIndex, position.columnId);
  };

  const {
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
  } = cellProperties;

  const [className, style] = getCellClassAndStyleMemo(
    cellProperties.textWrap === true,
    maxLinesPerRow ?? 1,
    compactMode,
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    typographyFlagEnabled,
  );

  const canColumnTypeHaveIcon =
    columnType === ColumnTypes.TEXT || columnType === ColumnTypes.EMAIL;
  const hasLeftIcon =
    Boolean(cellIcon) && cellIconPosition === "LEFT" && canColumnTypeHaveIcon;
  const hasRightIcon =
    Boolean(cellIcon) && cellIconPosition === "RIGHT" && canColumnTypeHaveIcon;

  let variantClassName: string | undefined;
  if (typographyFlagEnabled) {
    variantClassName = getTableCellTextStyleClassName({
      tableCellTextStyleVariant: tableCellTextStyle?.variant,
      cellTextStyleVariant: cellProps.cellProperties?.cellTextStyle?.variant,
    });
  }

  switch (columnType) {
    case ColumnTypes.IMAGE: {
      if (!value) {
        return (
          <CellWrapper
            isHidden={isHidden}
            cellProperties={cellProperties}
            compactMode={compactMode}
            onClick={onClick}
            className={className}
            style={style}
          >
            {defaultValue && (
              <DefaultValueWrapper>{defaultValue}</DefaultValueWrapper>
            )}
          </CellWrapper>
        );
      }
      const images = parseImageURLValue(value);
      if (!images) {
        return (
          <CellWrapper
            isHidden={isHidden}
            cellProperties={cellProperties}
            onClick={onClick}
            compactMode={compactMode}
            className={className}
            style={style}
          >
            <div>Invalid Image</div>
          </CellWrapper>
        );
      }
      return (
        <CellWrapper
          isHidden={isHidden}
          cellProperties={cellProperties}
          compactMode={compactMode}
          onClick={onClick}
          className={className}
          style={{
            ...style,
            ...(images.length > 1 ? { display: "flex" } : {}),
          }}
        >
          {images.map((item: string, index: number) => {
            if (isValidUrl(item)) {
              const imageStyle = {
                borderRadius: cellProperties?.imageBorderRadius
                  ? `${cellProperties.imageBorderRadius.value}${cellProperties.imageBorderRadius.mode}`
                  : "50%",
              };
              if (
                cellProperties.openImageUrl ||
                cellProperties.openImageUrl == null // default behaviour needs to be true for backwards compatibility
              ) {
                return (
                  <a
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item}
                    className="image-cell-wrapper"
                    key={index}
                    data-image-size={
                      cellProperties.imageSize ?? ImageSize.Fixed
                    }
                  >
                    <img src={item} alt="" style={imageStyle} />
                  </a>
                );
              }
              return (
                <div
                  className="image-cell-wrapper"
                  key={index}
                  data-image-size={cellProperties.imageSize ?? ImageSize.Fixed}
                >
                  <img src={item} alt="" style={imageStyle} />
                </div>
              );
            } else {
              return <div key={index}>Invalid Image</div>;
            }
          })}
        </CellWrapper>
      );
    }
    case ColumnTypes.VIDEO: {
      if (!value) {
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
          >
            {defaultValue && (
              <DefaultValueWrapper>{defaultValue}</DefaultValueWrapper>
            )}
          </CellWrapper>
        );
      } else if (isString(value)) {
        return (
          <CellWrapper
            cellProperties={cellProperties}
            compactMode={compactMode}
            isHidden={isHidden}
            className="video-cell"
            onClick={onClick}
          >
            <PopoverVideo url={value} />
          </CellWrapper>
        );
      } else {
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
            className={className}
            style={style}
          >
            Invalid Video Link
          </CellWrapper>
        );
      }
    }
    case ColumnTypes.BOOLEAN: {
      if (value == null || value === "") {
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
            className={`${className} ${variantClassName ?? ""}`}
            style={style}
          >
            {defaultValue && (
              <DefaultValueWrapper>{defaultValue}</DefaultValueWrapper>
            )}
          </CellWrapper>
        );
      }
      if (typeof value === "boolean") {
        const ButtonIcon = value
          ? RadioButtonControlIcons["CHECK"]
          : RadioButtonControlIcons[
              cellProps.booleanStyleFalse || BooleanStyleFalse.EMPTY
            ];
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
            className={`${className} ${variantClassName ?? ""}`}
            style={style}
          >
            <ButtonIcon />
          </CellWrapper>
        );
      }
      // boolean value in Json was parsed to string when it reaches here
      if (
        isString(value) &&
        ["true", "false"].includes(value.toLocaleLowerCase())
      ) {
        const booleanValue = value.toLocaleLowerCase() === "true";
        const ButtonIcon = booleanValue
          ? RadioButtonControlIcons["CHECK"]
          : RadioButtonControlIcons[
              cellProps.booleanStyleFalse || BooleanStyleFalse.EMPTY
            ];
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
            className={`${className} ${variantClassName ?? ""}`}
            style={style}
          >
            <ButtonIcon />
          </CellWrapper>
        );
      } else {
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
            className={`${className} ${variantClassName ?? ""}`}
            style={style}
          >
            Invalid Boolean
          </CellWrapper>
        );
      }
    }
    case ColumnTypes.TAGS: {
      let displayValue = value;
      if (displayedValue && typeof displayedValue === "string") {
        // handle case where displayedValue is a stringified array
        displayValue = displayedValue.split(", ");
      } else if (_.isString(value) || _.isNumber(value) || _.isBoolean(value)) {
        displayValue = [value];
      }
      if (!Array.isArray(displayValue) || (!value && defaultValue)) {
        return (
          <CellWrapper
            cellProperties={cellProperties}
            isHidden={isHidden}
            compactMode={compactMode}
            onClick={onClick}
            className={className}
            style={style}
          >
            {/* if value is null or undefined, leave the cell empty */}
            {!value
              ? <DefaultValueWrapper>{defaultValue}</DefaultValueWrapper> ?? ""
              : "Not an array"}
          </CellWrapper>
        );
      }
      return (
        <TagsCell
          cellProperties={cellProperties}
          isHidden={isHidden}
          tagValues={displayValue}
          tagsColorAssignment={cellProps.tagsColorAssignment}
          tagsCustomColorAssignment={cellProps.tagsCustomColorAssignment}
          compactMode={compactMode}
          handleCellFocus={onClick}
          maxLinesPerRow={maxLinesPerRow}
        />
      );
    }
    default: {
      const [iconSize, iconColor] = (() => {
        if (!typographyFlagEnabled) {
          return [undefined, undefined];
        }

        const variantTypography: TextStyleWithVariant | undefined =
          cellTextStyle?.variant
            ? get(typographies, cellTextStyle?.variant)
            : undefined;
        const colorForCellIcon: string | undefined =
          style.color ?? variantTypography?.textColor?.default;

        const fontSizeForCell = extractPixels(
          String(
            style.fontSize ??
              variantTypography?.fontSize ??
              get(typographies, DEFAULT_CELL_TEXT_STYLE_VARIANT)?.fontSize,
          ),
        );
        const sizeForCellIcon = getIconSizeBasedOnFontSize(fontSizeForCell);

        return [sizeForCellIcon, colorForCellIcon];
      })();

      return (
        <TextIconWrapper cellProperties={cellProperties}>
          {hasLeftIcon && (
            <LeftColumnIconWrapper
              cellProperties={cellProperties}
              style={{
                color: iconColor,
              }}
            >
              <DynamicSVG iconName={cellIcon} size={iconSize} />{" "}
            </LeftColumnIconWrapper>
          )}
          <AutoToolTipComponent
            title={value.toString()}
            isHidden={isHidden}
            cellProperties={cellProperties}
            tableWidth={tableWidth}
            compactMode={compactMode}
            handleCellFocus={onClick}
            maxLinesPerRow={maxLinesPerRow}
            hasLeftIcon={hasLeftIcon}
            hasRightIcon={hasRightIcon}
            tableCellTextStyle={tableCellTextStyle}
            isDropdownType={
              editInputType === EditInputType.Dropdown ||
              editInputType === EditInputType.Date
            }
          >
            {!value && defaultValue ? (
              <DefaultValueWrapper>{defaultValue}</DefaultValueWrapper>
            ) : (
              formatByType(value, columnType, {
                ...cellProps,
                maxLen: 10_000,
              })
            )}
          </AutoToolTipComponent>
          {hasRightIcon && (
            <RightColumnIconWrapper
              cellProperties={cellProperties}
              isDropdownType={
                editInputType === EditInputType.Dropdown ||
                editInputType === EditInputType.Date
              }
              style={{
                color: iconColor,
              }}
            >
              <DynamicSVG iconName={cellIcon} size={iconSize} />{" "}
            </RightColumnIconWrapper>
          )}
        </TextIconWrapper>
      );
    }
  }
};

interface RenderActionProps {
  rowIndex: number;
  isSelected: boolean;
  isDisabled?: boolean;
  columnActions?: ColumnAction[];
  backgroundColor?: string;
  buttonVariant: ButtonStyle;
  buttonLabelColor: string | undefined;
  cellIcon?: string;
  cellIconPosition: string;
  onCommandClick: ReactTableComponentProps["onCommandClick"];
  handleRowSelect: ReactTableComponentProps["onRowSelect"];
  compactMode: CompactMode;
}

export const renderActions = (
  props: RenderActionProps,
  isHidden: boolean,
  cellProperties: SingleCellProperties,
  compactMode: CompactMode,
  position: CellPosition,
  handleCellFocus: (currentRowIndex: number, columnId: string) => void,
) => {
  const onClick = () => {
    handleCellFocus(position.currentRowIndex, position.columnId);
  };
  if (!props.columnActions)
    return (
      <CellWrapper
        cellProperties={cellProperties}
        isHidden={isHidden}
        compactMode={compactMode}
        onClick={onClick}
      ></CellWrapper>
    );

  return (
    <CellWrapper
      cellProperties={cellProperties}
      isHidden={isHidden}
      compactMode={compactMode}
      onClick={onClick}
    >
      {props.columnActions.map((action: ColumnAction, index: number) => {
        return (
          <TableAction
            key={index}
            action={action}
            rowIndex={props.rowIndex}
            isSelected={props.isSelected}
            isDisabled={props.isDisabled}
            backgroundColor={props.backgroundColor}
            buttonVariant={props.buttonVariant}
            cellIcon={props.cellIcon}
            cellIconPosition={props.cellIconPosition as "LEFT" | "RIGHT"}
            buttonLabelColor={props.buttonLabelColor}
            onCommandClick={props.onCommandClick}
            handleRowSelect={props.handleRowSelect}
            compactMode={props.compactMode}
          />
        );
      })}
    </CellWrapper>
  );
};

export const renderLink = (props: {
  isHidden: boolean;
  cellProperties: SingleCellProperties;
  compactMode: CompactMode;
  position: CellPosition;
  handleCellFocus?: (currentRowIndex: number, columnId: string) => void;
  maxLinesPerRow?: number;
  tableCellTextStyle?: TextStyleWithVariant;
  typographyFlagEnabled?: boolean;
}) => {
  const { isHidden, cellProperties, typographyFlagEnabled } = props;
  const url = cellProperties.linkUrl || "";
  const label = cellProperties.linkLabel || url || "";
  const openInNewTab = cellProperties.openInNewTab;

  const {
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
  } = cellProperties;

  const [className, style] = getCellClassAndStyleMemo(
    cellProperties.textWrap === true,
    props.maxLinesPerRow ?? 1,
    props.compactMode,
    cellTextStyle,
    fontStyle,
    textColor,
    horizontalAlignment,
    verticalAlignment,
    textSize,
    typographyFlagEnabled,
  );

  const isRelative = isRelativeURL(url);
  const newWindow = openInNewTab === undefined || openInNewTab === true;

  let variantClassName: string | undefined;
  if (typographyFlagEnabled) {
    variantClassName = getTableCellTextStyleClassName({
      tableCellTextStyleVariant: props.tableCellTextStyle?.variant,
      cellTextStyleVariant: cellTextStyle?.variant,
    });
  }

  return (
    <CellWrapper
      cellProperties={cellProperties}
      isHidden={isHidden}
      compactMode={props.compactMode}
      onClick={(e) => {
        props.handleCellFocus &&
          props.handleCellFocus(
            props.position.currentRowIndex,
            props.position.columnId,
          );
        // prevent onRowSelect from getting called
        e.stopPropagation();
      }}
      className={`${className} ${variantClassName ?? ""}`}
      style={style}
    >
      <PlainLink
        href={url}
        rel="noreferrer nofollow"
        // we only want the browser to handle this link if we are keeping navigations non-relative
        target={!isRelative && newWindow ? "_blank" : undefined}
        onClick={(e) => {
          if (newWindow && "navigation" in window) {
            e.preventDefault();
            // using the full URL lets our internal navigation handle the link + new window behavior
            const targetUrl = isRelative
              ? new URL(url, document.baseURI).toString()
              : url;

            (window.navigation as any).dispatchEvent(
              new NavigationEvent(
                isRelative,
                targetUrl,
                undefined,
                false,
                true,
              ),
            );
          }
        }}
        style={style}
      >
        {label}
      </PlainLink>
    </CellWrapper>
  );
};

const TableAction = (props: {
  rowIndex: number;
  isSelected: boolean;
  isDisabled?: boolean;
  action: ColumnAction;
  backgroundColor?: string;
  buttonVariant: ButtonStyle;
  buttonLabelColor?: string;
  cellIcon?: string;
  cellIconPosition: "LEFT" | "RIGHT";
  onCommandClick: RenderActionProps["onCommandClick"];
  handleRowSelect: RenderActionProps["handleRowSelect"];
  compactMode: CompactMode;
}) => {
  const [loading, setLoading] = useState(false);
  const onComplete = () => {
    setLoading(false);
  };

  const [wrapperStyle, buttonStyle] = useMemo(() => {
    const mode = props.compactMode ?? CompactModeTypes.DEFAULT;
    const rowHeight = TABLE_SIZES[mode].ROW_HEIGHT;
    const buttonHeight = `${Math.min(30, rowHeight - 8)}px`;
    const wrapperStyle = {
      height: buttonHeight,
      margin: "0 5px 0 0",
      overflow: "hidden",
    };
    return [
      wrapperStyle,
      {
        height: buttonHeight,
        fontWeight: 400,
        lineHeight: `${Math.min(32, rowHeight - 6)}px`,
      },
    ];
  }, [props.compactMode]);

  return (
    <div data-test={`action-button`} style={wrapperStyle}>
      <Button
        textColor={props.buttonLabelColor}
        buttonStyle={props.buttonVariant}
        disabled={props.isDisabled}
        isLoading={loading}
        backgroundColor={props.backgroundColor}
        text={props.action.label}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          if (props.isDisabled) return;
          if (!props.isSelected) {
            props.handleRowSelect(props.rowIndex);
          }
          if (props.action.actions?.length && !loading) {
            setLoading(true);
            props.onCommandClick(props.action.actions, onComplete);
          }
        }}
        style={buttonStyle}
        useDynamicContrast={props.buttonVariant === "PRIMARY_BUTTON"}
        icon={props.cellIcon ? <DynamicSVG iconName={props.cellIcon} /> : <></>}
        iconPosition={props.cellIconPosition}
      />
    </div>
  );
};

export const renderEmptyRow = (
  columns: any,
  tableWidth: number,
  page: any,
  prepareRow: any,
  index: number,
  columnSizeMap: undefined | { [key: string]: number },
  resizingColumnIdRef: React.MutableRefObject<string>,
  canvasScaleFactor: number,
) => {
  if (page.length) {
    const row = page[0];
    prepareRow(row);
    return (
      <div {...row.getRowProps()} className="tr" key={index}>
        {row.cells.map((cell: any, cellIndex: number) => {
          const cellProps = cell.getCellProps();
          return (
            <div
              {...cellProps}
              style={{
                ...cellProps.style,
                ...getColumnWidthStyle({
                  column: cell.column,
                  columnSizeMap,
                  resizingColumnIdRef,
                  canvasScaleFactor,
                }),
              }}
              className="td"
              key={cellIndex}
            />
          );
        })}
      </div>
    );
  }

  const tableColumns = columns.length
    ? columns
    : new Array(3).fill({ width: tableWidth / 3, isHidden: false });
  return (
    <div
      className="tr"
      key={index}
      style={{
        display: "flex",
        flex: "1 0 auto",
      }}
    >
      {tableColumns.map((column: any, colIndex: number) => {
        return (
          <div
            key={colIndex}
            className="td"
            style={{
              width: column.width + "px",
              boxSizing: "border-box",
              flex: `${column.width} 0 auto`,
            }}
          />
        );
      })}
    </div>
  );
};

export const getIsColumnFrozen = (
  columnId: string,
  columnFreezes: undefined | Record<string, boolean>,
  defaultFrozen: undefined | boolean,
) => {
  if (columnFreezes && columnId in columnFreezes) {
    return columnFreezes[columnId];
  }
  return defaultFrozen;
};

export const getMergedColumnOrder = (
  originalColumnOrder: string[],
  newColumnIds: string[],
): string[] => {
  const sortIndexes: Record<string, number> = {};
  newColumnIds.forEach((col, i) => {
    sortIndexes[col] = i;
  });
  originalColumnOrder.forEach((col, i) => {
    sortIndexes[col] = i;
  });
  const sortedIds = [...newColumnIds].sort((colA, colB) => {
    return sortIndexes[colA] - sortIndexes[colB];
  });
  return sortedIds;
};

const getOrderedColumnIds = (
  columns: Record<string, ColumnProperties>,
  columnOrder: string[],
  columnFreezes?: Record<string, boolean>,
): Array<string> => {
  if (!Array.isArray(columnOrder)) {
    logger.error(
      `columnOrder is not an array. type: ${typeof columnOrder} value: ${JSON.stringify(
        columnOrder,
        null,
        2,
      )}`,
    );
  }

  const columnOrderVal = !Array.isArray(columnOrder) ? [] : columnOrder;

  const frozenColIdsInOrder = columnOrderVal.filter((colId) =>
    getIsColumnFrozen(colId, columnFreezes, columns[colId]?.isFrozen),
  );
  const nonFrozenColIdsInOrder = columnOrderVal.filter(
    (colId) =>
      !getIsColumnFrozen(colId, columnFreezes, columns[colId]?.isFrozen),
  );
  const orderedColumnIds = [...frozenColIdsInOrder, ...nonFrozenColIdsInOrder];
  const remaining = without(Object.keys(columns), ...orderedColumnIds);
  return [...frozenColIdsInOrder, ...nonFrozenColIdsInOrder, ...remaining];
};

export const TABLE_MULTISELECT_COLID = "SB_$$_MULTISELECT";
export const getVisibleOrderedColumnIds = (
  columns: Record<string, ColumnProperties>,
  columnOrder: string[],
  columnFreezes: undefined | Record<string, boolean>,
  isMultiSelect: boolean | undefined,
  hiddenColumns: undefined | string[],
) => {
  const baseColIds = getOrderedColumnIds(
    columns,
    columnOrder,
    columnFreezes,
  ).filter(
    (colId) => !hiddenColumns?.includes(colId) && columns[colId].isVisible,
  );
  if (isMultiSelect) {
    baseColIds.unshift(TABLE_MULTISELECT_COLID);
  }
  return baseColIds;
};

export const reorderColumns = (
  columns: Record<string, ColumnProperties>,
  columnOrder: string[],
  columnFreezes?: Record<string, boolean>,
) => {
  const newColumnsInOrder: Record<string, ColumnProperties> = {};
  const orderedColumnIds = getOrderedColumnIds(
    columns,
    columnOrder,
    columnFreezes,
  );

  orderedColumnIds.forEach((id: string, index: number) => {
    if (columns[id]) newColumnsInOrder[id] = { ...columns[id], index };
  });
  return newColumnsInOrder;
};

export function getDefaultColumnProperties(
  accessor: string,
  index: number,
  widgetName: string,
  isDerived?: boolean,
): ColumnProperties {
  return {
    index: index,
    width: 150,
    id: accessor,
    horizontalAlignment: CellAlignmentTypes.LEFT,
    verticalAlignment: VerticalAlignmentTypes.CENTER,
    columnType: ColumnTypes.TEXT,
    textSize: TextSizes.PARAGRAPH,
    enableFilter: true,
    enableSort: true,
    isVisible: true,
    isDerived: !!isDerived,
    label: accessor,
    computedValue: isDerived
      ? ""
      : `{{${widgetName}.tableDataWithInserts.map((currentRow) => { return currentRow${getDottedPathTo(
          accessor,
        )} })}}`,
    linkUrl: isDerived
      ? ""
      : `{{${widgetName}.tableDataWithInserts.map((currentRow) => { return currentRow${getDottedPathTo(
          accessor,
        )} })}}`,
    linkLabel: isDerived
      ? ""
      : `{{${widgetName}.tableDataWithInserts.map((currentRow) => { return currentRow${getDottedPathTo(
          accessor,
        )} })}}`,
    openInNewTab: true,
    isEditableOnInsertion: true,
    tagDisplayConfig: {},
    useLabelAsDisplayValue: true,
    notation: "standard",
    headerIcon: "",
    headerIconPosition: "LEFT",
    // image props
    imageBorderRadius: Dimension.px(0),
    imageSize: ImageSize.Fit,
    openImageUrl: true,
  };
}

export function columnHasNonDefaultProps(
  columnProperties: ColumnProperties,
  widgetName: string,
) {
  const defaultColumnProperties = getDefaultColumnProperties(
    columnProperties.id,
    columnProperties.index,
    widgetName,
    columnProperties.isDerived,
  );
  // parse and stringify to remove undefined values
  return !equal(
    JSON.parse(JSON.stringify(columnProperties)),
    JSON.parse(JSON.stringify(defaultColumnProperties)),
  );
}

export function getTableStyles(props: TableStyles) {
  return {
    textColor: props.textColor,
    textSize: props.textSize,
    fontStyle: props.fontStyle,
    cellBackground: props.cellBackground,
    verticalAlignment: props.verticalAlignment,
    horizontalAlignment: props.horizontalAlignment,
  };
}

const SingleDropDown = Select.ofType<DropdownOption>();

const StyledSingleDropDown = styled(SingleDropDown)`
  div {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  span {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .${Classes.BUTTON} {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    background: transparent;
    min-height: 32px;
  }

  .${Classes.BUTTON_TEXT} {
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  && {
    .${Classes.ICON} {
      width: fit-content;
      color: ${LegacyNamedColors.SLATE_GRAY};
    }
  }
`;

export const renderDropdown = (props: {
  options: DropdownOption[];
  onItemSelect: (onOptionChange: MultiStepDef, item: DropdownOption) => void;
  onOptionChange: MultiStepDef;
  selectedIndex?: number;
}) => {
  const isOptionSelected = (selectedOption: DropdownOption) => {
    const optionIndex = findIndex(props.options, (option) => {
      return option.value === selectedOption.value;
    });
    return optionIndex === props.selectedIndex;
  };
  const renderSingleSelectItem = (
    option: DropdownOption,
    itemProps: ItemRendererProps,
  ) => {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }
    const isSelected: boolean = isOptionSelected(option);
    return (
      <MenuItem
        className="single-select"
        active={isSelected}
        key={option.value}
        onClick={itemProps.handleClick}
        text={option.label}
      />
    );
  };
  return (
    <div
      style={{ height: "100%" }}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      }}
    >
      <StyledSingleDropDown
        items={props.options}
        itemRenderer={renderSingleSelectItem}
        onItemSelect={(item: DropdownOption) => {
          props.onItemSelect(props.onOptionChange, item);
        }}
        popoverProps={{
          minimal: true,
          usePortal: true,
          popoverClassName: "select-popover-wrapper",
          //Allow dropdown to overflow its container and placed based on viewport
          rootBoundary: "viewport",
        }}
        filterable
      >
        <BButton
          rightIcon={IconNames.CHEVRON_DOWN}
          text={
            !isEmpty(props.options) &&
            props.selectedIndex !== undefined &&
            props.selectedIndex > -1
              ? props.options[props.selectedIndex].label
              : "-- Select --"
          }
        />
      </StyledSingleDropDown>
    </div>
  );
};

export const getColumnWidthStyle = ({
  column,
  columnSizeMap,
  resizingColumnIdRef,
  canvasScaleFactor,
}: {
  column: any;
  columnSizeMap: { [key: string]: number } | undefined;
  resizingColumnIdRef: React.MutableRefObject<string>;
  canvasScaleFactor: number;
}) => {
  const deltaX = (column.totalWidth - column.originalWidth) / canvasScaleFactor;
  const scaledWidth = column.originalWidth + deltaX;

  if (columnSizeMap && column.id in columnSizeMap) {
    return {
      flex: `${scaledWidth} 0 auto`,
      width: `${scaledWidth}px`,
    };
  }

  if (column.isResizing && resizingColumnIdRef.current !== column.id) {
    resizingColumnIdRef.current = column.id;
  }
  //for flex column turning into fixed column
  if (
    column.actualFlexWidth &&
    (column.isResizing ||
      (!column.isResizing && resizingColumnIdRef.current === column.id))
  ) {
    const deltaRatio =
      (column.actualFlexWidth - column.minWidth) /
      (column.originalWidth - column.minWidth);
    const actualFlexWidth =
      column.actualFlexWidth + deltaX * deltaRatio * canvasScaleFactor;
    const displayFlexWidth = Math.max(actualFlexWidth, TABLE_COLUMN_MIN_WIDTH);
    // The actualFlexWidth is used in flex on purpose. The fixed columns also use "flex: x,x,x"
    // with absolute width value as flex-grow number, we need to set this number proportionally.
    // The fixed column is fixed because they have max-width
    return {
      flex: `${displayFlexWidth} 0 auto`,
      width: `${displayFlexWidth}px`,
    };
  }
};

export const getFluidColumnWidth = (
  componentWidth: number,
  allColumns: Record<string, ColumnProperties>,
  columnSizeMap: { [key: string]: number } | undefined,
) => {
  let totalColumnSizes = 0;
  let fixedWidthColumnCount = 0;
  for (const i in columnSizeMap) {
    if (allColumns[i]?.isVisible) {
      totalColumnSizes += columnSizeMap[i];
      fixedWidthColumnCount += 1;
    }
  }

  const visibleColCount = Object.values(allColumns).filter(
    (col) => col.isVisible,
  ).length;
  //calculate fluid column width based on table width, but min as 150 before resizing
  const fluidColumnCount = visibleColCount - fixedWidthColumnCount;
  let fluidColumnWidth = DEFAULT_TABLE_COLUMN_WIDTH;
  if (fluidColumnCount > 0) {
    fluidColumnWidth = (componentWidth - totalColumnSizes) / fluidColumnCount;
    if (fluidColumnWidth < DEFAULT_TABLE_COLUMN_WIDTH) {
      fluidColumnWidth = DEFAULT_TABLE_COLUMN_WIDTH;
    }
  }
  return fluidColumnWidth;
};

export const getColumnWidth = (
  colId: string,
  columnSizeMap: { [key: string]: number } | undefined,
  fluidColumnWidth: number,
) => {
  if (columnSizeMap && columnSizeMap[colId]) {
    return { width: columnSizeMap[colId] };
  }
  return {
    width: DEFAULT_TABLE_COLUMN_WIDTH,
    actualFlexWidth: fluidColumnWidth,
  };
};
