import {
  ApiTriggerType,
  ApplicationScope,
  DatasourceDto,
} from "@superblocksteam/shared";
import { Dropdown, Menu } from "antd";
import { findIndex } from "lodash";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import React, { useCallback, useMemo, useState } from "react";
import { InView } from "react-intersection-observer";
import { ReactComponent as LightbulbIcon } from "assets/icons/common/lightbulb.svg";
import { ReactComponent as PlusCircleIcon } from "assets/icons/common/plus-circle.svg";
import useCreateNewApi, {
  CreateApiSuccessOptions,
} from "hooks/api/useCreateNewApi";
import {
  getAllEmbedPropNames,
  getExistingWidgetNames,
} from "legacy/selectors/sagaSelectors";
import { NEW_INTEGRATION_URL } from "pages/routes";
import { useAppSelector } from "store/helpers";
import { selectAllNonStreamingApiUnionNames } from "store/slices/apisShared/selectors";
import { getScopedStateVarNames } from "store/slices/application/stateVars/selectors";
import { getScopedTimerNames } from "store/slices/application/timers/selectors";
import { getDottedPathTo } from "utils/dottedPaths";
import { getPluginById } from "utils/integrations";
import { getOpenAiAssistantShortcutString } from "utils/navigator";
import {
  AddNewIntegrationButton,
  AiShortcutMenuTitle,
  AiShortcutOption,
  AiShortcutOptionSubtext,
  Icon,
  StyledShortcutMenu,
} from "./components";

interface Props {
  insertText: (value: string, cursor?: number) => void;
  onOptionHover: (entityName: string) => void;
  onOptionHoverLeave: () => void;
  setIsVisible: (isVisible: boolean) => void;
  setCanEvaluate: (canEval: boolean) => void;
  handleParentEditorBlur: () => void;
  isVisible: boolean;
  datasources: DatasourceDto[];
  options: { id: string; name: string; icon?: string }[];
  currentScope: ApplicationScope;
  additionalDynamicData?: Record<string, Record<string, unknown>>;
  children: React.ReactNode;
  openAiAssistant?: () => void;
  // because menus can disappear when a parent loses focus entirely (editors), we don't want them to capture focus themselves
  canKeyboardFocus?: boolean;
}

enum ShortcutTypes {
  LOCAL = "LOCAL",
  API = "API",
  COMPONENT = "COMPONENT",
  TIMER = "TIMER",
  STATE = "STATE",
  LOAD_MORE_COMPONENTS = "LOAD_MORE_COMPONENTS",
  EMBED_PROP = "EMBED_PROP",
}

const MAX_WIDGETS_TO_LOAD = 20;

const DefaultShortcutMenu = (props: Props) => {
  return (
    <Dropdown
      destroyPopupOnHide //There could be more than one data-superblocks="shortcut-menu" selector results if not destroyed
      overlay={
        // avoid rerenders when menu is closed, because code editors are expensive
        props.isVisible ? <DefaultShortcutOverlay {...props} /> : <></>
      }
      open={props.isVisible}
      transitionName={""}
    >
      {props.children}
    </Dropdown>
  );
};

function DefaultShortcutOverlay({
  insertText,
  onOptionHover,
  onOptionHoverLeave,
  setIsVisible,
  handleParentEditorBlur,
  datasources,
  options,
  currentScope,
  additionalDynamicData,
  openAiAssistant,
  canKeyboardFocus,
}: Props) {
  const createNewApi = useCreateNewApi(
    {
      triggerType: ApiTriggerType.UI,
      scope: ApplicationScope.PAGE,
    },
    CreateApiSuccessOptions.OPEN_IN_SAME_TAB,
  );
  const [componentPage, setComponentPage] = useState(1);

  const widgetNames = useAppSelector(getExistingWidgetNames);

  // Ignore API and Action IDs if we're in the PropertyPane (and hence we have data tree path)
  // NOTE: pay attention to the memoization function at the bottom of this file as it only compares the router match info
  // if params apiId or actionId are changed
  const stateVarNames = useAppSelector((state) =>
    getScopedStateVarNames(state, currentScope),
  );
  const timerNames = useAppSelector((state) =>
    getScopedTimerNames(state, currentScope),
  );

  const apiNames = useAppSelector(selectAllNonStreamingApiUnionNames);
  const embedPropNames = useAppSelector(getAllEmbedPropNames);

  const localContext = useMemo(
    () =>
      Object.entries(additionalDynamicData ?? {})
        .filter(([key]) => key.indexOf("!") !== 0)
        .flatMap(([key, value]) =>
          Object.keys(value)
            .filter((subKey) => subKey.indexOf("!") !== 0)
            .map((subKey) => `${key}${getDottedPathTo(subKey)}`),
        ),
    [additionalDynamicData],
  );

  const onOptionClick = useCallback(
    async (key: string, type: string) => {
      if (key === "aiAssistant") {
        openAiAssistant?.();
        return;
      }
      handleParentEditorBlur();
      if (key === "add-new-integration") return;
      if (type === ShortcutTypes.LOAD_MORE_COMPONENTS) {
        setComponentPage((prevPage) => prevPage + 1);
        return;
      }

      if (apiNames?.includes(key)) {
        const dynamicApiResponse = "{{" + key + ".response}}";
        insertText(dynamicApiResponse, dynamicApiResponse.length - 2); // put cursor after .response before }} to allow user to continue typing if necessary
      } else if (embedPropNames?.includes(key)) {
        const dynamicEmbedProp = "{{ Embed." + key + ".value }}";
        insertText(dynamicEmbedProp, dynamicEmbedProp.length - 3); // put cursor after .value before }} to allow user to continue typing if necessary
      } else if (findIndex(options, (option) => option.id === key) !== -1) {
        const datasource = datasources.find(
          (datasource) => datasource.id === key,
        );
        const plugin = getPluginById(datasource?.pluginId);

        const api = await createNewApi({ datasource, plugin });
        const dynamicApiResponse = `{{${api?.name}.response}}`;
        insertText(dynamicApiResponse, dynamicApiResponse.length - 2);
      } else if (type === ShortcutTypes.LOCAL) {
        insertText("{{" + key + "}}", key.length + 2);
      } else {
        insertText("{{" + key + ".}}", key.length + 3); // place cursor after . to open autocomplete
      }
      setIsVisible(false);
    },
    [
      apiNames,
      createNewApi,
      datasources,
      embedPropNames,
      handleParentEditorBlur,
      insertText,
      openAiAssistant,
      options,
      setIsVisible,
    ],
  );

  const onItemSelected: MenuClickEventHandler = useCallback(
    ({ key, item }) =>
      onOptionClick(key as string, (item as any)?.props["data-type"]),
    [onOptionClick],
  );

  return (
    <StyledShortcutMenu
      onClick={onItemSelected}
      data-superblocks="shortcut-menu"
      tabIndex={canKeyboardFocus ? 0 : -1}
    >
      {openAiAssistant && (
        <Menu.Item
          key="aiAssistant"
          onMouseEnter={() => onOptionHover("")}
          onMouseLeave={() => onOptionHoverLeave()}
        >
          <AiShortcutOption>
            <AiShortcutMenuTitle>
              <LightbulbIcon />
              Ask AI for help
            </AiShortcutMenuTitle>
            <AiShortcutOptionSubtext>
              {getOpenAiAssistantShortcutString()}
            </AiShortcutOptionSubtext>
          </AiShortcutOption>
        </Menu.Item>
      )}
      {localContext.length > 0 && (
        <Menu.ItemGroup title="Current">
          {localContext.map((widgetName) => (
            <Menu.Item
              key={widgetName}
              data-type={ShortcutTypes.LOCAL}
              // TODO decide what to show on component hover
              onMouseEnter={() => onOptionHover("")}
              onMouseLeave={() => onOptionHoverLeave()}
            >
              {widgetName}
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      )}
      {currentScope === ApplicationScope.PAGE && (
        <Menu.ItemGroup title="API Responses">
          {apiNames?.map((apiName) => (
            <Menu.Item
              key={apiName}
              data-type={ShortcutTypes.API}
              onMouseEnter={({ key }) => onOptionHover(key as string)}
              onMouseLeave={() => onOptionHoverLeave()}
            >
              {apiName + ".response"}
            </Menu.Item>
          ))}
          <Menu.SubMenu
            key="new-api-submenu"
            title="Add a new API"
            popupClassName="new-api-submenu"
            onTitleMouseEnter={({ key }) => onOptionHover(key as string)}
            onTitleMouseLeave={() => onOptionHoverLeave()}
          >
            <Menu.Item
              key="add-new-integration"
              onMouseEnter={({ key }) => onOptionHover(key as string)}
              onMouseLeave={() => onOptionHoverLeave()}
            >
              <AddNewIntegrationButton
                href={NEW_INTEGRATION_URL("")}
                target="_blank"
                rel="noreferrer"
              >
                <PlusCircleIcon />
                <span>Add new integration</span>
              </AddNewIntegrationButton>
            </Menu.Item>
            {options.map((option) => (
              <Menu.Item
                key={option.id}
                icon={<Icon src={option.icon} />}
                onMouseEnter={({ key }) => onOptionHover(key as string)}
                onMouseLeave={() => onOptionHoverLeave()}
              >
                {option.name}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu.ItemGroup>
      )}
      {currentScope === ApplicationScope.PAGE && (
        <Menu.ItemGroup title="Components">
          {widgetNames
            ?.slice(0, MAX_WIDGETS_TO_LOAD * componentPage)
            .map((widgetName) => (
              <Menu.Item
                key={widgetName}
                data-type={ShortcutTypes.COMPONENT}
                // TODO decide what to show on component hover
                onMouseEnter={() => onOptionHover("")}
                onMouseLeave={() => onOptionHoverLeave()}
              >
                {widgetName}
              </Menu.Item>
            ))}
          {(widgetNames?.length ?? 0) > MAX_WIDGETS_TO_LOAD * componentPage && (
            <Menu.Item
              onMouseEnter={() => onOptionHover("")}
              onMouseLeave={() => onOptionHoverLeave()}
              data-type={ShortcutTypes.LOAD_MORE_COMPONENTS}
              key="more-components"
            >
              <InView
                onChange={(visible) => {
                  visible && setComponentPage((prevPage) => prevPage + 1);
                }}
              >
                Loading...
              </InView>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      )}
      <Menu.ItemGroup title="State">
        {stateVarNames?.map((stateVarName) => (
          <Menu.Item key={stateVarName} data-type={ShortcutTypes.STATE}>
            {stateVarName}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Timers">
        {timerNames?.map((timerName) => (
          <Menu.Item key={timerName} data-type={ShortcutTypes.TIMER}>
            {timerName}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
      {embedPropNames && embedPropNames.length > 0 && (
        <Menu.ItemGroup title="Embed Properties">
          {embedPropNames?.map((embedPropName) => (
            <Menu.Item
              key={embedPropName}
              data-type={ShortcutTypes.EMBED_PROP}
              onMouseEnter={({ key }) => onOptionHover(key as string)}
              onMouseLeave={() => onOptionHoverLeave()}
            >
              {"Embed." + embedPropName + ".value"}
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      )}
    </StyledShortcutMenu>
  );
}

export default DefaultShortcutMenu;
