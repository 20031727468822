import {
  DB_SQL_INITIAL_TEXT,
  EditorLanguage,
  FormComponentType,
  FormItem,
  InputDataType,
  Plugin,
  PluginResponseType,
  PluginType
} from '../../types';
import { PARAMETERIZED_SQL_DESCRIPTION } from './constants';
import { SqlOperations, sshTunnelSections } from './shared';
import { CONNECTION_METHODS_AND_DISPLAY_NAMES, makeDropdownItem } from './shared/db';

export const PostgresPluginVersions = {
  V1: '0.0.1',
  V2: '0.0.2',
  V6: '0.0.6',
  V8: '0.0.8',
  V9: '0.0.9',
  V10: '0.0.10',
  V11: '0.0.11'
};

const BASE_HOST = {
  label: 'Host',
  name: 'endpoint.host',
  componentType: FormComponentType.INPUT_TEXT,
  placeholder: 'staging-database.amazonaws.com',
  rules: [{ required: true, message: 'Host is required' }]
};

const BASE_PORT = {
  label: 'Port',
  name: 'endpoint.port',
  componentType: FormComponentType.INPUT_TEXT,
  initialValue: '5432',
  rules: [{ required: true, message: 'Port is required' }]
};

const BASE_DATABASE_NAME = {
  label: 'Database name',
  name: 'authentication.custom.databaseName.value',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_USERNAME = {
  label: 'Database username',
  name: 'authentication.username',
  componentType: FormComponentType.INPUT_TEXT
};

const BASE_DATABASE_PASSWORD = {
  label: 'Database password',
  name: 'authentication.password',
  componentType: FormComponentType.INPUT_TEXT,
  dataType: InputDataType.PASSWORD
};

const BASE_ENABLE_SSL = {
  label: 'Enable SSL',
  name: 'connection.useSsl',
  initialValue: 'checked',
  componentType: FormComponentType.CHECKBOX
};

const BASE_SELFSIGNED_SSL = {
  label: 'Use a self-signed SSL certificate',
  name: 'connection.useSelfSignedSsl',
  componentType: FormComponentType.CHECKBOX,
  tooltip: {
    markdownText:
      'Use a self-signed SSL certificate, e.g. when [connecting to Cloud SQL](https://cloud.google.com/sql/docs/postgres/connect-admin-ip#connect-ssl)'
  }
};

const BASE_SERVER_CA = {
  label: 'Server CA',
  name: 'connection.ca',
  componentType: FormComponentType.CODE_EDITOR,
  language: EditorLanguage.TEXT,
  placeholder:
    '-----BEGIN CERTIFICATE-----\n' +
    'MIIC/jCCAeYCCQDLwS4pIwJC3zANBgkqhkiG9w0BAQsFADBBMQswCQYDVQQGEwJV\n' +
    '...\n' +
    '-----END CERTIFICATE-----'
};

const BASE_CLIENT_KEY = {
  label: 'Client key',
  name: 'connection.key',
  componentType: FormComponentType.CODE_EDITOR,
  language: EditorLanguage.TEXT,
  placeholder:
    '-----BEGIN RSA PRIVATE KEY-----\n' +
    'BAoMBFRlc3QwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCpvozN05Ou\n' +
    '...\n' +
    '-----END RSA PRIVATE KEY-----'
};

const BASE_CLIENT_CERT = {
  label: 'Client cert',
  name: 'connection.cert',
  componentType: FormComponentType.CODE_EDITOR,
  language: EditorLanguage.TEXT,
  placeholder:
    '-----BEGIN CERTIFICATE-----\n' +
    'Ur2LYWdrVjqlS/wJyVIze15FMf7sgl+RINsLbQDLwS4pIwJC3zANBgkqhkiG9w0B\n' +
    '...\n' +
    '-----END CERTIFICATE-----'
};

export const PostgresPlugin: Plugin = {
  id: 'postgres',
  name: 'Postgres',
  moduleName: 'PostgresPlugin',
  modulePath: 'plugins/postgres/PostgresPlugin',
  iconLocation: 'https://superblocks.s3-us-west-2.amazonaws.com/img/integrations/postgres.png',
  docsUrl: 'https://docs.superblocks.com/integrations/integrations-library/postgres',
  type: PluginType.DB,
  responseType: PluginResponseType.TABLE,
  hasRawRequest: true,
  hasMetadata: true,
  rawRequestName: 'Executed SQL',
  datasourceTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Display name',
            name: 'name',
            startVersion: PostgresPluginVersions.V1,
            componentType: FormComponentType.INPUT_TEXT,
            placeholder: 'ProdDB',
            rules: [{ required: true, message: 'Display name is required' }]
          },
          {
            label: 'Connection method',
            name: 'connectionType',
            startVersion: PostgresPluginVersions.V11,
            componentType: FormComponentType.DROPDOWN,
            initialValue: 'fields',
            rules: [{ required: true }],
            options: Object.entries(CONNECTION_METHODS_AND_DISPLAY_NAMES).map(([value, displayName]) =>
              makeDropdownItem(value, displayName)
            )
          },
          {
            label: 'URI',
            name: 'connectionUrl',
            startVersion: PostgresPluginVersions.V11,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            placeholder: `postgres://[:user]:[:password]@[:host]:[:port]/[:database]`,
            tooltip: {
              markdownText:
                'Learn more about [PostgresDB connection options](https://github.com/brianc/node-postgres/tree/master/packages/pg-connection-string)'
            },
            rules: [{ required: true, message: 'URI is required' }],
            display: {
              show: {
                connectionType: ['url']
              }
            }
          },
          // HOST
          { ...BASE_HOST, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_HOST,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // PORT
          { ...BASE_PORT, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_PORT,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE NAME
          { ...BASE_DATABASE_NAME, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_DATABASE_NAME,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE USERNAME
          { ...BASE_DATABASE_USERNAME, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_DATABASE_USERNAME,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // DATABASE PASSWORD
          { ...BASE_DATABASE_PASSWORD, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_DATABASE_PASSWORD,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // ENABLE SSL
          { ...BASE_ENABLE_SSL, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_ENABLE_SSL,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // USE SELF-SIGNED SSL
          { ...BASE_SELFSIGNED_SSL, startVersion: PostgresPluginVersions.V1, endVersion: PostgresPluginVersions.V10 } as FormItem,
          {
            ...BASE_SELFSIGNED_SSL,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // SERVER CA
          {
            ...BASE_SERVER_CA,
            startVersion: PostgresPluginVersions.V1,
            endVersion: PostgresPluginVersions.V10,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true']
              }
            }
          } as FormItem,
          {
            ...BASE_SERVER_CA,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true'],
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // CLIENT KEY
          {
            ...BASE_CLIENT_KEY,
            startVersion: PostgresPluginVersions.V1,
            endVersion: PostgresPluginVersions.V10,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true']
              }
            }
          } as FormItem,
          {
            ...BASE_CLIENT_KEY,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true'],
                connectionType: ['fields']
              }
            }
          } as FormItem,
          // CLIENT CERT
          {
            ...BASE_CLIENT_CERT,
            startVersion: PostgresPluginVersions.V1,
            endVersion: PostgresPluginVersions.V10,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true']
              }
            }
          } as FormItem,
          {
            ...BASE_CLIENT_CERT,
            startVersion: PostgresPluginVersions.V11,
            display: {
              show: {
                'connection.useSelfSignedSsl': ['true'],
                connectionType: ['fields']
              }
            }
          } as FormItem,
          ...sshTunnelSections({
            startVersion: PostgresPluginVersions.V1,
            endVersion: PostgresPluginVersions.V10
          }),
          ...sshTunnelSections({
            startVersion: PostgresPluginVersions.V11,
            hideForConnectionUrl: true
          })
        ]
      }
    ]
  },
  actionTemplate: {
    sections: [
      {
        name: 'main',
        items: [
          {
            label: 'Operation',
            name: 'operation',
            startVersion: PostgresPluginVersions.V8,
            endVersion: PostgresPluginVersions.V9,
            componentType: FormComponentType.DROPDOWN,
            options: [
              {
                displayName: 'Run SQL',
                key: SqlOperations.RUN_SQL,
                value: SqlOperations.RUN_SQL
              },
              {
                displayName: 'Update rows with form',
                key: SqlOperations.UPDATE_ROWS,
                value: SqlOperations.UPDATE_ROWS
              }
            ],
            initialValue: SqlOperations.RUN_SQL
          },
          {
            label: 'Operation',
            name: 'operation',
            startVersion: PostgresPluginVersions.V10,
            componentType: FormComponentType.DROPDOWN,
            options: [
              {
                displayName: 'Run SQL',
                key: SqlOperations.RUN_SQL,
                value: SqlOperations.RUN_SQL
              },
              {
                displayName: 'Insert, update, or delete rows with form',
                key: SqlOperations.UPDATE_ROWS,
                value: SqlOperations.UPDATE_ROWS
              }
            ],
            initialValue: SqlOperations.RUN_SQL
          },
          {
            label: '', // Query
            name: 'body',
            startVersion: PostgresPluginVersions.V1,
            componentType: FormComponentType.CODE_EDITOR,
            language: EditorLanguage.SQL,
            initialValue: DB_SQL_INITIAL_TEXT,
            display: {
              show: {
                // Frontend is using string equality checks here
                operation: ['undefined', SqlOperations.RUN_SQL]
              }
            }
          },
          {
            label: 'Postgres schema',
            name: 'schema',
            startVersion: PostgresPluginVersions.V9,
            componentType: FormComponentType.METADATA_DROPDOWN,
            placeholder: 'Select a schema',
            keyAccessor: 'name',
            valueAccessor: 'name',
            listAccessor: 'metadata.dbSchema.schemas',
            displayNameAccessor: 'name',
            defaultToFirstOption: true,
            clearDependentFieldsOnChange: ['table'],
            triggerGetMetadata: true,
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            },
            rules: [{ required: true, message: 'Schema is required' }],
            showSearch: true,
            optionFilterProp: 'value'
          },
          {
            label: 'Postgres table',
            name: 'table',
            startVersion: PostgresPluginVersions.V8,
            componentType: FormComponentType.METADATA_DROPDOWN,
            placeholder: 'Select a table',
            keyAccessor: 'name',
            valueAccessor: 'name',
            listAccessor: 'metadata.dbSchema.tables',
            filterDependency: 'schema',
            filterFieldName: 'schema',
            displayNameAccessor: 'name',
            triggerGetMetadata: true,
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            },
            rules: [{ required: true, message: 'Table is required' }],
            showSearch: true,
            optionFilterProp: 'value'
          }
        ]
      },
      {
        name: 'main2',
        sectionHeader: 'Step 1 - Match rows',
        items: [
          {
            label: 'Row matching mode',
            name: 'useAdvancedMatching',
            startVersion: PostgresPluginVersions.V8,
            componentType: FormComponentType.DROPDOWN,
            options: [
              {
                displayName: 'Automatically match against database table primary key',
                subText: 'Table must have a primary key. Does not allow updates that modify primary key columns.',
                key: 'auto',
                value: 'auto'
              },
              {
                displayName: 'Manually match against any column(s)',
                subText: 'Use with any column. Allows updates that modify primary key columns.',
                key: 'advanced',
                value: 'advanced'
              }
            ],
            initialValue: 'auto',
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          },
          {
            label: 'Primary keys',
            name: 'primaryKeyDisplay',
            startVersion: PostgresPluginVersions.V8,
            componentType: FormComponentType.PRIMARY_KEY_DISPLAY,
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS],
                useAdvancedMatching: ['undefined', 'auto']
              }
            }
          },
          // Group
          {
            label: 'Array of original rows to be updated',
            name: 'oldValues',
            startVersion: PostgresPluginVersions.V8,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            subHeading: `E.g. for an editable Table, it will be \`{{Table1.editedRows.originalRows}}\``,
            placeholder: '{{ [{name: “OriginalBilly”, email: “billy@joel.com”}, {name: “OriginalAlice”, email: “a@alice.com”}] }}',
            tooltip: {
              markdownText: `Used to identify the Postgres rows that will be updated.`
            },
            rules: [{ required: true, message: 'Missing required value' }],
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS],
                useAdvancedMatching: ['advanced']
              }
            }
          },
          {
            label: 'Postgres columns to match on',
            name: 'filterBy',
            startVersion: PostgresPluginVersions.V8,
            componentType: FormComponentType.FILTER_COLUMNS,
            tooltip: {
              markdownText: `Specify which columns are used to find unique matches.`
            },
            rules: [{ required: true, message: 'At least one filter column is required' }],
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS],
                useAdvancedMatching: ['advanced']
              }
            }
          }
        ]
      },
      {
        name: 'updates',
        sectionHeader: 'Step 2 - Update matched rows',
        items: [
          {
            label: 'Rows to insert',
            name: 'insertedRows',
            startVersion: PostgresPluginVersions.V10,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: '{{ [{name: “Billy”, email: “billy@joel.com”}, {name: “Alice”, email: “a@alice.com”}] }}',
            subHeading: `E.g for an editable Table, it will be \`{{Table1.editedRows.insertedRows}}\``,
            tooltip: {
              markdownText: 'These rows will be inserted'
            },
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          },
          {
            label: 'Rows to update',
            name: 'newValues',
            startVersion: PostgresPluginVersions.V8,
            endVersion: PostgresPluginVersions.V9,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: '{{ [{name: “Billy”, email: “billy@joel.com”}, {name: “Alice”, email: “a@alice.com”}] }}',
            rules: [{ required: true, message: 'Missing required value' }],
            subHeading: `E.g for an editable Table, it will be \`{{Table1.editedRows.updatedRows}}\``,
            tooltip: {
              markdownText: 'Matched rows from Step 1 will be updated to these values'
            },
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          },
          {
            label: 'Rows to update',
            name: 'newValues',
            startVersion: PostgresPluginVersions.V10,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: '{{ [{name: “Billy”, email: “billy@joel.com”}, {name: “Alice”, email: “a@alice.com”}] }}',
            subHeading: `E.g for an editable Table, it will be \`{{Table1.editedRows.updatedRows}}\``,
            tooltip: {
              markdownText: 'Matched rows from Step 1 will be updated to these values'
            },
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          },
          {
            label: 'Rows to delete',
            name: 'deletedRows',
            startVersion: PostgresPluginVersions.V10,
            componentType: FormComponentType.DYNAMIC_INPUT_TEXT,
            initialValue: '',
            placeholder: '{{ [{name: “Billy”, email: “billy@joel.com”}, {name: “Alice”, email: “a@alice.com”}] }}',
            subHeading: `E.g for an editable Table, it will be \`{{Table1.editedRows.deletedRows}}\``,
            tooltip: {
              markdownText: 'Matched rows from Step 1 will be deleted'
            },
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          }
        ]
      },
      {
        name: 'preview',
        sectionHeader: 'Step 3 - Match columns',
        items: [
          {
            label: 'Map JSON keys to Postgres columns',
            name: 'mappingSettingsDisplay',
            startVersion: PostgresPluginVersions.V8,
            componentType: FormComponentType.KEY_MAPPING,
            tooltip: {
              markdownText: 'Used to map JSON keys from the previous steps to Postgres columns'
            },
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          },
          {
            label: 'Preview',
            name: 'preview',
            startVersion: PostgresPluginVersions.V8,
            endVersion: PostgresPluginVersions.V9,
            componentType: FormComponentType.SQL_PREVIEW,
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          },
          {
            label: 'Preview',
            name: 'preview',
            startVersion: PostgresPluginVersions.V10,
            componentType: FormComponentType.SQL_PREVIEW_WITH_INSERT_DELETE,
            display: {
              show: {
                operation: [SqlOperations.UPDATE_ROWS]
              }
            }
          }
        ]
      },
      {
        name: 'advanced:main',
        items: [
          {
            label: 'Use parameterized SQL',
            name: 'usePreparedSql',
            startVersion: PostgresPluginVersions.V6,
            componentType: FormComponentType.SWITCH,
            initialValue: true,
            tooltip: {
              markdownText: PARAMETERIZED_SQL_DESCRIPTION
            },
            display: {
              show: {
                operation: ['undefined', SqlOperations.RUN_SQL]
              }
            }
          }
        ]
      }
    ]
  }
};
