import { IHomepageApplicationV2Dto } from '../application';
import { Billing } from '../billing';
import { GroupBrief } from '../group';
import { PluginExecutionVersions } from '../plugin';
import { Profile } from '../profile';
import { Agent, AgentType } from './agent';

export const SUPERBLOCKS_ORG_NAME = 'superblockshq.com';
// for server tests
export const SUPERBLOCKS_TEST_ORG_NAME = 'superblocksservertest.com';
// for server tests
export const SUPERBLOCKS_TEST_ORG_NAME2 = 'superblocksservertest2.com';
// for manual testing
export const SUPERBLOCKS_MANUAL_TEST_ORG_NAME = 'superblockstest.com';
export const SUPERBLOCKS_SUPPORT = 'Superblocks Support';

export const VISITOR_ORG_NAME = 'visitor';

export interface Organization {
  id: string;
  name: string;
  displayName: string;
  agents?: Agent[];
  apiKey: string;
  agentType: AgentType;
  minExternalAgentVersion: string;
  pluginExecutionVersions?: PluginExecutionVersions;
  groups: GroupBrief[];
  billing: Billing;
  profiles?: Profile[];
}

export type OrgBriefDto = {
  id: string;
  name: string;
};

export interface IOrganizationDto {
  id: string;
  name: string;
  email: string;
  slug: string;
  logoUrl: string;
  new: boolean;
  userPermissions: string[];

  agents: Agent[] | undefined;
  pluginExecutionVersions: PluginExecutionVersions;
  minExternalAgentVersion: string;
}

export interface IOrganizationApplicationsV2Dto {
  organization: IOrganizationDto;
  applications: IHomepageApplicationV2Dto[];
}

export * from './agent';
export * from './invite';
export * from './token';
