import { Profile } from "@superblocksteam/shared";
import { Dropdown, Menu, Tooltip, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import { produce } from "immer";
import { isEmpty } from "lodash";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useCallback, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { ReactComponent as EllipsisIcon } from "assets/icons/common/ellipsis.svg";
import { ReactComponent as PlusCircle } from "assets/icons/common/plus-circle.svg";
import { ReactComponent as WarningIcon } from "assets/icons/common/warning.svg";
import { InitialValueFn } from "components/app/SBDynamicForm/utils";
import { StyledShortcutMenu } from "components/app/ShortcutMenu/components";
import { AlertType } from "components/ui/Alert";
import { Markdown } from "components/ui/Markdown";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import {
  StyledMenu,
  StyledMenuItem,
  StyledSider,
} from "pages/components/PageNav";
import { ConfigurationProfilesModal } from "./ConfigurationProfilesModal";
import { ConfigMeta, generateEmptyConfigTooltipText } from "./utils";

// the real width is calculated by the width below based on algorithm in DropdownSearch component

export interface ConnectionAlert {
  type: AlertType;
  message: string | React.ReactNode | null;
}

const ConfigurationNavWrapper = styled.div`
  border-right: 1px solid ${LegacyNamedColors.GRAY_LIGHT};
  background-color: ${LegacyNamedColors.WHITE};
  padding-top: 10px;
  .ant-menu {
    margin-bottom: 0px;
  }

  li.ant-menu-item {
    overflow: visible;
    padding-right: 8px !important;
  }

  li.ant-menu-item.empty-config {
    background-color: ${({ theme }) => `${theme.colors.ACCENT_ORANGE}14`};
    span.ant-menu-title-content {
      margin-left: 0px;
    }
  }

  li.ant-menu-item.empty-config::after {
    border-color: ${({ theme }) => `${theme.colors.ACCENT_ORANGE}`};
  }

  li.ant-menu-item .ant-dropdown-trigger {
    display: none;
  }

  li.ant-menu-item:hover .ant-dropdown-trigger,
  li.ant-menu-item.empty-config:hover .ant-dropdown-trigger {
    display: flex;
  }

  .ant-layout-sider-children {
    margin-bottom: 10px;
  }
`;

const PlusButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledPlus = styled(PlusCircle)`
  cursor: pointer;
  width: 21px;
  height: 21px;
  color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};

  :hover path {
    fill: ${({ theme }) => theme.colors.ACCENT_BLUE_600};
  }

  :active path {
    fill: ${({ theme }) => theme.colors.ACCENT_BLUE_700};
  }
`;

const NavTextWrapper = styled.div<{
  $isDefault: boolean;
  $isUnused: boolean;
  $prevUsed: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $isDefault, $isUnused, $prevUsed, theme }) =>
    !$isDefault && $isUnused
      ? css`
          color: ${theme.colors.GREY_300} !important;
          span.ant-typography {
            text-decoration-color: ${theme.colors.GREY_300} !important;
            color: ${theme.colors.GREY_300} !important;
            padding-right: 2px;
            text-decoration: ${$prevUsed ? "line-through" : "none"};
          }
        `
      : ""}
`;

const MenuItemInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const ContextMenuButton = styled.div<{
  $isSelected: boolean;
  $isWarning: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme, $isSelected, $isWarning }) =>
    $isWarning
      ? `${theme.colors.ACCENT_ORANGE}`
      : $isSelected
      ? theme.colors.ACCENT_BLUE_600
      : theme.colors.GREY_300};
  transition: none;

  :hover,
  :active,
  :focus {
    color: ${({ theme, $isSelected, $isWarning }) =>
      $isWarning
        ? theme.colors.ACCENT_ORANGE_600
        : $isSelected
        ? theme.colors.ACCENT_BLUE_700
        : theme.colors.GREY_500};
  }

  svg {
    width: 12px;
  }
`;

const WarningIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
`;

const ConfigurationMenuItemContent = ({
  configMeta,
  defaultConfigMeta,
  currentConfigMeta,
  profiles,
  setConfigMetaToClone,
  setAddModalVisible,
  setConfigIdToConfigMeta,
  setCurrentConfigurationId,
  setIsDirty,
}: {
  configMeta: ConfigMeta;
  defaultConfigMeta: ConfigMeta | undefined;
  currentConfigMeta: ConfigMeta;
  profiles: Profile[];
  setConfigMetaToClone: (configMeta: ConfigMeta) => void;
  setAddModalVisible: (visible: boolean) => void;
  setConfigIdToConfigMeta: (
    value:
      | Record<string, ConfigMeta>
      | ((configIdToConfigMeta: Record<string, ConfigMeta>) => void),
  ) => void;
  setCurrentConfigurationId: (id: string) => void;
  setIsDirty: (isDirty: boolean) => void;
}) => {
  const isSelected = configMeta.id === currentConfigMeta.id;
  const configName =
    configMeta.profileIds
      .map(
        (profileId: string) =>
          profiles.find((profile) => profile.id === profileId)?.displayName,
      )
      .join(", ") ||
    configMeta.originalProfileIds
      .map(
        (profileId: string) =>
          profiles.find((profile) => profile.id === profileId)?.displayName,
      )
      .join(", ") ||
    "No profiles specified";

  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onClone = useCallback(
    (info: MenuInfo) => {
      info.domEvent.stopPropagation();
      setConfigMetaToClone(configMeta);
      setAddModalVisible(true);
      setIsDirty(true);
    },
    [configMeta, setAddModalVisible, setConfigMetaToClone, setIsDirty],
  );

  const onDelete = useCallback(
    (info: MenuInfo) => {
      info.domEvent.stopPropagation();
      setConfigIdToConfigMeta(
        produce((draft: Record<string, ConfigMeta>) => {
          delete draft[configMeta.id];
          // add profiles in the deleted config to the default config
          const defaultConfig = Object.values(draft).find(
            (config) => config.isDefault,
          );
          if (defaultConfig) {
            defaultConfig.profileIds = [
              ...defaultConfig.profileIds,
              ...configMeta.profileIds,
            ];
          }
        }),
      );
      if (isSelected && defaultConfigMeta?.id) {
        setCurrentConfigurationId(defaultConfigMeta?.id);
      }
      setIsDirty(true);
    },
    [
      configMeta.id,
      configMeta.profileIds,
      defaultConfigMeta?.id,
      isSelected,
      setConfigIdToConfigMeta,
      setCurrentConfigurationId,
      setIsDirty,
    ],
  );

  const tobeDeleted = !configMeta.isDefault && isEmpty(configMeta.profileIds);

  return (
    <Tooltip
      title={
        <Markdown>
          {isEmpty(configMeta.profileIds)
            ? generateEmptyConfigTooltipText(configMeta, profiles)
            : configName}
        </Markdown>
      }
    >
      <MenuItemInnerWrapper>
        <NavTextWrapper
          $isDefault={configMeta.isDefault}
          $isUnused={isEmpty(configMeta.profileIds)}
          $prevUsed={!isEmpty(configMeta.originalProfileIds)}
        >
          <Typography.Text>
            {configMeta?.isDefault ? "Default" : configName}
          </Typography.Text>
        </NavTextWrapper>

        <Dropdown
          trigger={["click"]}
          placement="bottomCenter"
          overlay={
            <StyledShortcutMenu>
              {!tobeDeleted && (
                <Menu.Item
                  key="clone"
                  data-test="configuration-context-clone"
                  onClick={onClone}
                >
                  Clone
                </Menu.Item>
              )}
              {!configMeta?.isDefault && (
                <Menu.Item
                  key="delete"
                  data-test="configuration-context-delete"
                  onClick={onDelete}
                >
                  Delete
                </Menu.Item>
              )}
            </StyledShortcutMenu>
          }
          destroyPopupOnHide={true}
        >
          <ContextMenuButton
            data-test="configuration-actions-button"
            $isSelected={isSelected}
            $isWarning={tobeDeleted}
            onClick={stopPropagation}
          >
            <EllipsisIcon />
          </ContextMenuButton>
        </Dropdown>
      </MenuItemInnerWrapper>
    </Tooltip>
  );
};

const ConfigurationNav = React.memo(
  ({
    currentConfigMeta,
    setCurrentConfigurationId,
    profiles,
    configMetas,
    setConfigIdToConfigMeta,
    configIdToFormData,
    setConfigIdToFormData,
    initialValuesFn,
    setIsDirty,
  }: {
    currentConfigMeta: any;
    setCurrentConfigurationId: (id: string) => void;
    profiles: Profile[];
    configMetas: ConfigMeta[];
    setConfigIdToConfigMeta: (
      value:
        | Record<string, ConfigMeta>
        | ((configIdToConfigMeta: Record<string, ConfigMeta>) => void),
    ) => void;
    configIdToFormData: Record<string, any>;
    setConfigIdToFormData: (configIdToFormData: Record<string, any>) => void;
    initialValuesFn: InitialValueFn;
    setIsDirty: (isDirty: boolean) => void;
  }) => {
    const theme = useTheme();

    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const defaultConfigMeta = configMetas.find(
      (configMetas) => configMetas.isDefault,
    );

    const [configMetaToClone, setConfigMetaToClone] = useState<
      ConfigMeta | undefined
    >(undefined);

    const onAddClicked = useCallback(() => {
      // create new config as blank if not cloning
      setConfigMetaToClone(undefined);
      setIsAddModalVisible(true);
      setIsDirty(true);
    }, [setIsDirty]);

    return (
      <ConfigurationNavWrapper>
        <StyledSider width={theme.legacy.profilesSidebarWidth}>
          <StyledMenu
            mode="inline"
            defaultSelectedKeys={[currentConfigMeta.id]}
          >
            <StyledMenuItem key={"title"} style={{ pointerEvents: "none" }}>
              <Title level={5}>Configurations</Title>
            </StyledMenuItem>

            <div style={{ margin: "4px" }}></div>
            {configMetas.map((configMeta) => {
              const willBeDeleted =
                !configMeta.isDefault && isEmpty(configMeta.profileIds);
              return (
                <StyledMenuItem
                  key={configMeta.id}
                  onClick={() => {
                    return setCurrentConfigurationId(configMeta.id);
                  }}
                  data-test={`config-nav-item-${configMeta.id}`}
                  className={willBeDeleted ? "empty-config" : ""}
                  icon={
                    willBeDeleted && (
                      <WarningIconWrapper>
                        <WarningIcon />
                      </WarningIconWrapper>
                    )
                  }
                >
                  <ConfigurationMenuItemContent
                    key={configMeta.id}
                    defaultConfigMeta={defaultConfigMeta}
                    configMeta={configMeta}
                    currentConfigMeta={currentConfigMeta}
                    profiles={profiles}
                    setConfigMetaToClone={setConfigMetaToClone}
                    setAddModalVisible={setIsAddModalVisible}
                    setConfigIdToConfigMeta={setConfigIdToConfigMeta}
                    setCurrentConfigurationId={setCurrentConfigurationId}
                    setIsDirty={setIsDirty}
                  />
                </StyledMenuItem>
              );
            })}
          </StyledMenu>
          <PlusButtonWrapper>
            <Tooltip title="Add new configuration">
              <StyledPlus
                data-test="add-config-button"
                onClick={onAddClicked}
              />
            </Tooltip>
          </PlusButtonWrapper>
          <ConfigurationProfilesModal
            isNew={true}
            profiles={profiles}
            configMetaToClone={configMetaToClone}
            configIdToFormData={configIdToFormData}
            setConfigIdToFormData={setConfigIdToFormData}
            setConfigIdToConfigMeta={setConfigIdToConfigMeta}
            configMetas={configMetas}
            isModalVisible={isAddModalVisible}
            setIsModalVisible={setIsAddModalVisible}
            setCurrentConfigurationId={setCurrentConfigurationId}
            initialValuesFn={initialValuesFn}
          />
        </StyledSider>
      </ConfigurationNavWrapper>
    );
  },
);

ConfigurationNav.displayName = "ProfilesNav";
export default ConfigurationNav;
