import { Space, Switch as AntSwitch, SwitchProps, Typography } from "antd";
import React from "react";
import styled from "styled-components";

const SwitchLabel = styled(Typography.Text)`
  cursor: pointer;
  user-select: none;
`;

type Props = {
  label?: string | JSX.Element;
} & SwitchProps;

export const Switch = (props: Props) => {
  const onChange = props.onChange || props.onClick;
  return (
    <Space>
      <AntSwitch {...props} />
      <SwitchLabel
        onClick={(e: any) => onChange && onChange(!props.checked ?? false, e)}
      >
        {props.label}
      </SwitchLabel>
    </Space>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default Switch;
