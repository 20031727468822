import { Button, Tooltip, TooltipProps } from "antd";
import React from "react";
import styled from "styled-components";
import { colors } from "styles/colors";

const StyledButton = styled(Button)`
  color: ${colors.GREY_300};
  border-color: transparent;
  background-color: unset;

  display: flex;
  justify-content: center;
  align-items: center;

  /* override antd specificity */
  && {
    box-shadow: none;
  }

  &:hover,
  &:focus {
    color: ${colors.GREY_500};
    background-color: ${colors.GREY_25};
    border-color: ${colors.GREY_100};
  }
  &:active {
    background-color: ${colors.GREY_50};
  }
  &:disabled {
    color: ${colors.GREY_200};
    border-color: transparent; // override antd
    background-color: unset; // override antd
  }
`;

export type TooltipPlacement = TooltipProps["placement"];
export interface IconButtonProps
  extends Pick<React.ComponentProps<"button">, "className" | "style"> {
  icon: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  title?: TooltipProps["title"];
  "data-test"?: string;
  disabled?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  tooltipPlacement?: TooltipPlacement;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      className,
      style,
      onClick,
      title,
      disabled,
      tooltipPlacement = "left",
      ...rest
    },
    ref,
  ) => {
    return (
      <Tooltip title={title} placement={tooltipPlacement} mouseEnterDelay={0.5}>
        <StyledButton
          style={style}
          className={className ?? ""}
          ref={ref}
          onClick={onClick}
          data-test={rest["data-test"]}
          disabled={disabled}
          icon={icon}
          size="small"
          data-disabled={disabled}
        />
      </Tooltip>
    );
  },
);
IconButton.displayName = "IconButton";

export default IconButton;
