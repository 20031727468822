import {
  ApplicationScope,
  NotificationPosition,
  PageRoute,
  RouteDef,
} from "@superblocksteam/shared";
import { isPlainObject } from "lodash";
import { generatePath } from "react-router";
import {
  all,
  select,
  takeEvery,
  put,
  call,
  getContext,
  take,
  race,
} from "redux-saga/effects";
import { updateWidgetProperties } from "legacy/actions/controlActions";
import {
  restartEvaluation,
  stopEvaluation,
} from "legacy/actions/evaluationActions";
import {
  pageLoadSuccess,
  switchCurrentPage,
  updateCurrentRoute,
  updateDataUrl,
} from "legacy/actions/pageActions";
import { showItemPropertyPane } from "legacy/actions/propertyPaneActions";
import {
  closeAllModals,
  runEventHandlers,
  showModal,
} from "legacy/actions/widgetActions";
import {
  EventType,
  MultiStepDef,
  TriggerStepType,
} from "legacy/constants/ActionConstants";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { PAGE_WIDGET_ID } from "legacy/constants/WidgetConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import {
  NOTIFICATION_KEY,
  sendPageRouteSuccessNotification,
} from "legacy/pages/Editor/Routes/Notifications";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import {
  getCurrentApplicationId,
  getCurrentBranch,
  getCurrentPageId,
  getCurrentPageName,
} from "legacy/selectors/editorSelectors";
import { getCanvasWidget } from "legacy/selectors/entitiesSelector";
import {
  getCurrentRoute,
  getCurrentRoutePath,
  getRoutes,
  getRoutesList,
} from "legacy/selectors/routeSelectors";
import { createRunEventHandlersPayload } from "legacy/utils/actions";
import { generateReactKey } from "legacy/utils/generators";
import {
  getCurrentQueryParams,
  getSystemQueryParams,
} from "legacy/utils/queryParams";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { resetApis } from "store/slices/apisV2";
import {
  requestApplicationSave,
  updateApplication,
} from "store/slices/application/applicationActions";
import { Flag, selectFlags } from "store/slices/featureFlags";
import { queuedBatchDebounce } from "store/utils/effects";
import { addNewPromise } from "store/utils/resolveIdSingleton";
import { NOOP } from "utils/function";
import { getTargetNavigationURL } from "utils/navigation";
import {
  closeNotification,
  sendSuccessUINotification,
  sendWarningUINotification,
} from "utils/notification";
import {
  extractDynamicSegments,
  findParentPageIfPossible,
} from "utils/routing";
import { markPageLoadApis, executePageLoadActions } from "./EvaluationsSaga";
import { runEventHandlersSaga } from "./TriggerExecutionSaga";
import { waitForEvaluationToComplete } from "./waitForEvaluation";

function* persistRoutesSaga(): Generator<any, any, any> {
  const appId: ReturnType<typeof getCurrentApplicationId> = yield select(
    getCurrentApplicationId,
  );
  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );
  if (!appId) return;
  const routePersistence: ReturnType<typeof getRoutes> = yield select(
    getRoutes,
  );
  yield put(
    updateApplication(appId, branch?.name, {
      configuration: {
        routes: routePersistence,
      },
    }),
  );
  yield put(requestApplicationSave({ hasUpdatedConfiguration: true }));
}

// Selects the active route after it's created
function* createRouteSaga(
  action: ReduxAction<{
    routeDef: RouteDef;
    addSlideoutOnClose: boolean;
  }>,
): Generator<any, any, any> {
  const allRoutes: ReturnType<typeof getRoutesList> = yield select(
    getRoutesList,
  );
  const route = allRoutes.find((r) => r.path === action.payload.routeDef.path);
  if (!route) return;

  yield call(persistRoutesSaga);

  const isDynamic = action.payload.routeDef.path.includes(":");
  if (isDynamic) {
    sendPageRouteSuccessNotification({
      isDynamic: true,
      pageName: "",
      showEdit: false,
      createdRoute: true,
      viewRouteParams: {},
    });
  }

  if (!action.payload.addSlideoutOnClose) return;

  const widgetId =
    "widgetId" in action.payload.routeDef && action.payload.routeDef.widgetId;
  if (!widgetId) return;
  const widget: ReturnType<typeof getCanvasWidget> = yield select(
    getCanvasWidget,
    widgetId,
  );
  const parentRoute = findParentPageIfPossible(
    allRoutes,
    action.payload.routeDef.path,
    (action.payload.routeDef as PageRoute).pageId,
  );
  if (!parentRoute) return;
  yield put(
    updateWidgetProperties(widgetId, {
      onClose: [
        ...((widget as any).onClose ?? []),
        {
          id: generateReactKey(),
          type: TriggerStepType.NAVIGATE_TO,
          url: parentRoute,
          newWindow: false,
        },
      ] as MultiStepDef,
    }),
  );
}

// Deletes any reference to the route from within the page
function* deleteRouteSaga(
  action: ReduxAction<{ id: string }>,
): Generator<any, any, any> {
  yield call(persistRoutesSaga);

  const currentPageId = yield select(getCurrentPageId);
  const currentPath = yield select(getCurrentRoutePath);
  const allRoutes: ReturnType<typeof getRoutesList> = yield select(
    getRoutesList,
  );

  // if we come across the current route, we dont do anything actually
  const routeForCurrentPath = allRoutes.find((r) => r.path === currentPath);
  if (routeForCurrentPath) {
    return;
  }
  const routeToTransitionTo = allRoutes.find(
    (route) => "pageId" in route && route.pageId === currentPageId,
  );

  if (routeToTransitionTo && "pageId" in routeToTransitionTo) {
    yield put({
      type: ReduxActionTypes.EDITOR_VIEW_ROUTE,
      payload: {
        path: routeToTransitionTo.path,
        pageId: routeToTransitionTo.pageId,
      },
    });
  }
}

function* getSessionRouteParams() {
  let previousRoutesStr: ReturnType<typeof sessionStorage.getItem> = yield call(
    sessionStorage.getItem,
    SessionStorageKey.EDITOR_PREVIOUS_ROUTES,
  );
  previousRoutesStr ??= "{}";
  try {
    const response = JSON.parse(previousRoutesStr);
    if (!isPlainObject(response)) {
      throw new Error("Invalid previous routes");
    }
    return response;
  } catch (e) {
    return {};
  }
}

function* getSessionQueryParams(pageId: string) {
  let previousQueryParamsStr: ReturnType<typeof sessionStorage.getItem> =
    yield call(
      sessionStorage.getItem,
      SessionStorageKey.EDITOR_PREVIOUS_QUERY_PARAMS,
    );
  previousQueryParamsStr ??= "{}";
  try {
    const queryParams = JSON.parse(previousQueryParamsStr);
    return queryParams[pageId] ?? {};
  } catch (e) {
    // Reset session params to empty object as well if we error?
    return {};
  }
}

function* writeSessionQueryParams(
  pageId: string,
  queryParams: Record<string, string>,
) {
  const allQueryParamsStr: ReturnType<typeof sessionStorage.getItem> =
    yield call(
      sessionStorage.getItem,
      SessionStorageKey.EDITOR_PREVIOUS_QUERY_PARAMS,
    );

  try {
    const allQueryParams = JSON.parse(allQueryParamsStr ?? "{}");
    allQueryParams[pageId] = queryParams;
    yield call(
      sessionStorage.setItem,
      SessionStorageKey.EDITOR_PREVIOUS_QUERY_PARAMS,
      JSON.stringify(allQueryParams),
    );
  } catch {
    return {};
  }
}

export function* viewRouteSaga(
  action: ReduxAction<
    | {
        path: string;
        showPropertyPane?: boolean;
        testParams?: Record<string, string>;
        routeParams?: Record<string, string>;
        restoreQueryParams?: boolean;
      }
    | {
        pageId: string;
        showPropertyPane?: boolean;
        testParams?: Record<string, string>;
        routeParams?: Record<string, string>;
        restoreQueryParams?: boolean;
      }
  >,
): Generator<any, any, any> {
  let targetPath: string;
  let routeParams: Record<string, string> = {};
  const routes: ReturnType<typeof getRoutesList> = yield select(getRoutesList);
  let routeMatch: RouteDef | undefined;

  closeNotification(NOTIFICATION_KEY);

  const showPropertyPane = action.payload.showPropertyPane ?? true;

  if ("pageId" in action.payload) {
    // Look up matching route
    const pageId = action.payload.pageId;
    routeMatch = routes.find(
      (route) => "pageId" in route && route.pageId === pageId,
    );
    if (!routeMatch) {
      // TODO: if there are no matching routes, show a warning to the user
      return;
    }

    targetPath = routeMatch.path;
    const sessionRouteParams = yield call(getSessionRouteParams);
    routeParams = {
      ...(routeMatch.testParams ?? {}),
      ...(sessionRouteParams?.[routeMatch.id] ?? {}),
      ...(action.payload?.routeParams ?? {}),
    };
    if (action.payload.testParams) {
      routeParams = { ...routeParams, ...action.payload.testParams };
    }

    if (showPropertyPane) {
      yield put(
        showItemPropertyPane({
          kind: ItemKinds.WIDGET,
          id: "widgetId" in routeMatch ? routeMatch.widgetId : PAGE_WIDGET_ID,
          scope: ApplicationScope.PAGE,
        }),
      );
    }
  } else {
    targetPath = action.payload.path;

    routeMatch = routes.find((route) => route.path === targetPath);

    if (!routeMatch) return;

    const sessionRouteParams = yield call(getSessionRouteParams);
    routeParams = {
      ...(routeMatch?.testParams ?? {}),
      ...(sessionRouteParams?.[routeMatch?.id] ?? {}),
      ...(action.payload?.routeParams ?? {}),
    };
    if (action.payload.testParams) {
      routeParams = { ...routeParams, ...action.payload.testParams };
    }

    if (showPropertyPane) {
      yield put(
        showItemPropertyPane({
          kind: ItemKinds.WIDGET,
          id: "widgetId" in routeMatch ? routeMatch.widgetId : PAGE_WIDGET_ID,
          scope: ApplicationScope.PAGE,
        }),
      );
    }
  }
  const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);

  const dynamicPathSegments = extractDynamicSegments(targetPath);
  if (dynamicPathSegments.length > 0) {
    const haveAllRouteParams = dynamicPathSegments.every((segment) => {
      return Boolean(routeParams[segment]);
    });

    if (haveAllRouteParams) {
      targetPath = generatePath(targetPath, routeParams);
    } else if (appMode === APP_MODE.EDIT) {
      const newParams = Object.fromEntries(
        dynamicPathSegments.map((key, index) => [
          key,
          // coerce "" to "TEST_VALUE1", "TEST_VALUE2", etc.
          routeParams[key] || `TEST_VALUE${index + 1}`,
        ]),
      );
      targetPath = generatePath(targetPath, newParams);
    } else {
      // If we are missing route parameters, show an error in deployed apps
      sendWarningUINotification({
        message: `Route parameters are missing for ${targetPath}`,
      });
      return;
    }
  }

  const nextPageId = "pageId" in routeMatch ? routeMatch.pageId : null;
  const applicationId = yield select(getCurrentApplicationId);
  const currentPageId = yield select(getCurrentPageId);

  const resetUrlState =
    routeMatch && "pageId" in routeMatch && currentPageId !== routeMatch.pageId;

  const currentQueryParams = getCurrentQueryParams();

  yield call(writeSessionQueryParams, currentPageId, currentQueryParams);

  const previousQueryParamsForPage = nextPageId
    ? yield call(getSessionQueryParams, nextPageId)
    : {};

  let queryParams = getSystemQueryParams();
  if (action.payload.restoreQueryParams) {
    queryParams = {
      ...queryParams,
      ...previousQueryParamsForPage,
    };
  }

  const url = getTargetNavigationURL({
    targetPath,
    applicationId: applicationId ?? "",
    appMode,
    params: queryParams,
    resetUrlState,
  });

  const navigate = yield getContext("navigate");
  yield call(navigate, url.pathname + url.search);
  yield put(updateDataUrl());
}

export function* runRouteEvents(
  action: ReturnType<typeof updateCurrentRoute>,
): Generator<any, any, any> {
  const flags: ReturnType<typeof selectFlags> = yield select(selectFlags);
  if (!flags[Flag.ENABLE_MULTIPAGE]) return;

  const appMode: ReturnType<typeof getAppMode> = yield select(getAppMode);
  if (action.payload && action.payload.isNewPage) {
    const pageId = (action.payload?.routeDef as PageRoute).pageId;

    if (pageId) {
      yield put(
        switchCurrentPage((action.payload.routeDef as PageRoute).pageId),
      );
      const { failure } = yield race({
        success: take(pageLoadSuccess.type),
        failure: take(ReduxActionErrorTypes.FETCH_APPLICATION_ERROR),
      });
      if (failure) {
        // already handled
        return;
      }
    } else if (!pageId) {
      // route without a pageId means we are not deployed, we need to bail early
      yield put({
        type: ReduxActionErrorTypes.FETCH_PUBLISHED_PAGE_ERROR,
      });
      return;
    }
  } else if (!action.payload) {
    yield all([
      put(restartEvaluation()),
      put({ type: ReduxActionTypes.RESET_WIDGETS }),
      put(resetApis.create({})),
      put({
        type: ReduxActionErrorTypes.FETCH_PUBLISHED_PAGE_ERROR,
      }),
    ]);
  }

  const currentRoute: ReturnType<typeof getCurrentRoute> = yield select(
    getCurrentRoute,
  );
  const currentPageName: ReturnType<typeof getCurrentPageName> = yield select(
    getCurrentPageName,
  );

  const routeDef = currentRoute?.routeDef;
  if (!routeDef) return;

  // If we are in edit mode, detect changes in route test params
  if (
    routeDef &&
    appMode === APP_MODE.EDIT &&
    extractDynamicSegments(routeDef.path).length > 0 &&
    currentRoute.params &&
    Object.keys(currentRoute.params).length &&
    Object.values(currentRoute.params).every((v) => !v.startsWith("TEST_VALUE"))
  ) {
    if (
      !routeDef?.testParams ||
      Object.keys(routeDef.testParams).length === 0
    ) {
      yield put({
        type: ReduxActionTypes.UPDATE_ROUTE_PROPERTIES,
        payload: {
          [routeDef.id]: {
            ...routeDef,
            testParams: currentRoute.params,
          },
        },
      });
      sendSuccessUINotification({
        message: `Saved URL route parameters ${Object.values(
          currentRoute.params,
        )
          .map((v) => `"${v}"`)
          .join(
            ", ",
          )} as editor fallback values. To edit, open the route properties panel.`,
        placement: NotificationPosition.top,
        style: {
          width: "500px",
          animationDuration: "0s",
        },
        duration: 30,
      });
    } else {
      const previousRoutes = yield call(getSessionRouteParams);
      previousRoutes[routeDef.id] = currentRoute.params;

      yield call(
        sessionStorage.setItem,
        SessionStorageKey.EDITOR_PREVIOUS_ROUTES,
        JSON.stringify(previousRoutes),
      );
    }
  }

  // Run multi-page pageload actions until cancelled
  yield race({
    cancelled: take([
      updateCurrentRoute.type,
      restartEvaluation.type,
      stopEvaluation.type,
    ]),
    success: call(function* (): Generator<any, any, any> {
      if (action.payload?.isNewPage) {
        yield take(ReduxActionTypes.EXTRACTED_PAGE_LOAD_DEPS);
      }

      const result: ReturnType<typeof markPageLoadApis> = yield call(
        markPageLoadApis,
      );

      if (routeDef.onRouteLoad) {
        const callbackId = yield call(addNewPromise, NOOP);
        yield call(
          runEventHandlersSaga,
          runEventHandlers(
            createRunEventHandlersPayload({
              steps: routeDef.onRouteLoad,
              type: EventType.ON_ROUTE_LOAD,
              entityName: currentPageName ?? "Page",
              // always run in page context
              currentScope: ApplicationScope.PAGE,
              triggerLabel: `onRouteLoad`,
              callbackId,
            }),
          ),
        );
      }

      yield call(waitForEvaluationToComplete);

      const routeWidgetId = "widgetId" in routeDef && routeDef.widgetId;
      if (routeWidgetId) {
        yield put(
          showModal(routeDef.widgetId, {
            showPropertyPane: true,
            isRouteInitiated: true,
          }),
        );
      } else {
        // If we are navigating away from a route, close all modals
        yield put(closeAllModals());
      }

      yield call(executePageLoadActions, result as any);
    }),
  });
}

export default function* routeSagas() {
  yield all([
    takeEvery(ReduxActionTypes.CREATE_ROUTE, createRouteSaga),
    takeEvery(ReduxActionTypes.DELETE_ROUTE, deleteRouteSaga),
    // Debounce on route update
    queuedBatchDebounce(
      [
        ReduxActionTypes.UPDATE_ROUTE_PROPERTIES,
        ReduxActionTypes.DELETE_ROUTES,
      ],
      persistRoutesSaga,
      500,
    ),
    takeEvery(ReduxActionTypes.EDITOR_VIEW_ROUTE, viewRouteSaga),
    takeEvery(updateCurrentRoute.type, runRouteEvents),
  ]);
}
