import {
  ApiTriggerType,
  DropdownOption,
  OrganizationUserDto,
  PermissionEntityType,
  PermissionedEntities,
  ShareEntryDto,
} from "@superblocksteam/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MultiFilter, SingleFilter } from "components/ui/Filter";
import RecommendedTable, {
  RecColumn,
  TagStyle,
} from "components/ui/RecommendedTable";
import {
  SearchContainer,
  SearchInput,
  filterBySearch,
} from "components/ui/SearchSection";
import { useDebounce } from "hooks/ui/useDebounce";
import { getEntitiesV2, getUsers } from "pages/Permissions/client";
import {
  entityCompareFn,
  getApiTriggerTypeFromPermissionEntity,
} from "pages/Permissions/constants";
import ShareModal, {
  ApiModalProps,
  ApplicationModalProps,
  ShareRole,
} from "pages/components/ShareModal";
import { selectOnlyOrganization } from "store/slices/organizations";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import {
  EntityToRender,
  convertToEntityToRender,
  getResourceType,
} from "./constants";

const NameStyle = styleAsClass`
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type ColType = RecColumn<EntityToRender>;

type TypeValue = PermissionEntityType | "all";

const allTypeOptions: {
  value: TypeValue | "all";
  displayName: string;
  key: TypeValue;
}[] = [
  { value: "all", displayName: "All", key: "all" },
  {
    value: PermissionEntityType.APPLICATION,
    displayName: "Application",
    key: PermissionEntityType.APPLICATION,
  },
  {
    value: PermissionEntityType.WORKFLOW,
    displayName: "Workflow",
    key: PermissionEntityType.WORKFLOW,
  },
  {
    value: PermissionEntityType.SCHEDULED_JOB,
    displayName: "Scheduled Job",
    key: PermissionEntityType.SCHEDULED_JOB,
  },
];

const renderType = ({ value }: { value: PermissionEntityType }) => {
  let text = "Unknown";
  let style: React.CSSProperties = {
    color: colors.GREY_500,
    backgroundColor: `${colors.GREY_500}14`,
    borderColor: `${colors.GREY_500}2E`,
  };

  switch (value) {
    case PermissionEntityType.APPLICATION:
    case PermissionEntityType.APPLICATION_V2:
      text = "Application";
      style = {
        color: colors.ACCENT_BLUE_500,
        backgroundColor: colors.ACCENT_BLUE_500_25,
        borderColor: colors.ACCENT_BLUE_500_18,
      };

      break;
    case PermissionEntityType.SCHEDULED_JOB:
      text = "Scheduled Job";
      style = {
        color: colors.ACCENT_PURPLE,
        backgroundColor: `${colors.ACCENT_PURPLE}14`,
        borderColor: `${colors.ACCENT_PURPLE}2E`,
      };
      break;
    case PermissionEntityType.WORKFLOW:
      text = "Workflow";
      style = {
        color: colors.ACCENT_GREEN,
        backgroundColor: `${colors.ACCENT_GREEN}14`,
        borderColor: `${colors.ACCENT_GREEN}2E`,
      };
  }

  return (
    <div className={TagStyle} style={style}>
      {text}
    </div>
  );
};

const EntityList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearchChangeDebounced = useDebounce(
    (e) => setSearchTerm(e.target.value),
    200,
  );
  const [entities, setEntities] = useState<EntityToRender[]>([]);
  const [entityToModify, setEntityToModify] = useState<EntityToRender>();
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [allOrgUsers, setAllOrgUsers] = useState<OrganizationUserDto[]>([]);

  const allUsersOptions = useMemo(
    () =>
      allOrgUsers.map((user) => ({
        value: user.id,
        displayName: user.name,
        key: user.id,
      })),
    [allOrgUsers],
  );

  const [selectedUsers, setSelectedUsers] = useState<DropdownOption[]>([]);

  const [selectedType, setSelectedType] = useState<DropdownOption>(
    allTypeOptions[0],
  );
  const orgId = useSelector(selectOnlyOrganization).id;

  const [isShareModalVisible, setShareModalVisible] = useState(false);

  const [shareModalProps, setShareModalProps] = useState<{
    organizationId: string;
    resourceId: string;
    resourceType: PermissionedEntities;
    resourceDisplayName: string;
    applicationModalProps?: ApplicationModalProps;
    apiModalProps?: ApiModalProps;
    roles: ShareRole[];
  }>();

  useEffect(() => {
    async function loadEntities() {
      setEntitiesLoading(true);
      const entities = await getEntitiesV2();
      if (entities) {
        setEntities(entities.map(convertToEntityToRender));
      }
      setEntitiesLoading(false);
    }
    loadEntities();
  }, []);

  useEffect(() => {
    async function loadAllOrgUsers() {
      const users = await getUsers(orgId);
      if (users) {
        setAllOrgUsers(users);
        setSelectedUsers(
          users.map((user) => ({
            value: user.id,
            displayName: user.name,
            key: user.id,
          })),
        );
      }
    }
    loadAllOrgUsers();
  }, [orgId]);

  const onRowClick = (entity: EntityToRender) => {
    setShareModalVisible(true);
    setEntityToModify(entity);
    const isEntityApplication =
      entity.type === PermissionEntityType.APPLICATION ||
      entity.type === PermissionEntityType.APPLICATION_V2;
    setShareModalProps({
      organizationId: orgId,
      resourceId: entity.id,
      resourceType: getResourceType(entity.type),
      resourceDisplayName: entity.name,
      ...(isEntityApplication
        ? {
            applicationModalProps: {
              inEditMode: false,
            },
          }
        : {
            apiModalProps: {
              apiTriggerType:
                getApiTriggerTypeFromPermissionEntity(entity) ??
                ApiTriggerType.UI,
            },
          }),
      roles: isEntityApplication
        ? [ShareRole.VIEWER, ShareRole.BUILDER]
        : [ShareRole.BUILDER],
    });
  };

  const onModalClose = useCallback(
    (shareEntries: ShareEntryDto[]) => {
      if (!entityToModify) return;
      setEntities((entities) => {
        const modifiedEntity = entities.find(
          (entity) => entity.id === entityToModify.id,
        );
        if (!modifiedEntity) return entities;

        modifiedEntity.viewers = shareEntries
          .filter((entry) => entry.role === "viewer")
          .map((entry) => entry.name);
        modifiedEntity.builders = shareEntries
          .filter((entry) => entry.role === "builder")
          .map((entry) => entry.name);
        return [...entities];
      });
    },
    [entityToModify],
  );

  const selectedUserIds = useMemo(
    () => selectedUsers.map((user) => user.value),
    [selectedUsers],
  );

  const fitleredEntities = useMemo(() => {
    let filtered =
      searchTerm.length > 1
        ? entities.filter((entity) =>
            filterBySearch<EntityToRender>(entity, searchTerm, [
              "name",
              "type",
              "owner",
              "builders",
              "viewers",
            ]),
          )
        : entities;
    filtered =
      selectedType.value === "all"
        ? filtered
        : filtered.filter((entity) =>
            entity.type
              .toLocaleLowerCase()
              .includes(selectedType.value.toLocaleLowerCase()),
          );

    filtered =
      selectedUsers.length === allUsersOptions.length
        ? filtered
        : filtered.filter((entity) => selectedUserIds.includes(entity.ownerId));
    return filtered.sort(entityCompareFn);
  }, [
    allUsersOptions.length,
    entities,
    searchTerm,
    selectedType.value,
    selectedUserIds,
    selectedUsers.length,
  ]);

  const columns: ColType[] = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        hidden: true,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: renderType,
        style: { width: 130 },
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => <div className={NameStyle}>{value}</div>,
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Builders",
        accessor: "builders",
        Cell: ({ value, cell }) => (
          <div
            className={NameStyle}
            data-test={`permissions-entities-${cell.row.original.name}-builder`}
          >
            {value.join(", ")}
          </div>
        ),
      },
      {
        Header: "Viewers",
        accessor: "viewers",
        Cell: ({ value, cell }) => (
          <div
            className={NameStyle}
            data-test={`permissions-entities-${cell.row.original.name}-viewer`}
          >
            {value?.join(", ")}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <div className={SearchContainer}>
        <SearchInput
          placeholder="Search"
          onChange={onSearchChangeDebounced}
          data-test="permissions-entities-search"
        />
        <SingleFilter
          value={selectedType}
          options={allTypeOptions}
          onChange={setSelectedType}
          width={155}
          label={"Type"}
        />
        <MultiFilter
          selectedItems={selectedUsers}
          options={allUsersOptions}
          onChange={setSelectedUsers}
          label="Owner"
          width={168}
          placeholder="Filter by status"
          enableSelectAll={true}
          defaultSelectAll={true}
          showSearchInPopover={true}
        />
      </div>
      <RecommendedTable<EntityToRender>
        data={fitleredEntities}
        dataLabel={
          selectedType.value === "all"
            ? "applications, workflows or scheduled jobs"
            : `${selectedType.value}s`
        }
        uniqueKey="id"
        columns={columns}
        // using totalCount to avoid frequent update when search which could cause crash
        paginationOptions={entities.length > 10 ? { pageSize: 10 } : undefined}
        loading={entitiesLoading}
        onRowClick={onRowClick}
      />
      {shareModalProps && (
        <ShareModal
          isVisible={isShareModalVisible}
          setShareModalVisible={setShareModalVisible}
          {...shareModalProps}
          onModalClose={onModalClose}
        />
      )}
    </>
  );
};

export default EntityList;
