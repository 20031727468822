import { Dimension } from "@superblocksteam/shared";
import * as React from "react";
import { useMemo } from "react";
import { AutolinkedText } from "components/ui/AutolinkedText";
import { Button } from "components/ui/Button";
import DynamicSVG from "components/ui/DynamicSVG";
import { ComponentProps } from "legacy/components/designSystems/default/BaseComponent";
import {
  VerticalAlign,
  GridDefaults,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import {
  getLineHeightInPixels,
  getTextCssClassFromVariant,
} from "legacy/themes/utils";
import {
  formatCurrency,
  formatDate,
  formatNumber,
} from "legacy/utils/FormatUtils";
import { useAppSelector } from "store/helpers";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { styleAsClass } from "styles/styleAsClass";
import { getComponentDimensions } from "utils/size";
import { isFitContent } from "../base/sizing";
import { useTypographyStyling } from "../typographyHooks";
import {
  getFontSizeInPxFromTextStyle,
  getIconGapBasedOnTextStyle,
  getIconSizeBasedOnFontSize,
  getStylePropertyBasedOnVariant,
} from "../typographyUtils";
import {
  TextStyle,
  TextAlign,
  TextWidgetProps,
  getTextStyleVariant,
  getDefaultTextColor,
  DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
} from "./index";
import type { AppState } from "store/types";

const LEGACY_LINE_HEIGHTS: { [key in TextStyle]: number } = {
  // The following values are arrived at by multiplying line-height with font-size
  HEADING_1: 1.28581 * 22,
  HEADING_2: 1.28581 * 16,
  LABEL: 1.28581 * 14,
  NORMAL_TEXT: 1.5 * 14,
};

const TextContainer = styleAsClass`
  && {
    height: 100%;
    width: 100%;
  }
`;

const StyledText = styleAsClass`
  height: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: 100%;
  justify-content: flex-start;

  align-items: center;
  &[data-align="TOP"] {
    align-items: flex-start;
  }
  &[data-align="BOTTOM"] {
    align-items: flex-end;
  }

  &.bp5-ui-text {
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }

  .icon-text-container {
    width: 100%;
    display: flex;
    align-items: center;
   }
  &[data-ellipsize="true"] span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

interface TextComponentProps extends ComponentProps {
  text?: string;
  textAlign: TextAlign;
  verticalAlign: VerticalAlign;
  textColor: string;
  textStyle?: TextStyle;
  textStyleVariant?: string;
  ellipsize?: boolean;
  isLoading: boolean;
  shouldScroll?: boolean;
  style?: React.CSSProperties;
  icon?: string;
  iconPosition?: string;
  typographyEnabled?: boolean;
  variantFontSize?: number;
}

class TextComponent extends React.PureComponent<
  TextComponentProps,
  { isVisible: boolean }
> {
  legacyGetTextClass(textStyle?: TextStyle) {
    const className = [StyledText];

    if (this.props.isLoading) {
      className.push("bp5-skeleton");
    }

    switch (textStyle) {
      case "HEADING_1":
        className.push(CLASS_NAMES.HEADING3);
        break;
      case "HEADING_2":
        className.push(CLASS_NAMES.HEADING4);
        break;
      case "NORMAL_TEXT":
        className.push(CLASS_NAMES.BODY2);
        break;
      case "LABEL":
        className.push(CLASS_NAMES.LABEL);
        break;
      default:
        break;
    }

    return className.join(" ");
  }

  getTextClass(textStyleVariant?: string) {
    const className = [StyledText];

    if (this.props.isLoading) {
      className.push("bp5-skeleton");
    }

    className.push(
      getTextCssClassFromVariant(
        textStyleVariant ?? DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
      ),
    );

    return className.join(" ");
  }

  getTextAlignClass(textAlign: TextAlign) {
    switch (textAlign) {
      case "LEFT":
        return {
          justifyContent: "flex-start",
        };
      case "CENTER":
        return {
          justifyContent: "center",
        };
      case "RIGHT":
        return {
          justifyContent: "flex-end",
        };
    }
  }

  legacyGetIconSizeBasedOnTextStyle(textStyle?: TextStyle) {
    switch (textStyle) {
      case "HEADING_1":
        return 26;
      case "HEADING_2":
        return 23;
      case "NORMAL_TEXT":
        return 18;
      case "LABEL":
        return 17;
      default:
        return 18;
    }
  }

  legacyGetIconGapBasedOnTextStyle(textStyle?: TextStyle) {
    switch (textStyle) {
      case "HEADING_1":
        return {
          gap: "6px",
        };
      case "HEADING_2":
        return {
          gap: "4px",
        };
      case "NORMAL_TEXT":
        return {
          gap: "4px",
        };
      case "LABEL":
        return {
          gap: "4px",
        };
      default:
        return {
          gap: "4px",
        };
    }
  }

  constructor(props: TextComponentProps) {
    super(props);
    this.state = { isVisible: false };
  }

  render() {
    const {
      textAlign,
      textColor,
      textStyle,
      textStyleVariant,
      text,
      icon,
      iconPosition,
      style,
      typographyEnabled,
      variantFontSize,
    } = this.props;

    const isUsingVariant = typographyEnabled && textStyleVariant !== undefined;

    const textClassName = isUsingVariant
      ? this.getTextClass(textStyleVariant)
      : this.legacyGetTextClass(textStyle);

    const iconGap =
      isUsingVariant && variantFontSize
        ? getIconGapBasedOnTextStyle(variantFontSize)
        : this.legacyGetIconGapBasedOnTextStyle(textStyle);

    const iconSize =
      isUsingVariant && variantFontSize
        ? getIconSizeBasedOnFontSize(variantFontSize)
        : this.legacyGetIconSizeBasedOnTextStyle(textStyle);

    return (
      <div className={TextContainer}>
        <div
          className={textClassName}
          data-ellipsize={this.props.ellipsize}
          data-align={this.props.verticalAlign}
          style={{
            overflow: this.props.shouldScroll ? "auto" : "hidden",
            textAlign: textAlign.toLowerCase() as any,
            color: textColor,
            ...style,
          }}
          data-test={this.props.dataTest}
        >
          <span
            className="icon-text-container"
            style={{
              ...this.getTextAlignClass(textAlign),
              ...iconGap,
            }}
          >
            {iconPosition === "LEFT" && icon && (
              <DynamicSVG iconName={icon ?? ""} size={iconSize} />
            )}
            {typeof text !== "string" ||
            (typeof text === "string" && text.length <= 100000) ||
            this.state.isVisible ? (
              <AutolinkedText text={text} />
            ) : (
              <Button
                onClick={() => this.setState({ isVisible: true })}
                size="small"
              >
                Load large text...
              </Button>
            )}
            {iconPosition === "RIGHT" && icon && (
              <DynamicSVG iconName={icon ?? ""} size={iconSize} />
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default TextComponent;

const getMinGridHeight = (
  lineHeightPx: number,
  typographyEnabled?: boolean,
) => {
  return Math.max(
    Math.ceil(lineHeightPx / GridDefaults.DEFAULT_GRID_ROW_HEIGHT),
    typographyEnabled ? 1 : 3,
  );
};

const textWidgetIsOneLine = (
  props: TextWidgetProps,
  lineHeightPx: number,
  typographyEnabled?: boolean,
): boolean => {
  const { componentHeight } = getComponentDimensions(props);

  const isAutoHeight = isFitContent(props.height.mode);
  const isAutoWidth = isFitContent(props.width.mode);

  if (isAutoWidth && !isAutoHeight) {
    return true;
  }
  if (isAutoHeight) {
    // If the height mode is fit content, then we need to check if the component height is less than 2 rows
    const gh = getMinGridHeight(lineHeightPx, typographyEnabled);
    return componentHeight / props.parentRowSpace <= gh ? true : false;
  }

  return Math.floor(componentHeight / lineHeightPx) === 1;
};

const getTextWidgetVerticalAlign = (
  props: TextWidgetProps,
  oneLine?: boolean,
): VerticalAlign => {
  let verticalAlign = props.verticalAlign
    ? props.verticalAlign
    : VerticalAlign.CENTER;

  if (verticalAlign === VerticalAlign.AUTO) {
    verticalAlign = !oneLine ? VerticalAlign.TOP : VerticalAlign.CENTER;
  }

  return verticalAlign;
};

const getText = (props: TextWidgetProps): string | undefined => {
  switch (props.textType) {
    case "number":
      return formatNumber(
        props.text,
        props.notation,
        props.minimumFractionDigits,
        props.maximumFractionDigits,
      );
    case "currency":
      return formatCurrency(
        props.text,
        props.currency,
        props.notation,
        props.minimumFractionDigits,
        props.maximumFractionDigits,
      );
    case "date":
      return formatDate(
        props.text,
        props.dateInputFormat,
        props.dateOutputFormat,
      );
    default:
      return props.text;
  }
};

const getFontSizePx = (
  props: Partial<TextWidgetProps>,
  typographies: GeneratedTheme["typographies"],
  typographyEnabled: boolean,
): number => {
  const textStyleVariant = getTextStyleVariant(props, typographyEnabled);
  return getFontSizeInPxFromTextStyle({
    nestedProps: props.textProps?.textStyle,
    typographies,
    textStyleVariant,
    defaultTextStyleVariant: DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
  });
};

const getLineHeightPx = (
  props: Partial<TextWidgetProps>,
  typographies: GeneratedTheme["typographies"],
  typographyEnabled: boolean,
): number => {
  const textStyleVariant = getTextStyleVariant(props, typographyEnabled);
  let lineHeight;
  if (props.textStyle && !props.textProps?.textStyle) {
    lineHeight = `${LEGACY_LINE_HEIGHTS[props.textStyle]}px`;
  } else {
    lineHeight = getStylePropertyBasedOnVariant({
      nestedProps: props.textProps?.textStyle,
      typographies,
      textStyleVariant,
      propName: "lineHeight",
      defaultTextStyleVariant: DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
    }) as string | number;
  }

  const fontSize = getFontSizePx(props, typographies, typographyEnabled);
  return getLineHeightInPixels(lineHeight, fontSize);
};

export const estimateInitialTextWidgetHeightGU = (
  typographyEnabled: boolean,
  theme?: GeneratedTheme,
): number => {
  if (!theme?.typographies || !typographyEnabled) {
    return 2;
  }

  const heightPx = getLineHeightPx({}, theme?.typographies, typographyEnabled);

  return Dimension.toGridUnit(
    Dimension.px(heightPx),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};

export const TextComponentWithLayoutManaged = (props: TextWidgetProps) => {
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);
  const typographyEnabledFlag = useAppSelector((state: AppState) =>
    selectFlagById(state, Flag.ENABLE_TYPOGRAPHY),
  );
  const typographyEnabled = Boolean(typographyEnabledFlag);

  const lineHeightPx = getLineHeightPx(
    props,
    themeTypographies,
    typographyEnabled,
  );
  const fontSize = getFontSizePx(props, themeTypographies, typographyEnabled);
  const oneLine = textWidgetIsOneLine(props, lineHeightPx, typographyEnabled);
  const verticalAlign = getTextWidgetVerticalAlign(props, oneLine);
  const isAutoHeight = props.height.mode === "fitContent";
  const isAutoWidth = props.width.mode === "fitContent";

  const baseStyle = useMemo(() => {
    if (isAutoHeight && !isAutoWidth) {
      const minGridHeight = getMinGridHeight(lineHeightPx, typographyEnabled);

      return {
        minHeight:
          minGridHeight * GridDefaults.DEFAULT_GRID_ROW_HEIGHT -
          WIDGET_PADDING * 2,
        wordBreak: "break-word",
      } satisfies React.CSSProperties;
    }

    return {};
  }, [isAutoHeight, isAutoWidth, lineHeightPx, typographyEnabled]);

  const isFitFit =
    isFitContent(props.height.mode) && isFitContent(props.width.mode);

  const textStyleVariant = getTextStyleVariant(props, typographyEnabled);
  const textColor = getDefaultTextColor(props, typographyEnabled);

  const priorityStyle = useMemo(() => {
    return {
      color: textColor,
    };
  }, [textColor]);

  const textProps = useTypographyStyling({
    baseStyle,
    textStyle: props.textProps?.textStyle,
    forceTextStyleVariant: textStyleVariant,
    defaultTextStyleVariant: DEFAULT_TEXT_WIDGET_TEXT_STYLE_VARIANT,
    skipTextStyleColorDefaultOverwrite: true,
    priorityStyle,
  });

  return (
    <TextComponent
      dataTest={`text-component-${props.widgetName}`}
      widgetId={props.widgetId}
      key={props.widgetId}
      typographyEnabled={typographyEnabled}
      text={getText(props)}
      textAlign={props.textAlign ? props.textAlign : "LEFT"}
      verticalAlign={
        props.height.mode === "fitContent" ? VerticalAlign.AUTO : verticalAlign
      }
      textColor={textColor}
      textStyle={props.textStyle}
      textStyleVariant={textProps.textStyleVariant}
      variantFontSize={fontSize}
      isLoading={props.isLoading}
      shouldScroll={props.shouldScroll}
      ellipsize={(oneLine && props.height.mode !== "fitContent") || isFitFit}
      style={textProps.style}
      icon={props.icon}
      iconPosition={props.iconPosition ?? "LEFT"}
    />
  );
};
