import { produce } from "immer";
import { set } from "lodash";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDebounce } from "hooks/ui";
import { Unsubscribe, FormContextType } from "./FormContext";

interface Props {
  data: Record<string, unknown>;
  onChange: (newData: Record<string, unknown>) => void;
}

interface ChangeValueAction {
  type: "CHANGE_VALUES";
  payload: Record<string, { val: unknown; isNested?: boolean }>;
}

interface ResetAllValuesAction {
  type: "RESET_ALL_VALUES";
  payload: Record<string, unknown>;
}

const reducer = (
  data: Record<string, unknown>,
  action: ChangeValueAction | ResetAllValuesAction,
) => {
  switch (action.type) {
    case "CHANGE_VALUES": {
      return produce(data, (draft) => {
        Object.entries(action.payload).forEach(([path, { val, isNested }]) => {
          if (isNested) {
            // isNested should be true when the path will look something like foo.bar or foo[2].bar
            // in the above example, foo is expected to be an array/object
            set(draft, path, val);
          } else {
            // (default) isNested should be false when the path might contain literal special characters.
            // e.g. foo.bar where "foo.bar" is the actual key in the configuration
            draft[path] = val;
          }
        });
      });
    }
    case "RESET_ALL_VALUES": {
      return action.payload;
    }
    default:
      return data;
  }
};

const useFormStateManager = ({
  onChange,
  data,
}: Props): [
  <T = unknown>(path: string, callback: (value: T) => void) => Unsubscribe,
  FormContextType["onChange"],
] => {
  const [subscriptions, setSubscriptions] = useState<
    Record<
      string,
      | {
          path: string;
          callback: (value: any) => void;
        }
      | undefined
    >
  >({});

  const [localData, localDispatch] = useReducer(reducer, data);
  const [dirty, setDirty] = useState(false);

  const subscriptionDataRef = useRef<Record<string, unknown>>({});

  useEffect(() => {
    if (dirty) {
      onChange(localData);
      setDirty(false);
    }
  }, [localData, dirty, onChange]);

  useEffect(() => {
    setDirty(false);
    localDispatch({ type: "RESET_ALL_VALUES", payload: data });
  }, [data]);

  useEffect(() => {
    Object.entries(subscriptions).forEach(([id, registration]) => {
      if (!registration) {
        return;
      }
      const currentData = dirty
        ? localData[registration.path]
        : data[registration.path];
      const previousData = subscriptionDataRef.current[id];

      if (currentData !== previousData) {
        registration.callback(currentData);
        subscriptionDataRef.current[id] = currentData;
      }
    });
  }, [data, dirty, localData, subscriptions]);

  const subscribe = useCallback(
    (path: string, callback: (value: any) => void) => {
      const id = uuidv4();
      setSubscriptions((subscriptions) => ({
        ...subscriptions,
        [id]: { path, callback },
      }));

      return () =>
        setSubscriptions((subscriptions) => ({
          ...subscriptions,
          [id]: undefined,
        }));
    },
    [],
  );

  const dispatchQueueRef = useRef<
    Record<string, { val: unknown; isNested?: boolean }>
  >({});

  const flushDispatch = useCallback(() => {
    localDispatch({
      type: "CHANGE_VALUES",
      payload: dispatchQueueRef.current,
    });
    setDirty(true);

    dispatchQueueRef.current = {};
  }, []);

  const debouncedFlushChanges = useDebounce(flushDispatch, 500);

  const queueChange = useCallback(
    (path: string, value: unknown, isNested?: boolean) => {
      dispatchQueueRef.current[path] = { val: value, isNested };
      debouncedFlushChanges && debouncedFlushChanges();
    },
    [debouncedFlushChanges],
  );

  const changeHandler = useCallback(
    (
      path: string,
      newValue: unknown,
      options: Parameters<FormContextType["onChange"]>[2],
    ) => {
      queueChange(path, newValue, options?.isNested);

      if (!options?.debounced) {
        debouncedFlushChanges?.flush();
      }
    },
    [debouncedFlushChanges, queueChange],
  );

  return [subscribe, changeHandler];
};

export default useFormStateManager;
