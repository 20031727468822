import {
  ApplicationConfiguration,
  ApplicationScope,
  ApplicationSettings,
} from "@superblocksteam/shared";
import { createSelector } from "reselect";
import { toUnscopedEntity } from "store/utils/scope";
import { AppStateVar } from "./stateVars/StateConstants";
import { getScopedStateVars } from "./stateVars/selectors";
import { AppTimer } from "./timers/TimerConstants";
import { getScopedTimers } from "./timers/selectors";
import type { AppState } from "store/types";

export const getCurrentApplication = (state: AppState) => {
  return state.application.wrapper.currentApplication;
};
export const getCurrentApplicationName = (state: AppState) =>
  state.application.wrapper?.currentApplication?.name;

export const getCurrentCommitId = (state: AppState) =>
  state.application.wrapper.commitId;

export const getIsSavingAppName = (state: AppState) =>
  state.application.wrapper.isSavingAppName;

export const getIsSavingApplication = (state: AppState) =>
  state.application.wrapper.isSavingApplication;

export const getIsSavingApplicationFailed = (state: AppState) =>
  state.application.wrapper.isSavingApplicationFailed;

export const getIsSavingApplicationStale = (state: AppState) =>
  state.application.wrapper.isSavingApplicationStale;

export const getEnvironment = (state: AppState) =>
  state.application.wrapper.environment;

export const getDatasourceToAuthenticate = (state: AppState) =>
  state.application.wrapper.datasourcesToAuthenticate.length >= 1
    ? state.application.wrapper.datasourcesToAuthenticate[0]
    : "";

export const getApplicationSettings = (
  state: AppState,
): ApplicationSettings | undefined =>
  state.application.wrapper?.currentApplication?.settings;

export const selectCurrentApplicationSignatureTree = (state: AppState) =>
  state.application.wrapper?.currentApplicationSignatureTree;

export const getAppRepoConnection = (state: AppState) =>
  state.application.wrapper.currentApplication?.repoConnection;

export const getIsIframeLoaded = createSelector(
  (state: AppState) => state.application.wrapper,
  (applications): boolean => applications.isIframeLoaded,
);

export const getMergedAppConfiguration = createSelector(
  [
    (state: AppState) =>
      state.application.wrapper.currentApplication?.configuration,
    (state: AppState) => getScopedStateVars(state, ApplicationScope.APP),
    (state: AppState) => getScopedTimers(state, ApplicationScope.APP),
  ],
  (configuration, stateVars, timers): ApplicationConfiguration => {
    return {
      ...(configuration ?? {}),
      dsl: {
        ...(configuration?.dsl ?? { version: 8 }),
        stateVars: {
          stateVarMap: toUnscopedEntity<AppStateVar>(stateVars),
        },
        timers: {
          timerMap: toUnscopedEntity<AppTimer>(timers),
        },
      },
    };
  },
);
