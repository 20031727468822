import {
  Organization,
  GetGroupsResponseDto,
  GroupBrief,
  GroupType,
  TASKS,
  OrganizationUserDto,
} from "@superblocksteam/shared";
import { Select } from "antd";
import Modal from "antd/lib/modal";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { PrimaryButton, SecondaryButton } from "components/ui/Button";
import { FormItem, FormWrapper } from "components/ui/Form";
import { FooterWrapper, ModalInnerWrapper } from "components/ui/Modal";
import { useMarkTaskComplete } from "hooks/ui/useCheckTask";
import { User } from "legacy/constants/userConstants";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { selectOnlyOrganization } from "store/slices/organizations";
import { callServer, HttpMethod } from "store/utils/client";
import { HttpError } from "store/utils/types";
import { sendSuccessUINotification } from "utils/notification";
import { sendOrgInvitation } from "./utils";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onUsersAdded?: (users: OrganizationUserDto[]) => void;
}

const InviteOrgUserModal = ({ isVisible, onClose, onUsersAdded }: Props) => {
  const organization = useSelector(selectOnlyOrganization) as Organization;
  const currentUser = useSelector(getCurrentUser) as User;
  const organizationId = currentUser.currentOrganizationId;

  const [emails, setEmails] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [emailsError, setEmailsError] = useState<string | undefined>(undefined);
  const [inviteError, setInviteError] = useState<string | undefined>(undefined);

  const cleanStates = useCallback(() => {
    setEmails([]);
    setEmailsError(undefined);
    setGroupIds([]);
    setInviteError(undefined);
  }, []);

  // User groups
  const [groups, setGroups] = useState<GroupBrief[]>([]);

  const groupOptions = useMemo(
    () =>
      groups
        .filter(
          (group) =>
            group.type !== GroupType.EVERYONE &&
            (group.requestingUserIsMember || currentUser?.isAdmin),
        )
        .map((group) => ({
          label: group.name,
          value: group.id,
        })),
    [currentUser?.isAdmin, groups],
  );

  const fetchGroups = useCallback(async () => {
    function getGroups(orgId: string) {
      return callServer<GetGroupsResponseDto>({
        method: HttpMethod.Get,
        url: `v1/organizations/${organizationId}/groups`,
      });
    }

    const getGroupsResponseDto = await getGroups(organizationId);
    setGroups(getGroupsResponseDto.groups);
  }, [organizationId]);

  const init = useCallback(async () => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    if (isVisible) {
      // reload users list on modal open
      init();
    }
  }, [isVisible, init]);

  const [checkTask] = useMarkTaskComplete(TASKS.INVITE_TEAMMATE);

  const [isSending, setIsSending] = useState(false);

  const validate = useCallback(() => {
    let validated = true;
    if (emails.length === 0) {
      setEmailsError("Name is required");
      validated = false;
    }
    return validated;
  }, [emails.length]);

  const handleOk = useCallback(async () => {
    if (!validate()) {
      return;
    }
    setIsSending(true);
    try {
      let maybeError: HttpError | undefined;
      const onError = (e: HttpError) => {
        maybeError = e;
      };
      const invitationResponse = await sendOrgInvitation(
        emails,
        organizationId,
        groupIds,
        onError,
      );
      if (maybeError) {
        setInviteError(maybeError.message);
      } else {
        const { users, error } = invitationResponse;
        if (error) {
          setInviteError(error ?? "Failed to send invites");
          return;
        }
        // without await, event will not be sent
        await checkTask();

        onUsersAdded?.(users);
        onClose();

        sendSuccessUINotification({
          message: "Invites sent",
        });

        cleanStates();
      }
    } catch (error) {
      setInviteError("Failed to send invites");
    }
    setIsSending(false);
  }, [
    validate,
    emails,
    organizationId,
    groupIds,
    checkTask,
    onUsersAdded,
    onClose,
    cleanStates,
  ]);

  const handleCancel = useCallback(() => {
    cleanStates();
    onClose();
  }, [cleanStates, onClose]);

  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      title={`Invite users to ${organization.name}`}
      onOk={handleOk}
      footer={null}
    >
      <div className={ModalInnerWrapper}>
        <div className={FormWrapper}>
          <FormItem label="Emails" error={emailsError} required={true}>
            <Select
              data-test="invite-org-user-email-input"
              mode="tags"
              dropdownStyle={{ zIndex: 0 }}
              value={emails}
              onChange={setEmails}
            />
          </FormItem>
          <FormItem label="Add to permission groups">
            {/* TODO: use RecommendMultiDropdown if support enter and add typed input as new option */}
            <Select
              mode="multiple"
              data-test="invite-org-user-group-input"
              allowClear
              showSearch
              value={groupIds}
              options={groupOptions}
              filterOption={(input, option) => {
                const label = option?.label as string;
                return label?.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={setGroupIds}
            />
          </FormItem>
          <FormItem error={inviteError} hidden={!inviteError} />

          <div className={FooterWrapper}>
            <SecondaryButton
              data-test="cancel-create-token-button"
              onClick={handleCancel}
              loading={isSending}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              type="primary"
              onClick={handleOk}
              data-test="invite-org-user-submit"
              loading={isSending}
            >
              Invite
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteOrgUserModal;
