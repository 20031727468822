import { Typography, Progress } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { ChecklistIcons } from "legacy/icons/ChecklistIcons";
import { selectUserChecklist } from "store/slices/user";
import { getTaskSchema, tasks } from "./tasks";

const ContainerWrapper = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};

  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  padding: 5px 12px 1px 12px;
  margin-bottom: 4px;
  cursor: pointer;

  > div {
    align-items: center;
    display: flex;
    flex-direction: vertical;
    gap: 8px;
  }

  transform: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  background-color: #ffffff;
  border: 1px solid #e8eaed;

  &:hover {
    background-color: ${({ theme }) => theme.colors.HOVER_GREEN};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.CLICK_GREEN};
  }

  .ant-progress-bg {
    height: 4px !important;
  }
`;

const ChecklistMiniStatus = () => {
  const checklist = useSelector(selectUserChecklist);
  const totalSteps = tasks.filter((task) => !task.hidden).length;
  const stepsComplete = Math.min(
    totalSteps,
    (checklist ?? [])
      .filter((t) => t.completed)
      .filter((t) => !getTaskSchema(t.id)?.hidden ?? true).length,
  );

  const theme = useTheme();
  const show = totalSteps !== stepsComplete;

  return (
    <ContainerWrapper
      show={show}
      data-test={`checklist-miniStatus${show ? "" : "-hidden"}`}
    >
      <div>
        <ChecklistIcons.TROPHY_ICON
          color={theme.colors.ACCENT_GREEN}
          width={16}
          height={16}
        />
        <Typography.Text>
          <b>
            {stepsComplete} / {totalSteps}
          </b>
          {"  Steps Completed"}
        </Typography.Text>
      </div>
      <Progress
        percent={(stepsComplete / totalSteps) * 100}
        size="small"
        showInfo={false}
        strokeColor={theme.colors.ACCENT_GREEN}
        trailColor="#14CDb733"
      />
    </ContainerWrapper>
  );
};

export default ChecklistMiniStatus;
