import { Agent, AgentStatus, AgentType } from "@superblocksteam/shared";
import { isEmpty } from "lodash";
import { createSelector } from "reselect";
import { ROOT } from "store/utils/types";
import {
  controlFlowOverrideFlagSelector,
  selectControlFlowEnabledDynamic,
} from "../apisShared/selectors/controlFlowEnabledDynamic";
import { AgentWithHealth } from "./client";
import slice from "./slice";
import { isProfileSupported } from "./utils";

export const selectOnPremAgentWithHealths = createSelector(
  slice.selector,
  (state) => {
    const agents = Object.values(state.entities);
    return agents.filter(
      (agentWithHealth) => agentWithHealth.agent.type === AgentType.ONPREMISE,
    );
  },
);

export const selectActiveAgents = (
  agentType: AgentType,
  targetEnvironment: string,
) => {
  return createSelector(
    slice.selector,
    selectControlFlowEnabledDynamic,
    controlFlowOverrideFlagSelector,
    (state, controlFlowEnabled, controlFlowOverrideEnabled) => {
      const agents = Object.values(state.entities).map(
        (agentWithHealth) => agentWithHealth.agent,
      );
      return shuffleAgents(agents).filter((agent) =>
        canExecute({
          targetAgentType: agentType,
          targetEnvironment,
          agent,
          controlFlowEnabled,
          controlFlowOverrideEnabled,
        }),
      );
    },
  );
};

export const selectAllAgents = createSelector(slice.selector, (state) => {
  return state.entities;
});

export const isLoadingAgentsHealth = createSelector(slice.selector, (state) => {
  return state.loading?.[ROOT] === true;
});

export const chooseAgents = (
  idToAgentHealth: Record<string, AgentWithHealth>,
  agentType: AgentType,
  profileKey: string,
  controlFlowEnabled: boolean,
  controlFlowOverrideEnabled: boolean,
) => {
  const agents = Object.values(idToAgentHealth).map(
    (agentWithHealth) => agentWithHealth.agent,
  );

  const availableAgents = shuffleAgents(agents).filter((agent) =>
    canExecute({
      targetAgentType: agentType,
      targetEnvironment: profileKey,
      agent,
      controlFlowEnabled,
      controlFlowOverrideEnabled,
    }),
  );
  return availableAgents;
};

export const selectShouldShowAgentDisasterHeader = (agentType: AgentType) => {
  const res = createSelector(slice.selector, (state) => {
    // if no organizationId, we don't know if the agent is active or not
    if (!state.meta[ROOT]?.organizationId) {
      return false;
    }

    // if loading and no agents, we don't know if the agent is active or not
    const loading = state.loading[ROOT] === true;
    const noAgents = isEmpty(state.entities);
    if (loading && noAgents) return false;

    const agents = Object.values(state.entities).map(
      (agentWithHealth) => agentWithHealth.agent,
    );
    const anyAgent = agents.find(
      (agent) =>
        agent.type === agentType && agent.status === AgentStatus.ACTIVE,
    );
    return !anyAgent;
  });
  return res;
};

export const selectActiveAgentUrl = (
  agentType: AgentType,
  targetEnvironment: string,
) => {
  return createSelector(
    slice.selector,
    selectControlFlowEnabledDynamic,
    controlFlowOverrideFlagSelector,
    (state, controlFlowEnabled, controlFlowOverrideEnabled) => {
      const agents = Object.values(state.entities).map(
        (agentWithHealth) => agentWithHealth.agent,
      );
      const selectedAgent = shuffleAgents(agents).find((agent) =>
        canExecute({
          targetAgentType: agentType,
          targetEnvironment,
          agent,
          controlFlowEnabled,
          controlFlowOverrideEnabled,
        }),
      );
      return selectedAgent?.url;
    },
  );
};

export const selectActiveAgentUrls = (
  agentType: AgentType,
  targetEnvironment: string,
) => {
  return createSelector(
    slice.selector,
    selectControlFlowEnabledDynamic,
    controlFlowOverrideFlagSelector,
    (state, controlFlowEnabled, controlFlowOverrideEnabled) => {
      const agents = Object.values(state.entities).map(
        (agentWithHealth) => agentWithHealth.agent,
      );
      const selectedAgents = shuffleAgents(agents).filter((agent) =>
        canExecute({
          targetAgentType: agentType,
          targetEnvironment,
          agent,
          controlFlowEnabled,
          controlFlowOverrideEnabled,
        }),
      );
      return selectedAgents.map((agent) => agent.url);
    },
  );
};

const shuffleAgents = (agents: Agent[]): Agent[] => {
  return agents
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

export const canExecute = ({
  targetAgentType,
  targetEnvironment,
  agent,
  controlFlowEnabled,
  controlFlowOverrideEnabled,
}: {
  targetAgentType: AgentType;
  targetEnvironment: string;
  agent: Agent;
  controlFlowEnabled: boolean;
  controlFlowOverrideEnabled: boolean;
}): boolean => {
  if (agent.status !== AgentStatus.ACTIVE || agent.type !== targetAgentType) {
    return false;
  }
  if (!isProfileSupported(agent, targetEnvironment)) {
    return false;
  }
  if (controlFlowOverrideEnabled && controlFlowEnabled) {
    // technically redundant since controlFlowEnabled is always true if the override is also true
    return true;
  }
  if (!controlFlowEnabled) {
    return false;
  }
  return true;
};

export const selectMostRecentKeyRotation = createSelector(
  slice.selector,
  (state) => {
    if (
      !Array.isArray(state.meta[ROOT].keyRotations) ||
      state.meta[ROOT].keyRotations.length === 0
    ) {
      return undefined;
    }
    // sort by date descending, this array should never be large
    const sorted = [...state.meta[ROOT].keyRotations];
    sorted.sort(
      (a, b) =>
        new Date(b.created ?? "").getTime() -
        new Date(a.created ?? "").getTime(),
    );
    return sorted[0];
  },
);
