import {
  Agent,
  AuthType,
  DatasourceOneTimeState,
  ENVIRONMENT_PRODUCTION,
  IntegrationAuthType,
  RestApiIntegrationDatasourceConfiguration,
  TokenScope,
  isRedirectRequired,
} from "@superblocksteam/shared";
import { Alert, Button, Form, Typography } from "antd";
import Modal from "antd/lib/modal";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { FullWidthSpace } from "components/ui/Space";
import { useIsControlFlowEnabled } from "hooks/api/useIsControlFlowEnabled";
import { useFeatureFlag } from "hooks/ui/useFeatureFlag";
import { useInterval } from "hooks/ui/useInterval";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { OAUTH_CALLBACK_URL } from "legacy/constants/routes";
import {
  getAppProfilesInCurrentMode,
  getAppViewMode,
} from "legacy/selectors/applicationSelectors";
import { getCurrentUser } from "legacy/selectors/usersSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { checkAuthV3 } from "store/slices/apisShared/client-auth";
import { getEnvironment } from "store/slices/application/selectors";
import { selectPluginDatasources } from "store/slices/datasources";
import { getConfigFromIntegrationWithProfileId } from "store/slices/datasources/utils";
import { Flag } from "store/slices/featureFlags/models/Flags";
import { generateOneTimeCode } from "utils/crypto";
import localStorage from "../../legacy/utils/localStorage";
import { selectActiveAgents } from "../../store/slices/agents";
import { selectOnlyOrganization } from "../../store/slices/organizations";

interface Props {
  datasourceId: string;
}

export const LS_OAUTH_DATASOURCE_KEY = "oauth-datasource";
export const LS_OAUTH_ENV_KEY = "oauth-env";
export const LS_OAUTH_ERROR_KEY = "oauth-error";
export const LS_OAUTH_REDIRECT_FINISHED = "oauth-redirect-finished";
export const LS_OAUTH_ONE_TIME_CODE = "oauth-one-time-code";
export const LS_FULL_STATE = "oauth-full-state";

// auth types that require OAuth redirect
const authTypes = [
  IntegrationAuthType.OAUTH2_IMPLICIT as AuthType,
  IntegrationAuthType.OAUTH2_CODE as AuthType,
];

const OAuthRedirectLoginModal = ({ datasourceId }: Props) => {
  const [isVisible, setVisible] = useState(false);
  const [loginForm] = Form.useForm();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isVisible) {
      // Reset form on every open.
      setErrorMessage("");
      loginForm.resetFields();
    }
  }, [isVisible, loginForm]);

  const dispatch = useAppDispatch();
  const organization = useAppSelector(selectOnlyOrganization);
  const datasources = useAppSelector(selectPluginDatasources);
  const datasource = datasources[datasourceId];
  const environment = useAppSelector(getEnvironment);

  const enableProfiles = useFeatureFlag(Flag.ENABLE_PROFILES);
  const profiles = useAppSelector(getAppProfilesInCurrentMode);
  const profile = profiles?.selected;
  const profileId = useMemo(() => {
    return (
      (enableProfiles
        ? profile?.id
        : organization.profiles?.find((p) => p.key === environment)?.id) ?? ""
    );
  }, [enableProfiles, profile?.id, organization.profiles, environment]);

  const agents: Agent[] = useAppSelector(
    selectActiveAgents(
      organization.agentType,
      enableProfiles ? profile?.key ?? "" : environment,
    ),
  );
  const datasourceConfig:
    | RestApiIntegrationDatasourceConfiguration
    | undefined = useMemo(() => {
    return getConfigFromIntegrationWithProfileId(datasource, profileId);
  }, [datasource, profileId]);

  const authType = useMemo(() => {
    return datasourceConfig?.authType ?? IntegrationAuthType.NONE;
  }, [datasourceConfig]);

  const location = useLocation();
  const controlFlowEnabled = useIsControlFlowEnabled();
  const mode = useAppSelector(getAppViewMode);

  // When Superblocks is running in embedded mode(inside of iFrame, the local storage can't be used for storing auth state)
  const isEmbeddedMode = window.self !== window.top;

  // get current user
  const currentUser = useAppSelector(getCurrentUser);

  // keep checking whether oauth redirect flow is already finished every 2 seconds
  useInterval(() => {
    (async () => {
      if (!datasourceId) {
        return;
      }
      if (!authTypes.includes(authType)) {
        return;
      }
      if (
        !isEmbeddedMode &&
        !localStorage.getItem(LS_OAUTH_REDIRECT_FINISHED)
      ) {
        return;
      }
      if (!isEmbeddedMode) {
        localStorage.removeItem(LS_OAUTH_REDIRECT_FINISHED);
      }

      const errMsg = isEmbeddedMode
        ? ""
        : localStorage.getItem(LS_OAUTH_ERROR_KEY);
      if (errMsg) {
        setErrorMessage(errMsg);
        localStorage.removeItem(LS_OAUTH_ERROR_KEY);
        return;
      }

      const isAuthenticated: { authenticated: boolean } = await checkAuthV3(
        controlFlowEnabled
          ? {
              orchestrator: true,
              integrationId: datasourceId,
              agents,
              organization,
              ...(enableProfiles ? { profile } : {}),
            }
          : {
              orchestrator: false,
              agents,
              organization,
              body: {
                authType: datasourceConfig?.authType,
                datasourceId: datasourceId,
                environment: enableProfiles ? profile?.key : environment,
              },
              ...(enableProfiles ? { profile } : {}),
              mode,
            },
      );
      if (isAuthenticated?.authenticated) {
        setErrorMessage("");
        dispatch({ type: ReduxActionTypes.AUTH_FINISHED });
      }
      setVisible(!isAuthenticated?.authenticated);
    })();
  }, 2000);

  useEffect(() => {
    if (isEmpty(datasourceId)) {
      setVisible(false);
      return;
    }
    (async () => {
      if (!authTypes.includes(authType)) {
        return;
      }
      // This information is used by the OAuth callback page. It informs the
      // callback page which datasource it should be associating the returned
      // token with.
      localStorage.setItem(LS_OAUTH_DATASOURCE_KEY, datasourceId);
      localStorage.setItem(
        LS_OAUTH_ENV_KEY,
        enableProfiles ? profile?.key ?? ENVIRONMENT_PRODUCTION : environment,
      );

      const errMsg = localStorage.getItem(LS_OAUTH_ERROR_KEY);
      if (errMsg) {
        setErrorMessage(errMsg);
        localStorage.removeItem(LS_OAUTH_ERROR_KEY);
      }
      const isAuthenticated: { authenticated: boolean } = await checkAuthV3(
        controlFlowEnabled
          ? {
              orchestrator: true,
              integrationId: datasourceId,
              ...(enableProfiles ? { profile } : {}),
              agents,
              organization,
            }
          : {
              orchestrator: false,
              agents,
              organization,
              body: {
                authType: datasourceConfig?.authType,
                datasourceId: datasourceId,
                environment: enableProfiles ? profile?.key : environment,
              },
              ...(enableProfiles ? { profile } : {}),
              mode,
            },
      );
      setVisible(!isAuthenticated?.authenticated);
    })();
  }, [
    datasourceId,
    authType,
    datasourceConfig,
    environment,
    agents,
    location.pathname,
    organization,
    enableProfiles,
    profile,
    mode,
    controlFlowEnabled,
    currentUser?.source,
  ]);

  const configureIntegrationUrl = useMemo(() => {
    return `/integrations/${datasource?.pluginId}/${datasource?.id}`;
  }, [datasource]);

  const [authorizationUrl, configurationError] = useMemo(() => {
    let authorizationUrl: URL | undefined;
    const authConfig = datasourceConfig?.authConfig;
    if (!isRedirectRequired(datasourceConfig?.authType) || !authConfig) {
      return [undefined, undefined];
    }
    try {
      if (!authConfig?.authorizationUrl && datasource?.id) {
        return [undefined, "OAuth 2.0 authorization URL is not configured."];
      }
      authorizationUrl = new URL(authConfig?.authorizationUrl as string);
    } catch (err) {
      return [undefined, `Invalid authorization URL: ${err}`];
    }
    const currentHost = new URL(window.location.href);
    currentHost.search = "";
    currentHost.pathname = OAUTH_CALLBACK_URL;
    const redirectUri = currentHost.toString();

    const responseType =
      datasourceConfig?.authType === IntegrationAuthType.OAUTH2_IMPLICIT
        ? "token"
        : "code";
    const clientId = authConfig.clientId;
    if (!clientId) {
      return [undefined, "OAuth 2.0 client ID is not configured."];
    }
    const audience = authConfig.audience;
    const scope = authConfig.scope;
    const defaultPromptType = "consent";
    const promptType = isEmpty(authConfig.promptType)
      ? defaultPromptType
      : authConfig.promptType ?? defaultPromptType; // shouldn't be needed by but isn't figuring that out
    authorizationUrl.searchParams.append("redirect_uri", redirectUri);
    authorizationUrl.searchParams.append("prompt", promptType);
    authorizationUrl.searchParams.append("response_type", responseType);
    authorizationUrl.searchParams.append("client_id", clientId);
    if (audience) {
      authorizationUrl.searchParams.append("audience", audience);
    }
    if (scope) {
      authorizationUrl.searchParams.append("scope", scope);
    }
    if (datasourceConfig?.authType === IntegrationAuthType.OAUTH2_CODE) {
      if (authConfig?.tokenScope === TokenScope.DATASOURCE) {
        return [
          undefined,
          `This integration is configured to share access token across all users, but there is no active token found.
        Please go to integration configuration page using the link below and connect your account.`,
        ];
      }
      if (!authConfig.sendOAuthState && isEmbeddedMode) {
        return [
          undefined,
          `This integration is configured not to send state parameter.
        In order to use in embedded mode, the integration must be configured to send state parameter.
        Please go to integration configuration page using the link below and configure integration to send state parameter.`,
        ];
      }
      // Specify an offline access type to get a refresh token.
      authorizationUrl.searchParams.append("access_type", "offline");
      if (authConfig.sendOAuthState) {
        const oneTimeCode = generateOneTimeCode();
        localStorage.setItem(LS_OAUTH_ONE_TIME_CODE, oneTimeCode);
        const stateObj: DatasourceOneTimeState = {
          oneTimeCode,
          useLocalStorage: !isEmbeddedMode,
          integrationId: datasourceId,
          externalUser: currentUser?.source === "Superblocks",
        };
        const jsonString = JSON.stringify(stateObj);
        const buffer = Buffer.from(jsonString, "utf-8");
        const state = buffer.toString("base64");
        authorizationUrl.searchParams.append("state", state);
      }
    }
    return [authorizationUrl, undefined];
  }, [
    datasourceConfig,
    datasource?.id,
    datasourceId,
    isEmbeddedMode,
    currentUser?.source,
  ]);

  useEffect(() => {
    if (!datasource) {
      return;
    }

    if (isVisible && authorizationUrl) {
      window.open(authorizationUrl?.toString());
    }
  }, [authorizationUrl, datasource, isVisible]);

  if (!datasource) {
    return <></>;
  }

  const handleCancel = () => {
    dispatch({ type: ReduxActionTypes.AUTH_FINISHED });
  };

  return (
    <Modal
      open={isVisible}
      title={`OAuth 2.0 Login for ${datasource.name}`}
      onCancel={handleCancel}
      footer={<Button onClick={handleCancel}>Cancel</Button>}
    >
      <FullWidthSpace direction="vertical" size={10}>
        <Typography.Text>
          If not redirected to login,{" "}
          <Typography.Link
            href={authorizationUrl?.toString() ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            click here.
          </Typography.Link>
        </Typography.Text>
        {!isEmpty(authorizationUrl) && (
          <Typography.Title level={5} style={{ display: "inline" }}>
            The integration requires additional configuration
          </Typography.Title>
        )}
        {!isEmpty(errorMessage) && (
          <FullWidthSpace>
            <Alert message={errorMessage} type={"error"} />
          </FullWidthSpace>
        )}
        {!isEmpty(configurationError) && (
          <FullWidthSpace>
            <Alert message={configurationError} type={"error"} />
          </FullWidthSpace>
        )}
        {!isEmpty(configurationError) && (
          <Typography.Link
            href={configureIntegrationUrl}
            target="_blank"
            rel="noreferrer"
          >
            Configure integration
          </Typography.Link>
        )}
      </FullWidthSpace>
    </Modal>
  );
};

export default OAuthRedirectLoginModal;
