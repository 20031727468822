import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export const HeaderWrapper = styleAsClass`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
gap: 8px;
.page-header-title {
  line-height: 36px;
  font-size: 30px;
  font-weight: 500;
  color: ${colors.GREY_900};
}
.page-header-description {
  // TODO: change this back to 12 px once all page title font are unified
  font-size: 14px;
  font-weight: 400;
  color: ${colors.GREY_700};
}
margin-bottom: 24px;
& + div.ant-tabs {
  margin-top: -12px;
}
`;

export const MainWrapperStyles = styleAsClass`
  .ant-breadcrumb {
    margin-top: -28px;
    margin-bottom: -8px;
  }
`;
