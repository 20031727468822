import { createAction } from "@reduxjs/toolkit";
import {
  PutApplicationMetadataUpdateRequestBody,
  PutApplicationUpdateRequestBody,
} from "@superblocksteam/schemas";
import {
  ApplicationConfiguration,
  ApplicationSettings,
  ApplicationV2GlobalDto,
  BranchDto,
} from "@superblocksteam/shared";
import ApplicationApi, {
  UpdateNonVersionedApplicationSettingsPayload,
} from "legacy/api/ApplicationApi";
import {
  Page,
  ReduxAction,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { APP_MODE } from "../../../legacy/reducers/types";

export interface FetchApplicationPayload {
  applicationId: string;
  mode: APP_MODE;
  branch: string | undefined;
  commitId?: string;
}

export type SetCurrentBranchPayload = {
  branch: BranchDto | undefined;
};

export const fetchApplication = createAction(
  "FETCH_APPLICATION_INIT",
  (
    applicationId: string,
    mode: APP_MODE,
    branch: string | undefined,
    commitId?: string,
  ) => ({
    payload: {
      applicationId,
      mode,
      branch,
      commitId,
    },
  }),
);

export const fetchApplicationSuccess = createAction(
  "FETCH_APPLICATION_SUCCESS",
  (
    application: Awaited<
      ReturnType<typeof ApplicationApi.fetchSingleApplication>
    >["data"]["application"] & {
      global?: Partial<ApplicationV2GlobalDto>;
    },
  ) => ({ payload: application }),
);

export const updateApplication = createAction(
  "UPDATE_APPLICATION",
  (
    id: string,
    branch: string | undefined,
    data: PutApplicationUpdateRequestBody,
  ) => {
    return {
      payload: {
        id,
        branch,
        ...data,
      },
    };
  },
);

export const updateApplicationSettings = createAction<ApplicationSettings>(
  "CURRENT_APPLICATION_SETTINGS_UPDATE",
);

export const doneRefactoringAppConfiguration =
  createAction<ApplicationConfiguration>(
    "application/doneRefactoringAppConfiguration",
  );

export const requestApplicationSave = createAction<{
  hasUpdatedConfiguration?: boolean;
  hasUpdatedSettings?: boolean;
}>("REQUEST_APPLICATION_SAVE");

export const updateNonVersionedApplicationSettings =
  createAction<UpdateNonVersionedApplicationSettingsPayload>(
    "UPDATE_NON_VERSIONED_APPLICATION_SETTINGS",
  );

export const updateApplicationMetadata = createAction(
  "UPDATE_APPLICATION_METADATA",
  (id: string, data: PutApplicationMetadataUpdateRequestBody) => ({
    payload: { id, ...data },
  }),
);

export const setCurrentBranch = (
  branch: BranchDto | undefined,
): ReduxAction<SetCurrentBranchPayload> => ({
  type: ReduxActionTypes.SET_CURRENT_BRANCH,
  payload: {
    branch,
  },
});

export const fetchPageListSuccess = createAction(
  ReduxActionTypes.FETCH_PAGE_LIST_SUCCESS,
  (payload: { pages: Page[]; applicationId: string }) => ({ payload }),
);
