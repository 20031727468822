import {
  AgentType,
  ENVIRONMENT_ALL,
  ProfileType,
} from "@superblocksteam/shared";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Space,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import Typography from "antd/lib/typography";
import Title from "antd/lib/typography/Title";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { ReactComponent as EditIcon } from "assets/icons/common/dotdotdot.svg";
import { Layout, MainWrapper } from "components/app";
import { StyledReactMarkdown } from "components/app/SBDynamicForm/DynamicFormItem";
import {
  getCurrentUser,
  getIsSuperUser,
} from "legacy/selectors/usersSelectors";
import Header from "pages/components/Header";
import { PageNav } from "pages/components/PageNav";
import { PageWrapper, PROFILES_TITLE } from "pages/components/PageWrapper";
import { useAppSelector } from "store/helpers";
import { selectActiveAgents } from "store/slices/agents";
import { generateKey } from "utils/generateKey";
import { useSaga } from "../../hooks/store";
import {
  DroppableComponent,
  RenderComponentProps,
} from "../../legacy/components/designSystems/default/DraggableListComponent";
import { LegacyNamedColors } from "../../legacy/constants/LegacyNamedColors";
import {
  DOCS_PAGE_URL,
  DocsPage,
  OPA_URL,
} from "../../legacy/constants/routes";
import { DropdownMenuItem } from "../../legacy/pages/common/CustomizedDropdown/StyledComponents";
import { getUserCurrentOrgId } from "../../legacy/selectors/organizationSelectors";
import { selectOrganizationById } from "../../store/slices/organizations";
import { createProfileSaga } from "../../store/slices/organizations/sagas/createProfile";
import { deleteProfileSaga } from "../../store/slices/organizations/sagas/deleteProfile";
import { updateProfileSaga } from "../../store/slices/organizations/sagas/updateProfile";

const TableWrapper = styled.div`
  table-layout: fixed;
  border: 1px solid ${({ theme }) => theme.colors.GREY_100};
  border-radius: 4px;
  width: 100%;
  margin-top: 16px;
  min-width: 600px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const HeaderText = styled.div`
  font-weight: 500;
`;

const ItemWrapper = styled.div<{ $isHeader: boolean; $showAgentCol: boolean }>`
  display: flex;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.WHITE};
  color: ${({ theme }) => theme.colors.GREY_700};
  ${({ $isHeader, theme }) => {
    if ($isHeader) {
      return ``;
    }
    return `border-top: 1px solid ${theme.colors.GREY_100};`;
  }}
  position: relative;
  cursor: default;
  padding-right: 8px;
  ${({ $showAgentCol }) =>
    $showAgentCol
      ? css`
          & > div:nth-child(1) {
            width: 25%;
            min-width: 150px;
          }
          & > div:nth-child(2) {
            width: 25%;
            min-width: 150px;
          }

          & > div:nth-child(3) {
            width: 30%;
            min-width: 180px;
            & > div > p {
              // since we are using react-markdown
              min-width: 180px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          & > div:nth-child(4) {
            width: 20%;
            min-width: 60px;
          }
        `
      : css`
          & > div:first-child {
            width: 30%;
          }
          & > div:nth-child(2) {
            width: 30%;
          }
          & > div:nth-child(3) {
            width: 40%;
            min-width: 208px; // minus 32px for the edit button
            & > div > div > p {
              min-width: 240px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        `}

  &:hover {
    background: ${({ theme }) => theme.colors.GREY_25};

    .hide-column-icon,
    .delete-column-icon,
    .drag-column-icon {
      visibility: visible;
    }
  }
`;

const StyledText = styled.div`
  &&& {
    flex: auto;
    height: 44px;
    line-height: 44px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.GREY_700};

    :first-child {
      margin-left: 16px;
    }

    & p {
      font-size: 14px;
    }
  }
`;

const StyledTextForAgent = styled(StyledText)`
  &&& {
    text-align: center;
    padding-right: 44px; //make up for the dropdown button
  }
`;

const StyledToolTip = styled(Tooltip)`
  height: 32px;
  align-self: center;
`;

const EditButton = styled(Button)`
  background-color: transparent;
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 44px;
  align-self: center;
  border: 1px solid #e8eaed;
  color: ${(props) => props.theme.colors.GREY_500};

  :hover,
  :active {
    background-color: transparent;
    color: ${(props) => props.theme.colors.GREY_500};
  }

  :focus {
    color: ${(props) => props.theme.colors.GREY_500};
    background-color: ${(props) => props.theme.colors.GREY_50};
    border: 2px solid ${(props) => props.theme.colors.GREY_100};
    border-radius: 4px;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active {
    background-color: ${(props) => props.theme.colors.GREY_100};
    border-color: ${(props) => props.theme.colors.GREY_200};
    color: ${(props) => props.theme.colors.GREY_400};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  .ant-typography {
    margin: 0;
  }

  margin-bottom: 1rem;
`;

const TitleSubtitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;

  div.ant-typography {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.GREY_700};
  }
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 20px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
  .ant-modal-header {
    padding: 16px;
  }
`;

const AgentsColumn = styled.div`
  text-align: center;
`;

interface ProfilesListItemProps {
  id: string;
  displayName: string;
  key: string;
  type: ProfileType;
  description: string;
  isDescriptionMarkdown: boolean;
  setDeleteProfileId: (id: string) => void;
  setEditModalProfileId: (id: string) => void;
  isDragging: boolean;
}

const DUPLICATE_DISPLAY_NAME = "Display Name is already in use";
const DUPLICATE_NAME = "Key is already in use";

//A react function component to show count of agents given profile
const AgentsCount = (props: { profileKey: string }) => {
  const { profileKey } = props;
  const navigate = useNavigate();
  const agents = useSelector(
    selectActiveAgents(AgentType.ONPREMISE, profileKey),
  );
  const hasAllEnvAgents = agents.some(
    (agent) => agent.environment === ENVIRONMENT_ALL,
  );
  const activeCount = agents.length;
  return (
    <AgentsColumn>
      <Tooltip
        title={`Supported by ${activeCount} active agent${
          activeCount !== 1 ? "s" : ""
        }`}
        placement="left"
      >
        <Button
          onClick={() => {
            navigate({
              pathname:
                activeCount === 0
                  ? OPA_URL
                  : `${OPA_URL}?status=active${
                      hasAllEnvAgents ? "&tag=*" : ""
                    }&tag=${profileKey}`,
            });
          }}
          type="link"
        >
          {activeCount}
        </Button>
      </Tooltip>
    </AgentsColumn>
  );
};

const ProfilesListItem = (
  props: RenderComponentProps<ProfilesListItemProps>,
) => {
  const { item } = props;
  const editDeleteProfileMenu = useMemo(
    () => (
      <Menu>
        <DropdownMenuItem
          key={"edit"}
          data-test={`profile-edit-button`}
          onClick={() => item.setEditModalProfileId(item.id)}
        >
          Edit{" "}
        </DropdownMenuItem>
        <DropdownMenuItem
          key={"delete"}
          data-test={`profile-delete-button`}
          onClick={() => item.setDeleteProfileId(item.id)}
        >
          Delete{" "}
        </DropdownMenuItem>
      </Menu>
    ),
    [item],
  );
  const editable = useMemo(() => {
    return item.type !== ProfileType.RESERVED;
  }, [item]);
  const currentUser = useSelector(getCurrentUser);
  const isAdmin = Boolean(currentUser?.isAdmin);
  const organizationId = useSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  // opa profiles
  const isSuperUser = useSelector(getIsSuperUser);
  const isOPA = organization.agentType === AgentType.ONPREMISE;
  const showAgentCol = isOPA && (isAdmin || isSuperUser);

  return (
    <ItemWrapper $isHeader={false} $showAgentCol={showAgentCol}>
      <StyledText data-test={`profile-list-item-${item.displayName}`}>
        {item.displayName}
      </StyledText>
      <StyledText> {item.key} </StyledText>
      {showAgentCol && (
        <StyledText>
          {item.isDescriptionMarkdown ? (
            <StyledReactMarkdown>{item.description}</StyledReactMarkdown>
          ) : (
            item.description
          )}
        </StyledText>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {showAgentCol ? (
          <StyledText style={{ marginLeft: "0" }}>
            <AgentsCount profileKey={item.key} />
          </StyledText>
        ) : (
          <StyledText style={{ marginLeft: "0" }}>
            {item.isDescriptionMarkdown ? (
              <StyledReactMarkdown>{item.description}</StyledReactMarkdown>
            ) : (
              item.description
            )}
          </StyledText>
        )}
        <Dropdown
          overlay={editDeleteProfileMenu}
          trigger={["click"]}
          disabled={!editable || !isAdmin}
        >
          <StyledToolTip
            title={
              editable
                ? !isAdmin
                  ? "Only Admins can edit or delete profiles"
                  : ""
                : "Cannot edit or delete reserved profiles"
            }
            placement="left"
          >
            <EditButton
              data-test={`profile-${item.id}-dropdown`}
              icon={<EditIcon style={{ verticalAlign: "30%" }} />}
              style={{ marginLeft: "12px" }}
              disabled={!editable || !isAdmin}
            />
          </StyledToolTip>
        </Dropdown>
      </div>
    </ItemWrapper>
  );
};

export default function Profiles() {
  const organizationId = useSelector(getUserCurrentOrgId) ?? "";
  const organization = useAppSelector((state) =>
    selectOrganizationById(state, organizationId),
  );
  // opa profiles
  const isOPA = organization.agentType === AgentType.ONPREMISE;
  const currentUser = useSelector(getCurrentUser);
  const [updateProfile] = useSaga(updateProfileSaga);
  const [createProfile] = useSaga(createProfileSaga);
  const [deleteProfile] = useSaga(deleteProfileSaga);

  const [createProfileModalVisible, setCreateProfileModalVisible] =
    useState(false);
  const [createForm] = Form.useForm();
  const handleCreateProfile = useCallback(async () => {
    createForm.validateFields().then(async (values) => {
      await createProfile({
        orgId: organizationId,
        created: {
          key: generateKey(values.displayName),
          displayName: values.displayName,
          description: values.profileDescription,
        },
      });
      setCreateProfileModalVisible(false);
    });
  }, [createForm, organizationId, createProfile]);

  const handleDisplayNameChange = useCallback(() => {
    const key = generateKey(createForm.getFieldValue("displayName"));
    createForm.setFieldsValue({ key: key });
  }, [createForm]);
  // Validate that the display name is not already being used by another profile
  const newDisplayNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (value) {
        const profile = organization.profiles?.find(
          (profile) => profile.displayName === value,
        );
        if (profile) {
          return Promise.reject(DUPLICATE_DISPLAY_NAME);
        }
        return Promise.resolve();
      }
    },
    [organization.profiles],
  );
  const newNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (value) {
        const profile = organization.profiles?.find(
          (profile) => profile.key === value,
        );
        if (profile) {
          return Promise.reject(DUPLICATE_NAME);
        }
        return Promise.resolve();
      }
    },
    [organization.profiles],
  );
  const createProfileModal = useMemo(() => {
    return (
      <StyledModal
        data-test="create-profile-modal"
        open={createProfileModalVisible}
        closable
        onCancel={() => {
          setCreateProfileModalVisible(false);
        }}
        title={`Create new profile`}
        footer={null}
        width={450}
      >
        <div>
          <StyledForm form={createForm} name="basic" layout="vertical">
            <Form.Item
              label="Display Name"
              name="displayName"
              rules={[
                { required: true, message: "Please enter a display name!" },
                {
                  validator: newDisplayNameValidator,
                  message: DUPLICATE_DISPLAY_NAME,
                },
              ]}
            >
              <Input
                data-test="profile-key-input-create"
                placeholder="Example Profile"
                onChange={handleDisplayNameChange}
              />
            </Form.Item>
            <Tooltip
              title="The key is generated based on the display name"
              placement="bottom"
            >
              <Form.Item
                label="Key"
                name="key"
                rules={[
                  {
                    validator: newNameValidator,
                    message: DUPLICATE_NAME,
                  },
                ]}
              >
                <Input
                  placeholder="example_profile"
                  disabled={true}
                  data-test={"profile-key-create"}
                />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Description" name="profileDescription">
              <TextArea
                placeholder="Enter a description"
                data-test={"profile-description-input-create"}
                maxLength={100}
              />
            </Form.Item>
          </StyledForm>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
        >
          <Button
            data-test="create-profile-modal-close"
            key="close"
            type="default"
            onClick={() => {
              setCreateProfileModalVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleCreateProfile}
            data-test={"create-profile-button"}
          >
            Create
          </Button>
        </div>
      </StyledModal>
    );
  }, [
    createProfileModalVisible,
    createForm,
    newDisplayNameValidator,
    handleDisplayNameChange,
    newNameValidator,
    handleCreateProfile,
  ]);

  const profileById = useCallback(
    (id: string) => {
      return (organization.profiles ?? []).find((profile) => {
        return profile.id === id;
      });
    },
    [organization.profiles],
  );
  const [editForm] = Form.useForm();
  const [profileIdBeingEdited, setProfileIdBeingEdited] = useState<
    string | undefined
  >(undefined);
  const startEditingForProfileId = useCallback(
    (id: string) => {
      const profBeingEdited = profileById(id);
      // Fill in the initial form values.
      editForm.setFieldsValue({
        displayName: profBeingEdited?.displayName ?? "",
        key: profBeingEdited?.key ?? "",
        profileDescription: profBeingEdited?.description ?? "",
      });
      // `profileIdBeingEdited` gives the `editForm` access to the profile
      // being edited and activates the edit modal.
      setProfileIdBeingEdited(id);
    },
    [editForm, profileById, setProfileIdBeingEdited],
  );

  const handleUpdateProfile = useCallback(async () => {
    editForm.validateFields().then(async (values) => {
      const profBeingEdited = profileById(profileIdBeingEdited ?? "");
      await updateProfile({
        orgId: organizationId,
        profileId: profBeingEdited?.id ?? "",
        updated: {
          // NOTE: Do not regenerate the key here because the key must not
          // change once it is persisted in the create flow.
          key: profBeingEdited?.key ?? "",
          displayName: values.displayName,
          description: values.profileDescription,
        },
      });
      setProfileIdBeingEdited(undefined);
    });
  }, [
    editForm,
    profileById,
    profileIdBeingEdited,
    updateProfile,
    organizationId,
  ]);
  // Validate that the display name is not already being used by another profile
  const editedDisplayNameValidator = useCallback(
    async (rule: any, value: any) => {
      if (value) {
        const profile = organization.profiles?.find(
          (profile) =>
            profile.displayName === value &&
            profile.id !== profileIdBeingEdited,
        );
        if (profile) {
          return Promise.reject(DUPLICATE_DISPLAY_NAME);
        }
        return Promise.resolve();
      }
    },
    [organization.profiles, profileIdBeingEdited],
  );

  // TODO(aayush): DRY with the edit/create modals
  const editProfileModal = useMemo(() => {
    return (
      <StyledModal
        data-test="edit-profile-modal"
        open={Boolean(profileIdBeingEdited)}
        closable
        onCancel={() => {
          setProfileIdBeingEdited(undefined);
        }}
        title={`Edit this profile`}
        footer={null}
        width={450}
      >
        <div>
          <StyledForm form={editForm} name="basic" layout="vertical">
            <Form.Item
              label="Display Name"
              name="displayName"
              rules={[
                { required: true, message: "Please enter a display name!" },
                {
                  validator: editedDisplayNameValidator,
                  message: DUPLICATE_DISPLAY_NAME,
                },
              ]}
            >
              <Input
                placeholder="Example Profile"
                data-test="profile-key-input-edit"
              />
            </Form.Item>
            <Tooltip
              title="The key cannot be edited in order to avoid breaking existing usages"
              placement="bottom"
            >
              <Form.Item label="Key" name="key">
                <Input
                  placeholder="example_profile"
                  disabled={true}
                  data-test="profile-key-edit"
                />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Description" name="profileDescription">
              <TextArea
                placeholder="Enter a description"
                data-test="profile-description-input-edit"
                maxLength={100}
              />
            </Form.Item>
          </StyledForm>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
        >
          <Button
            data-test="create-profile-modal-close"
            key="close"
            type="default"
            onClick={() => {
              setProfileIdBeingEdited(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleUpdateProfile}
            data-test={"save-profile-button"}
          >
            Save
          </Button>
        </div>
      </StyledModal>
    );
  }, [
    editForm,
    editedDisplayNameValidator,
    handleUpdateProfile,
    profileIdBeingEdited,
  ]);

  const [deleteProfileId, setDeleteProfileId] = useState(undefined);
  const handleDeleteProfile = useCallback(async () => {
    await deleteProfile({
      orgId: organizationId,
      profileId: deleteProfileId ?? "",
    });
    setDeleteProfileId(undefined);
  }, [deleteProfile, deleteProfileId, organizationId]);
  const deleteProfileModal = useMemo(() => {
    return (
      <StyledModal
        data-test="delete-profile-modal"
        open={Boolean(deleteProfileId)}
        closable
        onCancel={() => {
          setDeleteProfileId(undefined);
        }}
        title={`Delete this profile?`}
        footer={null}
        width={450}
      >
        <Space direction="vertical" style={{ marginBottom: "20px" }}>
          <div>
            <Typography.Text
              style={{
                color: LegacyNamedColors.GRAY_DARK,
              }}
            >
              This action cannot be undone and may break Applications,
              Workflows, or Scheduled Jobs using the{" "}
              <Typography.Text strong>
                {profileById(deleteProfileId ?? "")?.displayName}
              </Typography.Text>{" "}
              profile.
            </Typography.Text>
          </div>
        </Space>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button
            data-test="profile-delete-cancel"
            key="cancel"
            type="default"
            onClick={() => {
              setDeleteProfileId(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            data-test="profile-delete-confirm"
            key="delete"
            type="primary"
            danger
            onClick={handleDeleteProfile}
          >
            <span data-test="confirm-delete-profile-button">Delete</span>
          </Button>
        </div>
      </StyledModal>
    );
  }, [deleteProfileId, handleDeleteProfile, profileById]);

  const availableProfiles = useMemo(() => {
    const allProfileItems = (organization.profiles ?? []).map((profile) => {
      return {
        id: profile.id,
        displayName: profile.displayName,
        key: profile.key,
        type: profile.type,
        description: profile.description
          ? profile.description
          : `Configure integrations for your **${profile.displayName}** profile`,
        isDescriptionMarkdown: profile.description ? false : true,
        setEditModalProfileId: startEditingForProfileId,
        setDeleteProfileId: setDeleteProfileId,
      };
    });
    return allProfileItems.slice().sort((a, b) => {
      if (a.displayName === `Production`) {
        return -1;
      }
      if (b.displayName === `Production`) {
        return 1;
      }
      if (a.displayName === `Staging`) {
        return -1;
      }
      if (b.displayName === `Staging`) {
        return 1;
      }
      return 0;
    });
  }, [organization.profiles, startEditingForProfileId, setDeleteProfileId]);
  const startCreatingProfile = useCallback(() => {
    createForm.resetFields();
    setCreateProfileModalVisible(true);
  }, [createForm, setCreateProfileModalVisible]);

  // Determine if the user is an admin.
  const isAdmin = Boolean(currentUser?.isAdmin);
  const isSuperUser = useSelector(getIsSuperUser);

  const showAgentCol = isOPA && (isAdmin || isSuperUser);

  return (
    <PageWrapper pageName={PROFILES_TITLE}>
      <Layout Header={<Header />} Sider={<PageNav />}>
        <MainWrapper data-test="profiles-home">
          <HeaderWrapper>
            <TitleSubtitleWrapper>
              <Title level={2}>{PROFILES_TITLE}</Title>
              <Typography.Paragraph>
                Configure Superblocks to connect all of your organization’s
                profiles.{" "}
                <Typography.Link
                  href={DOCS_PAGE_URL(DocsPage.INTEGRATION_PROFILES)}
                  target="_blank"
                >
                  Learn more
                </Typography.Link>
              </Typography.Paragraph>
            </TitleSubtitleWrapper>
            <div style={{ marginTop: "2.4em" }}>
              <Tooltip
                title={!isAdmin ? "Only Admins can create profiles" : ""}
              >
                <Button
                  type="primary"
                  onClick={startCreatingProfile}
                  disabled={!isAdmin}
                  data-test={"add-profile-button"}
                >
                  Add Profile
                </Button>
              </Tooltip>
            </div>
          </HeaderWrapper>

          <TableWrapper data-test={`profiles-table`}>
            <HeaderText>
              <ItemWrapper $isHeader={true} $showAgentCol={showAgentCol}>
                <StyledText>Display Name</StyledText>
                <StyledText>Key</StyledText>
                <StyledText>Description</StyledText>
                {showAgentCol && (
                  <StyledTextForAgent>Agents </StyledTextForAgent>
                )}
              </ItemWrapper>
            </HeaderText>
            <DroppableComponent
              items={availableProfiles}
              renderComponent={ProfilesListItem}
              hideSpacer={true}
              isDragDisabled={true}
            />
          </TableWrapper>
        </MainWrapper>
        {createProfileModal}
        {deleteProfileModal}
        {editProfileModal}
      </Layout>
    </PageWrapper>
  );
}
