import { EyeInvisibleOutlined } from "@ant-design/icons";
import { Button, Spin, Typography } from "antd";
import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import CodeEditorWithAiAssistant from "components/app/CodeEditor/CodeEditorWithAiAssistant";
import { EditorProps } from "components/app/CodeEditor/types";
import useSaga from "hooks/store/useSaga";
import { useElementRect, useFeatureFlag } from "hooks/ui";
import { getSharedDeveloperPreferences } from "legacy/selectors/sagaSelectors";
import { useAppSelector } from "store/helpers";
import formatV2ApiStepsSaga from "store/slices/apisV2/formatCodeSaga";
import { selectV2ApiBlockFormattingStatusById } from "store/slices/apisV2/selectors";
import { Flag } from "store/slices/featureFlags";
import { colors } from "styles/colors";
import { DynamicFormItemProps, StyledReactMarkdown } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormSubheading } from "../FormSubheading";
import { FormText } from "../FormText";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";

type DynamicFormCodeEditorProps = {
  wrapperRef?: React.RefObject<HTMLElement>;
  datasourceId?: string;
  isLoading?: boolean;
  actionId?: string;
  apiId?: string;
  onPasswordVisibilityChange?: (path: string, visible: boolean) => void;
} & DynamicFormItemProps &
  EditorProps["input"] &
  Omit<
    EditorProps,
    "autocompleteConfiguration" | "autocompleteAdditionalData" | "input"
  >;

const IconButton = styled(Button)`
  border: none;
  padding: 0px;
  position: absolute;
  top: 20px;
  right: 12px;
`;

// Ant design uses 24px margins in forms, plus 2px for the borders
const DEFAULT_PADDING = 26;

export const DynamicFormCodeEditor = ({
  label,
  path,
  onBlur,
  onDragStart,
  onDrop,
  onFocus,
  name,
  rules,
  subHeading,
  showShortcutMenu = false,
  wrapperRef,
  datasourceId,
  isLoading,
  showHideIcon,
  onPasswordVisibilityChange,
  apiId,
  actionId,
  ...otherProps
}: DynamicFormCodeEditorProps) => {
  const context = useContext(FormContext);
  const [value, setValue] = useState<string>("");
  const { formatCodeOnBlur } = useAppSelector(getSharedDeveloperPreferences);
  const blockFormatting = useAppSelector((state) => {
    if (!apiId) {
      return undefined;
    }
    return selectV2ApiBlockFormattingStatusById(state, apiId, actionId);
  });

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) =>
      setValue(value as string),
    );

    return () => unsubscribe();
  }, [path, context]);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) =>
        setValidationMessage(value),
      );
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [path, rules, context]);
  const onChange = useCallback(
    (value: any) => {
      context.onChange(path, value);
    },
    [context, path],
  );

  const wrapperRect = useElementRect(wrapperRef?.current ?? null);

  const heightProps = useMemo(() => {
    if (wrapperRect) {
      return {
        height: `${wrapperRect.height - DEFAULT_PADDING}px`,
        minHeight: `${wrapperRect.height - DEFAULT_PADDING}px`,
        maxHeight: `${wrapperRect.height - DEFAULT_PADDING}px`,
      };
    }
    return {};
  }, [wrapperRect]);

  const input = useMemo(
    () => ({ name, value, onChange, onBlur, onDragStart, onDrop, onFocus }),
    [name, value, onChange, onBlur, onDragStart, onDrop, onFocus],
  );

  const [formatV2APISteps] = useSaga(formatV2ApiStepsSaga);

  // Enables code formatting, VIM bindings and new code theme
  const isNewCodeEditorFeatureFlagEnabled = useFeatureFlag(
    Flag.CODE_FORMATTING,
  );

  // Trigger code formatting when the editor unmounts, because
  // onBlur is not triggered when the API editor is minimized
  // or when clicking between different API steps.
  const handleEditorWillUnmount = useCallback(async () => {
    if (
      isNewCodeEditorFeatureFlagEnabled &&
      formatCodeOnBlur &&
      apiId &&
      actionId
    ) {
      // We use Sagas for this change, instead of triggering the `onChange`.
      // That's because `onChange` is managed by useFormStateManager,
      // which doesn't trigger changes when components unmount
      // (it relies on an extra render to trigger the change).

      await formatV2APISteps({
        apiId: apiId,
        blockName: actionId,
      });
    }
  }, [
    formatV2APISteps,
    formatCodeOnBlur,
    apiId,
    actionId,
    isNewCodeEditorFeatureFlagEnabled,
  ]);

  return (
    <div style={{ position: "relative" }}>
      <label>
        <FormLabel>
          {label}{" "}
          {isLoading && (
            <FormText>
              <Spin size="small" /> &nbsp; Loading metadata...
            </FormText>
          )}
        </FormLabel>
        <FormTooltip tooltip={otherProps.tooltip} />
      </label>
      <CodeEditorWithAiAssistant
        input={input}
        showShortcutMenu={showShortcutMenu}
        monospace={true}
        minHeight="350px"
        autocompleteConfiguration={context.autocompleteConfiguration}
        additionalDynamicData={context.autocompleteAdditionalData}
        isInPropertyPanel={false}
        datasourceId={datasourceId}
        formatCodeOnBlur={formatCodeOnBlur}
        formatCodeOnShortcut={true}
        onEditorWillUnmount={handleEditorWillUnmount}
        formattingError={blockFormatting && blockFormatting.error}
        formattingStatus={blockFormatting && blockFormatting.status}
        {...heightProps}
        {...otherProps}
      />
      {showHideIcon && (
        <IconButton
          onClick={() => onPasswordVisibilityChange?.(path, false)}
          type="ghost"
        >
          <EyeInvisibleOutlined />
        </IconButton>
      )}
      <Typography.Text type="danger">{validationMessage}</Typography.Text>
      {subHeading && <FormSubheading text={subHeading} />}
      <div style={{ color: colors.GREY_400 }}>
        <StyledReactMarkdown linkTarget="_blank">
          {otherProps.subtitle ?? ""}
        </StyledReactMarkdown>
      </div>
    </div>
  );
};
