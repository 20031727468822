import React from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
} from "react-beautiful-dnd";
import styled from "styled-components";
const ItemWrapper = styled.div`
  margin: 0;
`;

const StyledLine = styled.div<{ visible: boolean }>`
  width: 1px;
  height: 100%;
  transition: background-color 1000ms linear;
  background-color: ${({ theme, visible }) =>
    visible ? theme.colors.GREY_100 : "transparent"};
`;

const StyledSpacer = styled.div`
  display: flex;
  justify-content: center;
  height: 4px;
`;

export type RenderComponentProps<T extends { id: string } = any> = {
  index: number;
  item: T;
  items: T[];
  deleteOption?: (index: number, value: string) => void;
  updateOption?: (index: number, value: string) => void;
  toggleVisibility?: (index: number) => void;
  toggleEditability?: (index: number) => void;
  onEdit?: (index: number) => void;
  draggable: boolean;
  isDragging: boolean;
  parentDivId?: string;
  disabled?: boolean;
  draggableProvided: DraggableProvided;
};

interface DroppableComponentProps {
  items: Array<Record<string, unknown>>;
  showConnectingLine?: boolean;
  renderComponent: (props: RenderComponentProps) => JSX.Element;
  deleteOption?: (index: number, value: string) => void;
  updateOption?: (index: number, value: string) => void;
  toggleVisibility?: (index: number) => void;
  toggleEditability?: (index: number) => void;
  updateItems?: (items: Array<Record<string, unknown>>) => void;
  onEdit?: (index: number) => void;
  hideSpacer?: boolean;
  parentDivId?: string;
  isDisabled?: boolean;
  isDragDisabled?: boolean;
}

interface DroppableComponentState {
  items: Array<Record<string, unknown>>;
}

export class DroppableComponent extends React.Component<
  DroppableComponentProps,
  DroppableComponentState
> {
  constructor(props: DroppableComponentProps) {
    super(props);
    this.state = {
      items: props.items,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<DroppableComponentProps>,
    prevState: Readonly<DroppableComponentState>,
  ) {
    if (this.props.items.length !== prevState.items.length) {
      this.setState({ items: this.props.items });
    } else if (
      JSON.stringify(this.props.items) !== JSON.stringify(prevState.items)
    ) {
      this.setState({ items: this.props.items });
    }
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    const items: Array<Record<string, unknown>> = [...this.state.items];
    const startIndex = source.index;
    let endIndex: number;
    if (!destination) {
      endIndex = items.length;
    } else {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
      endIndex = destination.index;
    }
    const [removed] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, removed);

    this.setState({ items: items });
    this.props.updateItems?.(items);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable
          droppableId="droppable"
          renderClone={(provided, snapshot, rubric) =>
            this.renderItem(
              provided,
              snapshot,
              this.state.items[rubric.source.index],
              rubric.source.index,
              this.props.parentDivId,
            )
          }
        >
          {({ innerRef, droppableProps, placeholder }, droppableSnapshot) => (
            <div ref={innerRef} {...droppableProps}>
              {this.state.items.map((item, index) => {
                return (
                  <Draggable
                    draggableId={String(item.id)}
                    key={String(item.id)}
                    index={index}
                    isDragDisabled={
                      this.props.isDisabled || this.props.isDragDisabled
                    }
                  >
                    {(provided, snapshot) =>
                      this.renderItem(
                        provided,
                        snapshot,
                        item,
                        index,
                        this.props.parentDivId,
                      )
                    }
                  </Draggable>
                );
              })}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  private renderItem(
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    item: Record<string, unknown>,
    index: number,
    parentDivId?: string,
  ): React.ReactElement<
    HTMLElement,
    string | React.JSXElementConstructor<any>
  > {
    const {
      renderComponent,
      deleteOption,
      updateOption,
      toggleVisibility,
      toggleEditability,
      onEdit,
      updateItems,
      hideSpacer,
      isDisabled,
    } = this.props;
    return (
      <ItemWrapper
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {!hideSpacer && (
          <StyledSpacer key={`spacer-top-${item.id}`}>
            {this.props.showConnectingLine && index !== 0 && (
              <StyledLine visible={!snapshot.isDragging} />
            )}
          </StyledSpacer>
        )}
        {renderComponent({
          deleteOption,
          updateOption,
          toggleVisibility,
          toggleEditability,
          onEdit,
          item,
          items: this.state.items,
          index,
          draggable: Boolean(updateItems),
          isDragging: snapshot.isDragging,
          parentDivId,
          disabled: isDisabled,
          draggableProvided: provided,
        })}
        {!hideSpacer && (
          <StyledSpacer key={`spacer-bottom-${item.id}`}>
            {this.props.showConnectingLine && (
              <StyledLine visible={!snapshot.isDragging} />
            )}
          </StyledSpacer>
        )}
      </ItemWrapper>
    );
  }
}
