import { Tooltip, TooltipProps } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as CopyIcon } from "assets/icons/common/copy.svg";
import { Button } from "./Button";

interface Props {
  textToCopy: string;
  tooltipPlacement?: TooltipProps["placement"];
  dataTest?: string;
  ddogActionName?: string;
  buttonStyle?: React.CSSProperties;
}

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const CopyButton = ({
  textToCopy,
  tooltipPlacement = "left",
  dataTest,
  ddogActionName,
  buttonStyle,
}: Props) => {
  const copyText = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
  }, [textToCopy]);

  return (
    <Tooltip placement={tooltipPlacement} title={`Copy`}>
      <StyledButton
        onClick={copyText}
        size="small"
        data-test={dataTest}
        data-dd-action-name={ddogActionName}
        style={buttonStyle}
      >
        <CopyIcon />
      </StyledButton>
    </Tooltip>
  );
};
