import {
  PropertyPaneCategoryConfig,
  PropertyPaneConfig,
  PropertyPaneControlConfig,
  PropertyPaneSectionConfig,
} from "legacy/constants/PropertyControlConstants";

export function isPropertyPaneControlConfig<T>(
  value: PropertyPaneConfig<T>,
): value is PropertyPaneControlConfig<T> {
  return "propertyName" in value;
}
export function isPropertyPaneSectionConfig<T>(
  value: PropertyPaneConfig<T>,
): value is PropertyPaneSectionConfig<T> {
  return "sectionName" in value;
}
export function isPropertyPaneCategoryConfig<T>(
  value: PropertyPaneConfig<T>,
): value is PropertyPaneCategoryConfig<T> {
  return "categoryName" in value;
}
