import { get, findIndex } from "lodash";
import { EditorOpenTabWithEntity } from "legacy/constants/EditorPreferencesConstants";
import { FlattenedWidgetProps } from "legacy/reducers/entityReducers/canvasWidgetsReducer";

/**
 * Checks if the given widget property path is an array property
 * It also returns true for paths encoded with IDs rather than array indices
 * because we use IDs in the path when encoding into the URL
 * Ex with array indices: onClick[0].code or onRowSelected[4].code
 * Ex with IDs: onClick[abc123].code or onRowSelected[abc123].code
 */
const ARRAY_PROPERTY_PATH_REGEX = /\[[a-zA-Z0-9]+\]/g;
export const isArrayPropertyPath = (path?: string) => {
  if (!path) return false;
  return path.match(ARRAY_PROPERTY_PATH_REGEX) !== null;
};

/*
 * Checks if the given widget property path is a code property path.
 * We currently only care about code properties for widget triggers,
 * so we first check if the path is an array property path, and then
 * check if the final property name is "code"
 */
export const isCodeProperty = (path?: string) => {
  if (!path || !isArrayPropertyPath(path)) return false;
  const pathComponents = path.split(".");
  return pathComponents[pathComponents.length - 1] === "code";
};

export const getTabName = (tab: EditorOpenTabWithEntity, isV2: boolean) => {
  if (tab.entityType === "API") {
    const v1ApiName =
      "actions" in tab.entity ? tab.entity?.actions?.name : undefined;
    const v2ApiName =
      "apiPb" in tab.entity ? tab.entity?.apiPb?.metadata.name : undefined;
    const legacyApiName =
      "metadata" in tab.entity ? tab.entity?.metadata?.name : undefined;

    return (isV2 ? v2ApiName : v1ApiName) || legacyApiName;
  }

  if (tab.entityType === "WIDGET" && tab.widgetProperty) {
    const formattedPath = formatPropertyPathWithIndices(
      tab.entity,
      tab.widgetProperty,
    );
    if (!formattedPath) return "";
    return getWidgetTabName(tab.entity.widgetName, formattedPath);
  }
};

const PROPERTY_NAMES_FOR_TABS: Record<string, string> = {
  primaryColumns: "columns",
  code: "Run JS",
};

const getFinalPropertyNameForTab = (propertyName: string) => {
  return PROPERTY_NAMES_FOR_TABS[propertyName] || propertyName;
};

export const getWidgetTabName = (
  widgetName: string,
  widgetProperty?: string,
) => {
  if (!widgetProperty) return "";

  const propertyPathComponents = widgetProperty.split(".");
  const finalPropertyPathComponents = propertyPathComponents
    .map((pathString) => {
      return getFinalPropertyNameForTab(pathString);
    })
    .join(".");

  return `${widgetName}.${finalPropertyPathComponents}`;
};

/**
 * Format a widget property path that uses indices to use ids instead
 * Ex: widgetProperties.onClick[0].code becomes widgetProperties.onClick[abc123].code
 * We use this so that we can store the path to a widget trigger in the URL without
 * becoming out of date due to changes, as the ID is stable
 */
const ARRAY_INDEX_PATH_REGEX = /\[\d+\]/g;
export const formatPropertyPathWithIds = (
  widgetProperties: FlattenedWidgetProps,
  path: string,
) => {
  if (path && isArrayPropertyPath(path)) {
    const arrayPathMatches = path.match(ARRAY_INDEX_PATH_REGEX);
    if (!arrayPathMatches) return path;

    let newPath = path;
    arrayPathMatches.forEach((arrayPathMatch) => {
      const [pathToMatch] = newPath.split(arrayPathMatch);

      const arrayIndex = parseInt(arrayPathMatch.slice(1, -1), 10);

      // Need to make sure we use the path with numeric indices in order for
      // the get() call to work correctly
      const restoredPathToMatch = formatPropertyPathWithIndices(
        widgetProperties,
        pathToMatch,
      ) as string;

      const id = get(widgetProperties, restoredPathToMatch)[arrayIndex].id;
      newPath = newPath.replace(arrayPathMatch, `[${id}]`);
    });

    return newPath;
  } else {
    return path;
  }
};

/**
 * Format a widget property path that was updated to use IDs instead of indices
 * (likely from using formatPropertyPathWithIds) to instead use indices again
 * Ex: widgetProperties.onClick[1bc123].code becomes widgetProperties.onClick[2].code
 * We use this so that we can take a path to a widget trigger from the URL and
 * get the true path we can use with get/set lodash utils
 * If there is no trigger with the given ID, we return undefined
 */
const ARRAY_ID_PATH_REGEX = /\[(.*?)\]/g;
export const formatPropertyPathWithIndices = (
  widgetProperties: FlattenedWidgetProps,
  path?: string,
): string | undefined => {
  if (path && isArrayPropertyPath(path)) {
    const arrayPathMatches = path.match(ARRAY_ID_PATH_REGEX);
    if (!arrayPathMatches) return path;

    let newPath = path;
    arrayPathMatches.forEach((arrayPathMatch) => {
      const [pathToMatch] = newPath.split(arrayPathMatch);
      const idFromPath = arrayPathMatch.slice(1, -1);
      const objectIndex = findIndex(get(widgetProperties, pathToMatch), {
        id: idFromPath,
      });
      if (objectIndex === -1) return;
      newPath = newPath.replace(arrayPathMatch, `[${objectIndex}]`);
    });

    return newPath;
  } else {
    return path;
  }
};
