import { GroupBrief } from '../..';
import { ApplicationUserStatus } from '../user';

export enum OrganizationRbacAction {
  MANAGE = 'manage'
}

export enum RbacAction {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  DEPLOY = 'deploy',
  SHARE = 'share',
  BUILD = 'build',
  EXECUTE = 'execute',
  RESET = 'reset'
}

export enum GroupRbacAction {
  READ = 'read',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum RbacRole {
  VIEWER = 'viewer',
  BUILDER = 'builder',
  CONFIGURATOR = 'configurator',
  OWNER = 'owner',
  NONE = 'none',
  EXECUTOR = 'executor' // for app api
}

// used when querying permissions (roles table)
export enum PermissionedEntities {
  APPLICATION = 'application',
  APPLICATION_V2 = 'application_v2',
  API_V3 = 'api_v3',
  API = 'api',
  /**
   * @deprecated The Datasource entity is being deprecated in favor of the Integration entity.
   */
  DATASOURCE = 'datasource',
  INTEGRATION = 'integration'
}

// used in representation layer
export enum PermissionEntityType {
  APPLICATION = 'application',
  APPLICATION_V2 = 'application_v2',
  WORKFLOW = 'workflow',
  SCHEDULED_JOB = 'scheduled_job',
  /**
   * @deprecated The Datasource entity is being deprecated in favor of the Integration entity.
   */
  DATASOURCE = 'datasource',
  INTEGRATION = 'integration'
}

type BaseShareEntryDto = {
  id: string;
  role: RbacRole;
  name: string;
  actorId: string;
};

export type UserShareEntryDto = BaseShareEntryDto & {
  type: 'user';
  email: string;
  status: ApplicationUserStatus;
};

export type GroupShareEntryDto = BaseShareEntryDto & {
  type: 'group';
  size?: number;
};

export type ShareEntryDto = UserShareEntryDto | GroupShareEntryDto;

export const isUserShareEntry = (shareEntry: ShareEntryDto): shareEntry is UserShareEntryDto => shareEntry.type === 'user';
export const isGroupShareEntry = (shareEntry: ShareEntryDto): shareEntry is GroupShareEntryDto => shareEntry.type === 'group';

// When creating a new share entry, we might not have the user ID yet.
export type ShareEntryCreationDto = {
  // lookupId is either an email address or a group ID.
  lookupId: string;
  type: 'user' | 'group';
  role: RbacRole;
};

type BasePermissionEntity = {
  id: string;
  type: PermissionEntityType;
  name: string;
};

export type ApplicationEntity = BasePermissionEntity;

export type WorkflowEntity = BasePermissionEntity;
export type JobEntity = BasePermissionEntity;
/**
 * @deprecated The Datasource entity is being deprecated in favor of the Integration entity.
 */
export type DatasourceEntity = BasePermissionEntity;
export type IntegrationEntity = BasePermissionEntity;

export type PermissionEntity = ApplicationEntity | WorkflowEntity | JobEntity | DatasourceEntity | IntegrationEntity;

export const isApplicationEntity = (entity: PermissionEntity): entity is ApplicationEntity =>
  entity.type === PermissionEntityType.APPLICATION;

// Entity is referring to application, workflow, or job.
export type EntityPermissionsDto = {
  entity: PermissionEntity;
  permissions: ShareEntryDto[];
};

export type AccessRequestDto = {
  id: string;
  requesterId: string;
  requesteeId: string;
  role: RbacRole;
};

export type AcceptRequestDto = {
  permissions: ShareEntryDto[];
  role: RbacRole;
  requester: {
    id: string;
    name: string;
    email: string;
  };
};

// RBAC V2 lives below here

export enum AssignmentTypeEnum {
  ROLE = 'role',
  PERMISSION = 'permission'
}

export enum PrincipalTypeEnum {
  GROUP = 'group',
  USER = 'user'
  // ORGANIZATION = 'organization',
  // API_KEY = 'api_key'
}

export enum ResourceTypeEnum {
  ACCESS_TOKENS = 'access_tokens',
  AGENTS = 'agents',
  APPLICATIONS = 'apps',
  APPLICATIONS_APIS = 'apps.apis',
  APPLICATIONS_PAGES = 'apps.pages',
  FOLDERS = 'folders',
  GROUPS = 'groups',
  GROUPS_MEMBERS = 'groups.members',
  INTEGRATIONS = 'integrations',
  LOGS = 'logs',
  LOGS_STREAMS = 'logs.streams',
  ORGANIZATION = 'org',
  ORGANIZATION_REQUESTS = 'org.requests',
  ORGANIZATION_USERS = 'org.users',
  PROFILES = 'profiles',
  REPOSITORIES = 'repos',
  ROLES = 'roles',
  ROLES_MEMBERS = 'roles.members',
  SCHEDULED_JOBS = 'jobs',
  SECRETS_STORES = 'secrets_stores',
  WORKFLOWS = 'workflows'
}

export enum ActionTypeEnum {
  ACCESS = 'access',
  BUILD = 'build',
  CREATE = 'create',
  DELETE = 'delete',
  DEPLOY = 'deploy',
  EXECUTE = 'execute',
  MANAGE = 'manage',
  MANAGE_SCHEDULE = 'manage_schedule',
  MANAGE_VISIBILITY = 'manage_visibility',
  PREVIEW = 'preview',
  READ = 'read',
  RUN = 'run',
  SHARE = 'share',
  UPDATE = 'update',
  VIEW = 'view'
}

export enum RoleTypeEnum {
  APPLICATIONS = 'apps',
  GROUPS = 'groups',
  INTEGRATIONS = 'integrations',
  ORGANIZATION = 'org', // This represents a role that can contain permissions across resource type boundaries, to be used for organization-wide roles.
  PROFILES = 'profiles',
  SCHEDULED_JOBS = 'jobs',
  SECRETS_STORES = 'secrets_stores',
  WORKFLOWS = 'workflows'
}

export type AssignmentDto = {
  id: string;
  assignmentId: string;
  assignmentType: AssignmentTypeEnum;
  principalId: string;
  principalType: PrincipalTypeEnum;
  resourceId: string;
  resourceType: ResourceTypeEnum;
  // role and permission maps to assignmentId in the model
  // that means we will either have role or permission in this object
  role?: RoleDto;
  permission?: PermissionDto;
  // group and user maps to principalId in the model
  // that means we will either have group or user in this object
  group?: GroupBrief;
  user?: {
    id: string;
    name: string;
    email: string;
    status: ApplicationUserStatus;
  };
};

export type RoleDto = {
  id: string;
  name: string;
  description: string;
  type: RoleTypeEnum;
  organizationId: string | null;
  permissions?: PermissionDto[];
};

export type PermissionDto = {
  id: string;
  actionType: ActionTypeEnum;
  resourceType: ResourceTypeEnum;
  description: string;
};
