import scrollIntoView from "scroll-into-view-if-needed";

function findScrollableParent(element: HTMLElement) {
  while (element) {
    if (element === document.body) {
      // Reached the body element, no scrollable parent found
      return null;
    }

    const overflowY = window.getComputedStyle(element).overflowY;
    const isScrollable = overflowY === "auto" || overflowY === "scroll";
    const canScroll =
      isScrollable && element.scrollHeight > element.clientHeight;

    if (canScroll) {
      return element;
    }

    element = element.parentElement as HTMLElement;
  }
  return null;
}

export function isElementPartiallyVisible(
  element: Element,
  visibleThreshold = 40,
) {
  if (!element) {
    return false; // Not found
  }
  const scrollableParent = findScrollableParent(element as HTMLElement);

  if (!scrollableParent) {
    return false; // No scrollable parent found
  }

  const elRect = element.getBoundingClientRect();
  const parentRect = scrollableParent.getBoundingClientRect();

  const verticalOverlap =
    Math.min(elRect.bottom, parentRect.bottom) -
    Math.max(elRect.top, parentRect.top);

  return verticalOverlap >= visibleThreshold;
}

function hasClass(ele: HTMLElement, cls: string) {
  return ele.classList.contains(cls);
}

function addClass(ele: HTMLElement, cls: string) {
  if (!hasClass(ele, cls)) ele.classList.add(cls);
}

function removeClass(ele: HTMLElement, cls: string) {
  if (hasClass(ele, cls)) {
    ele.classList.remove(cls);
  }
}

const flashElement = (el: HTMLElement, flashTimeout = 1000) => {
  const flashClass = "flash";
  addClass(el, flashClass);
  setTimeout(() => {
    if (el) {
      // in case el is removed in timeout interval
      removeClass(el, flashClass);
    }
  }, flashTimeout);
};

export const flashElementsById = (
  id: string,
  timeout = 0,
  flashTimeout = 1000,
) => {
  setTimeout(() => {
    const el = document.getElementById(id);

    el &&
      scrollIntoView(el, {
        behavior: "smooth",
        block: "nearest",
        inline: "center",
        scrollMode: "if-needed",
      });

    if (el) flashElement(el, flashTimeout);
  }, timeout);
};

export const flashElementById = (id: string) => {
  const el = document.getElementById(id);
  el &&
    scrollIntoView(el, {
      behavior: "smooth",
      block: "nearest",
      inline: "center",
      scrollMode: "if-needed",
    });

  if (el) flashElement(el);
};

// Note that these fucntions don't work if the widget is in the iFrame
// (which is now the default)
// because we can't access the iFrame document directly like this

export const scrollWidgetIntoView = (id: string) => {
  const el = document.getElementById(`widget-${id}`);
  if (el)
    scrollIntoView(el, {
      behavior: "smooth",
      block: "nearest",
      inline: "center",
      scrollMode: "if-needed",
    });
};
