import { Input, InputProps } from "antd";
import React from "react";
import { ReactComponent as SearchIcon } from "assets/icons/common/search.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";

export function filterBySearch<T>(
  obj: T,
  searchTerm: string,
  searchableFields: Array<keyof T>,
): boolean {
  for (const field of searchableFields) {
    const value = obj[field];
    if (
      String(value).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    ) {
      return true;
    }
  }
  return false;
}

// the container for search, filter or add if not found
export const SearchContainer = styleAsClass`
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  gap: 8px;
  height: 32px;
`;

export const SearchInput = (props: InputProps) => {
  return (
    <Input
      {...props}
      prefix={
        <SearchIcon
          style={{
            color: colors.GREY_300,
            width: 16,
            height: 16,
            marginLeft: -4,
          }}
        />
      }
    />
  );
};
