import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { NEW_SECRETS_MANAGEMENT_URL } from "pages/routes";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { AvailablePlugin } from "./types";

const PluginCardWrapper = styleAsClass`
    display: flex;
    border-radius: 4px;
    border: 1px solid ${colors.GREY_100};
    padding: 10px 8px;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    img.plugin-img {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }
    div.plugin-name {
        color: ${colors.GREY_700};
        font-weight: 500;
        font-size: 12px;
    }
    button.plugin-add {
        height: 24px;
        width: 24px;
        padding: 3px 4px 4px 3px;
    }
`;

export const SecretPluginCard = ({
  pluginInfo,
  buttonStyle,
}: {
  pluginInfo: AvailablePlugin;
  buttonStyle: "PRIMARY" | "SECONDARY";
}) => {
  return (
    <Link to={NEW_SECRETS_MANAGEMENT_URL(pluginInfo.pluginId)}>
      <div className={PluginCardWrapper}>
        <div style={{ display: "flex" }}>
          <img
            className="plugin-img"
            src={pluginInfo.iconLocation ?? ""}
            alt=""
          />
          <div className="plugin-name">{pluginInfo.pluginName}</div>
        </div>
        <Button
          type={buttonStyle === "PRIMARY" ? "primary" : "ghost"}
          className="plugin-add"
          data-test={`create-secret-manager-${pluginInfo.pluginId}`}
        >
          <PlusIcon />
        </Button>
      </div>
    </Link>
  );
};
