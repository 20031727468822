import { Avatar } from "antd";
import styled from "styled-components";
import { generateHSL } from "styles/colorGeneration";

const ColoredAvatar = styled(Avatar)<{ name: string }>`
  color: ${({ name }) => generateHSL(name).front};
  background: ${({ name }) => generateHSL(name).background};
`;

export default ColoredAvatar;
