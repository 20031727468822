import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  ApiRunStatus,
  AuditLogDetails,
  AuditLogEntityType,
  isApiRunDetails,
} from "@superblocksteam/shared";

import { Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";
import { Table } from "components/ui/Table";
import { getEditorBasePath } from "hooks/store/useGetEditorPath";
import {
  EditorRoute,
  SCHEDULED_JOB_CONFIGURE_URL,
  WORKFLOW_CONFIGURE_URL,
} from "legacy/constants/routes";

type AuditLogTableRow = {
  entityId: string;
  entityType: AuditLogEntityType;
  deployed: boolean;
  startTime: Date;
  source?: string;
  details?: AuditLogDetails;

  // The rendered log name.
  logName?: string;
};

const ErrorMsgPre = styled.pre`
  white-space: pre-wrap;
  padding: 0 ${(props) => props.theme.paddings.small};
  font-size: ${(props) => props.theme.text.sizes.small};
`;

const DATE_WIDTH = 230;
const ERR_MSG_WIDTH = 300;
const STATUS_WIDTH = 110;
const MODE_WIDTH = 110;
const EXECUTION_TIME_WIDTH = 140;

const renderStatus = (
  log: AuditLogTableRow,
  record: any,
  idx: number,
): JSX.Element => {
  if (!isApiRunDetails(log.details)) {
    return <Typography.Text>-</Typography.Text>;
  }
  const elapsedMinutes = moment
    .duration(moment(new Date()).diff(moment(log.startTime)))
    .asMinutes();
  if (log.details?.status === ApiRunStatus.SUCCESS) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="success">
        SUCCESS
      </Tag>
    );
  } else if (log.details?.status === ApiRunStatus.FAIL) {
    return (
      <Tag icon={<CloseCircleOutlined />} color="error">
        ERROR
      </Tag>
    );
  } else if (elapsedMinutes > 30) {
    return (
      <Tag icon={<CloseCircleOutlined />} color="warning">
        ABORTED
      </Tag>
    );
  }
  return (
    <Tag icon={<ClockCircleOutlined />} color="processing">
      RUNNING
    </Tag>
  );
};

export const columns: ColumnsType<any> = [
  {
    title: <Typography.Text strong>Date</Typography.Text>,
    dataIndex: ["startTime"],
    key: "Date",
    fixed: "left",
    width: DATE_WIDTH,
    // eslint-disable-next-line react/display-name
    render: (value, record, idx) => (
      <Typography.Text key={idx}>
        {moment(value).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]")}
      </Typography.Text>
    ),
  },
  {
    title: <Typography.Text strong>Name</Typography.Text>,
    key: "Name",
    fixed: "left",
    // eslint-disable-next-line react/display-name
    render: (value, record, idx) => (
      <Typography.Text key={idx}>{getEntityLink(value)}</Typography.Text>
    ),
  },
  {
    title: <Typography.Text strong>Triggered by</Typography.Text>,
    dataIndex: ["source"],
    key: "Triggered by",
    // eslint-disable-next-line react/display-name
    render: (value, record, idx) => (
      <Typography.Text key={idx}>{value}</Typography.Text>
    ),
  },
  {
    title: <Typography.Text strong>Mode</Typography.Text>,
    dataIndex: ["deployed"],
    key: "Mode",
    width: MODE_WIDTH,
    // eslint-disable-next-line react/display-name
    render: (isDeployed, record, idx) => (
      <Typography.Text key={idx}>
        {isDeployed ? "Deployed" : "Testing"}
      </Typography.Text>
    ),
  },
  {
    title: <Typography.Text strong>Status</Typography.Text>,
    key: "Status",
    width: STATUS_WIDTH,
    render: renderStatus,
  },
  {
    width: ERR_MSG_WIDTH,
    title: <Typography.Text strong>Error message</Typography.Text>,
    key: "Error message",
    // eslint-disable-next-line react/display-name
    render: (value, record, idx) => {
      const errorMessage = value.details?.error?.trim();
      if (!errorMessage) {
        return <Typography.Text>-</Typography.Text>;
      }
      return (
        <div
          style={{
            // Reduce by 10px to ensure scrollbars are visible
            width: ERR_MSG_WIDTH - 10,
            maxHeight: "300px",
            overflowY: "scroll",
          }}
        >
          <code>
            <ErrorMsgPre>{errorMessage}</ErrorMsgPre>
          </code>
        </div>
      );
    },
  },
  {
    title: <Typography.Text strong>Execution time</Typography.Text>,
    key: "Execution time",
    width: EXECUTION_TIME_WIDTH,
    // eslint-disable-next-line react/display-name
    render: (value, record, idx) => (
      <Typography.Text key={idx}>{getExecutionTime(value)}</Typography.Text>
    ),
  },
];

const getEntityLink = (log: AuditLogTableRow): JSX.Element => {
  switch (log.entityType) {
    case AuditLogEntityType.APPLICATION:
      if (!isApiRunDetails(log.details)) {
        return <Typography.Text>{log.logName}</Typography.Text>;
      }
      return (
        <a
          rel="noreferrer"
          target="_blank"
          href={getEditorBasePath(EditorRoute.EditApiInputs, {
            applicationId: log.details.locationContext?.applicationId,
            // "default",
            apiId: log.details.target,
          })}
        >
          {log.logName}
        </a>
      );
    case AuditLogEntityType.WORKFLOW:
      return (
        <a
          rel="noreferrer"
          target="_blank"
          href={WORKFLOW_CONFIGURE_URL(log.entityId)}
        >
          {log.logName}
        </a>
      );
    case AuditLogEntityType.SCHEDULED_JOB:
      return (
        <a
          rel="noreferrer"
          target="_blank"
          href={SCHEDULED_JOB_CONFIGURE_URL(log.entityId)}
        >
          {log.logName}
        </a>
      );
  }
  return <></>;
};

const getExecutionTime = (log: AuditLogTableRow): string => {
  if (!isApiRunDetails(log.details) || !log.details?.endTime) {
    return "-";
  }
  const duration = moment.duration(
    moment(log.details?.endTime).diff(log.startTime),
  );
  return `${duration.as("milliseconds")}ms`;
};

const LogTable = ({
  filteredLogs,
  colVisibility,
}: {
  filteredLogs: AuditLogTableRow[];
  colVisibility: Record<string, boolean>;
}) => {
  const columnKeys = useMemo(() => {
    return Object.keys(colVisibility).filter((key) => colVisibility[key]);
  }, [colVisibility]);

  const getColumns = useMemo(() => {
    return columns.filter((column) => {
      return columnKeys.includes((column.key as string) ?? "");
    });
  }, [columnKeys]);

  return (
    <Table
      data-test="audit-logs-table"
      rowKey={(record) => {
        const row = record as AuditLogTableRow;
        return row.entityId + row.startTime.toString();
      }}
      dataSource={filteredLogs}
      columns={getColumns}
      bordered={false}
      scroll={{ x: 1000 }}
      locale={{
        emptyText: "No audit logs found for your query",
      }}
      pagination={{
        defaultPageSize: 20,
        pageSizeOptions: ["20", "50", "100"],
      }}
    />
  );
};

export default LogTable;
