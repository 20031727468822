import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import JsonView from "components/ui/JsonView";
import HighlightedCode from "legacy/components/editorComponents/HighlightedCode";
import LazyThemeProvider from "styles/LazyThemeProvider";
import { getDataType } from "./getDataType";
import { TypeInfoDocType } from "./types";

// This component is meant to be rendered via a root render call
// Do not use context or redux in this component
const ACWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CodeComponent = (
  props: React.PropsWithChildren<{
    className?: string;
  }>,
) => {
  const { children } = props;
  const codeText: string = useMemo(() => {
    if (typeof children === "object") {
      return (Array.isArray(children) ? children : [children])
        .map((child) => (typeof child === "object" ? child?.value : child))
        .join("");
    }
    return String(children);
  }, [children]);
  return <HighlightedCode codeText={codeText} />;
};

export const showAutocompleteHint = (params: {
  "!doc"?: TypeInfoDocType;
  "!type"?: string;
}): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { "!doc": doc, "!type": _type } = params;
  return !!doc;
};

type Props = {
  doc?: TypeInfoDocType;
  type?: string;
};

const AutocompleteHint = (props: Props) => {
  const { doc, type } = props;

  const expanded = doc?.expanded;
  const funcDef =
    getDataType(type ?? "?") === "FUNCTION"
      ? expanded?.customFnDef ?? type
      : undefined;
  const evalData = doc?.value;
  const docString = doc?.docString;
  const markdownDoc = doc?.expanded?.markdownDoc;

  if (!docString && !markdownDoc && !funcDef && evalData == null) {
    return null;
  }

  return (
    <LazyThemeProvider>
      <ACWrapper>
        {docString || markdownDoc ? <h5>DEFINITION</h5> : null}
        {docString && (
          <>
            <div>{docString}</div>
            <br />
          </>
        )}
        {markdownDoc && (
          <ReactMarkdown
            components={{
              code: CodeComponent,
            }}
          >
            {markdownDoc}
          </ReactMarkdown>
        )}
        {funcDef && (
          <>
            <h5>FUNCTION</h5>
            <code>{funcDef}</code>
          </>
        )}
        {!funcDef && evalData != null && (
          <>
            <h5>VALUE</h5>
            <JsonView
              data={evalData}
              maxStringLength={20}
              maxArrayLength={100}
              width={300}
              maxHeight={300}
            />
          </>
        )}
      </ACWrapper>
    </LazyThemeProvider>
  );
};

export default AutocompleteHint;
