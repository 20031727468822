import { MenuItem, Classes, Menu } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  MultiSelect,
  ItemRendererProps,
  Classes as MultiSelectClasses,
  ItemListRendererProps,
} from "@blueprintjs/select";
import { DropdownOption } from "@superblocksteam/shared";
import Fuse from "fuse.js";
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { FixedSizeList } from "react-window";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import { useElementRect } from "hooks/ui";
import { BlueprintInputTransform } from "legacy/constants/DefaultTheme";
import { LegacyNamedColors } from "legacy/constants/LegacyNamedColors";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { Checkbox } from "./Checkbox";
import { SearchInput } from "./SearchSection";

const ITEM_HEIGHT = 32;
const MAX_RENDER_MENU_ITEMS_HEIGHT = 292;
const EXTRA_SPACE_PER_ITEM = 2;
const DIVIDER_SPACE = 3;

const StyledChevronDown = styled(ChevronDown)<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "")};
  position: absolute;
  right: 8px;
  & path {
    fill: ${({ theme, $isOpen }) =>
      $isOpen ? theme.colors.ACCENT_BLUE_500 : theme.colors.GREY_300};
    stroke: ${({ theme, $isOpen }) =>
      $isOpen ? theme.colors.ACCENT_BLUE_500 : theme.colors.GREY_300};
    stroke-width: 0.5px;
  }
`;

const MultiDropDown = MultiSelect.ofType<DropdownOption>();

const StyledMultiDropDown = styled(MultiDropDown)<{
  $isOpen: boolean;
  $hideTags: boolean; // show customized selected items instead of default tags
}>`
  color: ${colors.GREY_700};
  div {
    flex: 1 1 auto;
    .${MultiSelectClasses.MULTISELECT} {
      position: relative;
      min-width: 0;
      min-height: 0;
    }
  }

  // Negative margin
  margin-right: 1px;

  && {
    ${BlueprintInputTransform}
    .${Classes.TAG_INPUT}::-webkit-scrollbar {
      display: none;
    }
    .${Classes.TAG_INPUT} {
      font-size: 12px;
      border-radius: 4px !important;
      display: flex;
      width: 100%;
      min-width: 0;
      align-items: center;
      justify-content: space-between;
      text-overflow: ellipsis;
      overflow: auto;
      padding-left: 3px;
      border-color: ${({ theme, $isOpen }) =>
        $isOpen
          ? theme.colors.ACCENT_BLUE_500
          : theme.colors.GREY_100} !important;
      min-height: 32px;

      .${Classes.TAG_INPUT_VALUES} {
        margin-right: 28px;
        margin-top: 2px;
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        scrollbar-width: none;
        // not using display none because click event is registered on input values
        ${({ $hideTags }) => ($hideTags ? "opacity: 0;" : "")}
      }

      .${Classes.TAG} {
        background: none;
        border: 1px solid ${(props) => props.theme.colors.GREY_100};
        border-radius: 3px;
        height: 24px;
        font-size: 12px;
        color: ${(props) => props.theme.colors.GREY_700};
        padding: 4px 8px;
        margin-bottom: 3px;
        margin-right: 3px;
        svg[data-icon="small-cross"] path {
          fill: ${(props) => props.theme.colors.GREY_300};
        }
        ${({ $hideTags }) => ($hideTags ? "display: none;" : "")}
      }

      & > .${Classes.ICON} {
        align-self: center;
        margin-right: 0px;
        color: ${LegacyNamedColors.SLATE_GRAY};
      }
      .${Classes.INPUT_GHOST}:first-child {
        padding-left: 9px;
      }
      .${Classes.INPUT_GHOST} {
        margin: 0;
        display: flex;
        height: 26px;
        flex: 1;
        padding-left: 5px;
      }
    }
  }
`;

const OptionTextWrapper = styleAsClass`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SELECT_ALL_IDENTIFIER = "dropdown-multi-select-item-all";
const SelectAllOption = {
  displayName: "All",
  value: SELECT_ALL_IDENTIFIER,
  key: SELECT_ALL_IDENTIFIER,
};

export const RecommendedMultiDropdown = ({
  options,
  placeholder,
  disabled,
  selectedItems,
  dataTest,
  parentRef,
  style,
  onChange,
  disableSearch,
  resetOnQuery,
  resetOnSelect,
  showSearchInPopover,
  renderSelectedItems,
  width,
  enableSelectAll,
}: {
  options?: DropdownOption[];
  placeholder?: string;
  // Must be a controlled value
  selectedItems: DropdownOption[];
  disabled?: boolean;
  dataTest?: string;
  onChange: (options: DropdownOption[]) => void;
  parentRef?: React.RefObject<HTMLElement>;
  style?: React.CSSProperties;
  disableSearch?: boolean;
  resetOnSelect?: boolean;
  resetOnQuery?: boolean;
  showSearchInPopover?: boolean;
  renderSelectedItems?: (selectedItems: DropdownOption[]) => JSX.Element;
  width?: number;
  enableSelectAll?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRect = useElementRect(wrapperRef);

  const [query, setQuery] = useState("");
  const optionWithAll = useMemo(
    () =>
      enableSelectAll && options ? [SelectAllOption, ...options] : options,
    [enableSelectAll, options],
  );

  const isAllSelected = selectedItems?.length === options?.length;
  const nonSelected = selectedItems?.length === 0;

  const [isOpen, setIsOpen] = React.useState(false);

  const handlePopoverInteraction = (nextOpenState: boolean) => {
    setIsOpen(nextOpenState);
  };

  const renderTag = (option: DropdownOption) => {
    return option.displayName;
  };

  const isOptionSelected = useCallback(
    (selectedOption: DropdownOption) =>
      selectedItems.find((item) => item.value === selectedOption.value) !==
      undefined,
    [selectedItems],
  );

  const fuse = useMemo(() => {
    const fuse = new Fuse(optionWithAll ?? [], {
      shouldSort: true,
      threshold: 0.3,
      ignoreLocation: true,
      minMatchCharLength: 1,
      findAllMatches: true,
      keys: ["displayName", "value"],
    });
    return fuse;
  }, [optionWithAll]);

  const itemListPredicate = useCallback(
    (query: string, items: DropdownOption[]) => {
      if (disableSearch) {
        return items;
      }
      const filteredItems = query
        ? fuse.search(query).map(({ item }) => item)
        : items;
      return filteredItems;
    },
    [disableSearch, fuse],
  );

  const renderMultiSelectItem = useCallback(
    (option: DropdownOption, itemProps: ItemRendererProps) => {
      if (!itemProps.modifiers.matchesPredicate) {
        return null;
      }
      const isSelected: boolean = isOptionSelected(option);
      return (
        <>
          <MenuItem
            className={`multi-select ${isSelected ? "selected" : ""}`}
            active={itemProps.modifiers.active}
            key={option.value}
            text={
              <div
                data-test={`dropdown-multi-select-item-${option.displayName}`}
              >
                <Checkbox
                  checked={
                    isSelected ||
                    (option.value === SELECT_ALL_IDENTIFIER && isAllSelected)
                  }
                  partialChecked={
                    option.value === SELECT_ALL_IDENTIFIER &&
                    !isAllSelected &&
                    !nonSelected
                  }
                  style={{ padding: "6px 12px 6px 12px" }}
                >
                  <div title={option.displayName} className={OptionTextWrapper}>
                    {option.displayName ?? ""}
                  </div>
                </Checkbox>
              </div>
            }
            onClick={itemProps.handleClick}
          />
          {option.value === SELECT_ALL_IDENTIFIER && (
            <div
              style={{
                borderTop: `1px solid ${colors.GREY_50}`,
                margin: "2px 0 0 -4px",
                width: "calc(100% + 8px)",
              }}
            ></div>
          )}
        </>
      );
    },
    [isAllSelected, isOptionSelected, nonSelected],
  );

  const itemListRenderer = useCallback(
    ({
      filteredItems,
      itemsParentRef,
      renderItem,
    }: ItemListRendererProps<DropdownOption>) => {
      let itemsToRender: DropdownOption[] = filteredItems;

      if (!filteredItems?.length) {
        const noResultsMessage = "No results found";
        itemsToRender = [
          {
            displayName: noResultsMessage,
            value: noResultsMessage,
            key: noResultsMessage,
          },
        ];
      }
      const menuStyle: React.CSSProperties = {
        width: wrapperRect?.width ?? "100%",
        padding: "2px 0",
      };

      return (
        <div>
          {/* a search section if the selectedItems are rendered in the place of input */}
          {renderSelectedItems && showSearchInPopover && (
            <div style={{ padding: 5, marginRight: -15, width: "100%" }}>
              <SearchInput
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          )}
          <Menu ulRef={itemsParentRef} style={menuStyle}>
            <FixedSizeList
              itemData={{
                renderItem,
                items: itemsToRender,
              }}
              height={Math.min(
                MAX_RENDER_MENU_ITEMS_HEIGHT,
                itemsToRender.length * (ITEM_HEIGHT + EXTRA_SPACE_PER_ITEM) +
                  (enableSelectAll
                    ? EXTRA_SPACE_PER_ITEM + DIVIDER_SPACE
                    : EXTRA_SPACE_PER_ITEM),
              )}
              itemCount={itemsToRender.length}
              itemSize={ITEM_HEIGHT + EXTRA_SPACE_PER_ITEM}
              width="100%"
            >
              {({ index, data, style }) => {
                return (
                  <div
                    key={data.items[index].value}
                    style={{
                      ...style,
                      padding: "0px 4px",
                      top:
                        data.items[index].value === SELECT_ALL_IDENTIFIER
                          ? -DIVIDER_SPACE
                          : style.top,
                      height: `${ITEM_HEIGHT}px`,
                      margin: `${EXTRA_SPACE_PER_ITEM}px 0px`,
                    }}
                  >
                    {renderItem(data.items[index], index)}
                  </div>
                );
              }}
            </FixedSizeList>
          </Menu>
        </div>
      );
    },
    [
      enableSelectAll,
      query,
      renderSelectedItems,
      showSearchInPopover,
      wrapperRect?.width,
    ],
  );

  // used to adjust active item when a new item is selected (otherwise, resetOnSelect will cause the active item to be the first one after selection)
  const itemJustSelected = useRef<DropdownOption | null>(null);

  const onItemSelect = (selectedItem: DropdownOption): void => {
    if (selectedItem.value === SELECT_ALL_IDENTIFIER) {
      if (selectedItems.length === options?.length) {
        onChange([]);
      } else {
        onChange(options ?? []);
      }
      resetOnSelect && setQuery("");

      itemJustSelected.current = selectedItem;
      return;
    }
    if (
      selectedItems.find((item) => item.value === selectedItem.value) !==
      undefined
    ) {
      onChange(
        selectedItems.filter((item) => item.value !== selectedItem.value),
      );
    } else {
      onChange([...selectedItems, selectedItem]);
    }

    itemJustSelected.current = selectedItem;
    resetOnSelect && setQuery("");
  };

  const onItemRemoved = (_tag: ReactNode, indexRemoved: number) => {
    const newItems = selectedItems.filter(
      (item, index) => index !== indexRemoved,
    );
    onChange(newItems);
  };

  const [activeItem, setActiveItem] = useState<DropdownOption | null>(null);
  const onActiveItemChange = useCallback(
    (activeItem: DropdownOption | null) => {
      if (itemJustSelected.current) {
        setActiveItem(itemJustSelected.current);
        itemJustSelected.current = null;
      } else {
        setActiveItem(activeItem);
      }
    },
    [itemJustSelected],
  );

  return (
    <div ref={wrapperRef} style={style}>
      <div style={{ position: "relative", ...(width ? { width } : {}) }}>
        <StyledMultiDropDown
          $isOpen={isOpen}
          $hideTags={Boolean(renderSelectedItems)}
          items={optionWithAll ?? []}
          scrollToActiveItem={false}
          query={query}
          onQueryChange={setQuery}
          resetOnSelect={resetOnSelect ?? true}
          resetOnQuery={resetOnQuery ?? false}
          itemListPredicate={itemListPredicate}
          placeholder={placeholder}
          tagRenderer={renderTag}
          itemRenderer={renderMultiSelectItem}
          itemsEqual={"value"}
          selectedItems={selectedItems}
          itemListRenderer={itemListRenderer}
          tagInputProps={{
            onRemove: onItemRemoved,
            tagProps: (value, index) => ({
              minimal: true,
              interactive: false,
              rightIcon: IconNames.CHEVRON_DOWN,
              "data-test": `${dataTest}-${value}`,
            }),
            disabled: disabled,
            fill: true,
            rightElement: renderSelectedItems ? (
              <div
                style={{
                  position: "absolute",
                  left: "10px",
                  right: 0,
                  display: "flex",
                }}
              >
                {renderSelectedItems(selectedItems)}
                <StyledChevronDown
                  $isOpen={isOpen}
                  data-test="dropdown-toggle-icon"
                />
              </div>
            ) : (
              <StyledChevronDown
                $isOpen={isOpen}
                data-test="dropdown-toggle-icon"
              />
            ),
            inputProps: {
              type: "search",
              "data-test": dataTest,
            } as any,
          }}
          onItemSelect={onItemSelect}
          popoverProps={{
            // TODO(wylie): this type was removed from Popover2, confirm behavior
            // fill: true,
            minimal: true,
            popoverClassName: "select-popover-wrapper",
            // Allow dropdown to overflow its container and placed based on viewport
            rootBoundary: "viewport",
            hasBackdrop: true,
            usePortal: true,
            portalContainer: parentRef?.current ?? undefined,
            onInteraction: handlePopoverInteraction,
          }}
          activeItem={activeItem}
          onActiveItemChange={onActiveItemChange}
        />
      </div>
    </div>
  );
};
