import { createAction } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import { EventDefinition } from "legacy/constants/EventConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import { showItemPropertyPane } from "./propertyPaneActions";

export const createEvent = createAction(
  "CREATE_CUSTOM_EVENT",
  (id?: string, name?: string, open?: boolean) => ({
    payload: {
      id,
      name,
      open,
    },
  }),
);

export function createAndOpenEvent(id?: string, name?: string) {
  return createEvent(id, name, true);
}

export const deleteCustomEvent = createAction(
  "DELETE_CUSTOM_EVENT",
  (id: EventDefinition["id"]) => ({
    payload: { id },
  }),
);

export const duplicateCustomEvent = createAction(
  "DUPLICATE_CUSTOM_EVENT",
  (eventId: EventDefinition["id"]) => ({
    payload: { eventId },
  }),
);

export const updateCustomEvent = createAction(
  "UPDATE_CUSTOM_EVENT",
  (
    payload: Record<
      EventDefinition["id"],
      Partial<Pick<EventDefinition, "arguments" | "onTrigger">>
    >,
  ) => ({
    payload,
  }),
);

export const setCreatingCustomEvent = createAction(
  "SET_CREATING_CUSTOM_EVENT",
  (payload: boolean) => ({ payload }),
);

export const editEventPropertyPane = (
  eventId: string,
  scope: ApplicationScope,
) => showItemPropertyPane({ kind: ItemKinds.CUSTOM_EVENT, id: eventId, scope });
