import { EnvStore } from "@superblocksteam/shared";

const envs = new EnvStore(window._env_);

envs.addAll([
  {
    name: "SUPERBLOCKS_UI_ENV",
  },
  {
    name: "SUPERBLOCKS_UI_REGION",
    defaultValue: "us",
  },
  // TODO: new app related api call will use SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL instead, keep this for backward compatibility, can remove once api url base deployment is done.
  {
    name: "SUPERBLOCKS_UI_API_BASE_URL",
    defaultValue: "/api/",
  },
  {
    name: "SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL",
    defaultValue: "/api/",
  },
  {
    name: "SUPERBLOCKS_UI_SOCKET_ENDPOINT_BASE_URL",
    defaultValue: "/api/",
  },
  {
    // the name AGENT_BASE_URL is a bit misleading - this points to the orchestrator
    name: "SUPERBLOCKS_UI_AGENT_BASE_URL",
    defaultValue: "/orchestrator/",
  },
  {
    name: "SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL",
    defaultValue: "/opa/",
  },
  {
    name: "SUPERBLOCKS_UI_DATA_DOMAIN",
    defaultValue: "app.superblocks.com",
  },
  {
    name: "SUPERBLOCKS_UI_PROBE_AGENT_TIMEOUT_MS",
    defaultValue: "5000",
  },
  {
    name: "SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS",
    defaultValue: "500000",
  },
  {
    name: "SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS",
    defaultValue: "30000",
  },
  {
    name: "SUPERBLOCKS_UI_SAVE_LAYOUT_TIMEOUT_MS",
    defaultValue: "30000",
  },
  {
    name: "SUPERBLOCKS_UI_PERSIST_API_DEBOUNCE_MS",
    defaultValue: "500",
  },
  {
    name: "SUPERBLOCKS_UI_WHITELIST_IPS",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_GOOGLE_MAPS_API_KEY",
  },
  {
    name: "SUPERBLOCKS_UI_DEMO_VIDEO_URL",
    defaultValue:
      "https://player.vimeo.com/video/656415917?h=d8547c2b82&autoplay=1&byline=0&portrait=0",
  },
  {
    name: "SUPERBLOCKS_AGENT_DEPLOYMENT_DOCKER_URL",
    defaultValue:
      "https://raw.githubusercontent.com/superblocksteam/agent/main/docker/v2.compose.yaml",
  },
  {
    name: "SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS",
    defaultValue: "60000",
  },
  // Telemetry
  {
    name: "SUPERBLOCKS_UI_DATADOG_CLIENT_TOKEN",
  },
  {
    name: "SUPERBLOCKS_UI_DATADOG_APPLICATION_ID",
  },
  {
    name: "SUPERBLOCKS_UI_GA_MEASUREMENT_ID",
  },
  // Auth
  {
    name: "SUPERBLOCKS_UI_AUTH_AUDIENCE",
    defaultValue: "https://superblocks/api",
  },
  {
    name: "SUPERBLOCKS_UI_AUTH_ISSUER_URL",
  },
  {
    name: "SUPERBLOCKS_UI_AUTH_CLIENT_ID",
  },
  {
    name: "SUPERBLOCKS_UI_ASSET_URL",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_ROCKSET_INTEGRATION_KEY",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_VERSION",
  },
  {
    name: "SUPERBLOCKS_UI_INTERCOM_APPID",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_ONBOARDING_SURVEY_URL",
    defaultValue:
      "https://superblocks-marketing-assets.s3.us-west-2.amazonaws.com/onboarding-survey/survey-prod.json",
  },
  {
    name: "SUPERBLOCKS_UI_ONBOARDING_SURVEY_URL_EXISTING_ORG",
    defaultValue:
      "https://superblocks-marketing-assets.s3.us-west-2.amazonaws.com/onboarding-survey/survey-existing-org-prod.json",
  },
  {
    name: "SUPERBLOCKS_UI_ONBOARDING_SURVEY_SKIP_ON_DEV",
    defaultValue: "true",
  },
  {
    name: "SUPERBLOCKS_UI_ENABLE_OBSERVABILITY",
    defaultValue: "true",
  },
  {
    name: "SUPERBLOCKS_UI_STRIPE_KEY",
  },
  {
    name: "SUPERBLOCKS_UI_PRODUCTHUNT_URL",
    defaultValue: "https://www.producthunt.com/posts/superblocks",
  },
  {
    name: "SUPERBLOCKS_UI_PRODUCTHUNT_AVATAR_URL",
    defaultValue:
      "https://superblocks-marketing-assets.s3.us-west-2.amazonaws.com/superblocks-logo.mp4",
  },
  {
    name: "SUPERBLOCKS_UI_DATADOG_PROXY_URL",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_DATADOG_RUM_SAMPLE_RATE",
    defaultValue: "100",
  },
  {
    name: "SUPERBLOCKS_UI_DATADOG_SITE",
    defaultValue: "datadoghq.com",
  },
  {
    name: "SUPERBLOCKS_UI_USE_EVAL_WITH_PROXY_ORGS",
    defaultValue: "*",
  },
  {
    name: "SUPERBLOCKS_UI_USE_REC_EVAL_ORGS",
    defaultValue: "*",
  },
  // Launch Darkly (feature flags)
  {
    name: "SUPERBLOCKS_UI_LAUNCH_DARKLY_CLIENT_KEY",
  },
  // Algolia (search)
  {
    name: "SUPERBLOCKS_UI_ALGOLIA_APP_ID",
  },
  {
    name: "SUPERBLOCKS_UI_ALGOLIA_SEARCH_KEY",
  },
  {
    name: "SUPERBLOCKS_UI_ALGOLIA_INDEX_NAME",
  },
  {
    name: "SUPERBLOCKS_UI_GLOBAL_CACHE_URL",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_IFRAME_URL",
    defaultValue: "http://localhost:3001/",
  },
  {
    name: "SUPERBLOCKS_UI_AI_URL",
    defaultValue: "http://localhost:10200/api/",
  },
  {
    name: "SUPERBLOCKS_ENABLE_REPOSITORIES",
    defaultValue: "true",
  },
  // For file uploading service
  {
    name: "SUPERBLOCKS_UI_BUCKETEER_URL",
    defaultValue: "https://bucketeer.dev.superblocks.com",
  },
  {
    name: "SUPERBLOCKS_UI_GITHUB_CLIENT_ID",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_GITHUB_APP_SLUG",
    defaultValue: "",
  },
  {
    name: "SUPERBLOCKS_UI_MATERIAL_ICON_CDN_PATH",
    defaultValue:
      "https://staging-cdn.superblocks.com/icons/material-400/nofill",
  },
  {
    name: "SUPERBLOCKS_UI_GOOGLE_SHEETS_CLIENT_ID",
  },
  {
    name: "SUPERBLOCKS_UI_PUB_KEY_RSA",
  },
  {
    name: "SUPERBLOCKS_UI_PUB_KEY_ED25519",
  },
]);

export const SUPERBLOCKS_UI_PROBE_AGENT_TIMEOUT_MS = envs.get(
  "SUPERBLOCKS_UI_PROBE_AGENT_TIMEOUT_MS",
);

export const SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS = envs.get(
  "SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS",
);

export const SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS = envs.get(
  "SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS",
);

export const SUPERBLOCKS_UI_SAVE_LAYOUT_TIMEOUT_MS = envs.get(
  "SUPERBLOCKS_UI_SAVE_LAYOUT_TIMEOUT_MS",
);

export const SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS = envs.get(
  "SUPERBLOCKS_UI_POLL_AGENT_INTERVAL_MS",
);

export const SUPERBLOCKS_UI_ENABLE_OBSERVABILITY =
  envs.get("SUPERBLOCKS_UI_ENABLE_OBSERVABILITY") === "true";

export const SUPERBLOCKS_UI_AGENT_BASE_URL = envs.get(
  "SUPERBLOCKS_UI_AGENT_BASE_URL",
);

export const SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL = envs.get(
  "SUPERBLOCKS_UI_CLOUD_OPA_BASE_URL",
);

export const SUPERBLOCKS_UI_DATA_DOMAIN = envs.get(
  "SUPERBLOCKS_UI_DATA_DOMAIN",
);

export const SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL = envs.get(
  "SUPERBLOCKS_UI_API_ENDPOINT_BASE_URL",
);

export const SUPERBLOCKS_UI_SOCKET_ENDPOINT_BASE_URL = envs.get(
  "SUPERBLOCKS_UI_SOCKET_ENDPOINT_BASE_URL",
);

export const SUPERBLOCKS_UI_AI_URL = envs.get("SUPERBLOCKS_UI_AI_URL");

export const SUPERBLOCKS_UI_BUCKETEER_URL = envs.get(
  "SUPERBLOCKS_UI_BUCKETEER_URL",
);

export const SUPERBLOCKS_UI_GITHUB_CLIENT_ID = envs.get(
  "SUPERBLOCKS_UI_GITHUB_CLIENT_ID",
);

export const SUPERBLOCKS_UI_GITHUB_APP_SLUG = envs.get(
  "SUPERBLOCKS_UI_GITHUB_APP_SLUG",
);

export const SUPERBLOCKS_UI_MATERIAL_ICON_CDN_PATH = envs.get(
  "SUPERBLOCKS_UI_MATERIAL_ICON_CDN_PATH",
);

// This is the Client ID for the Superblocks Google Sheets integration.
export const SUPERBLOCKS_UI_GOOGLE_SHEETS_CLIENT_ID = envs.get(
  "SUPERBLOCKS_UI_GOOGLE_SHEETS_CLIENT_ID",
);

export default envs;
