import { DownOutlined } from "@ant-design/icons";
import { BillingPlan, SUPERBLOCKS_ORG_NAME } from "@superblocksteam/shared";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { Logo } from "components/app/Header/logo";
import Link from "components/ui/Link";
import TextButton from "components/ui/TextButton";
import { openPricingModal } from "legacy/actions/modalActions";
import { HOME_URL, LOGOUT_URL } from "legacy/constants/routes";
import { ANONYMOUS_USERNAME } from "legacy/constants/userConstants";
import {
  getCurrentUser,
  getIsSuperUser,
} from "legacy/selectors/usersSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import PlanAndUsageModal from "pages/Home/PlanAndUsageModal";
import { useAppDispatch } from "store/helpers";
import { selectFlags } from "store/slices/featureFlags";
import {
  selectOrganizations,
  selectOnlyOrganization,
} from "store/slices/organizations";
import { AgentDisasterHeader } from "./AgentDisasterHeader";
import OrgSwitcher from "./OrgSwitcher";
import OrgWarningBanner from "./OrgWarningBanner";

const LayoutHeader = styled(Layout.Header)<{ $showWarningInHeader: boolean }>`
  padding: 0 6px;
  height: ${(props) => props.theme.legacy.headerHeight};
  line-height: ${(props) => props.theme.legacy.headerHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.$showWarningInHeader
      ? props.theme.colors.DANGER
      : props.theme.colors.GREY_900};
`;

export const TrialBanner = styled.div<{ trialExpired: boolean }>`
  ${({ trialExpired }) =>
    trialExpired
      ? css`
          background: linear-gradient(90deg, #ff5c87 0%, #ff4760 100%);
        `
      : css`
          background: linear-gradient(90deg, #47a9ff 0%, #7c4ff8 100%);
        `}

  height: ${({ theme }) => theme.legacy.freeTrialBannerHeight};
  min-height: ${({ theme }) => theme.legacy.freeTrialBannerHeight};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 12px;
    font-weight: 500;
    cursor: pointer;
  }
`;

const LogoWrapper = styled.span`
  font-size: 16px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.OFF_WHITE};
  display: flex;
`;

const SuperblocksText = styled.div`
  margin-left: 16px;
`;

const StyledDownArrow = styled(DownOutlined)`
  color: ${(props) => props.theme.colors.WHITE};
  margin-left: 10px;
`;

const UserSettingsMenuWrapper = styled.div`
  padding: 0 10px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.GREY_800};
  }
`;

const StyledSettingsMenu = styled(Menu)`
  width: 240px;
  padding: 0 0 4px 0;
  border-radius: 4px;

  > li.ant-dropdown-menu-item-divider {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .ant-dropdown-menu-item {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.GREY_700};
    font-size: 12px;
  }
  .ant-dropdown-menu-item-active {
    color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  }
`;

const StyledNameDiv = styled.div`
  font-size: 12px;
  padding: 8px 14px 4px 14px;
`;

const StyledRightSection = styled.div`
  display: flex;
  align-items: center;
`;

const OrgWarningText = styled.div`
  display: flex;
  color: white;
  background-color: ${(props) => props.theme.colors.DANGER};
  padding: 0 10px;
`;

const Header = React.memo(() => {
  const user = useSelector(getCurrentUser);
  const organizations = useSelector(selectOrganizations);
  const organization = Object.values(organizations)[0];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // This should not be here for long.
  const [currentPlanModalVisible, setCurrentPlanModalVisible] = useState(false);
  const showPlanAndUsage = useMemo(() => {
    return user?.isAdmin && organization?.billing?.plan !== "TRIAL";
  }, [organization?.billing?.plan, user?.isAdmin]);

  const handleLogout = useCallback(
    () => navigate({ pathname: LOGOUT_URL }),
    [navigate],
  );
  const isSuperUser = useSelector(getIsSuperUser);
  const currentOrg = useSelector(selectOnlyOrganization);
  const isInCustomerOrg =
    isSuperUser && currentOrg?.name !== SUPERBLOCKS_ORG_NAME;

  const flags = useSelector(selectFlags);
  return (
    <>
      {organization.billing.plan === BillingPlan.TRIAL && (
        <TrialBanner trialExpired={organization.billing.isExpired}>
          {organization.billing.isExpired
            ? "Your Superblocks trial has expired"
            : `You have ${organization.billing.daysLeft} days left in your trial`}
          <TextButton
            onClick={() => {
              dispatch(openPricingModal());
            }}
          >
            Upgrade Now
          </TextButton>
        </TrialBanner>
      )}
      <LayoutHeader $showWarningInHeader={isInCustomerOrg}>
        <Link data-test="app-home" to={HOME_URL}>
          <LogoWrapper>
            <Logo />
            <SuperblocksText>Superblocks</SuperblocksText>
          </LogoWrapper>
        </Link>
        {isInCustomerOrg && (
          <OrgWarningText>
            {`You are not in org ${SUPERBLOCKS_ORG_NAME}. Current org is:`}
            <b>&nbsp;{currentOrg.name}</b>
          </OrgWarningText>
        )}
        <StyledRightSection>
          {isSuperUser && <OrgSwitcher />}
          {isSuperUser && (
            <Dropdown
              overlay={
                <StyledSettingsMenu
                  selectable={false}
                  className={CLASS_NAMES.DROPDOWN_MENU}
                >
                  <StyledNameDiv>
                    <strong>{user?.name}</strong>
                  </StyledNameDiv>
                  <Menu.Divider />
                  {showPlanAndUsage && (
                    <Menu.Item
                      key="plan-and-usage"
                      onClick={() => {
                        setCurrentPlanModalVisible(true);
                      }}
                    >
                      Plan and Usage
                    </Menu.Item>
                  )}
                  <Menu.Item key="logout" onClick={() => handleLogout()}>
                    Logout
                  </Menu.Item>
                  {isSuperUser && flags && (
                    <>
                      <Menu.Divider />
                      <Menu.SubMenu title="Feature Flags">
                        <div
                          style={{
                            maxHeight: 500,
                            maxWidth: 500,
                            overflow: "auto",
                            padding: "0px 8px",
                            fontSize: "12px",
                          }}
                        >
                          {Object.entries(flags).map(([k, v]) => (
                            <div key={k}>
                              {k}={String(v)}
                            </div>
                          ))}
                        </div>
                      </Menu.SubMenu>
                    </>
                  )}
                </StyledSettingsMenu>
              }
              trigger={["click"]}
            >
              <UserSettingsMenuWrapper>
                <Avatar
                  size={24}
                  icon={
                    user?.name[0].toUpperCase() ??
                    ANONYMOUS_USERNAME[0].toUpperCase()
                  }
                />
                <StyledDownArrow />
              </UserSettingsMenuWrapper>
            </Dropdown>
          )}
        </StyledRightSection>
      </LayoutHeader>
      <AgentDisasterHeader />
      <OrgWarningBanner />
      <PlanAndUsageModal
        isVisible={currentPlanModalVisible}
        setVisible={setCurrentPlanModalVisible}
      />
    </>
  );
});

Header.displayName = "Header";

export default Header;
