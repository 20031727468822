import { Modal } from "antd";
import React from "react";
import { ReactComponent as ErrorCircleIcon } from "assets/icons/common/error-circle.svg";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { DangerButton, SecondaryButton } from "./Button";

export const ModalWrapClass = styleAsClass`
  .ant-modal-content{
    font-size: 13px;
    input {
      font-size: 13px;
    }
  }
  .ant-modal-header {
    padding: 14px 12px;
  }
  .ant-modal-body {
    padding: 16px;
`;

const ModalTitle = styleAsClass`
  font-size: 15px;
  font-weight: 600;
  color: ${colors.GREY_900};
  padding: 14px 12px;
`;

export const ModalInnerWrapper = styleAsClass`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;
export const FooterWrapper = styleAsClass`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
`;

export const FooterWrapperWide = styleAsClass`
  margin-top: 20px;
  display: grid;
  justify-content: space-between;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
`;

const ModalTitleWrapper = styleAsClass`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: -6px 0px;
  
`;

const DeleteMessage = styleAsClass`
  font-size: 13px !important;
  line-height: 20px;
  color: ${colors.GREY_500};
  margin-bottom: 20px;
  display: block;
  b {
    font-weight: 500;
  }
`;

const ErrorWrapper = styleAsClass`
  color: ${colors.RED_500};
`;

export const DeleteModal = ({
  open,
  title,
  children,
  onDelete,
  onCancel,
  isDeleting,
  error,
  dataTestName,
  confirmText = "Delete",
  disableDelete,
  width,
}: {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onCancel: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  error?: string;
  dataTestName?: string;
  confirmText?: string;
  disableDelete?: boolean;
  width?: number;
}) => {
  return (
    <Modal
      width={width}
      wrapClassName={ModalWrapClass}
      open={open}
      onCancel={onCancel}
      footer={null}
      title={
        <div className={ModalTitleWrapper}>
          <ErrorCircleIcon />
          <div className={ModalTitle}>{title}</div>
        </div>
      }
      destroyOnClose={true}
    >
      <div>
        <div className={`${DeleteMessage}`}>{children}</div>
        {error && <div className={ErrorWrapper}>{error}</div>}
        <div className={FooterWrapperWide}>
          <SecondaryButton
            onClick={onCancel}
            loading={isDeleting}
            data-test={`cancel-delete-${dataTestName ?? ""}`}
          >
            Cancel
          </SecondaryButton>
          <DangerButton
            onClick={onDelete}
            loading={isDeleting}
            data-test={`confirm-delete-${dataTestName ?? ""}`}
            disabled={disableDelete}
          >
            {confirmText}
          </DangerButton>
        </div>
      </div>
    </Modal>
  );
};
