import { ScheduleState } from "@superblocksteam/shared";
import { Button, Dropdown, Menu, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as AppIcon } from "assets/icons/home/app.svg";
import { ReactComponent as EditIcon } from "assets/icons/home/edit.svg";
import { ReactComponent as JobIcon } from "assets/icons/home/job.svg";
import { ReactComponent as MoreIcon } from "assets/icons/home/more.svg";
import { ReactComponent as WorkflowIcon } from "assets/icons/home/workflow.svg";
import Link from "components/ui/Link";
import { FullWidthSpace } from "components/ui/Space";
import { colors } from "styles/colors";
import { EntityType, getEntityLabel } from "utils/entity";

export const EmptyCard = styled(FullWidthSpace)`
  height: 50px;
  border-radius: 10px;
  justify-content: center;
`;

// An Entity is an App, Workflow, or Scheduled Job. This is a common interface
// that can wrap all of them.
export interface Entity {
  id: string;
  name?: string;
  isDeployed: boolean;
  isEditable: boolean;
  type: EntityType;
  updated?: Date;
  editUrl?: string;
  deployUrl?: string;
  scheduleState?: ScheduleState;
  folderId?: string | null;
  canDelete?: boolean;
}

export const CARD_HEIGHT = 112;
export const CARD_MIN_WIDTH = 360;
export const MAX_CARDS_PER_ROW = 6;
export const CARD_SPACING = 24;
const CARD_INNER_WIDTH = 150;
const ICON_WIDTH = 72;

export type DropdownAction = {
  label: string;
  action: (entity: Entity) => void;
  isDanger?: boolean;
  disabled?: boolean;
};

export const IconWrapper = styled.div<{ color?: string; size?: number }>`
  background-color: ${(props) => props.color};
  svg {
    color: ${(props) => props.theme.colors.WHITE};
    path {
      fill: ${(props) => props.theme.colors.WHITE};
    }
  }
  width: ${(props) => props.size ?? ICON_WIDTH}px;
  height: ${(props) => props.size ?? ICON_WIDTH}px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoWrapper = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: start;
  align-self: flex-start;
  min-width: ${CARD_INNER_WIDTH}px;

  .entity-type {
    font-size: 13px;
    line-height: 20px;
    color: ${(props) => props.color};
    margin-bottom: ${(props) => props.theme.margins.tiny};
  }

  .aux-info {
    font-size: 13px;
    line-height: 16px;
    padding-right: 20px;
    color: ${(props) => props.theme.colors.GREY_500};
  }
`;

const EntityName = styled(Typography.Text)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.GREY_900};
`;

const ActionWrapper = styled.div`
  position: absolute;
  right: 1em;
  height: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;

  .ant-btn {
    box-shadow: 0 0 6px 6px ${({ theme }) => theme.colors.GREY_25};
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.colors.GREY_100};

    svg path {
      transition: fill 0.2s ease-in-out;
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.ACCENT_BLUE_500};

      svg path {
        transition: fill 0.2s ease-in-out;
        fill: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
      }
    }
  }

  > * {
    margin: 4px 0;
  }
`;

type EntityCardProps = {
  entity: Entity;
  dropdownActions: DropdownAction[];
  updatePauseResume?: (apiId: string, newState: ScheduleState) => void;
};

export const getIconForType = (entityType: string) => {
  switch (entityType) {
    case EntityType.APPLICATION:
      return <AppIcon />;
    case EntityType.WORKFLOW:
      return <WorkflowIcon />;
    case EntityType.SCHEDULED_JOB:
      return <JobIcon />;
  }
};

export const getColorForType = (entityType: string) => {
  switch (entityType) {
    case EntityType.APPLICATION:
      return colors.ACCENT_BLUE_500;
    case EntityType.WORKFLOW:
      return colors.ACCENT_GREEN;
    case EntityType.SCHEDULED_JOB:
      return colors.ACCENT_PURPLE;
  }
};

const StyledCard = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.GREY_100};
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.paddings.medium};
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${CARD_HEIGHT}px;
  padding: ${({ theme }) => theme.paddings.medium};

  &:hover,
  &:focus-within,
  &.force-hover {
    background-color: ${({ theme }) => theme.colors.GREY_25};
  }

  .action-wrapper {
    display: flex;
    visibility: hidden;
  }

  &:hover .action-wrapper,
  &:focus-within .action-wrapper,
  &.force-hover .action-wrapper {
    visibility: visible;
    display: flex;
  }

  .entity-link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .aux-info {
    visibility: hidden;
  }

  &:hover .aux-info,
  &:focus-within .aux-info,
  &.force-hover .aux-info {
    visibility: visible;
  }

  .main {
    position: absolute;
    display: block;
    z-index: 1;
    height: ${CARD_HEIGHT}px;
  }

  .bp5-icon {
    color: ${({ theme }) => theme.colors.GREY_400};
  }
`;

const EntityCard = memo(
  ({ entity, dropdownActions, updatePauseResume }: EntityCardProps) => {
    const [stickyHovered, setStickyHovered] = useState(false);

    const deployedState = useMemo(() => {
      if (entity.scheduleState !== undefined) {
        return entity.scheduleState === ScheduleState.ACTIVE
          ? "Active"
          : "Paused";
      } else {
        return entity.isDeployed ? "Deployed" : "Not Deployed";
      }
    }, [entity]);

    const onFocus = useCallback(() => setStickyHovered(true), []);
    const onBlur = useCallback(() => setStickyHovered(false), []);

    return (
      <StyledCard
        className={stickyHovered ? "force-hover" : ""}
        onFocus={onFocus}
        onBlur={onBlur}
        data-test={`entity-card-${entity.name}`}
        tabIndex={0}
      >
        <Link
          to={entity.deployUrl ?? entity.editUrl ?? ""}
          className="entity-link"
          // Using aria-label since the link is focusable but the text is separate
          aria-label={entity.name}
          data-test={`entity-card-${entity.type}`}
        >
          <span className="main"></span>
        </Link>

        <IconWrapper color={getColorForType(entity.type)}>
          {getIconForType(entity.type)}
        </IconWrapper>

        <InfoWrapper color={getColorForType(entity.type)}>
          <EntityName ellipsis strong>
            {entity.name}
          </EntityName>

          <Typography.Text strong className="entity-type" type="secondary">
            {getEntityLabel(entity.type)}
          </Typography.Text>

          <Typography.Text type="secondary" className="aux-info">
            {deployedState}
            &nbsp;&bull;&nbsp;
            {entity.updated && "Updated " + moment(entity.updated).fromNow()}
          </Typography.Text>
        </InfoWrapper>

        <ActionWrapper className="action-wrapper">
          {entity.editUrl && entity.type === EntityType.APPLICATION && (
            <Link
              to={entity.isEditable ? entity.editUrl : "#"}
              data-test={`edit-app-${entity.name}`}
            >
              <Tooltip
                title={
                  !entity.isEditable
                    ? "You do not have the permission to edit this application"
                    : undefined
                }
              >
                <Button disabled={!entity.isEditable} className="edit-button">
                  <EditIcon />
                </Button>
              </Tooltip>
            </Link>
          )}
          {entity.scheduleState !== undefined && updatePauseResume && (
            <Button
              className="edit-button"
              data-test="pause-resume-button"
              onClick={(e) => {
                e.stopPropagation();
                updatePauseResume(
                  entity.id,
                  entity.scheduleState === ScheduleState.ACTIVE
                    ? ScheduleState.PAUSED
                    : ScheduleState.ACTIVE,
                );
              }}
            >
              {entity.scheduleState === ScheduleState.ACTIVE
                ? "Pause"
                : "Resume"}
            </Button>
          )}
          <Dropdown
            overlay={
              <Menu onClick={() => setStickyHovered(false)}>
                {dropdownActions.map((dropdownAction) => (
                  <Menu.Item
                    key={dropdownAction.label}
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      dropdownAction.action(entity);
                    }}
                    danger={dropdownAction.isDanger}
                    disabled={dropdownAction.disabled}
                    data-test={`entity-card-dropdown-action-${dropdownAction.label}`}
                  >
                    {dropdownAction.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["click"]}
            disabled={!entity.isEditable}
            onOpenChange={(visible) => {
              setStickyHovered(visible);
            }}
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              disabled={!entity.isEditable}
              className="more-button"
              data-test={`entity-card-dropdown-button-${entity.type}`}
            >
              <MoreIcon />
            </Button>
          </Dropdown>
        </ActionWrapper>
      </StyledCard>
    );
  },
);

EntityCard.displayName = "EntityCard";

export default EntityCard;
