import {
  FormComponentType,
  FormItem,
  NotImplementedError,
} from "@superblocksteam/shared";
import { Typography } from "antd";
import React, { useContext, useState } from "react";
import { getSyntaxForPlugin } from "code-formatting/utils";
import {
  EditorModes,
  EditorSize,
  EditorTheme,
  TabBehaviour,
} from "components/app/CodeEditor/EditorConfig";
import { useFeatureFlag } from "hooks/ui";
import { Flag } from "store/slices/featureFlags";
import { getEntityACShortcutString } from "utils/navigator";
import { EditorLanguage } from "../CodeEditor/types";
import { FormContext, FormContextType } from "./FormContext";
import { ActionDropdown } from "./components/ActionDropdown";
import DynamicFormAlert from "./components/DynamicFormAlert";
import DynamicFormButton from "./components/DynamicFormButton";
import DynamicFormCheckbox from "./components/DynamicFormCheckbox";
import { DynamicFormCodeEditor } from "./components/DynamicFormCodeEditor";
import { DynamicFormDropdown } from "./components/DynamicFormDropdown";
import DynamicFormFieldList from "./components/DynamicFormFieldList";
import DynamicFormFieldListForm from "./components/DynamicFormFieldListForm";
import { DynamicFormFilterColumns } from "./components/DynamicFormFilterColumns";
import DynamicFormInputText from "./components/DynamicFormInputText";
import DynamicFormInputWithMetadataOptions from "./components/DynamicFormInputWithMetadataOptions";
import { DynamicFormKeyMapping } from "./components/DynamicFormKeyMapping";
import DynamicFormMetadataDropdown from "./components/DynamicFormMetadataDropdown";
import { DynamicFormPrimaryKeyDisplay } from "./components/DynamicFormPrimaryKeyDisplay";
import DynamicFormRadio from "./components/DynamicFormRadio";
import DynamicFormSwitch from "./components/DynamicFormSwitch";
import { DynamicSqlPreview } from "./components/DynamicSqlPreview";
import { DynamicSqlPreview as DynamicSqlPreviewWithInsertDelete } from "./components/DynamicSqlPreviewWithInsertDelete";
import { OpenApiActionDropdown } from "./components/OpenApiActionDropdown";
import { UrlInputText } from "./components/UrlInputText";

const EDITOR_MODE_LANGUAGE: Record<EditorLanguage, EditorModes> = {
  [EditorLanguage.TEXT]: EditorModes.TEXT,
  [EditorLanguage.TEXT_BINDING]: EditorModes.TEXT_WITH_BINDING,
  [EditorLanguage.SQL]: EditorModes.SQL_WITH_BINDING,
  [EditorLanguage.SQL_BINDING]: EditorModes.SQL_WITH_BINDING,
  [EditorLanguage.JSON]: EditorModes.JSON_WITH_BINDING,
  [EditorLanguage.JSON_BINDING]: EditorModes.JSON_WITH_BINDING,
  [EditorLanguage.JAVASCRIPT]: EditorModes.JAVASCRIPT,
  [EditorLanguage.PYTHON]: EditorModes.PYTHON,
};

type Props = {
  formItem: FormItem;
  wrapperRef?: React.RefObject<HTMLElement>;
  readOnly?: boolean;
  defaultValues: any;
  datasourceId: string;
  configurationId?: string;
  pluginId: string;
  setFormError: (error: string) => void;
  isNew?: boolean;
  onPasswordVisibilityChange?: (fieldName: string, visible: boolean) => void;
  actionId?: string;
  apiId?: string;
};

type ItemComponentProps = {
  onFocus: () => void;
  onBlur: () => void;
  context: FormContextType;
  isCodeFormattingEnabled: boolean;
} & Props;

function getItemComponent({
  formItem,
  wrapperRef,
  onFocus,
  onBlur,
  readOnly,
  datasourceId,
  configurationId,
  pluginId,
  setFormError,
  isNew,
  context,
  defaultValues,
  onPasswordVisibilityChange,
  actionId,
  apiId,
  isCodeFormattingEnabled,
}: ItemComponentProps): React.ReactElement {
  switch (formItem.componentType) {
    case FormComponentType.INPUT_TEXT:
      return (
        <DynamicFormInputText
          dataType={formItem.dataType}
          placeholder={formItem.placeholder}
          disabled={Boolean(formItem.disabled) || readOnly}
          initialValue={formItem.initialValue as string}
          path={formItem.name}
          label={formItem.label}
          rules={formItem.rules}
          tooltip={formItem.tooltip}
          subHeading={formItem.subHeading}
          singleLine={formItem.singleLine}
          hidden={formItem.hidden}
          minNumber={formItem.minNumber}
          subtitle={formItem.subtitle}
          enableCopy={formItem.enableCopy}
          initialValueFromEnv={formItem.initialValueFromEnv}
          onPasswordVisibilityChange={onPasswordVisibilityChange}
        />
      );
    case FormComponentType.DYNAMIC_INPUT_TEXT:
      return (
        <DynamicFormCodeEditor
          data-test={`dynamic-text-${formItem.name}`}
          showLineNumbers={false}
          mode={EditorModes.TEXT_WITH_BINDING}
          key={EditorModes.TEXT_WITH_BINDING}
          tabBehaviour={TabBehaviour.INPUT}
          disabled={Boolean(formItem.disabled) || readOnly}
          theme={EditorTheme.LIGHT}
          size={EditorSize.EXTENDED}
          monospace={true}
          minHeight={formItem.style?.minHeight ?? "28px"}
          maxHeight="350px"
          placeholder={formItem.placeholder}
          subtitle={formItem.subtitle}
          onEditorFocus={onFocus}
          onEditorBlur={onBlur}
          path={formItem.name}
          label={formItem.label}
          rules={formItem.rules}
          tooltip={formItem.tooltip}
          subHeading={formItem.subHeading}
          datasourceId={datasourceId}
          autoFocus={false}
          showHideIcon={formItem.showHideIcon}
          onPasswordVisibilityChange={onPasswordVisibilityChange}
        />
      );
    case FormComponentType.INPUT_AREA:
      throw new NotImplementedError("not implemented");
    case FormComponentType.CODE_EDITOR: {
      const mode =
        EDITOR_MODE_LANGUAGE[formItem.language ?? EditorLanguage.TEXT_BINDING];
      const syntax = getSyntaxForPlugin(pluginId);

      return (
        <DynamicFormCodeEditor
          data-test={`code-${formItem.name}`}
          showLineNumbers={true}
          mode={mode}
          syntax={syntax}
          key={mode}
          tabBehaviour={TabBehaviour.INDENT}
          disabled={Boolean(formItem.disabled) || readOnly}
          theme={EditorTheme.LIGHT}
          size={EditorSize.EXTENDED}
          monospace={true}
          borderLess={isCodeFormattingEnabled ? false : true}
          placeholder={formItem.placeholder}
          wrapperRef={wrapperRef}
          path={formItem.name}
          label={formItem.label}
          rules={formItem.rules}
          tooltip={formItem.tooltip}
          datasourceId={datasourceId}
          subHeading={formItem.subHeading}
          actionId={actionId}
          apiId={apiId}
          autoScroll={true}
          formatCodeOnShortcut={true}
        />
      );
    }
    case FormComponentType.FIELD_LIST: {
      return (
        <DynamicFormFieldList
          path={formItem.name}
          label={formItem.label}
          tooltip={formItem.tooltip}
          readOnly={readOnly}
          collapseValue={formItem.collapseValue}
          initialValue={formItem.initialValue}
        />
      );
    }
    case FormComponentType.FIELD_LIST_FORM: {
      return (
        <DynamicFormFieldListForm
          path={formItem.name}
          label={formItem.label}
          tooltip={formItem.tooltip}
          readOnly={readOnly}
        />
      );
    }
    case FormComponentType.DYNAMIC_FIELD_LIST: {
      // Will be removed alongside REST API Integration refactor
      throw new NotImplementedError("not implemented");
    }
    case FormComponentType.CHECKBOX:
      return (
        <DynamicFormCheckbox
          path={formItem.name}
          label={formItem.label}
          tooltip={formItem.tooltip}
          disabled={readOnly || formItem.disabled}
          readOnly={readOnly || formItem.disabled}
          rules={formItem.rules}
          mapBooleansTo={formItem.mapBooleansTo}
          validateReduxPath={formItem.validateReduxPath}
          integrationId={datasourceId}
          configurationId={configurationId}
          hidden={formItem.hidden}
        />
      );
    case FormComponentType.RADIO: {
      return (
        <DynamicFormRadio
          path={formItem.name}
          label={formItem.label}
          immutable={formItem.immutable}
          initialValue={formItem.initialValue}
          options={formItem.options}
          data-test={`radio-${formItem.name}`}
          isNew={isNew}
          disabled={formItem.disabled || readOnly}
        />
      );
    }
    case FormComponentType.DROPDOWN:
      return (
        <DynamicFormDropdown
          options={formItem.options}
          path={formItem.name}
          label={formItem.label}
          immutable={formItem.immutable}
          initialValue={formItem.initialValue as string}
          tooltip={formItem.tooltip}
          subHeading={formItem.subHeading}
          disabled={Boolean(formItem.disabled || readOnly)}
          hidden={formItem.hidden}
          data-test={`dropdown-${formItem.name}`}
          isNew={isNew}
          showSearch={formItem.showSearch ?? false}
          optionFilterProp={formItem.optionFilterProp ?? undefined}
          subtitle={formItem.subtitle}
          renderSelectedOptionWithStyles={
            formItem.renderSelectedOptionWithStyles
          }
        />
      );
    case FormComponentType.DYNAMIC_DROPDOWN:
      throw new NotImplementedError("not implemented");
    case FormComponentType.METADATA_DROPDOWN: {
      return (
        <DynamicFormMetadataDropdown
          path={formItem.name}
          datasourceId={datasourceId}
          label={formItem.label}
          initialValue={formItem.initialValue}
          tooltip={formItem.tooltip}
          subHeading={formItem.subHeading}
          disabled={Boolean(formItem.disabled || readOnly)}
          data-test={`dropdown-${formItem.name}`}
          rules={formItem.rules}
          dependencyPath={formItem.dependencyFieldName}
          setFormError={setFormError}
          showSearch={formItem.showSearch ?? true}
          optionFilterProp={formItem.optionFilterProp ?? "label"}
          keyAccessor={formItem.keyAccessor}
          valueAccessor={formItem.valueAccessor}
          displayNameAccessor={formItem.displayNameAccessor}
          listAccessor={formItem.listAccessor}
          defaultToFirstOption={formItem.defaultToFirstOption}
          clearDependentFieldsOnChange={formItem.clearDependentFieldsOnChange}
          filterDependency={formItem.filterDependency}
          filterFieldName={formItem.filterFieldName}
          enableGSheetsPagination={formItem.gSheetsPagination}
        />
      );
    }
    case FormComponentType.DYNAMIC_INPUT_WITH_METADATA_OPTIONS: {
      return (
        <DynamicFormInputWithMetadataOptions
          path={formItem.name}
          datasourceId={datasourceId}
          label={formItem.label}
          initialValue={formItem.initialValue}
          tooltip={formItem.tooltip}
          subHeading={formItem.subHeading}
          disabled={Boolean(formItem.disabled || readOnly)}
          data-test={`input-with-options-${formItem.name}`}
          rules={formItem.rules}
          dependencyPath={formItem.dependencyFieldName}
          setFormError={setFormError}
          optionFilterProp={formItem.optionFilterProp ?? "label"}
          valueAccessor={formItem.valueAccessor}
          listAccessor={formItem.listAccessor}
          defaultToFirstOption={formItem.defaultToFirstOption}
          clearDependentFieldsOnChange={formItem.clearDependentFieldsOnChange}
          filterDependency={formItem.filterDependency}
          filterFieldName={formItem.filterFieldName}
          onEditorFocus={onFocus}
          onEditorBlur={onBlur}
          placeHolder={formItem.placeholder}
          style={formItem.style}
          subtitle={formItem.subtitle}
        />
      );
    }
    case FormComponentType.SWITCH:
      return (
        <DynamicFormSwitch
          path={formItem.name}
          label={formItem.label}
          tooltip={formItem.tooltip}
          renderIconFirst={formItem.renderIconFirst}
        />
      );
    case FormComponentType.ALERT: {
      return (
        <DynamicFormAlert
          type={formItem.type}
          showIcon={formItem.showIcon}
          messageTemplate={formItem.messageTemplate as string}
          path={formItem.name}
          label={formItem.label}
          integrationId={datasourceId}
          configurationId={configurationId}
        />
      );
    }
    case FormComponentType.BUTTON: {
      return (
        <DynamicFormButton
          path={formItem.name}
          label={formItem.label}
          iconUrl={formItem.iconUrl}
          integrationId={datasourceId}
          configurationId={configurationId}
          disabled={formItem.disabled || readOnly}
          getValueFromContext={(path: string) => {
            return context.getValue(path) ?? defaultValues[path];
          }}
          buttonType={formItem.buttonType}
          valuesFromContext={formItem.valuesFromContext}
          extraValues={formItem.extraValues}
          dependencies={formItem.dependencies}
          subscribe={context.subscribe}
        />
      );
    }
    case FormComponentType.PRIMARY_KEY_DISPLAY: {
      return (
        <DynamicFormPrimaryKeyDisplay
          datasourceId={datasourceId}
          path={formItem.name}
          label={formItem.label}
        />
      );
    }
    case FormComponentType.KEY_MAPPING: {
      return (
        <DynamicFormKeyMapping
          datasourceId={datasourceId}
          path={formItem.name}
          label={formItem.label}
        />
      );
    }
    case FormComponentType.FILTER_COLUMNS: {
      return (
        <DynamicFormFilterColumns
          datasourceId={datasourceId}
          path={formItem.name}
          label={formItem.label}
          rules={formItem.rules}
        />
      );
    }
    case FormComponentType.SQL_PREVIEW: {
      return <DynamicSqlPreview path={formItem.name} label={formItem.label} />;
    }
    case FormComponentType.SQL_PREVIEW_WITH_INSERT_DELETE: {
      return (
        <DynamicSqlPreviewWithInsertDelete
          path={formItem.name}
          label={formItem.label}
        />
      );
    }
    case FormComponentType.OPENAPI_ACTION_DROPDOWN: {
      return (
        <OpenApiActionDropdown
          path={formItem.name}
          label={formItem.label}
          integrationId={datasourceId}
          initialValue={formItem.initialValue}
          pluginId={pluginId}
          disabled={readOnly}
        />
      );
    }
    case FormComponentType.ONEOF_ACTION_DROPDOWN: {
      return (
        <ActionDropdown
          path={formItem.name}
          secondaryPath={formItem.secondaryName ?? ""}
          label={formItem.label}
          integrationId={datasourceId}
          initialValue={formItem.initialValue}
          secondaryInitialValue={formItem.secondaryInitialValue}
          actionOptions={formItem.actionOptions ?? []}
          pluginId={pluginId}
        />
      );
    }
    case FormComponentType.URL_INPUT_TEXT: {
      return (
        <UrlInputText
          data-test={`dynamic-text-${formItem.name}`}
          showLineNumbers={false}
          mode={EditorModes.TEXT_WITH_BINDING}
          key={EditorModes.TEXT_WITH_BINDING}
          tabBehaviour={TabBehaviour.INDENT}
          disabled={Boolean(formItem.disabled) || readOnly}
          theme={EditorTheme.LIGHT}
          size={EditorSize.EXTENDED}
          monospace={true}
          minHeight={formItem.style?.minHeight ?? "28px"}
          maxHeight="350px"
          placeholder={formItem.placeholder}
          subtitle={formItem.subtitle}
          onEditorFocus={onFocus}
          onEditorBlur={onBlur}
          path={formItem.name}
          label={formItem.label}
          rules={formItem.rules}
          tooltip={formItem.tooltip}
          subHeading={formItem.subHeading}
          integrationId={datasourceId}
          autoFocus={false}
        />
      );
    }
    default: {
      const exhaustiveCheck: never = formItem;
      throw new Error(
        `Unhandled form case: ${JSON.stringify(exhaustiveCheck)}`,
      );
    }
  }
}

export function TemplatedDynamicFormItem({
  formItem,
  wrapperRef,
  readOnly,
  defaultValues,
  datasourceId,
  configurationId,
  pluginId,
  setFormError,
  isNew,
  apiId,
  actionId,
  onPasswordVisibilityChange,
}: Props) {
  const [showEntityACReminder, setShowEntityACReminder] = useState(false);
  const context = useContext(FormContext);
  const isCodeFormattingEnabled = useFeatureFlag(Flag.CODE_FORMATTING);
  const disableACReminder = true;
  return (
    <>
      {showEntityACReminder && !disableACReminder && (
        <Typography.Text
          type="secondary"
          style={{ float: "right" }}
        >{`${getEntityACShortcutString()} for variables`}</Typography.Text>
      )}
      {getItemComponent({
        formItem,
        wrapperRef,
        onFocus: () => setShowEntityACReminder(true),
        onBlur: () => setShowEntityACReminder(false),
        readOnly: readOnly,
        defaultValues: defaultValues,
        datasourceId: datasourceId,
        configurationId: configurationId,
        pluginId: pluginId,
        setFormError: setFormError,
        context: context,
        isNew: isNew,
        onPasswordVisibilityChange,
        apiId,
        actionId,
        isCodeFormattingEnabled,
      })}
    </>
  );
}
