import {
  DropdownOption,
  GENERIC_HTTP_REQUEST,
  HttpMethod,
  IntegrationAuthType,
  KVPair,
  RestApiBodyDataType,
  RestApiFields,
  getMethodColor,
} from "@superblocksteam/shared";
import { Divider, Typography } from "antd";
import { isEmpty } from "lodash";
import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { RecommendedSingleDropdown } from "components/ui/RecommendedSingleDropdown";
import { Spinner } from "components/ui/Spinner";
import { useSaga } from "hooks/store";
import {
  OpenAPIMethods,
  fillMethodPrefixWithSpaces,
  getDetailsFromRequest,
  getIsOpenAPIV2,
  getParamsByType,
} from "pages/Integrations/openApiUtils";
import { useAppSelector } from "store/helpers";
import {
  getOpenApiSpecSaga,
  selectDatasourceMetaById,
  selectOpenApiRefByDatasourceId,
} from "store/slices/datasources";
import { getPluginById } from "utils/integrations";
import { colors } from "../../../../styles/colors";
import { DynamicFormItemProps, StyledReactMarkdown } from "../DynamicFormItem";
import { FormContext } from "../FormContext";
import { FormLabel } from "../FormLabel";
import { FormText } from "../FormText";
import { FormTooltip } from "../FormTooltip";
import { ruleParser } from "../utils";
interface DropdownProps {
  showSearch?: boolean;
  optionFilterProp?: string;
  ["data-test"]?: string;
  integrationId: string;
  pluginId?: string;
}

type Props = DropdownProps & DynamicFormItemProps;

const AutoFillMaxSizeInBytes = 20 * 1024;

// These headers need to be lower case.
// They are the list of header values that we shouldn't auto-populate unless
// they have a default value.
const HEADERS_REQUIRE_DEFAULTS_TO_AUTOPOPULATE = [
  "content-type",
  "accept",
  "user-agent",
];

export const OpenApiActionDropdown = ({
  path,
  label,
  placeholder,
  initialValue,
  loading,
  rules,
  isNew,
  immutable,
  integrationId,
  pluginId,
  ...otherProps
}: Props) => {
  const context = useContext(FormContext);

  const [getOpenApiSpec] = useSaga(getOpenApiSpecSaga);
  const [openApiSpecLoading, setOpenApiSpecLoading] = useState(false);

  //options from meta data
  const datasourceMeta = useAppSelector((state) =>
    selectDatasourceMetaById(state, integrationId),
  );

  const pluginOpenApiSpecRef = getPluginById(pluginId)?.openApiSpecRef;
  const integrationOpenApiSpecRef = useAppSelector((state) =>
    selectOpenApiRefByDatasourceId(state, integrationId),
  );
  const openApiSpecRef = pluginOpenApiSpecRef ?? integrationOpenApiSpecRef;

  const openApiSpec = datasourceMeta?.metadata?.openApiSpec;

  useEffect(() => {
    (async () => {
      if (openApiSpecRef && !openApiSpec) {
        // load openapi url
        try {
          setOpenApiSpecLoading(true);
          await getOpenApiSpec({
            integrationId,
            openApiSpecRef: openApiSpecRef as string,
          });
          setOpenApiSpecLoading(false);
        } catch (e) {
          // the error should already be catched in saga
          console.error(e);
        } finally {
          setOpenApiSpecLoading(false);
        }
      }
    })();
  }, [integrationId, getOpenApiSpec, openApiSpec, openApiSpecRef]);

  const [value, setValue] = useState<string>(initialValue as string);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    const unsubscribe = context.subscribe(path, (value) => {
      setValue(value as string); //update UI
    });
    return () => unsubscribe();
  }, [path, context.subscribe, context]);

  useEffect(() => {
    if (rules) {
      ruleParser(path, rules, context.registerValidation, (value) => {
        setValidationMessage(value);
      });
    }
    return () => {
      context.unregisterValidation(path);
    };
  }, [
    path,
    rules,
    context,
    context.registerValidation,
    context.unregisterValidation,
  ]);

  const [options] = useMemo(() => {
    const isOpenApiSpecLoaded = datasourceMeta?.metadata?.openApiSpec?.paths;
    let options: DropdownOption[] = isOpenApiSpecLoaded
      ? (Object.entries(datasourceMeta?.metadata?.openApiSpec?.paths)
          .flatMap(([path, methods]) => {
            return Object.entries(methods as any).map(([method, methodObj]) => {
              if (method === "parameters") {
                return undefined;
              }
              if (!OpenAPIMethods.includes(method)) {
                return undefined;
              }
              method = method.toUpperCase();
              return {
                displayName: `${(methodObj as any)?.summary ?? path}`,
                value: `${method} ${path}`,
                key: `${method} ${path}`,
                prefixText: fillMethodPrefixWithSpaces(method),
                prefixColor: getMethodColor(method as HttpMethod),
                prefixWidth: 50,
              };
            });
          })
          .filter(Boolean) as DropdownOption[])
      : [];

    // generate an single option to display the current value when openApi spec is not fully loaded.
    const [method, actionPath] = value?.split(" ") ?? [undefined, undefined];
    const valueOption = {
      displayName: actionPath,
      value: `${method} ${actionPath}`,
      key: `${method} ${actionPath}`,
      prefixText: fillMethodPrefixWithSpaces(method),
      prefixColor: getMethodColor(method as HttpMethod),
      prefixWidth: 50,
    };

    options = [
      {
        key: "basicActions",
        value: "basicActions",
        displayName: "Basic Actions",
        isGroupHeader: true,
      },
      {
        key: GENERIC_HTTP_REQUEST,
        value: GENERIC_HTTP_REQUEST,
        displayName: "Generic HTTP Request",
      },
      ...(value === GENERIC_HTTP_REQUEST && !isOpenApiSpecLoaded
        ? []
        : [
            {
              key: "apiEndpoints",
              value: "apiEndpoints",
              displayName: "API Endpoints",
              isGroupHeader: true,
            },
          ]),
      ...(isOpenApiSpecLoaded
        ? options
        : value && value !== GENERIC_HTTP_REQUEST
        ? [valueOption]
        : []),
    ];

    let isActionInOpenApi = true;
    if (value && isOpenApiSpecLoaded) {
      isActionInOpenApi = options.some((option) => option.value === value);
      if (!isActionInOpenApi && value !== GENERIC_HTTP_REQUEST) {
        context.onChange(path, GENERIC_HTTP_REQUEST);
      }
    }
    return [options];
  }, [context, datasourceMeta?.metadata?.openApiSpec?.paths, path, value]);

  const onChange = useCallback(
    (val: DropdownOption) => {
      const isOpenAPIV2 = getIsOpenAPIV2(openApiSpec);
      context.onChange(path, val.value); // when user updates the value
      // do not update other fields if the value is GENERIC_HTTP_REQUEST
      if (val.value === GENERIC_HTTP_REQUEST) return;
      const [method, urlPath] = val.value.split(" ");
      const urlBase = context.getValue(RestApiFields.URL_BASE);

      if (method && urlPath) {
        context.onChange(RestApiFields.HTTP_METHOD, method);
        context.onChange(
          RestApiFields.URL_PATH,
          (urlBase as string)?.endsWith("/") && urlPath?.startsWith("/")
            ? urlPath.substring(1)
            : urlPath,
        );
      }
      // headers
      const defaultHeaders =
        (context.getValue(RestApiFields.HEADERS) as KVPair[])?.filter(
          (param: any) => param.editable === false || param.required,
        ) ?? [];

      const hasNoDefaultAuthHeader = defaultHeaders.every(
        (header) => header.key?.toLocaleLowerCase() !== "authorization",
      );

      const hasNoIntegrationAuth =
        isEmpty(context.getValue(RestApiFields.AUTH_TYPE)) ||
        context.getValue(RestApiFields.AUTH_TYPE) === IntegrationAuthType.NONE;

      const specParameters = openApiSpec?.paths?.[urlPath]?.[
        method.toLocaleLowerCase()
      ]?.parameters?.filter((param: any) => param && param.required);

      const headers = specParameters?.filter((header: any) => {
        const headerNameLowerCased = header?.name?.toLocaleLowerCase();
        if (headerNameLowerCased === "authorization") {
          return hasNoIntegrationAuth && hasNoDefaultAuthHeader;
        } else {
          if (
            HEADERS_REQUIRE_DEFAULTS_TO_AUTOPOPULATE.includes(
              headerNameLowerCased,
            )
          ) {
            return !!header?.schema?.default;
          } else {
            return true;
          }
        }
      });

      const headerPairs = getParamsByType({
        params: [...(headers ?? [])],
        openApiSpec,
        type: "header",
      })?.map((param: any) => ({
        key: param.name,
        value: param.schema?.default ?? "",
        system:
          HEADERS_REQUIRE_DEFAULTS_TO_AUTOPOPULATE.includes(
            param.name?.toLowerCase(),
          ) && !!param.schema?.default,
      }));

      context.onChange(RestApiFields.HEADERS, [
        ...(defaultHeaders ?? []),
        ...(headerPairs ?? []),
      ]);

      //query params
      const defaultParams = (
        context.getValue(RestApiFields.PARAMS) as KVPair[]
      )?.filter((param: any) => param.editable === false);

      const queryParamPairs = getParamsByType({
        params: [...(specParameters ?? [])],
        openApiSpec,
        type: "query",
      })?.map((param: any) => ({
        key: param.name,
        value: "",
      }));

      context.onChange(RestApiFields.PARAMS, [
        ...(defaultParams ?? []),
        ...(queryParamPairs ?? []),
      ]);

      //requestBody
      const currentBodyType = context.getValue(RestApiFields.BODY_TYPE);
      const actionDetails =
        openApiSpec?.paths?.[urlPath]?.[method.toLocaleLowerCase()];

      const {
        body: requestBody,
        bodyType,
        exampleString: requestExample,
      } = getDetailsFromRequest({
        actionDetails,
        isOpenAPIV2,
      });

      if (!requestBody) {
        if (currentBodyType !== RestApiBodyDataType.JSON)
          context.onChange(RestApiFields.BODY_TYPE, RestApiBodyDataType.JSON);
        if (context.getValue(RestApiFields.BODY) !== undefined)
          context.onChange(RestApiFields.BODY, "");
      } else if (bodyType === "application/json") {
        if (currentBodyType !== RestApiBodyDataType.JSON)
          context.onChange(RestApiFields.BODY_TYPE, RestApiBodyDataType.JSON);

        if (requestExample && requestExample.length < AutoFillMaxSizeInBytes) {
          // if the example is too large do not auto fill
          context.onChange(RestApiFields.BODY, requestExample);
        } else {
          context.onChange(RestApiFields.BODY, "");
        }
      } else if (bodyType === "application/x-www-form-urlencoded") {
        context.onChange(RestApiFields.BODY_TYPE, RestApiBodyDataType.FORM);
      }
      // TODO add more support for other request body types for auto fill
    },
    [context, path, openApiSpec],
  );

  return (
    <div>
      <label>
        <FormLabel hidden={otherProps.hidden}>
          {label}&nbsp;&nbsp;
          {openApiSpecLoading && (
            <FormText>
              <Spinner size="small" spinning={true} /> &nbsp; Loading OpenAPI
              data...
            </FormText>
          )}
        </FormLabel>
        <FormTooltip tooltip={otherProps.tooltip} />
        <RecommendedSingleDropdown
          data-test={otherProps["data-test"]}
          value={isEmpty(value) ? GENERIC_HTTP_REQUEST : value}
          onChange={onChange}
          options={options ?? []}
          renderSelectedOptionWithStyles={true}
          disabled={otherProps.disabled || (!isNew && immutable)}
        />
      </label>
      <Typography.Text type="danger">{validationMessage}</Typography.Text>
      <div style={{ color: colors.GREY_400 }}>
        <StyledReactMarkdown linkTarget="_blank">
          {otherProps.subtitle ?? ""}
        </StyledReactMarkdown>
      </div>
      <Divider style={{ marginTop: 18, marginBottom: 10 }} />
    </div>
  );
};
