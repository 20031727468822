import {
  ApiTriggerType,
  ApplicationUserStatus,
  DefaultGroupName,
  EntityPermissionsDto,
  PermissionEntity,
  PermissionEntityType,
  PermissionedEntities,
  RbacRole,
  ShareEntryDto,
  isGroupShareEntry,
  isUserShareEntry,
} from "@superblocksteam/shared";
import { GroupEntityToRender } from "pages/Groups/constants";

type PageType = "entities" | "integrations";

export const getResourceType = (entityType: PermissionEntityType) => {
  switch (entityType) {
    case "application":
      return PermissionedEntities.APPLICATION_V2;
    default:
      return PermissionedEntities.API_V3;
  }
};

export const isValidPageType = (
  pageType: string | undefined,
): pageType is PageType => {
  if (!pageType) return false;
  return ["entities", "integrations"].includes(pageType);
};

export type EntityToRender = {
  id: string;
  name: string;
  type: PermissionEntityType;
  owner: string;
  ownerId: string;
  builders: string[];
  viewers?: string[];
  configurators?: string[];
};

const priority = (entry: ShareEntryDto) => {
  if (isGroupShareEntry(entry) && entry.name === DefaultGroupName.EVERYONE) {
    return 0;
  }
  if (
    isUserShareEntry(entry) &&
    entry.status === ApplicationUserStatus.EDITOR_PENDING_JOIN
  ) {
    return 2;
  }
  return 1;
};

const sortEntityPermissionEntries = (a: ShareEntryDto, b: ShareEntryDto) => {
  if (priority(a) === priority(b)) {
    return (a.name ?? "").localeCompare(b.name ?? "");
  }
  return priority(a) - priority(b);
};

export const convertToEntityToRender = (
  entity: EntityPermissionsDto,
): EntityToRender => {
  const owner = entity.permissions.find(
    (permission) => permission.role === "owner",
  );
  return {
    id: entity.entity.id,
    name: entity.entity.name,
    type: entity.entity.type,
    owner: owner?.name ?? "",
    ownerId: owner?.actorId ?? "",
    builders: entity.permissions
      .filter((permission) => permission.role === "builder")
      .sort(sortEntityPermissionEntries)
      .map((permission) => permission.name),
    viewers: entity.permissions
      .filter((permission) => permission.role === "viewer")
      .sort(sortEntityPermissionEntries)
      .map((permission) => permission.name),
    configurators: entity.permissions
      .filter((permission) => permission.role === "configurator")
      .sort(sortEntityPermissionEntries)
      .map((permission) => permission.name),
  };
};

// used by components outside permission page
export const getVersionedPermissionedEntityType = (
  resourceType: PermissionedEntities,
) => {
  switch (resourceType) {
    case PermissionedEntities.APPLICATION_V2:
    case PermissionedEntities.APPLICATION:
      return PermissionedEntities.APPLICATION_V2;
    case PermissionedEntities.DATASOURCE:
      return PermissionedEntities.DATASOURCE;
    case PermissionedEntities.INTEGRATION:
      return PermissionedEntities.INTEGRATION;
    default:
      return PermissionedEntities.API_V3;
  }
};

export const getPermissionedEntitiesType = (type: PermissionEntityType) => {
  switch (type) {
    case PermissionEntityType.APPLICATION:
      return PermissionedEntities.APPLICATION_V2;
    case PermissionEntityType.DATASOURCE:
      return PermissionedEntities.DATASOURCE;
    case PermissionEntityType.INTEGRATION:
      return PermissionedEntities.INTEGRATION;
    default:
      return PermissionedEntities.API_V3;
  }
};

export const getApiTriggerTypeFromPermissionEntity = (
  permissionEntity: PermissionEntity,
): ApiTriggerType | null => {
  switch (permissionEntity.type) {
    case PermissionEntityType.APPLICATION:
    case PermissionEntityType.APPLICATION_V2:
      return ApiTriggerType.UI;
    case PermissionEntityType.SCHEDULED_JOB:
      return ApiTriggerType.SCHEDULE;
    case PermissionEntityType.WORKFLOW:
      return ApiTriggerType.WORKFLOW;
    default:
      return null;
  }
};

type TypeLabel = "Application" | "Scheduled Job" | "Integration" | "Workflow";

export const getTypeLabel = (entityType: PermissionEntityType): TypeLabel => {
  switch (entityType) {
    case PermissionEntityType.APPLICATION:
    case PermissionEntityType.APPLICATION_V2:
      return "Application";
    case PermissionEntityType.SCHEDULED_JOB:
      return "Scheduled Job";
    case PermissionEntityType.WORKFLOW:
      return "Workflow";
    case PermissionEntityType.INTEGRATION:
    case PermissionEntityType.DATASOURCE:
      return "Integration";
  }
};

export const getAccessText = (role: RbacRole) => {
  let accessText = "can access";
  if (role === RbacRole.BUILDER) {
    accessText = "can build";
  } else if (role === RbacRole.VIEWER) {
    accessText = "can view";
  }
  return accessText;
};

export function entityCompareFn(
  entity1: EntityToRender | GroupEntityToRender,
  entity2: EntityToRender | GroupEntityToRender,
): number {
  const sortOrder = {
    [PermissionEntityType.APPLICATION]: 0,
    [PermissionEntityType.APPLICATION_V2]: 0,
    [PermissionEntityType.WORKFLOW]: 2,
    [PermissionEntityType.SCHEDULED_JOB]: 3,
    [PermissionEntityType.DATASOURCE]: 4,
    [PermissionEntityType.INTEGRATION]: 5,
  };

  const entity1Order = sortOrder[entity1.type] ?? Object.keys(sortOrder).length;
  const entity2Order = sortOrder[entity2.type] ?? Object.keys(sortOrder).length;
  const typeComp = entity1Order - entity2Order;
  if (typeComp !== 0) {
    return typeComp;
  }
  // TODO: we can remove this fallback check once we assure no entity without name will be returned
  return (entity1.name ?? "").localeCompare(entity2.name ?? "");
}
