import {
  MenuItem,
  Classes,
  Menu,
  MenuDivider,
  MaybeElement,
} from "@blueprintjs/core";
import {
  ItemListRendererProps,
  ItemRendererProps,
  Suggest,
  SelectPopoverProps,
} from "@blueprintjs/select";
import { DropdownOption as DropdownOptionShared } from "@superblocksteam/shared";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import Fuse from "fuse.js";
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import styled, { DefaultTheme } from "styled-components";
import { useCachedValue, useElementRect } from "hooks/ui";
import { colors } from "styles/colors";

const MENU_ITEM_FONT_WEIGHT = 400;
const MENU_ITEM_TITLE_FONT_WEIGHT = 500;
const INPUT_FONT_WEIGHT = 400;
const SUBTEXT_FONT_WEIGHT = 400;
const MAX_RENDER_MENU_ITEMS_HEIGHT = 292;
const EXTRA_PER_ITEM = 2;
const EXTRA_PER_DIVIDER = 4;

export type DropdownOption = DropdownOptionShared & {
  disabled?: boolean;
  icon?: MaybeElement;
  style?: React.CSSProperties;
  dataTest?: string;
};
interface DropdownProps {
  options: DropdownOption[];
  onChange: (selectedOption: DropdownOption) => void;
  placeholder?: string;
  // Must be a controlled value
  value?: string | { key: string; value: any };
  disabled?: boolean;
  ["data-test"]?: string;
  // this decides if we will render the selected option with styles or just plain text as in input element
  // since blueprint Suggest does not support rendering selected option in input, we render the selected option in rightElement and hide the input text if input is not focused.
  renderSelectedOptionWithStyles?: boolean;
  // to render selected option in a custom way
  renderSelectedOption?: (option: DropdownOption) => JSX.Element;
  // disable typing to search;
  disableSearch?: boolean;
  parentRef?: React.RefObject<HTMLElement>;
  style?: React.CSSProperties;
  width?: number;
  resetOnSelect?: boolean;
  resetOnQuery?: boolean;

  itemListPredicate?: (
    query: string,
    items: DropdownOption[],
  ) => DropdownOption[];

  // Wraps the renderOption function used for hovers and selections
  wrapRenderOption?: (
    option: DropdownOption,
    children: JSX.Element,
    extraProps: ItemRendererProps,
  ) => JSX.Element;
  tooltip?: React.ReactNode;
  tooltipPlacement?: TooltipPlacement;
  minDropdownWidth?: { width: number; atLeastTargetWidth: boolean };
  popoverProps?: SelectPopoverProps["popoverProps"];
  popoverContentProps?: SelectPopoverProps["popoverContentProps"];
  menuFooterOptions?: Array<string | JSX.Element>;
}

type Props = DropdownProps;

export const OptionIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: -6px !important;
  padding-right: 3px !important;
  & > img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  & svg {
    width: 16px;
    height: 16px;
  }
`;

const SelectedOptionIconWrapper = styled(OptionIconWrapper)`
  display: flex;
  align-items: center;
  margin-right: 8px !important;
  padding-right: 3px !important;
  & > img {
    width: 16px;
    height: 16px;
  }
`;

const OptionPrefix = styled.div<{
  disabled?: boolean;
  color?: string;
  width?: number;
}>`
  display: inline-block;
  font-weight: ${MENU_ITEM_FONT_WEIGHT};
  color: ${({ disabled, color }) => (disabled ? colors.GREY_300 : color)};
  width: ${({ width }) => (width ? width + "px" : "auto")};
`;

const SelectedOptionPrefix = styled(OptionPrefix)`
  font-weight: ${INPUT_FONT_WEIGHT};
  white-space: pre;
`;

// This is almost entirely copied from our styled DropdownComponent, with a slightly different css transform
const SingleDropDown = Suggest.ofType<DropdownOption>();
const getGreyColor = ({ theme }: { theme: DefaultTheme }) =>
  encodeURIComponent(theme.colors.GREY_300);
const getAccentColor = ({ theme }: { theme: DefaultTheme }) =>
  encodeURIComponent(theme.colors.ACCENT_BLUE_500);

const StyledSingleDropDown = styled(SingleDropDown)<{
  isInvalid?: boolean;
  isTouched?: boolean;
  disableSearch?: boolean;
}>`
  color: ${colors.GREY_700};
  div {
    flex: 1 1 auto;
  }
  span {
    width: 100%;
    position: relative;
  }
  margin-right: 1px;
  ::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' fill='${getGreyColor}'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5a1 1 0 0 0-.7.3L8 8.6 4.7 5.3a1 1 0 0 0-1.4 1.4l4 4c.2.2.4.3.7.3s.5-.1.7-.3l4-4c.2-.2.3-.4.3-.7 0-.5-.4-1-1-1z' /%3E%3C/svg%3E");
    display: block;
    position: absolute;
    cursor: pointer;
    pointer-events: none;
    right: 2px;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 16px;
    // Must be below z-index of popover panels
    z-index: 1;
  }
  &.${Classes.POPOVER_OPEN} {
    ::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' fill='${getAccentColor}'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5a1 1 0 0 0-.7.3L8 8.6 4.7 5.3a1 1 0 0 0-1.4 1.4l4 4c.2.2.4.3.7.3s.5-.1.7-.3l4-4c.2-.2.3-.4.3-.7 0-.5-.4-1-1-1z' /%3E%3C/svg%3E");
      // This is a different transform than the one used in the dropdown widget that users see
      transform: scaleY(-1) translate(-50%, 8px);
    }
  }

  .${Classes.INPUT} {
    cursor: pointer;
    // We selected option with styles in a div by using rightElement in inputProps, we need to show the div and hide the input text
    display: flex;
    width: 100%;
    padding-right: 50px;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    background: white;
    border: 1px solid;
    border-color: ${({ theme, isInvalid, isTouched }) =>
      isInvalid && isTouched ? theme.colors.DANGER : theme.colors.GREY_100};
    border-radius: 4px;
    font-size: 12px;
    min-height: 32px;
    background-color: ${({ disabled, theme }) =>
      disabled ? `${theme.colors.GREY_50} !important` : "white"};
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-right: 30px;

    &:focus {
      border-color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
    }
    &:disabled {
      cursor: not-allowed;
      border-color: ${({ theme }) => theme.colors.GREY_50};
    }
    // We do not show input element, but show the actual rendered option, because we do not need to type
    padding-left: ${({ disableSearch }) =>
      disableSearch ? "0px !important" : "10px"};
  }

  .${Classes.INPUT_ACTION} {
    display: flex;
    pointer-events: none; // to pass down click event on styled input action div to the actual input element to trigger popover
    align-items: center;
    height: calc(100% - 2px);
    background-color: white;
    padding-top: 1px;
    padding-left: 10px;
    font-size: 12px;
    font-size: 12px;
    margin-top: 1px;
    margin-left: 1px;
    width: calc(100% - 2px);
    margin-right: 1px;
    border-radius: 4px;
    & > div {
      display: flex;
    }
  }
`;

const MenuWrapper = styled.div`
  .${Classes.MENU_HEADER} {
    border-top: unset;
  }
  .${Classes.MENU} {
    min-width: 120px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    & li {
      padding: 0px 4px;
    }
  }

  .${Classes.MENU_ITEM} {
    align-items: center;
  }
`;

const rightSubTextParentStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const EmptyMessageWrapper = styled.div`
  padding: 6px 12px;
  color: ${({ theme }) => theme.colors.GREY_500};
`;

const OptionText = styled.div<{ color?: string; fontWeight?: number }>`
  line-height: 16px;
  font-weight: ${({ fontWeight }) => fontWeight || MENU_ITEM_FONT_WEIGHT};
  color: ${({ color }) => color || "inherit"};
`;

const SubText = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) =>
    !disabled ? theme.colors.GREY_500 : "inherit"};
  font-size: 12px;
  font-weight: ${SUBTEXT_FONT_WEIGHT};
  white-space: normal;
  line-height: 16px;
  margin-top: 2px;
`;

const DropdownContext = createContext({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => {},
});

export const useDropdown = () => useContext(DropdownContext);

export const RecommendedSingleDropdown = forwardRef((props: Props, ref) => {
  const {
    placeholder,
    onChange,
    value,
    disabled,
    renderSelectedOptionWithStyles,
    renderSelectedOption,
    disableSearch,
    parentRef,
    style,
    resetOnQuery,
    resetOnSelect,
    width,
    tooltip,
    tooltipPlacement,
    popoverProps,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  // TODO alex: Without this useCachedValue, the items in the virtuso list get remounted when focus changes
  // Figure out why and fix the source
  const options = useCachedValue(props.options);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRect = useElementRect(wrapperRef);
  const filteredItemsRef = useRef<DropdownOption[]>([]);

  const listRef = useRef<VirtuosoHandle>(null);
  const [listHeight, setListHeight] = useState(
    MAX_RENDER_MENU_ITEMS_HEIGHT + EXTRA_PER_ITEM,
  );

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const selectedOption = useMemo(() => {
    const selectedIndex = options.findIndex((o) => {
      const valueHasKey = value && typeof value === "object" && "key" in value;
      if (valueHasKey) {
        return String(o.key) === String(value.key);
      }
      return String(o.value) === String(value);
    });
    if (selectedIndex !== -1) {
      setActiveItemIndex(selectedIndex);
      return options?.[selectedIndex];
    }
    return null;
  }, [options, value]);

  const itemListPredicate = useCallback(
    (query: string, items: DropdownOption[]) => {
      const fuse = new Fuse(items, {
        shouldSort: true,
        threshold: 0.3,
        ignoreLocation: true,
        minMatchCharLength: 1,
        findAllMatches: true,
        keys: ["displayName", "value", "groupName", "prefixText"],
      });
      const filteredItems =
        query && query.length > 1
          ? fuse.search(query).map(({ item }) => item)
          : items;
      filteredItemsRef.current = filteredItems;
      return filteredItems;
    },
    [],
  );

  const itemRenderer = useCallback(
    (option: DropdownOption, itemProps: ItemRendererProps) => {
      if (!itemProps.modifiers.matchesPredicate) {
        return null;
      }
      if (option.isGroupHeader) {
        return <MenuDivider title={option.displayName} />;
      }

      const isSelected = option.value === selectedOption?.value;

      const wrapRenderOption = props.wrapRenderOption ?? ((_o, c) => c);
      const item = (
        <>
          <MenuItem
            icon={option.icon}
            className={`single-select ${isSelected ? " selected" : ""}`}
            style={{
              height: "fit-content",
              minHeight: "32px",
              ...option.style,
            }}
            active={itemProps.modifiers.active}
            disabled={option.disabled}
            key={option.value}
            onClick={itemProps.handleClick}
            text={
              <div
                data-test={option.dataTest ?? `dropdown-select-${option.value}`}
                style={
                  option.subTextPosition === "right"
                    ? rightSubTextParentStyle
                    : {}
                }
              >
                {option?.prefixText && (
                  <OptionPrefix
                    disabled={disabled}
                    color={option.prefixColor}
                    width={option.prefixWidth}
                  >
                    {option.prefixText}&nbsp;
                  </OptionPrefix>
                )}
                <OptionText
                  color={option.textColor}
                  fontWeight={
                    option.subText
                      ? MENU_ITEM_TITLE_FONT_WEIGHT
                      : MENU_ITEM_FONT_WEIGHT
                  }
                >
                  {option.displayName ?? ""}
                </OptionText>
                {option.subText && (
                  <SubText disabled={option.disabled}>{option.subText}</SubText>
                )}
              </div>
            }
          />
          {option.hasDivider && (
            <div
              style={{
                borderTop: `1px solid ${colors.GREY_100}`,
                margin: "4px 0",
              }}
            />
          )}
        </>
      );
      return wrapRenderOption(option, item, itemProps);
    },
    [disabled, props.wrapRenderOption, selectedOption?.value],
  );

  const numOfDivider = options.filter((option) => option.hasDivider).length;

  const itemListRender = useCallback(
    ({
      filteredItems,
      itemsParentRef,
      renderItem,
    }: ItemListRendererProps<DropdownOption>) => {
      const itemsToRender: Array<
        | {
            type: "option";
            item: DropdownOption;
          }
        | { type: "footer-option"; item: JSX.Element | string }
      > = [
        ...filteredItems.map((item) => ({
          item,
          type: "option" as const,
        })),
        ...(props.menuFooterOptions ?? []).map((item) => ({
          item,
          type: "footer-option" as const,
        })),
      ];

      const shouldMatchTargetWidth =
        Boolean(popoverProps?.matchTargetWidth ?? true) ||
        props.minDropdownWidth?.atLeastTargetWidth;

      const menuStyle: React.CSSProperties = {
        width: shouldMatchTargetWidth ? wrapperRect?.width ?? "100%" : "100%",
        minWidth: props.minDropdownWidth?.width,
        padding: 0,
      };

      if (!filteredItems?.length) {
        const noResultsMessage = "No results found";
        return (
          <MenuWrapper>
            <Menu ulRef={itemsParentRef} style={menuStyle}>
              <EmptyMessageWrapper key="empty-result">
                {noResultsMessage}
              </EmptyMessageWrapper>
            </Menu>
          </MenuWrapper>
        );
      }

      return (
        <MenuWrapper>
          <Menu ulRef={itemsParentRef} style={menuStyle}>
            <Virtuoso
              ref={listRef}
              data={itemsToRender}
              totalListHeightChanged={(height) => {
                const itemCountSpacing = itemsToRender.length * EXTRA_PER_ITEM;
                const totalHeight =
                  itemCountSpacing + height + numOfDivider * EXTRA_PER_DIVIDER;
                setListHeight(
                  totalHeight < MAX_RENDER_MENU_ITEMS_HEIGHT
                    ? totalHeight
                    : MAX_RENDER_MENU_ITEMS_HEIGHT,
                );
              }}
              style={{
                width: "100%",
                height: listHeight,
              }}
              itemContent={(index, data) => {
                if (data.type === "footer-option") {
                  return data.item;
                }
                return renderItem(data.item, index);
              }}
            ></Virtuoso>
          </Menu>
        </MenuWrapper>
      );
    },
    [
      listHeight,
      numOfDivider,
      wrapperRect?.width,
      popoverProps?.matchTargetWidth,
      props.menuFooterOptions,
      props.minDropdownWidth,
    ],
  );

  const handleActiveItemChange = useCallback(
    (activeItem: DropdownOption | null) => {
      // find new index from options
      const newActiveIndex = options.findIndex(
        (option) => option.value === activeItem?.value,
      );

      // Update state.activeItemIndex if activeItem is different from the current value
      if (activeItem?.value !== options[activeItemIndex]?.value) {
        setActiveItemIndex(newActiveIndex);

        // Scroll newly active item into view
        // scrollToItem only scrolls minimum needed amount, so won't scroll
        // if item is already in view

        // virtuoso does not have full content of the entire results set, just the
        // filtered results, so we need to use the filtered results set when looking
        // for which item index to scroll to rather than the entire set
        const scrollToActiveItemIndex = (
          filteredItemsRef.current || options
        ).findIndex((option) => option.value === activeItem?.value);

        listRef.current?.scrollToIndex(scrollToActiveItemIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeItemIndex, options],
  );

  const onItemSelect = useCallback(
    (selectedItem: DropdownOption) => {
      onChange(selectedItem);
      setIsOpen(false);
    },
    [onChange],
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
    setIsOpen(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    // Timeout to reduce flickering caused by focus state changes before selected option update.
    setTimeout(() => setIsEditing(false), 200);
  }, []);

  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
      <div ref={wrapperRef} style={style}>
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          <div
            style={{ position: "relative", ...(width ? { width } : {}) }}
            data-test={props["data-test"]}
          >
            <StyledSingleDropDown
              items={options ?? []}
              itemsEqual={"value"}
              disabled={disabled}
              resetOnSelect={resetOnSelect ?? false}
              resetOnQuery={resetOnQuery ?? false}
              selectedItem={selectedOption}
              inputValueRenderer={(item) =>
                typeof item.displayName === "string"
                  ? `${item.prefixText ? `${item.prefixText} ` : ``}${
                      item.displayName
                    }`
                  : item.value
              }
              itemListRenderer={itemListRender}
              itemRenderer={itemRenderer}
              onItemSelect={onItemSelect}
              disableSearch={disableSearch}
              inputProps={{
                // this just disables the ability to type into the input, but the dropdown still opens and works as expected
                readOnly: disableSearch,
                placeholder,
                style: { paddingLeft: "0px !important" },
                onFocus: () => handleEditClick(),
                rightElement:
                  // this is a hack we render selected option using rightElement
                  renderSelectedOption &&
                  selectedOption &&
                  // we only render customized element instead of input if user is not typing or if typing is disabled
                  (!isEditing || disableSearch) ? (
                    renderSelectedOption(selectedOption)
                  ) : renderSelectedOptionWithStyles && !isEditing ? (
                    <div>
                      {selectedOption?.icon && (
                        <SelectedOptionIconWrapper>
                          {selectedOption.icon}
                        </SelectedOptionIconWrapper>
                      )}
                      {selectedOption?.prefixText && (
                        <SelectedOptionPrefix
                          disabled={disabled}
                          color={selectedOption?.prefixColor}
                        >
                          {`${selectedOption.prefixText}`}&nbsp;
                        </SelectedOptionPrefix>
                      )}
                      <span
                        style={{
                          fontWeight: INPUT_FONT_WEIGHT,
                          color: disabled
                            ? colors.GREY_300
                            : selectedOption?.textColor ?? colors.GREY_500,
                        }}
                      >
                        {selectedOption?.displayName ?? ""}
                      </span>
                    </div>
                  ) : undefined,
                onBlur: handleInputBlur,
              }}
              popoverProps={{
                // TODO(wylie)
                // fill: true,
                minimal: true,
                usePortal: true,
                matchTargetWidth: true,
                popoverClassName: "select-popover-wrapper",
                // Allow dropdown to overflow its container and placed based on viewport
                rootBoundary: "viewport",
                hasBackdrop: true,
                portalContainer: parentRef?.current ?? undefined,
                onInteraction: (nextOpenState) => {
                  setIsOpen(nextOpenState);
                },
                isOpen,
                ...(disabled ? { isOpen: false } : {}),
                ...(props.popoverProps ?? {}),
              }}
              popoverContentProps={props.popoverContentProps ?? {}}
              itemListPredicate={props.itemListPredicate ?? itemListPredicate}
              onActiveItemChange={handleActiveItemChange}
            />
          </div>
        </Tooltip>
      </div>
    </DropdownContext.Provider>
  );
});

RecommendedSingleDropdown.displayName = "RecommendedSingleDropdown";
